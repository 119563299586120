import { useState } from "react";
import Header from "./Header";
import "./Css/LoggedInHome.css";
import { Link, NavLink } from "react-router-dom";
import pen from "../Assets/Images/penIcon.svg";
import homeIcon from "../Assets/Images/HomeIcon.svg";
import mydesigns from "../Assets/Images/myDesignsIcon.svg";
import myLogo from "../Assets/Images/myLogoIcon.svg";
import profile from "../Assets/Images/profileImg.svg";
import { useNavigate } from "react-router-dom";
import SubscriptionModal from "./SubscriptionModal.js";
import {
  retrieveData,
  removeData,
} from "../LocalConnection/LocalConnection.js";
const TabsHeader = () => {
  const subscription_active = retrieveData("subscription_active");
  const brand_identity = retrieveData("brand_identity");
  const brandkit_templates = retrieveData("brandkit_templates");
  const full_name = retrieveData("full_name");
  const package_name = retrieveData("package_name");
  const navigate = useNavigate();

  const [updateModal, setupdateModal] = useState(false);
  const updateModalshow = () => {
    setupdateModal(true);
  };

  const handleClick = (route) => {
    const business_card = localStorage.getItem("business_card");
    console.log(route);
    console.log(business_card);

    if (business_card === "Business cards") {
      navigate(route);
    } else {
      updateModalshow(true);
    }
  };

  return (
    <div>
      <div className="profileSection">
        {subscription_active == 1 && (
          <>
            {brandkit_templates === "" ? (
              <img
                className="BrandKitimg"
                src={profile}
                alt="profile"
                id="profileImg"
              />
            ) : (
              <img
                className="BrandKitimg"
                src={brandkit_templates}
                alt="profile"
                id="profileImg"
              />
            )}

            <div className="profile_name_container">
              <h6>{full_name}</h6>
              {package_name != "undefined" && <desc>{package_name}</desc>}
            </div>
            {subscription_active == 1 && brand_identity != "" && (
              <div
                className="brand_kit_button home_brandKit_button"
                onClick={() => handleClick("/BrandKit")}
              >
                <img src={pen} alt="penicon" />
                Brand Kit
              </div>
            )}
          </>
        )}{" "}
      </div>

      <div className="tabss">
        <NavLink to="/home" activeClassName="active" exact>
          <img src={homeIcon} alt="homeicon " /> Home
        </NavLink>
        <NavLink to="/mylogo" activeClassName="active">
          <img src={myLogo} alt="homeicon " /> My Logos
        </NavLink>
        <NavLink to="/mydesigns" activeClassName="active">
          <img src={mydesigns} alt="homeicon " /> My Design
        </NavLink>
      </div>
      <SubscriptionModal
        handleModal={setupdateModal}
        updateModal={updateModal}
      />
    </div>
  );
};

export default TabsHeader;
