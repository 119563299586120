import "./Css/continuebutton.css";

const ContinueButtonLoader = () => {
  return (
    <div>
      <div className="container">
        <div className="stage">
          <div className="dot-pulse"></div>
        </div>
      </div>
    </div>
  );
};

export default ContinueButtonLoader;
