import React, { useRef, useEffect, useState } from "react";

import "./Css/AboutCanvas.css";
import Rectangle_108 from "../Assets/Images/Rectangle_108.png";
import { fabric } from "fabric";
import {
  server_post_data,
  get_home_data,
} from "../ServiceConnection/serviceconnection.js";
import { retrieveData } from "../LocalConnection/LocalConnection.js";
import { handleError } from "../CommonJquery/CommonJquery.js";
const AboutCanvas = ({ isOpenAbout2, data }) => {
  const [canvasData, setCanvasData] = useState(null);
  const [colorPalette, setColorPalette] = useState([]);
  const [logoFonts, setlogoFonts] = useState([]);
  let newcanvas;
  let refdonwload;
  let canvas_temp_download;
  ///console.log(canvasData);
  useEffect(() => {
    if (isOpenAbout2) {
      setCanvasData(data);
    } else {
      setCanvasData(null);
      setColorPalette([]);
      setlogoFonts([]);
    }
  }, [isOpenAbout2]);

  let imageDetails = [
    { label: "Transparent background", size: "200 * 200", color: "" },
    { label: "Black background", size: "200 * 200", color: "Black" },
    { label: "White background", size: "200 * 200", color: "White" },
    { label: "GrayScale", size: "200 * 200", color: "Grey" },
  ];

  const getFillColorFromSVG = (svgData) => {
    // Create a DOM parser to parse the SVG string
    const parser = new DOMParser();
    const doc = parser.parseFromString(svgData, "image/svg+xml");

    // Get the <svg> element
    const svgElement = doc.querySelector("svg");

    // Return the fill color from the <svg> element
    return svgElement ? svgElement.getAttribute("fill") : null;
  };
  useEffect(() => {
    if (canvasData) {
      //console.log(data);
      let canvaschng = 0;
      canvasData._objects.forEach(async (object, index) => {
        if (object.type === "image") {
          canvaschng++;
          let svgDataUrl = object.src;
          if (!svgDataUrl) {
            svgDataUrl = object.getSrc();
          }

          try {
            const response = await fetch(svgDataUrl); // Use await here
            const svgData = await response.text();
            const fillColor = await getFillColorFromSVG(svgData); // Ensure this function is defined
            //console.log(fillColor);
            setColorPalette((prevColors) => [...prevColors, fillColor]);
          } catch (error) {
            console.error("Error fetching SVG data:", error);
          }
        }
        // Check if the object is of type 'text', 'i-text', or 'textbox'
        else if (
          object.type === "text" ||
          object.type === "i-text" ||
          object.type === "textbox"
        ) {
          canvaschng++;

          if (object.text) {
            console.log(object.text);
            console.log(object.fill);
            let colors;
            if(object.color){
              colors = object.color
              
            }else{
              colors = object.fill
            }
            
            console.log(colors);
            setColorPalette((prevColors) => [...prevColors, colors]);
            console.log(object.fontFamily);
            setlogoFonts((prevColors) => [...prevColors, object.fontFamily]);
          }
        } else {
          // console.log(`Object ${index} is of type: ${object.type}.`); // Logs other types if present
        }
      });
      console.log(canvaschng);
    }
  }, [data]);

  const templateparseGradient = (gradientString) => {
    const gradientRegex = /linear-gradient\(([^,]+),(.+)\)/;
    const matches = gradientString.match(gradientRegex);

    if (matches) {
      const direction = matches[1].trim();
      const colors = matches[2].split(",").map((color) => color.trim());
      return { direction, colors };
    }

    // Default case if regex does not match (fallback)
    return { direction: "to right", colors: [gradientString] };
  };

  const setCanvasBackground = (canvas, background) => {
    if (background.startsWith("linear-gradient")) {
      const { direction, colors } = templateparseGradient(background);

      let x1 = 0,
        y1 = 0,
        x2 = 0,
        y2 = 0;
      if (direction.includes("to right")) {
        x2 = canvas.width;
      } else if (direction.includes("to bottom")) {
        y2 = canvas.height;
      } else if (direction.includes("to left")) {
        x1 = canvas.width;
      } else if (direction.includes("to top")) {
        y1 = canvas.height;
      }

      const gradient = new fabric.Gradient({
        type: "linear",
        gradientUnits: "pixels",
        coords: { x1, y1, x2, y2 },
        colorStops: colors.map((color, index) => ({
          offset: index / (colors.length - 1),
          color,
        })),
      });

      canvas.setBackgroundColor(gradient, canvas.renderAll.bind(canvas));
    } else {
      canvas.setBackgroundColor(background, canvas.renderAll.bind(canvas));
    }
  };

  const [rangeValue, setRangeValue] = useState("200");
  const createCanvasnew = (ref, logoData) => {
    return new Promise((resolve) => {
      if (!ref || !logoData) return;

      const originalWidth = 400;
      const originalHeight = 400;
      const scaleFactor = 200 / Math.max(originalWidth, originalHeight);

      const canvas_temp = new fabric.Canvas(ref, {
        width: 200,
        height: 200,
        backgroundColor: logoData.background,
      });

      // const scaleFactorWidth = canvasWidth / canvasOrginalWidth;
      // const scaleFactorHeight = canvasHeight / canvasOrginalHeight;
      // const scaleFactor = Math.min(scaleFactorWidth, scaleFactorHeight);

      const renderNextObject = async (index) => {
        if (index >= logoData.objects.length) {
          resolve(canvas_temp); // Resolve the promise when rendering is complete
          return;
        }
        const objData = logoData.objects[index];
        if (objData.type === "i-text" && objData.metadata) {
          const customWidth = objData.customWidth || objData.width;
          objData.customWidth = customWidth;
        }

        const scaledObject = {
          ...objData,
          scaleX: (objData.scaleX || 1) * scaleFactor,
          scaleY: (objData.scaleY || 1) * scaleFactor,
          left: (objData.left || 0) * scaleFactor,
          top: (objData.top || 0) * scaleFactor,
        };

        fabric.util.enlivenObjects([scaledObject], (objects) => {
          objects.forEach((obj) => {
            obj.selectable = false;
            if (obj.type === "i-text") {
              const customWidth = obj.customWidth || obj.width;
              obj.set({ textAlign: obj.textAlign });
              obj.set({
                width: customWidth,
              });
            }
            canvas_temp.add(obj);
          });
          canvas_temp.requestRenderAll();
          renderNextObject(index + 1);
        });
      };

      renderNextObject(0);
    });
    //return canvas_temp;
  };

  let canvasRefs = useRef([]);
  const handleCanvasRef = (refnity, logoData, index, color) => {
    if (canvasData) {
      //if (refnity && !canvasRefs.current[index]) {
      //console.log(logoData)
      if (logoData != null) {
        console.log(logoData);
        //console.log(logoData._objects.length);
        if (logoData._objects) {
          if (logoData._objects.length > 0) {
            // Set initial loader state
            //console.log(logoData);

            const data = logoData.getObjects().map((obj) => {
              let objData = obj.toObject();
              objData.flag = 0; // Append flag 0
              objData.color = obj.fill || "";
              objData.customWidth = obj.customWidth || obj.width;
              // Log customWidth to console

              // Add mergedFonts to each object and include font source
              return objData;
            });
            const canvasData = {
              objects: data,
              background: color,
              width: logoData.width,
              height: logoData.height,
            };
            //console.log(canvasData);
            canvasRefs.current[index] = refnity;

            try {
              createCanvasnew(refnity, canvasData);
            } catch (error) {
              console.error("Error creating canvas:", error);
            }
          }
        }
      }
    }
  };
  return (
    <div>
      <div className="container">
        <div className="row">
          <div className="col">
            <div className="LogoVersitions">
              <label className="logoTxt">Logo Versions</label>
              <div className="imgContainer">
                {imageDetails &&
                  imageDetails.map((img, index) => (
                    <div className="imgcont" key={index}>
                      {/* <img src={Rectangle_108} alt={`Rectangle_108_${index}`} /> */}
                      <canvas
                        ref={(refnity) =>
                          handleCanvasRef(refnity, data, index, img.color)
                        }
                      />
                      <div className="Content">
                        <label>{img.label}</label>
                        <p>{img.size}</p>
                      </div>
                    </div>
                  ))}
              </div>

              <div className="colorcont">
                <label className="logoTxt2">Color Palette</label>
                <div className="colorCOntainer">
                  {colorPalette.map((color, index) => (
                    color ? (  // Check if color is truthy
                      <div className="contInerCOlor" key={index}>
                        <button
                          className="colorBOx"
                          style={{ backgroundColor: color }}
                        ></button>
                        <label>{color}</label>
                      </div>
                    ) : null // Return null if color is falsy
                  ))}
                </div>

              </div>

              <div className="LogoFOnts">
                <label className="logoTxt2">Logo Fonts</label>

                {logoFonts.map((font, index) => (
                  <label className={`FontFamily${index + 1}`} key={index}>
                    {font}
                  </label>
                ))}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default AboutCanvas;
