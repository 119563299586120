import image2 from "../Assets/Images/logoImg.png";
import image3 from "../Assets/Images/LetterHeadImg.png";
import image4 from "../Assets/Images/EnvelopImg.png";
import image5 from "../Assets/Images/BusinessCard.png";
import image6 from "../Assets/Images/InvitationCard.png";
import crown from "../Assets/Images/crown.svg";
import { retrieveData } from "../LocalConnection/LocalConnection";
//changePassward
const changePasswardPage = {
  Error_massage:
    "Password must contain at least one uppercase letter, one lowercase letter, one number, one special character, and should be 8 characters long.",
};

const languages = [
  { value: "English", label: "En" },
  { value: "Swedish", label: "Sw" },
  { value: "Русский", label: "Ру" },
  { value: "Italiano", label: "It" },
];

const premium_features_images = [
  { image: image2, text: "Logos", icon: crown },
  { image: image3, text: "LetterHeads", icon: crown },
  { image: image4, text: "Envelope", icon: crown },
  { image: image5, text: "Businesscards", icon: crown },
  { image: image6, text: "Invitation Card", icon: crown },
];

const data_logo_ideas = [
  "Abstract",
  "Accessories",
  "Accounting",
  "Advertising",
  "Agriculture",
  "Airline",
  "Animals",
  "App",
  "Apparel",
  "Boutique",
  "Business",
  "Cafe",
  "Car",
  "CarGarage",
  "Carwash",
  "Catering",
  "CarGarage",
  "Carwash",
  "Catering",
  "Charity",
];

const website_data = {
  Email_id: "logoacademy01@gmail.com",
};

const cardDetails = [
  {
    title: "Red",
    description: "Bold, passionate, energetic, confident, vibrant, strong",
  },
  {
    title: "Green",
    description: "Natural, refreshing, growth, harmonious, peaceful, growing",
  },
  {
    title: "Black",
    description: "Classic, versatile, modern, elegant, refined",
  },
  {
    title: "Orange",
    description: "Sunny, cheerful, friendly, youth, warm, positivity",
  },
  {
    title: "Teal",
    description: "Fresh, Tropical, Inspiration, stylish, unique liverly",
  },
  {
    title: "Yellow",
    description: "Optimistic, bright, happy, joyful, energizing",
  },
  {
    title: "Pink",
    description:
      "Fresh, bright, happy, joyful, energizing, youth, love, tenderness",
  },
  {
    title: "Purple",
    description:
      "Royal, creativity, mysterious, luxurious, rich, friendly, Optimistic",
  },
  {
    title: "Blue",
    description: "Calming, reliable, smart, harmonious, confidence, trust",
  },
];

const suggestionsLinks = retrieveData("related_industry");
// [
//   { label: "Suggested" },
//   { label: "Farm" },
//   { label: "Book" },
//   { label: "Pen" },
//   { label: "Hat" },
//   { label: "Crown" },
//   { label: "Watch" },
//   { label: "Tea" },
//   { label: "Cap" },
//   { label: "Abstract" },
//   { label: "Minimal" },
//   { label: "Letter " },
//   { label: "Elegant" },
//   { label: "Jewellery" },
//   { label: "3D" },
//   { label: "Farm" },
//   { label: "Cycle" },
// ];

const svgRotateIcon = encodeURIComponent(`
  <svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
    <g filter="url(#filter0_d)">
      <circle cx="9" cy="9" r="5" fill="white"/>
      <circle cx="9" cy="9" r="4.75" stroke="#9b57ff" stroke-opacity="0.3" stroke-width="0.5"/>
    </g>
    <path d="M10.8047 11.1242L9.49934 11.1242L9.49934 9.81885" stroke="#9b57ff" stroke-width="0.5" stroke-linecap="round" stroke-linejoin="round"/>
    <path d="M6.94856 6.72607L8.25391 6.72607L8.25391 8.03142" stroke="#9b57ff" stroke-width="0.5" stroke-linecap="round" stroke-linejoin="round"/>
    <path d="M9.69517 6.92267C10.007 7.03301 10.2858 7.22054 10.5055 7.46776C10.7252 7.71497 10.8787 8.01382 10.9517 8.33642C11.0247 8.65902 11.0148 8.99485 10.9229 9.31258C10.831 9.63031 10.6601 9.91958 10.4262 10.1534L9.49701 11.0421M8.25792 6.72607L7.30937 7.73554C7.07543 7.96936 6.90454 8.25863 6.81264 8.57636C6.72073 8.89408 6.71081 9.22992 6.78381 9.55251C6.8568 9.87511 7.01032 10.174 7.23005 10.4212C7.44978 10.6684 7.72855 10.8559 8.04036 10.9663" stroke="#9b57ff" stroke-width="0.5" stroke-linecap="round" stroke-linejoin="round"/>
    <defs>
      <filter id="filter0_d" x="0" y="0" width="18" height="18" filterUnits="userSpaceOnUse" color-interpolation-filters="sRGB">
        <feFlood flood-opacity="0" result="BackgroundImageFix"/>
        <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"/>
        <feOffset/>
        <feGaussianBlur stdDeviation="2"/>
        <feColorMatrix type="matrix" values="0 0 0 0 0.137674 0 0 0 0 0.190937 0 0 0 0 0.270833 0 0 0 0.15 0"/>
        <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow"/>
        <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow" result="shape"/>
      </filter>
    </defs>
</svg>

  `);
const rotateIcon = `data:image/svg+xml;utf8,${svgRotateIcon}`;
const imgIconRotate = document.createElement("img");
imgIconRotate.src = rotateIcon;
export {
  changePasswardPage,
  languages,
  premium_features_images,
  data_logo_ideas,
  website_data,
  cardDetails,
  suggestionsLinks,
  imgIconRotate,
};
