import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import Delet from "../Assets/Images/deletWhite.png";
import "../Components/Css/ChooseIcons.css";
import searchIcon from "../Assets/Images/searchIcon.svg";
import Header from "./Header";
import {
  get_icon_img,
  server_post_data,
} from "../ServiceConnection/serviceconnection.js";

import { handleError } from "../CommonJquery/CommonJquery.js";
import { retrieveData, storeData } from "../LocalConnection/LocalConnection.js";
import LoderIcon from "./IconLoader.js";
import { useNavigate } from "react-router-dom";
import ContinueButtonLoader from "./ContinueButtonLoader.js";
let next_page = "";
let nextdata = true;
let iconselect;
let sesionclr = 0;
const ChooseIcons = () => {
  const companyName = retrieveData("companyName");
  const slogan = retrieveData("slogan");
  const [searchTerm, setSearchTerm] = useState("");
  const [GetIcon, SetIconImg] = useState([]);
  const [selectedSVGs, setSelectedSVGs] = useState([]);
  const [isActive, setIsActive] = useState(false);
  const [showLoaderAdmin, setshowLoaderAdmin] = useState(false);
  const [iconsRelated, seticonsRelated] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const navigate = useNavigate();
  let industry = retrieveData("selectedIndustry");
  useEffect(() => {
    searchIcons(0, "");
    const suggestionsLinks = retrieveData("related_industry");
    let related_suggested = "";
    if (suggestionsLinks != "null") {
      const elements =
        suggestionsLinks && typeof suggestionsLinks === "string"
          ? suggestionsLinks.split(",")
          : [];
      const elements2 =
        suggestionsLinks && typeof suggestionsLinks === "string"
          ? suggestionsLinks.replace(",", " ")
          : [];
      related_suggested = elements2;
      const resultArray = elements.map((element) => ({ label: element }));
      seticonsRelated(resultArray);
    }
    // Retrieve selected icons from local storage on component mount
    let selectedIconsFromStorage;
    if (retrieveData("selectedIcons")) {
      selectedIconsFromStorage = JSON.parse(retrieveData("selectedIcons"));
    }
    if (selectedIconsFromStorage) {
      setSelectedSVGs(selectedIconsFromStorage);
    }
  }, []);

  // window.addEventListener("beforeunload", function (event) {
  //   if (window.location.pathname === "/chooseIcons") {
  //     sessionStorage.setItem("reloadAttempt", "true");
  //     event.returnValue = "Are you sure you want to leave?";
  //   }
  // });

  // // On page load
  // window.addEventListener("load", function () {
  //   if (window.location.pathname === "/chooseIcons") {
  //     const reloadAttempt = sessionStorage.getItem("reloa  dAttempt");
  //     const navEntries = performance.getEntriesByType("navigation");
  //     if (
  //       navEntries.length > 0 &&
  //       window.location.pathname === "/chooseIcons"
  //     ) {
  //       const navType = navEntries[0].type;

  //       if (reloadAttempt || navType === "reload") {
  //         console.log(
  //           "The page was reloaded (Ctrl+R, refresh button, or Enter in the URL bar)."
  //         );
  //         // Clear sessionStorage flag after detecting the reload
  //         sessionStorage.removeItem("reloadAttempt");
  //         storeData("companyName", "");
  //         storeData("card_colors", "");
  //         storeData("selectedIcons", "");
  //         storeData("slogan", "");
  //         storeData("related_industry", null);
  //         storeData("industry", null);
  //         storeData("selectedIndustry", "");
  //         sesionclr = 0;
  //         navigate("/createlogohome");
  //       }
  //     }
  //   }
  // });

  const handleDelete = (index) => {
    const updatedSVGs = [...selectedSVGs];
    updatedSVGs.splice(index, 1);
    setSelectedSVGs(updatedSVGs);
    storeData("selectedIcons", JSON.stringify(updatedSVGs));
  };

  const handleClick = (index) => {
    const selectedSVG = GetIcon[index];
    const selectedIndex = selectedSVGs.findIndex(
      (svg) => svg.thumbnail_url === selectedSVG.thumbnail_url
    );

    if (selectedIndex === -1) {
      if (selectedSVGs.length < 5) {
        let svgDataUrl = selectedSVG.icon_url;

        // Ensure the URL is valid
        if (svgDataUrl) {
          fetch(svgDataUrl)
            .then((response) => {
              if (!response.ok) {
                throw new Error("Network response was not ok");
              }
              return response.text();
            })
            .then((svgData) => {
              const base64Svg = btoa(unescape(encodeURIComponent(svgData)));
              const dataUrl = "data:image/svg+xml;base64," + base64Svg;
              iconselect = dataUrl;
              const updatedSelectedSVGs = [
                ...selectedSVGs,
                {
                  id: selectedSVG.id,
                  thumbnail_url: selectedSVG.thumbnail_url,
                  icon_url: selectedSVG.icon_url,
                  iconselect: iconselect,
                },
              ];
              setSelectedSVGs(updatedSelectedSVGs);
              storeData("selectedIcons", JSON.stringify(updatedSelectedSVGs));
            })
            .catch((error) => {
              console.error("Failed to fetch SVG:", error);
            });
        } else {
          console.warn("No valid URL found for SVG data");
        }
      }
    } else {
      const updatedSelectedSVGs = [...selectedSVGs];
      updatedSelectedSVGs.splice(selectedIndex, 1);
      setSelectedSVGs(updatedSelectedSVGs);
      storeData("selectedIcons", JSON.stringify(updatedSelectedSVGs));
    }

    if (selectedSVGs.length >= 5) {
      setIsActive(null);
    }
  };

  const searchIcons = async (
    click_type = 0,
    search_data = "",
    loadmore = "0"
  ) => {
    const formData = new FormData();
    const inputElement = document.querySelector(".inputkeypick");
    const values = inputElement.value;
    if (!values) {
      if (industry) {
        const industry2 = JSON.parse(industry);
        formData.append("query", industry2.label);
      } else {
        formData.append("query", "software");
      }
    } else {
      formData.append("query", values);
      //formData.append("query", "software");
    }
    //console.log(search_data);
    formData.append("companyName", companyName);
    formData.append("slogan", slogan);
    formData.append("limit_to_public_domain", 1);
    formData.append("thumbnail_size", 30);
    formData.append("limit", 20);
    formData.append("click_type", click_type);
    if (loadmore == "loadmore") {
      formData.append("next_page", next_page);
    } else {
      formData.append("next_page", "");
      SetIconImg([]);
    }
    console.log("yes come");
    console.log(nextdata);
    formData.append("include_svg", 1);
    if (nextdata) {
      setshowLoaderAdmin(true);
      await server_post_data(get_icon_img, formData)
        .then((Response) => {
          console.log(" come by shubhs");
          console.log(Response.data.message);
          if (Response.data.error) {
            handleError(Response.data.message);
          } else {
            console.log(Response.data.message.icondata.icons.length);
            console.log(next_page);
            if (!next_page && loadmore == "0") {
              if (Response.data.message.icondata.icons.length < 1) {
                industry = "";
                searchIcons();
              }
            }
            if (loadmore == "loadmore") {
              SetIconImg((prevIcons) => [
                ...prevIcons,
                ...Response.data.message.icondata.icons,
              ]);
            } else {
              SetIconImg(Response.data.message.icondata.icons);
            }
            if (Response.data.message.related_words.length > 0) {
              seticonsRelated(Response.data.message.related_words);
            }
            next_page = Response.data.message.icondata.next_page;
            if (Response.data.message.icondata.icons.length < 10) {
              nextdata = false;
            }
          }
          setshowLoaderAdmin(false);
        })
        .catch((error) => {
          console.log("ssss");
          handleError("network");
          setshowLoaderAdmin(false);
        });
    }
  };

  const handleButtonClick = (index) => {
    nextdata = true;
    setIsActive(index);
    setSearchTerm(iconsRelated[index].label);
    searchIcons(1, iconsRelated[index].label);
  };

  const handleSearch = () => {
    nextdata = true;
    if (searchTerm != "") {
      searchIcons(1, searchTerm);
    }
  };

  const handleKeyDown = (event) => {
    if (event.key === "Enter") {
      nextdata = true;
      if (searchTerm !== "") {
        searchIcons(1, searchTerm);
      }
    }
  };
  const scrollToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  };
  const handleClickLoader = () => {
    setIsLoading(true);
    setTimeout(() => {
      setIsLoading(false);
      navigate("/showLogos");
    }, 400);
    scrollToTop();
  };

  useEffect(() => {
    const gridContainer = document.querySelector(
      ".grid_container2.chooseImgIcone"
    );

    const handleScroll = () => {
      if (
        gridContainer.scrollHeight - gridContainer.scrollTop ===
        gridContainer.clientHeight
      ) {
        // The user has scrolled to the bottom
        handleBottomScroll();
      }
    };

    gridContainer.addEventListener("scroll", handleScroll);
    return () => {
      gridContainer.removeEventListener("scroll", handleScroll);
    };
  }, []);

  const handleBottomScroll = () => {
    //console.log("Scrolled to the bottom!");
    searchIcons("1", searchTerm, "loadmore");
    // Add your function logic here
  };
  return (
    <>
      <Header Showheader={false} />
      <section className="chooseIcons_section">
        <div className="container-lg d-flex flex-column align-items-center h-100">
          <div className="col-lg-7 col-md-9">
            <div className="heading_section mb-3 m-auto">
              <h3 className="mb-0">Choose icons for logo</h3>
              <h6>You can choose up to 5 icons for your logo</h6>
            </div>
            <div className="container">
              {" "}
              <div className="all_wrapper" style={{ marginBottom: "1rem" }}>
                <div className="searchBar_wrapper">
                  <div className="input_wrapper d-flex align-items-center">
                    <img
                      src={searchIcon}
                      alt="Search"
                      className="search_icon"
                    />
                    <input
                      type="text"
                      className="search_input inputkeypick"
                      placeholder="Icon by Keywords..."
                      value={searchTerm}
                      onChange={(event) => setSearchTerm(event.target.value)}
                      onKeyDown={handleKeyDown}
                    />
                    <button
                      className={`searchBtn SerchBBBttnn ${
                        searchTerm ? "" : "disabled"
                      }`}
                      type="submit"
                      onClick={handleSearch}
                    >
                      Search
                    </button>
                  </div>
                  <div className="placeHolders_wrapper">
                    {[...Array(5)].map((_, index) => (
                      <div key={index} className="logo_placeholder">
                        {index < selectedSVGs.length ? (
                          <img
                            src={selectedSVGs[index].thumbnail_url}
                            alt={`Selected SVG ${index + 1}`}
                            style={{ width: "100%", height: "100%" }}
                            onError={(e) =>
                              (e.target.src = "default-image-url")
                            }
                          />
                        ) : (
                          <div className="empty_placeholder"></div>
                        )}
                        <button onClick={() => handleDelete(index)}>
                          <img src={Delet} alt="Logo Academy" />
                        </button>
                      </div>
                    ))}
                  </div>
                </div>
                <div className="suggestion_and_images_wrapper">
                  <div className="suggestions_container">
                    <div className="suggestions_wrapper">
                      {iconsRelated.map((suggestion, index) => (
                        <div key={index}>
                          <button
                            onClick={() => handleButtonClick(index)}
                            className={`suggestion_item ${
                              index === isActive ? "active" : ""
                            }`}
                          >
                            {suggestion.label}
                          </button>
                        </div>
                      ))}
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div
              className="container_grid mt-3"
              style={{ position: "relative" }}
            >
              {showLoaderAdmin && (
                <div className="centered-loader mt-5">
                  <LoderIcon />
                </div>
              )}

              <div
                className="grid_container2 chooseImgIcone"
                style={{ justifyContent: "center" }}
              >
                {GetIcon.map((svg, index) => (
                  <div
                    key={index}
                    className={`grid_item ${
                      selectedSVGs.some(
                        (selectedSVG) =>
                          selectedSVG.thumbnail_url === svg.thumbnail_url
                      )
                        ? "clicked"
                        : selectedSVGs.length >= 5
                        ? "unselected"
                        : ""
                    }`}
                    onClick={() =>
                      selectedSVGs.length < 5 ? handleClick(index) : null
                    }
                  >
                    <button>
                      <img
                        src={svg.thumbnail_url}
                        alt={`SVG ${index + 1}`}
                        onError={(e) => (e.target.src = "default-image-url")}
                      />
                    </button>
                  </div>
                ))}
              </div>
            </div>
          </div>
        </div>
      </section>
      <div className="navbar_bottom">
        <button
          onClick={handleClickLoader}
          className={`px-3 ${
            selectedSVGs.length >= 1 ? "generate_button" : "skip_btn"
          }`}
        >
          {isLoading ? (
            <div className="loader">
              <ContinueButtonLoader />
            </div>
          ) : (
            <p>{selectedSVGs.length >= 1 ? "Generate" : "Skip This Step"}</p>
          )}
        </button>
      </div>
    </>
  );
};

export default ChooseIcons;
