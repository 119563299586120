import React from "react";
import "./Css/ShowLogoLoader.css";
const ShowLogoLoader = () => {
  return (
    <div>
      <div className="spinner-box">
        <div className="configure-border-1">
          <div className="configure-core"></div>
        </div>
        <div className="configure-border-2">
          <div className="configure-core"></div>
        </div>
      </div>
    </div>
  );
};

export default ShowLogoLoader;
