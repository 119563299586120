import React from "react";
import "./Css/TermsandConditions.css";
import "./Css/PrivacyPolicy.css";
import Footer from "./Footer";
import Header from "./Header";
const Licensing = () => {
  return (
    <div className="howitworks_wrapper">
      <Header />
      <div className="headerSection_howitWorks col-lg-12">
        <div className="terms_imageContainer">
          <div className="center_text_wrapper">
            <h3>Licensing </h3>
          </div>
        </div>
      </div>
      <div className="container privacy_policy_headingContainer">
        <div className="termsAndConditions_points privacyPolicy_points">
          <ul>
            <li>
              1.Introduction
              <ul>
                <li>
                  Greetings from LogoAcademy, your reliable online resource for
                  branding kits and logos. When you use our website, you agree
                  to these terms. Please review them thoroughly before
                  proceeding.
                </li>
              </ul>
            </li>
            <li>
              2. Definition
              <ul style={{ listStyle: "disc" }}>
                <li>
                  • "Assets" are the icons, fonts, and design parts you find on
                  LogoAcademy.
                </li>
                <li>
                  • "Commercial Use" means using these assets to promote, create
                  a brand, or sell things or services.
                </li>
                <li>
                  • "Exclusive Licensing" means a logo is only for your use and
                  won't be sold or shown to anyone else.
                </li>
              </ul>
            </li>
            <li>
              3. Ownership and Rights
              <ul>
                <li>
                  <b>• Rights</b> We cannot guarantee that assets have all the
                  rights required for their use in business contexts.
                </li>
                <li>
                  <b>• Ownership</b> After you buy the logo, you own the
                  copyright for it for as long as your business exists but there
                  is no guarantee of ownership. We as “logoAcademy will not
                  claim any ownership on the assets created with us, but we
                  cannot assure for formal rights like trademark etc as they
                  need various other details like class etc.”
                </li>
                <li>
                  <b>• Restrictions</b> You can't claim ownership of individual
                  parts of the logo (like symbols or fonts).
                </li>
              </ul>
            </li>
            <li>
              4. Payment and Licensing
              <ul>
                <li>
                  <b>• Standard License</b> Paying a one-time fee gives you a
                  license to use the logo for one brand for life.
                </li>
                <li>
                  <b>• New Brands</b> Additional brands or businesses need their
                  own licenses.
                </li>
                <li>
                  <b>• Exclusive License</b> Pay extra for a license that only
                  allows your business to use the logo.
                </li>
                <li>
                  <b>• Non-modifiable</b> After purchase, neither the icon nor
                  the business name can be changed as the license is
                  specifically for the registered business.
                </li>
              </ul>
            </li>

            <li>
              5. Assurance of Quality
              <ul>
                <li>
                  Each item is put through a thorough verifying process to
                  ensure that it is unique and of high quality, meeting
                  professional requirements.
                </li>
              </ul>
            </li>
            <li>
              6. User Responsibilities
              <ul>
                <li>
                  <b> 6.1. Legal Compliance</b>
                  Need to make sure that designs don't break laws about using
                  someone else's ideas or creations.
                </li>
                <li>
                  <b>6.2. Representation</b>
                  Promise that everything you upload or use is either yours or
                  you have the right to use it.
                </li>
              </ul>
            </li>
            <li>
              7. Registering a Trademark
              <ul>
                <li>
                  <b>• Logo Ownership</b> Just having a logo file doesn't mean
                  it's registered as a trademark.
                </li>
                <li>
                  <b>• Registration Advice</b> It's a good idea to talk to a
                  trademark office to make sure your logo is properly protected.
                </li>
              </ul>
            </li>
            <li>
              8. Limits on Our Responsibility
              <ul>
                <li>
                  <b>• Liabilities</b> We can't be held responsible for things
                  like indirect, accidental, or follow-on damages that happen
                  from using our services.
                </li>
                <li>
                  <b>• Misuse</b> We are not accountable for any asset misuse or
                  the ensuing consequences.
                </li>
              </ul>
            </li>
            <li>
              9. Ending the Agreement
              <ul>
                <li>
                  <b>• Breaching Terms</b> We can terminate your permission to
                  use our stuff if you break the terms.
                </li>
                <li>
                  <b>• Post Termination</b> You must immediately stop using
                  LogoAcademy assets immediately and delete everything you have
                  from us.
                </li>
              </ul>
            </li>
            <li>
              10. Changing the Rules
              <ul>
                <li>
                  <b>• Changes</b> These terms are subject to periodic revision;
                  they take effect as soon as they are published on our website.
                </li>
                <li>
                  <b>• Acceptance</b> Using the updated content going forward
                  indicates acceptance of the revised terms.
                </li>
              </ul>
            </li>
            <li style={{ width: "50%" }}>
              You agree to these terms by using LogoAcademy. If you have any
              questions, email us at{" "}
              <a href="logoacademy01@gmail.com">logoacademy01@gmail.com </a> ,{" "}
              <a href="bdm@thefoxadvertisement.com">
                bdm@thefoxadvertisement.com{" "}
              </a>
              with any inquiries.
            </li>
          </ul>
        </div>
      </div>
      <Footer />
    </div>
  );
};

export default Licensing;
