import Footer from "./Footer";
import "./Css/PrivacyPolicy.css";
import Header from "./Header";

const PrivacyPolicy = () => {
  return (
    <div className="howitworks_wrapper">
      <Header />
      <div className="headerSection_howitWorks col-lg-12">
        <div className="terms_imageContainer">
          <div className="center_text_wrapper">
            <h3>Privacy Policy</h3>
          </div>
        </div>
      </div>
      <div className="container privacy_policy_headingContainer">
        <h5>
          This privacy notice explains how LogoAcademy ("we", "us") gathers and
          uses the personal details you voluntarily give on our website:
          logoacademy.com. It also explains how you can access and update your
          personal information and your choices about how you use it. Personal
          details are information about you that isn't public knowledge and that
          you voluntarily give to LogoAcademy so you can use or pay for our
          services.
        </h5>
        <h5 style={{ fontSize: "14px" }}>
          If you have any questions or concerns about this policy, please get in
          touch with us at{" "}
          <a href="logoacademy01@gmail.com">logoacademy01@gmail.com</a> ,{" "}
          <a href="bdm@thefoxadvertisement.com">bdm@thefoxadvertisement.com</a>
        </h5>
        <div className="privacyPolicy_points">
          <ul>
            <li>
              1. Collecting and Using Your Personal Details
              <ul>
                <li>
                  1.1. To help our website work smoothly and handle your
                  account, we gather Personal Information from you. For those
                  using our service for free or paid, this information might
                  include but isn't limited to, your name, email address, credit
                  or debit card details, your postal code, your address, and
                  your phone number.
                </li>
                <li>
                  1.2. Based on the documents you create with our service, we
                  might also collect Personal Information such as names of
                  individuals you have personal or business connections with,
                  things you own or want to pass on to others, financial
                  details, and personal accounts or account numbers with banks,
                  financial organizations, stores, and suppliers.
                </li>
              </ul>
            </li>
            <li>
              2.We use this information to:
              <ul>
                <ul>
                  <li>- Complete your purchase.</li>
                  <li>- Send you a confirmation of your order.</li>
                  <li>- Understand what products your business might need.</li>
                  <li>- Answer your customer service questions.</li>
                  <li>- Send you newsletters.</li>
                  <li>- Share promotional messages with you.</li>
                  <li>- Address any questions or issues you have.</li>
                  <li>
                    - Improve our website better and improving our marketing.
                  </li>
                  <li>- Do research and analyse data.</li>
                  <li>
                    - Collect information from your electronic devices and web
                    browsing.
                  </li>
                  <p className="mt-2 mb-0">
                    We automatically collect information about your computer,
                    like most websites do, including your IP address, browser
                    type, preferences, how you navigate our site(s),
                    referring/exit pages, and operating system. Personal
                    Information is not included in this data. To collect and
                    examine this data, we could make use of analytical services
                    and tools from outside sources.
                  </p>
                </ul>
              </ul>
            </li>
            <li>
              3.Removing Data
              <p className="mt-1 mb-0">
                You have the option to remove your account and the data we've
                gathered:
              </p>
              <ul>
                <li>
                  3.1.Click the "Delete account" button and then confirm this
                  choice on the Account page.
                  <a href="https://logoAcademy.com/settings">
                    {" "}
                    (visit https://logoAcademy.com/settings)
                  </a>
                </li>
                <li>
                  3.2. Send an email asking to delete your account to
                  <a href="support@logoacademy.com">support@logoacademy.com</a>
                </li>
              </ul>
            </li>
            <li>
              4. Opt-out
              <p className="mt-1 mb-0">
                If you don't want to get our newsletter or promotional emails
                anymore, you can:
              </p>
              <ul>
                <li>4.1. Use the unsubscribe link in those emails.</li>
                <li>
                  <label style={{ marginRight: "5px" }}>
                    {" "}
                    4.2. Reach out to us at support
                  </label>

                  <a href="https://support@logoacademy.com">
                    support@logoacademy.com
                  </a>
                </li>
              </ul>
            </li>
            <li>
              5.Information Sharing
              <ul>
                <li>
                  5.1. We will only give your personal details to other
                  companies as we've explained in this privacy notice. We do not
                  sell your personal details to anyone.
                </li>
                <li>
                  5.2. We might share your personal details with companies that
                  help us do things like process your credit or debit card
                  payment, send your order, or provide customer service. These
                  companies can only use your personal details to help us and
                  must follow our rules.
                </li>
                <li>
                  5.3.We might also have to share your personal details if the
                  law requires it, like when we get a legal order. We'll do this
                  to protect our rights, keep you or others safe, look into
                  fraud, or answer a request from the government.
                </li>
                <li>
                  5.4. If LogoAcademy goes through a big change like being
                  merged with another company or selling some of its parts,
                  we'll let you know through email or a clear message on our
                  website. We'll inform you about any changes to who has your
                  personal information, what they can do with it, and what
                  options you have. We'll only share your personal information
                  with others if you agree first.
                </li>
                <li>
                  5.5. We check how people use our website and services to stop
                  fraud and bad use. If we find someone doing something wrong,
                  we might use their information, along with help from security
                  experts or the police, to protect our business and systems,
                  and your personal information, and help anyone we think might
                  have been hurt by the bad use of our website and services. We
                  might share information like payment details, email addresses,
                  and IP addresses to do this.
                </li>
              </ul>
            </li>
            <li>
              6.Third-Party Online Shopping Help
              <ul>
                <li>
                  6.1. We work with a trusted company that follows strict rules
                  (PCI-DSS) to handle credit card payments securely over
                  encrypted connections (SSL). They take care of your payment
                  details to complete your purchases and subscriptions. We also
                  use another trusted company with encrypted connections to
                  handle refunds and changes to your credit or debit card
                  details. These companies only use your information for these
                  tasks and nothing else. We don't keep your credit or debit
                  card numbers on our computers or systems.
                </li>
              </ul>
            </li>
            <li>
              7. Cookies and Tracking Technologies
              <ul>
                <li>
                  7.1. We might use small data files called cookies to remember
                  your settings and information. These cookies also help us
                  gather general information about how our website is used, but
                  this doesn't include personal details.
                </li>
              </ul>
            </li>
            <li>
              8. Links to Other Websites
              <ul>
                <li>
                  8.1. Our website might have links to other websites that have
                  different privacy rules than logoAcademy. If you give your
                  personal details to those websites, those rules will control
                  how your information is used. We suggest you read the privacy
                  rules of any website you go to carefully.
                </li>
              </ul>
            </li>
            <li>
              9. Safety and Security
              <ul>
                <li>
                  <label style={{ marginRight: "5px" }}>
                    9.1. When we get personal details from you, we use common
                    safety measures to keep that information safe both when it's
                    being sent and after we get it. But no way of sending
                    information over the internet or storing it electronically
                    is completely safe. So, we can't promise it will be
                    completely secure. If you have questions about how we keep
                    your information safe on our website, you can email us at
                  </label>
                  <a href="https://support@logoacademy.com">
                    support@logoacademy.com
                  </a>
                </li>
                <li>
                  9.2. To buy something from us, you need to use a company that
                  handles credit card processing to complete and pay for your
                  order. They also have their own privacy policy and security
                  measures that apply to your purchase. We encourage you to read
                  that privacy statement before providing your information.
                </li>
              </ul>
            </li>
            <li>
              10. Additional Policy Information
              <ul>
                <li>
                  10.1.
                  <label style={{ marginRight: "5px" }}>
                    {" "}
                    If you need to check, fix, or change your personal details
                    to make sure they're correct, email us at
                  </label>
                  <a href="bdm@thefoxadvertisement.com ">
                    bdm@thefoxadvertisement.com
                  </a>
                </li>
              </ul>
            </li>
            <li>
              11. Privacy Statement Updates
              <ul>
                <li>
                  11.1. We might change our privacy rules from time to time. If
                  we make important changes, we'll tell you by email (to the
                  address you gave us) or by posting a message on our website
                  before the changes start. Please check this page now and then
                  to stay up-to-date on our privacy rules.
                </li>
              </ul>
            </li>
            <li>
              12. Contact Information
              <ul>
                <li>
                  You can contact us about this privacy policy by writing or
                  emailing us at:
                  <li>LogoAcademy</li>
                  <li>
                    Address: 2nd Floor, Gargi Rani Complex, <br /> Plot No-96,
                    near Hakeem Restaurant, <br /> Zone-II, Maharana Pratap
                    Nagar, <br /> Bhopal, Madhya Pradesh 462011
                  </li>
                  <li>
                    Contact: <a href="8770091042"> 87700 91042</a>
                  </li>
                  <li>
                    <label style={{ marginRight: "5px" }}>Email</label>

                    <a href="logoacademy01@gmail.com">
                      logoacademy01@gmail.com
                    </a>
                  </li>
                </li>
              </ul>
            </li>
          </ul>
        </div>
      </div>
      <Footer />
    </div>
  );
};

export default PrivacyPolicy;
