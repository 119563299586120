import React, { useRef, useEffect, useState } from "react";
import { Document, Packer, Paragraph, ImageRun } from "docx";

import { jsPDF } from "jspdf";
import { useParams, Link, useNavigate, useLocation } from "react-router-dom";
import Select from "react-select";
import { Modal, Button, Form, Alert } from "react-bootstrap";
import LinkDIniconShere from "../Assets/Images/LinkDIniconShere.svg";
import crossIcn from "../Assets/Images/crossIcon.png";
import Header from "./Header";
import {
  retrieveData,
  removeData,
} from "../LocalConnection/LocalConnection.js";
import redoIcn from "../Assets/Images/redoIcn.svg";
import redoIcnBlack from "../Assets/Images/redoIcnBlack.svg";
import undoIcn from "../Assets/Images/undoIcn.svg";
import undoIcnBlack from "../Assets/Images/undoIcnBlack.svg";
import lessIcn from "../Assets/Images/lessIcn.svg";
import graterIcn from "../Assets/Images/graterIcn.svg";
import aligmntIcn from "../Assets/Images/align-center.svg";
import CaptallSmallIcn from "../Assets/Images/aA.svg";
import BoldIcn from "../Assets/Images/bold-01.svg";
import IteLicIcn from "../Assets/Images/italics-01.svg";
import possitionIcn from "../Assets/Images/PossItionIcn.svg";
import starICN from "../Assets/Images/starICN.svg";
import crownIcn from "../Assets/Images/crownIcn.svg";
import pictureIcn from "../Assets/Images/pictureIcn.svg";
import pictureIcnBlue from "../Assets/Images/pictureIcnBlue.svg";
import uploadImgOptions from "../Assets/Images/threeDotNav.svg";
import DownLoadIcn from "../Assets/Images/dwnLoadIcom.svg";
import upgradeIcn from "../Assets/Images/UpgradeIcn.svg";
import resizeIcon from "../Assets/Images/resizeIcn.svg";
import backLayer from "../Assets/Images/backLayer.svg";
import frontLayer from "../Assets/Images/frontLayer.svg";
import watermarkImg from "../Assets/Images/watermark.png";
import HomeIcn from "../Assets/Images/HomeIcon.svg";
import homeIconBlue from "../Assets/Images/homeIconBlue.svg";
import EmogiIcn from "../Assets/Images/add_reaction.svg";
import add_reactionBlue from "../Assets/Images/add_reactionBlue.svg";
import TxtIcn from "../Assets/Images/TextIcn.svg";
import TextIcnBlue from "../Assets/Images/TextIcnBlue.svg";
import TemplGridIcn from "../Assets/Images/TempltIcn.svg";
import TemplGridIcnBlue from "../Assets/Images/TemplGridIcnBlue.svg";
import ShapeIcn from "../Assets/Images/shapesIcn.svg";
import ShapeIcnBlue from "../Assets/Images/ShapeIcnBlue.svg";
import ShapeInner from "../Assets/Images/shapeInner.svg";
import lineIcn from "../Assets/Images/line_start.svg";
import framicn from "../Assets/Images/framsicn.svg";
import UploadIcn from "../Assets/Images/UploadIcn.svg";
import UploadIcnBlue from "../Assets/Images/UploadIcnBlue.svg";
import RightIcnn from "../Assets/Images/rightIcnn.svg";
import ReplaceIcn from "../Assets/Images/replaceIcone.svg";
import aboutIcn from "../Assets/Images/aboutIcn.svg";
import aboutIcnBlue from "../Assets/Images/aboutIcnBlue.svg";
import BackIcn from "../Assets/Images/back-arrow.svg";
import objectDuplicateIcn from "../Assets/Images/objDuplicate.svg";
import objectDltIcn from "../Assets/Images/DltIcon.svg";
import BackgroundWindowIcn from "../Assets/Images/BackgroundIcn.svg";
import BackgroundWindowIcnBlue from "../Assets/Images/BackgroundWindowIcnBlue.svg";
import OpeSityObjctIcn from "../Assets/Images/opacityObject.svg";
import topALigment from "../Assets/Images/topALigment.svg";
import centerALigment from "../Assets/Images/centerALigment.svg";

import removeIcn from "../Assets/Images/removeIcn.svg";
import downloadicn from "../Assets/Images/downloadicn.svg";
import shareIcn from "../Assets/Images/shareIcn.svg";
import rightmakImg from "../Assets/Images/rightmakImg.svg";
import faceBookIcn from "../Assets/Images/faceBookIcn.svg";
import twitterLogo from "../Assets/Images/twitterLogo.svg";
import instaGramIcon from "../Assets/Images/instaGramIcon.svg";
import crossIconModa from "../Assets/Images/crossIconModa.png";
import fontFam from "../Assets/Images/fontFam.svg";
import fontFamilYblueee from "../Assets/Images/fontFamilYblueee.svg";
import shape1 from "../Assets/Images/shape1.svg";
import line1 from "../Assets/Images/line1.svg";
import fram1 from "../Assets/Images/fram1.svg";
import letterSpacing from "../Assets/Images/letterSpacing.svg";
import aligmentLeft from "../Assets/Images/aligmentLeft.svg";
import aligmentRight from "../Assets/Images/aligmentRight.svg";
import crossBtn from "../Assets/Images/crossBtn.svg";
import tempUpdate from "../Assets/Images/tempUpdate.jpg";
import HeaderCanvas from "./HeaderCanvas";
import { fabric } from "fabric";
import AboutCanvas from "./AboutCanvas.js";
import Icons from "./Icons.js";
import $ from "jquery";
import "../Components/Css/Canvas.css";
import "../Components/Css/TextEdit.css";
import axios from "axios";
import {
  server_post_data,
  get_admin_template_by_id,
  save_update_templates,
  insert_img_data,
  APL_LINK,
  Remove_upload_img,
  get_canvas_editor_data_user,
  post_customfont_data,
  save_update_upload_picture,
  get_user_pic_uploads_blog_website,
  get_more_pic_uploads_blog_website,
} from "../ServiceConnection/serviceconnection.js";
import {
  handleError,
  handleConfimDeleteClick,
  handleSuccess,
} from "../CommonJquery/CommonJquery";
import { imgIconRotate } from "../CommonJquery/WebsiteText.js";
import { saveAs } from "file-saver";
import Loader from "./Loader.js";
import ShowLogoLoader from "./ShowLogoLoader.js";
let mainCanvas;
let objectBeingCropped = null;
let colorTimeout;
let deletedObjects = [];
let redoObjects = [];
let clipboard = null;
let state = [];
let mods = 0;
let Haveselected = 0;
let newcanvas;
let refdonwload;
let canvas_temp_download;
let isMoving = false;
let isSpacingChanged = false;
let isColorChanged1 = false;
let isColorChanged2 = false;
let isRightImg;
let isshapbcg;

let colWidth = 400;
let colHeight = 400;
// let updateCanvasDimensions = [];
//push

const CanvasEditor = () => {
  let main_id = 0;
  let data_type_id = "logo";
  const { id, data_type } = useParams();
  if (id) {
    main_id = id;
  }
  if (data_type) {
    data_type_id = data_type;
  }
  const uploadRef = useRef(null);
  const mainEditorCanvasRef = useRef(null);
  const mainCanvasRef = useRef(null);
  const buttonRef = useRef(null);
  const resizeRef = useRef(null);
  const downloadRef = useRef(null);
  const navRef = useRef(null);
  const aligmentRef = useRef(null);
  const PosstionLayer = useRef(null);
  const FlipLayer = useRef(null);
  const HideDwnUpRef = useRef(null);
  const fileInputRef = useRef(null);
  const colorPickerRef = useRef(null);
  const inputRef = useRef(null);
  const inputRefShere = useRef(null);
  const inputRefs = [useRef(null), useRef(null), useRef(null)];
  const opacityInputRef = useRef(null);

  const containerRefs = useRef([]);
  const downloaddesign = useRef();
  const letterRef = useRef(null);
  const user_id = retrieveData("user_id");

  const { user_id: urlUserId } = useParams();
  const [canvasColor, setCanvasColor] = useState("white");
  const [company_nametext, setcompany_nametext] = useState(false);
  const [slogantext, setslogantext] = useState(false);
  const [showLoaderAdmin, setshowLoaderAdmin] = useState(false);
  const [secondaryCanvasDataURL, setSecondaryCanvasDataURL] = useState("");
  const [userpictures, setuserpictures] = useState([]);
  const [isbgcolorshape, setisbgcolorshape] = useState(true);
  const [templatejson, setTemplateJson] = useState("");
  const [GroupUngroup, setGroupUngroup] = useState("Group");
  const [selectedFontSize, setSelectedFontSize] = useState(20);
  const [isOpenshape, setIsOpenshape] = useState(false);
  const [isOpentext, setIsOpentext] = useState(false);
  const [isOpenBg, setIsOpenBg] = useState(false);
  const [isOpenicon, setIsOpenicon] = useState(data_type === "letterhead");
  const [isOpenTemplate, setIsOpenTemplate] = useState(false);
  const [isOpenAbout, setIsOpenAbout] = useState(false);
  const [isOpenAbout2, setIsOpenAbout2] = useState(false);
  const [isOpenuploads, setIsOpenuploads] = useState(false);
  const [isOpenPictures, setIsOpenPictures] = useState(false);
  const [, setRender] = useState(0); // Dummy state to force re-render

  const [isTextToolVisible, setIsTextToolVisible] = useState(false);
  const [isObjectSelected, setIsObjectSelected] = useState(false);

  const [opacity, setOpacity] = useState(100);
  const [showOpacityInput, setShowOpacityInput] = useState(false);
  //shapes new screen
  const [currentContent, setCurrentContent] = useState("main");
  const [filteredData, setfilteredData] = useState([]);
  const [showFlipButtons, setShowFlipButtons] = useState(false);

  const [showSecondButton, setShowSecondButton] = useState(false);
  const [showSecondButton2, setShowSecondButton2] = useState(false);
  const [windowWidth, setWindowWidth] = useState(window.innerWidth);
  const [GetUploadImg, SetUploadImg] = useState(false);
  const [Gettemplates, Settemplates] = useState(false);

  const [isVisible, setIsVisible] = useState(false);
  const [isVisible2, setIsVisible2] = useState(false);
  const [isVisible4, setIsVisible4] = useState(false);
  const [width, setWidth] = useState();
  const [height, setHeight] = useState();
  const [unit, setUnit] = useState();
  const [isManualSetup, setIsManualSetup] = useState(false);
  const [isPossitionsLayers, SetPossitionsLayers] = useState(false);
  const [hovered, setHovered] = useState(false);
  const [hovered2, setHovered2] = useState(false);
  const [hovered3, setHovered3] = useState(false);
  const [hovered4, setHovered4] = useState(false);
  const [hovered5, setHovered5] = useState(false);
  const [hovered6, setHovered6] = useState(false);
  const [hovered7, setHovered7] = useState(false);
  const [hovered10, setHovered10] = useState(false);
  const [clicked, setClicked] = useState(false);
  const [clicked2, setClicked2] = useState(false);
  const [selectedTab, setSelectedTab] = useState("company_name");
  const [selectedCategory, setSelectedCategory] = useState(null);
  const [activeColor, setActiveColor] = useState(null);
  const [showResize, setShowResize] = useState(false);
  const [showDownload, setDownload] = useState(false);
  const [isActive2, setIsActive2] = useState(false);
  const [isActive3, setIsActive3] = useState(false);
  const [isActive4, setIsActive4] = useState(false);
  const [isActive5, setIsActive5] = useState(false);
  const [isActive6, setIsActive6] = useState(false);
  const [isActive7, setIsActive7] = useState(false);
  const [isActive10, setIsActive10] = useState(false);
  const [position, setPosition] = useState({ left: 100, top: 100 });
  const [confirmModalVisible, setConfirmModalVisible] = useState(false);
  const [selectedLogoData, setSelectedLogoData] = useState(null);
  const [fonts, setFonts] = useState([]);
  const [buttonColor, setButtonColor] = useState("#6473fd");

  const [IMAGEPATH, setIMAGEPATH] = useState("main");
  const [IconShape, SetIconShape] = useState([]);
  const [hoveredImageIndex, setHoveredImageIndex] = useState(null);
  const [colorcodeloop, setcolorcodeloop] = useState([]);
  const [isCropMode, setIsCropMode] = useState(false);
  const [selectedFontFamily, setSelectedFontFamily] = useState("");
  const [categorySearchQuery, setCategorySearchQuery] = useState("");
  const [fontSearchQuery, setFontSearchQuery] = useState("");
  const [initialFontCount, setInitialFontCount] = useState(12);
  const [filteredFonts, setFilteredFonts] = useState([]);
  const [selectedFontCanvasFamily, setSelectedCanvasFontFamily] = useState("");
  const [category, setCategory] = useState("");
  const [isGroupObj, setIsGroupObj] = useState(false);
  const [shopcolor, setshopcolor] = useState(true);
  const [buttonVisible, setButtonVisible] = useState(false);
  const [selectedIconId, setSelectedIconId] = useState(null);
  const [isButtonEnabled, setIsButtonEnabled] = useState(false);
  const [selectedText, setSelectedText] = useState(null);
  const [isButtonChecked, setIsButtonChecked] = useState(false);
  const [iconchngcolor, seticonchngcolor] = useState("#000000");
  const [isLetterSpacingVisible, setLetterSpacingVisible] = useState(false);
  const [letterSpacingValue, setLetterSpacingValue] = useState(0);
  const [heightSpacingValue, setheightSpacingValue] = useState(0);
  const [isColor, setIsColor] = useState(false);
  const [isActive9, setIsActive9] = useState(false);
  const [hoveredImage, setHoveredImage] = useState(null);
  const [optionsVisible, setOptionsVisible] = useState(null);
  const [currentOptionsType, setCurrentOptionsType] = useState(null);
  const [showFontModal, setshowFontModal] = useState(false);
  const [file, setFile] = useState(null);
  const [validationMessage, setValidationMessage] = useState("");
  const [replaceText, setReplaceText] = useState(false);
  const [alignmentPosition, setAlignmentPosition] = useState("left");
  const [cropConfirmed, setCropConfirmed] = React.useState(false);
  const [isCollapsedPosstion, setIsCollapsedPosstion] = useState(false);
  const [pictures, setpictures] = useState([]);
  const [isCollapsedOp, setIsCollapsedop] = useState(false);
  const [isLtterSpacing, setIsCollapsedLtterSpacing] = useState(false);
  const [GetUserId, setGetUserId] = useState("");
  const [rangeValue, setRangeValue] = useState("200");

  const business_Card = retrieveData("business_card");
  const about_logo = retrieveData("about_logo");
  const package_name = retrieveData("package_name");
  const subscription_active = retrieveData("subscription_active");
  const business_card = retrieveData("business_card");
  const downloading_rights = retrieveData("downloading_rights");
  const resolution = retrieveData("resolution");
  const vector_file = retrieveData("vector_file");
  const transparent_background2 = retrieveData("transparent_background");
  const buttonLetterRef = useRef(null);

  const [fontCategories, setFontCategories] = useState([
    { label: "All", value: "All" },
  ]);
  const [uploadedFonts, setUploadedFonts] = useState([]);
  const [industryData, setIndustryData] = useState([]);
  const location = useLocation();
  const navigate = useNavigate();
  //home icone change on the hver
  const [isHovered, setIsHovered] = useState(false);
  useEffect(() => {
    master_data_get_full();
    master_data_get_pictures();
  }, []);

  useEffect(() => {}, [colorcodeloop]);

  //bg color active classs
  const handleClick = (color) => {
    setActiveColor(color);

    if (Array.isArray(color)) {
      const gradient = `linear-gradient(to right, ${color.join(", ")})`;
      const syntheticEvent = {
        target: { value: gradient },
      };
      handleCanvasColorChange(syntheticEvent, color);
    } else {
      const syntheticEvent = {
        target: { value: color },
      };
      handleCanvasColorChange(syntheticEvent);
    }
  };
  const [showSidebar, setShowSidebar] = useState(false);
  const [showCreateButton, setshowCreateButton] = useState(false);
  const matchId = (get_user_id) => {
    if (get_user_id != "0") {
      const storedUserId = localStorage.getItem("user_id");
      console.log("Stored UserId:", storedUserId);
      console.log("GetUserId:", get_user_id);

      if (storedUserId == get_user_id) {
        console.log("Stored UserId:", storedUserId);
        console.log("GetUserId:", get_user_id);
        setShowSidebar(true);
        setshowCreateButton(false);
      } else {
        setShowSidebar(false);
        setshowCreateButton(true);
      }
    } else {
      setShowSidebar(true);
      setshowCreateButton(false);
    }
  };

  const getDefaultZoomLevel = (data_type) => {
    console.log(data_type);
    switch (data_type) {
      case "letterhead":
        return 55; // 55% for letterhead
      case "businesscard":
        return 200; // Example default value for business cards
      case "invitationCard":
        return 100; // Example default value for invitation cards
      case "envelops":
        return 115; // Example default value for envelops
      default:
        return 100; // Default zoom level for other types
    }
  };

  const [zoomLevel, setZoomLevel] = useState(() =>
    getDefaultZoomLevel(data_type)
  );

  const master_data_get_full = async () => {
    setshowLoaderAdmin(true);
    const fd = new FormData();
    fd.append("call_id", main_id);
    fd.append("template_type", data_type_id);
    fd.append("template_id", main_id);
    await server_post_data(get_canvas_editor_data_user, fd)
      .then((Response) => {
        if (Response.data.error) {
          handleError(Response.data.message);
        } else {
          if (main_id != 0) {
            if (Response.data.message.data_template.length > 0) {
              setTemplateJson(
                Response.data.message.data_template[0].template_canvas
              );
              whenRenderFunction(
                true,
                Response.data.message.data_template[0].template_canvas
              );
            }
            setGetUserId(Response.data.message.data_template[0].user_id);
          }
          SetUploadImg(Response.data.message.image_uploads_blog);
          setfilteredData(Response.data.message.data_shapes);
          SetIconShape(Response.data.message.data_shapes);
          setIMAGEPATH(Response.data.message.image_path);

          setFonts(Response.data.message.fontlist);
          setUploadedFonts(Response.data.message.custom_font);
          setIndustryData(Response.data.message.data_industry);

          const fontlist = Response.data.message.fontlist;
          const customFonts = Response.data.message.custom_font;
          const combinedFonts = [...fontlist, ...customFonts];
          const allCategories = [
            ...new Set([
              ...fontlist.map((font) => font.category),
              ...customFonts.map((font) => font.category),
            ]),
          ];
          setFontCategories(allCategories);
          filterFonts(fontSearchQuery, selectedCategory);
          combinedFonts.forEach((font) => {
            const fontFace = new FontFace(font.family, `url(${font.src_file})`);
            fontFace
              .load()
              .then((loadedFace) => {
                document.fonts.add(loadedFace);
              })
              .catch((error) => {
                ///console.error(`Error loading font "${font.family}":`, error);
              });
          });
          // Filter and map fonts for style tag
          const fontStyles = Response.data.message.fontlist
            .filter((font) => font.files && font.files.regular)
            .map((font) => {
              return `@font-face {
                font-family: '${font.family}';
                font-style: normal;
                font-weight: 400;
                src: url(${font.files.regular});
              }`;
            })
            .join("\n");

          const styleElement = document.createElement("style");
          styleElement.textContent = fontStyles;
          document.head.appendChild(styleElement);
        }
        if (main_id != 0) {
          matchId(Response.data.message.data_template[0].user_id);
        } else {
          matchId(0);
        }
        setshowLoaderAdmin(false);
      })
      .catch((error) => {
        console.log(error);
        setshowLoaderAdmin(false);
        handleError("network");
      });
  };

  const master_data_action = async (call_id) => {
    if (handleConfimDeleteClick()) {
      setshowLoaderAdmin(true);
      const fd = new FormData();
      fd.append("main_id", call_id);
      fd.append("flag", "3");
      try {
        const Response = await server_post_data(Remove_upload_img, fd);
        setshowLoaderAdmin(false);

        if (Response.data.error) {
          handleError(Response.data.message);
        } else {
          setIsVisible4(false);
          handleSuccess("Item deleted successfully!");
          master_data_get_full();
        }
      } catch (error) {
        setshowLoaderAdmin(false);
        handleError("An error occurred. Please try again.");
      }
    }
  };

  const master_data_templates = async (flag, call_id, use_flag = 0) => {
    setshowLoaderAdmin(true);

    const fd = new FormData();

    fd.append("flag", flag);
    fd.append("call_id", call_id);
    fd.append("use_flag", use_flag);

    await server_post_data(get_admin_template_by_id, fd)
      .then((Response) => {
        if (Response.data.error) {
          handleError(Response.data.message);
        } else {
          Settemplates(
            Response.data.message.data.map((item) => {
              try {
                return JSON.parse(item.template_canvas);
              } catch (error) {
                console.error(error);
                return null; // Or handle the error appropriately
              }
            })
          );
        }

        setshowLoaderAdmin(false);
      })
      .catch((error) => {
        setshowLoaderAdmin(false);
      });
  };

  const convertCanvasToJPG = (canvs) => {
    return new Promise((resolve, reject) => {
      try {
        // Convert the canvas to a JPG data URL
        const canvas = canvs;
        const jpgDataURL = canvas.toDataURL("image/jpeg", 1.0);
        resolve(jpgDataURL);
      } catch (error) {
        reject(error);
      }
    });
  };

  const base64ToBlob = (base64, mime) => {
    // Check if base64 string is correct
    if (!base64 || !mime) {
      throw new Error("Invalid Base64 string or MIME type");
    }

    // Handle Data URL format
    const byteString = atob(base64.split(",")[1]);
    const mimeString = base64.split(",")[0].split(":")[1].split(";")[0];

    // Create a buffer and Uint8Array to handle byte data
    const arrayBuffer = new ArrayBuffer(byteString.length);
    const uintArray = new Uint8Array(arrayBuffer);

    for (let i = 0; i < byteString.length; i++) {
      uintArray[i] = byteString.charCodeAt(i);
    }

    return new Blob([arrayBuffer], { type: mime || mimeString });
  };

  const saveCanvasAsJSON = async () => {
    if (mainCanvas._objects.length > 0) {
      const user_id = retrieveData("user_id");
      if (user_id) {
        setshowLoaderAdmin(true);
        let fd_from = new FormData();

        const data = mainCanvas.getObjects().map((obj) => {
          let objData = obj.toObject();
          objData.flag = 0; // Append flag 0
          objData.color = obj.fill || "";
          objData.customWidth = obj.customWidth || obj.width;
          // Log customWidth to console

          // Add mergedFonts to each object and include font source
          return objData;
        });
        const canvasData = {
          objects: data,
          background: mainCanvas.backgroundColor,
          width: mainCanvas.width,
          height: mainCanvas.height,
        };
        console.log(mainCanvas.getObjects());
        const canvasImageURL = await convertCanvasToJPG(mainCanvas);
        const blob = base64ToBlob(canvasImageURL, "image/jpeg");
        // fd_from.append(
        //   "template_canvas_data",
        //   JSON.stringify(mainCanvas.toJSON())
        // );
        let template_colors = mainCanvas.backgroundColor;
        fd_from.append("file", blob, "canvas-image.jpg");
        fd_from.append("template_canvas_data", JSON.stringify(canvasData));
        fd_from.append("template_type", data_type);
        fd_from.append("company_name_data", company_nametext);
        fd_from.append("tag_line_data", slogantext);
        fd_from.append("selected_color_data", template_colors);
        fd_from.append("selected_icon_data", "");
        fd_from.append("main_id", main_id);
        fd_from.append("customer_id", user_id);

        // await server_post_data(save_update_templates, fd_from)
        //   .then((Response) => {
        try {
          const Response = await axios.post(save_update_templates, fd_from, {
            headers: {
              "Content-Type": "multipart/form-data", // Set the Content-Type header for form data
            },
          });
          if (Response.status === 200) {
            if (Response.data.error) {
              handleError(Response.data.message);
            } else {
              handleSuccess(
                Response.data.message,
                "/canvasEdit/" + data_type + "/" + id
              );
            }
            setshowLoaderAdmin(false);
            // })
            // .catch((error) => {
            //   setshowLoaderAdmin(false);
            //   handleError("network");
            // });
          }
        } catch (error) {
          setshowLoaderAdmin(false);
          handleError("network");
        }
      }
    } else {
      handleError("Please Add Creative");
    }
  };

  const handleUpload = async (file, svgContent) => {
    setshowLoaderAdmin(true);
    const formData = new FormData();
    formData.append("upload_img_new", file);
    formData.append("svg_content", svgContent);
    formData.append("user_id", localStorage.getItem("user_id"));
    formData.append("by_user_admin_flag", "1");
    await server_post_data(insert_img_data, formData)
      .then((Response) => {
        if (Response.data.error) {
          handleError(Response.data.message);
        } else {
          master_data_get_full();
        }
        setshowLoaderAdmin(false);
      })
      .catch((error) => {
        setshowLoaderAdmin(false);
        handleError("network");
      });
  };

  useEffect(() => {
    if (data_type !== "letterhead") {
      setIsOpenicon(false);
    }
  }, [data_type]);

  //Rotate Scaler Icon

  fabric.Object.prototype.controls.rotateControl = new fabric.Control({
    x: 0,
    y: -0.5,
    offsetX: 0,
    offsetY: -40,
    cursorStyle: "crosshair",
    actionHandler: fabric.controlsUtils.rotationWithSnapping,
    actionName: "rotate",
    render: function (ctx, left, top, styleOverride, fabricObject) {
      // Call both render functions
      renderIconrotate.call(this, ctx, left, top, styleOverride, fabricObject);
      // renderAdditionalControls.call(
      //   this,
      //   ctx,
      //   left,
      //   top,
      //   styleOverride,
      //   fabricObject
      // );
    },
    cornerSize: 33,
    withConnection: true,
  });

  function renderIconrotate(ctx, left, top, styleOverride, fabricObject) {
    var size = this.cornerSize;
    ctx.save();
    ctx.translate(left, top);
    ctx.rotate(fabric.util.degreesToRadians(fabricObject.angle));
    ctx.drawImage(imgIconRotate, -size / 2, -size / 2, size, size);
    ctx.restore();
  }

  //rotation show

  // New render function for additional controls

  // function renderAdditionalControls(
  //   ctx,
  //   left,
  //   top,
  //   styleOverride,
  //   fabricObject
  // ) {
  //   const size = this.cornerSize;
  //   const angle = fabricObject.angle % 360; // Get the current angle

  //   ctx.save();
  //   ctx.translate(left, top);

  //   // Ensure the angle indicator rotates with the control
  //   ctx.rotate(fabric.util.degreesToRadians(angle));

  //   // Draw the background box with rounded corners
  //   ctx.fillStyle = "black";
  //   const boxWidth = 150; // Width of the box
  //   const boxHeight = 25; // Height of the box
  //   const radius = 8; // Corner radius

  //   ctx.beginPath();
  //   ctx.moveTo(-boxWidth / 2 + radius, -size / 2 - boxHeight - 10);
  //   ctx.arcTo(
  //     -boxWidth / 2 + boxWidth,
  //     -size / 2 - boxHeight - 10,
  //     -boxWidth / 2 + boxWidth,
  //     -size / 2 - 10,
  //     radius
  //   );
  //   ctx.arcTo(
  //     -boxWidth / 2 + boxWidth,
  //     -size / 2 - 10,
  //     -boxWidth / 2 + radius,
  //     -size / 2 - 10,
  //     radius
  //   );
  //   ctx.arcTo(
  //     -boxWidth / 2 + radius,
  //     -size / 2 - 10,
  //     -boxWidth / 2 + radius,
  //     -size / 2 - boxHeight - 10,
  //     radius
  //   );
  //   ctx.arcTo(
  //     -boxWidth / 2 + radius,
  //     -size / 2 - boxHeight - 10,
  //     -boxWidth / 2 + boxWidth,
  //     -size / 2 - boxHeight - 10,
  //     radius
  //   );
  //   ctx.closePath();
  //   ctx.fill();

  //   // Draw the angle text
  //   ctx.fillStyle = "white";
  //   ctx.font = "12px Arial";
  //   ctx.textAlign = "center";
  //   ctx.textBaseline = "middle";
  //   ctx.fillText(
  //     `Angle ${Math.round(angle)}°`,
  //     0,
  //     -size / 2 - boxHeight / 2 - 10
  //   );
  //   ctx.restore();
  // }
  // Update the custom rotation icon control
  fabric.Object.prototype.controls.rotateControl = new fabric.Control({
    x: 0,
    y: -0.5,
    offsetX: 0,
    offsetY: -40,
    cursorStyle: "crosshair",
    actionHandler: fabric.controlsUtils.rotationWithSnapping,
    actionName: "rotate",
    render: function (ctx, left, top, styleOverride, fabricObject) {
      // Call both render functions
      renderIconrotate.call(this, ctx, left, top, styleOverride, fabricObject);
      // renderAdditionalControls.call(
      //   this,
      //   ctx,
      //   left,
      //   top,
      //   styleOverride,
      //   fabricObject
      // );
    },
    cornerSize: 33,
    withConnection: true,
  });
  const [isUserLoggedIn, setIsUserLoggedIn] = useState(false);

  useEffect(() => {
    const userId = localStorage.getItem("user_id");
    setIsUserLoggedIn(userId !== null && userId !== "");
  }, []);
  const setObjectsLocked = (canvas, locked) => {
    canvas.getObjects().forEach((obj) => {
      obj.selectable = !locked;
      obj.evented = !locked;
      obj.lockMovementX = locked;
      obj.lockMovementY = locked;
      obj.lockRotation = locked;
      obj.lockScalingX = locked;
      obj.lockScalingY = locked;
      obj.hasControls = !locked;
      obj.hasBorders = !locked;
      obj.hoverCursor = locked ? "default" : "move";
    });
    canvas.requestRenderAll();
  };
  // Define the new function that will contain the logic
  const initializeCanvas = () => {
    // Initial setup
    const originalWidth = templatejson.width;
    const originalHeight = templatejson.height;

    let scaleFactor = Math.min(
      colWidth / originalWidth,
      colHeight / originalHeight
    );
    console.log(scaleFactor);
    mainCanvas = new fabric.Canvas(mainCanvasRef.current, {
      width: colWidth,
      height: colHeight,
      backgroundColor: templatejson.background || canvasColor,
      selection: isUserLoggedIn,
    });
    console.log(colWidth);
    console.log(colHeight);
    console.log(originalWidth);
    handleClick(templatejson.background || canvasColor);

    const UserId = localStorage.getItem("user_id");
    const MatchingUserId = GetUserId && GetUserId == UserId;

    const handleResize = () => {
      if (data_type === "businesscard") {
        colWidth = 362.2;
        colHeight = 211.42;
        updateCanvasDimensions(362.2, 211.42);
      } else if (data_type === "invitationCard") {
        colWidth = 480;
        colHeight = 672;
        updateCanvasDimensions(480, 672);
      } else if (data_type === "envelops") {
        colWidth = 793.7;
        colHeight = 415.75;
        updateCanvasDimensions(793.7, 415.75);
      } else if (data_type === "letterhead") {
        colWidth = 793.7;
        colHeight = 1124.41;
        updateCanvasDimensions(793.7, 1124.41);
      } else {
        colWidth = 400;
        colHeight = 400;
        updateCanvasDimensions(400, 400);
      }
    };

    // Add gridlines if matching user
    if (MatchingUserId) {
      createGridLines(mainCanvas);
    }

    // Handle empty canvas scenario
    if (
      !templatejson ||
      !templatejson.objects ||
      templatejson.objects.length === 0
    ) {
      console.log("No templatejson found, initializing a blank canvas.");
      handleResize();
      mainCanvas.requestRenderAll();
      whenRenderFunction(true); // Optionally notify the render function

      // Attach event listener for object selection on a blank canvas
      mainCanvas.on("mouse:down", function (options) {
        handleTargetSelection(options.target);
        showObjectSelector(options.target); // Show toolbar regardless of initial canvas state
      });
      return;
    }

    // Handle canvas with objects
    const addObjectsSequentially = (index = 0) => {
      if (index >= templatejson.objects.length) {
        mainCanvas.requestRenderAll();
        whenRenderFunction(true);
        return;
      }

      const objData = templatejson.objects[index];

      // Handle scaling and positioning
      objData.scaleX = (objData.scaleX || 1) * scaleFactor;
      objData.scaleY = (objData.scaleY || 1) * scaleFactor;
      objData.left = (objData.left || 0) * scaleFactor;
      objData.top = (objData.top || 0) * scaleFactor;

      fabric.util.enlivenObjects([objData], (objects) => {
        const origRenderOnAddRemove = mainCanvas.renderOnAddRemove;
        mainCanvas.renderOnAddRemove = false;

        objects.forEach((obj) => {
          if (obj.type === "i-text") {
            const customWidth = obj.customWidth || obj.width;
            obj.set({ width: customWidth });
          }

          obj.set({
            borderColor: "#9b57ff",
            cornerColor: "white",
            cornerStrokeColor: "#ececec",
            strokeWidth: 2,
            transparentCorners: false,
            cornerRadius: 100,
          });

          obj.hoverCursor = "pointer";
          obj.on("mouseover", () => mainCanvas.renderAll());
          obj.on("mouseout", () => {
            obj.set("stroke", "transparent");
            mainCanvas.renderAll();
          });

          if (obj.type === "i-text") {
            obj.on("scaling", () => {
              const minTextWidth = obj.calcTextWidth();
              const newWidth = obj.width * obj.scaleX;
              obj.set({ width: Math.max(newWidth, minTextWidth) });
              obj.customWidth = obj.width;
              obj.setCoords();
              mainCanvas.requestRenderAll();
              whenRenderFunction(true);
            });

            obj.on("editing:entered", () => (obj.customWidth = obj.width));
            obj.on("editing:exited", () => {
              obj.set({ width: obj.customWidth || obj.width });
              obj.setCoords();
              mainCanvas.requestRenderAll();
            });
          }

          mainCanvas.add(obj);
        });

        mainCanvas.renderOnAddRemove = origRenderOnAddRemove;
        mainCanvas.requestRenderAll();
        addObjectsSequentially(index + 1);
      });
    };

    // Start adding objects if they exist
    addObjectsSequentially();

    // Other functionalities
    mainCanvas.preserveObjectStacking = true;
    mainCanvas.on("object:added", handleCanvasUpdate);
    mainCanvas.on("object:modified", handleCanvasUpdate);
    mainCanvas.on("object:removed", handleCanvasUpdate);
    mainCanvas.on("selection:cleared", handleObjectDeselection);

    mainCanvas.on("object:scaling", (e) => {
      const obj = e.target;
      if (obj.type === "i-text") {
        const minTextWidth = obj.calcTextWidth();
        const newWidth = obj.width * obj.scaleX;
        obj.set({ width: Math.max(newWidth, minTextWidth) });
        obj.customWidth = obj.width;
        obj.setCoords();
        mainCanvas.requestRenderAll();
      }
    });

    // Handle mouse down for showing toolbar
    mainCanvas.on("mouse:down", (options) => {
      handleTargetSelection(options.target);
      const storedUserId = localStorage.getItem("user_id");
      const isMatchingUserId = GetUserId && GetUserId == storedUserId;
      if (isMatchingUserId) {
        showObjectSelector(options.target); // Show toolbar on object selection
      } else {
        hideObjectSelector();
        mainCanvas.getObjects().forEach((obj) => {
          obj.selectable = false;
          obj.hoverCursor = "default";
          obj.hasControls = false;
          obj.hasBorders = false;
          obj.lockMovementX = true;
          obj.lockMovementY = true;
          obj.lockScalingX = true;
          obj.lockScalingY = true;
          obj.lockRotation = true;
        });
        mainCanvas.discardActiveObject();
        mainCanvas.requestRenderAll();
      }
    });

    // Handle canvas resizing for different data types

    window.addEventListener("resize", handleResize);

    // Initialize canvas dimensions on load
    handleResize();

    window.addEventListener("keydown", handleKeyDown);
    window.addEventListener("keydown", handleSelectAll);
  };

  // Functions to show or hide object selector
  const showObjectSelector = (target) => {
    // Your logic to show the object selector for the target object
  };

  const hideObjectSelector = () => {
    // Your logic to hide the object selector
  };

  // Call the new function inside useEffect
  useEffect(() => {
    initializeCanvas();

    return () => {
      mainCanvas.dispose();

      mainCanvas.off("selection:cleared", handleObjectDeselection);
      mainCanvas.off("mouse:down", handleMouseDown);
      window.removeEventListener("keydown", handleKeyDown);
      window.removeEventListener("keydown", handleSelectAll);
      window.removeEventListener("resize", updateCanvasDimensions);

      setTimeout(() => {
        handleCanvasUpdate();
      }, 1000);
    };
  }, [templatejson]);

  //Target

  const handleTargetSelection = async (target) => {
    if (target) {
      const activeObjects = mainCanvas.getActiveObjects();
      console.log(activeObjects);
      if (activeObjects.length > 1) {
        // Multiple objects selected
        setIsTextToolVisible(false);
        setIsObjectSelected(false);
        setIsGroupObj(true);
        setshopcolor(true);
        setButtonVisible(false);
        setisbgcolorshape(true);
        console.log("Multiple objects selected, showing group toolbar.");
      } else if (target.type === "i-text") {
        setGroupUngroup("Group");
        // Text object selected
        const oldTextColor = target.fill;
        console.log("Old Text Color:", oldTextColor);
        setsetTextColor(oldTextColor);
        setIsTextToolVisible(true);
        setIsObjectSelected(false);
        setIsGroupObj(false);
        setshopcolor(true);
        setSelectedCanvasFontFamily(target.fontFamily);
        setSelectedText(target);
        setSelectedFontSize(target.fontSize);
        setButtonVisible(false);
        if (target.fontStyle === "italic") {
          setTextstyle(true);
        } else if (target.fontStyle !== "italic") {
          setTextstyle(false);
        }
        if (target.fontWeight === "bold") {
          setTextweight(true);
        } else {
          setTextweight(false);
        }
      } else if (target.type === "image") {
        setGroupUngroup("Group");
        // Image object selected
        if (target.metadata) {
          if (target.metadata.role === "icon") {
            // Icon detected
            const iconColor = target.color;
            let svgDataUrl = target.src;

            if (!svgDataUrl) {
              svgDataUrl = target.getSrc();
            }

            try {
              // Fetching SVG data asynchronously
              const response = await fetch(svgDataUrl);
              const svgData = await response.text();

              // Process the SVG to extract fill color
              const fillColor = getFillColorFromSVG(svgData);
              console.log(fillColor);
              seticonchngcolor(fillColor);
            } catch (error) {
              console.error("Error fetching SVG data:", error);
            }

            console.log("Selected Icon - Old Color:", iconColor);

            // setshopcolor(true);
            // setisbgcolorshape(false); // Hide the button for icons
            // if (target.borderColor) {
            //   seticonchngcolor(target.borderColor);
            // } else if (target.color) {
            //   seticonchngcolor(target.color);
            // }
          } else if (target.metadata.role === "Shapes") {
            const shapeColor = target.color;

            seticonchngcolor(shapeColor);
            setshopcolor(true);
            setisbgcolorshape(true);

            // const iconColor = target.color;
            let svgDataUrl = target.src;

            if (!svgDataUrl) {
              svgDataUrl = target.getSrc();
            }

            try {
              // Fetching SVG data asynchronously
              const response = await fetch(svgDataUrl);
              const svgData = await response.text();

              // Process the SVG to extract fill color
              const fillColor = getFillColorFromSVG(svgData);
              console.log(fillColor);
              seticonchngcolor(fillColor);
            } catch (error) {
              console.error("Error fetching SVG data:", error);
            }
            // if (target.borderColor) {
            //   seticonchngcolor(target.borderColor);
            // } else if (target.color) {
            //   seticonchngcolor(target.color);
            // }
            // console.log(isshapbcg);
            // console.log(activeObjects[0].MkIconId);
            // console.log(activeObjects);
            if (activeObjects && isshapbcg === activeObjects[0].MkIconId) {
              isRightImg = true;
            } else {
              isRightImg = false;
            }
            setRender((prev) => prev + 1);
          } else {
            setisbgcolorshape(false);
            setshopcolor(false);
          }
        } else {
          setisbgcolorshape(false);
          setshopcolor(true);
        }
        setIsTextToolVisible(false);
        setIsObjectSelected(true);
        setIsGroupObj(false);
        setSelectedIconId(target.get("id"));
        setIsButtonEnabled(true);
        setIsButtonChecked(
          sessionStorage.getItem("checkedIconId") === target.get("id")
        );
        console.log("Selected Icon ID:", target.get("id"));
      } else if (target.type === "group") {
        setGroupUngroup("Group");
        // Group object selected
        setIsTextToolVisible(false);
        setIsObjectSelected(false);
        setIsGroupObj(true);
        setshopcolor(true);
        setButtonVisible(false);
        setisbgcolorshape(false); // Hide the button for groups
        console.log("Group selected, group toolbar visible.");
      } else if (target.shape_type) {
        setGroupUngroup("Group");
        // Shape object selected
        setIsTextToolVisible(false);
        setIsObjectSelected(true);
        setIsGroupObj(false);
        setshopcolor(true); // Ensure shopcolor is set to true for shapes
        setButtonVisible(false);
        setisbgcolorshape(true); // Show the button for shapes
        console.log("Shape selected, shopcolor set to true.");
      } else {
        setGroupUngroup("Group");
        // Other objects
        setIsTextToolVisible(false);
        setIsObjectSelected(true);
        setIsGroupObj(false);
        setisbgcolorshape(false); // Hide the button for other objects
        setshopcolor(true);
        if (target.globalCompositeOperation) {
          console.log(target);
          handleImageClick(target.globalCompositeOperation, 1);
        }
        setButtonVisible(false);
      }
    } else {
      // No target
      setIsTextToolVisible(false);
      setIsObjectSelected(false);
      setIsGroupObj(false);
      setshopcolor(true);
      setSelectedCanvasFontFamily("");
      setButtonVisible(false);
      setisbgcolorshape(false); // Hide the button when no target
    }

    console.log("isbgcolorshape state:", isbgcolorshape); // Debugging log
  };

  const [SetTextcolor, setsetTextColor] = useState();
  const [Textstyle, setTextstyle] = useState(false);
  const [Textweight, setTextweight] = useState(false);

  const handleSameAsbg = () => {
    const activeObject = mainCanvas.getActiveObject();
    if (activeObject) {
      if (isRightImg) {
        isRightImg = false;
        isshapbcg = "";
      } else {
        isRightImg = true;
        isshapbcg = activeObject.MkIconId;
      }
      setRender((prev) => prev + 1);
    }
  };

  // Function to create and manage grid lines
  // Function to create and manage grid lines
  const createGridLines = (mainCanvas) => {
    let verticalLine, horizontalLine;

    // Function to create grid lines centered on the canvas
    const createCenteredGridLines = () => {
      const canvasWidth = mainCanvas.width;
      const canvasHeight = mainCanvas.height;
      const centerX = canvasWidth / 2;
      const centerY = canvasHeight / 2;

      // Create vertical center line
      verticalLine = new fabric.Line([centerX, 0, centerX, canvasHeight], {
        stroke: "rgba(155, 87, 255, 0.5)",
        strokeWidth: 1,
        selectable: false,
        evented: false,
        perPixelTargetFind: true,
      });

      // Create horizontal center line
      horizontalLine = new fabric.Line([0, centerY, canvasWidth, centerY], {
        stroke: "rgba(155, 87, 255, 0.5)",
        strokeWidth: 1,
        selectable: false,
        evented: false,
        perPixelTargetFind: true,
      });

      mainCanvas.add(verticalLine, horizontalLine);
    };

    // Function to update grid lines based on object position
    const updateGridLines = (object) => {
      if (!verticalLine || !horizontalLine) return;

      const { left, top, width, height } = object.getBoundingRect();
      const centerX = left + width / 2;
      const centerY = top + height / 2;
      const canvasWidth = mainCanvas.width;
      const canvasHeight = mainCanvas.height;

      // Show vertical line if the object is near the vertical center
      const showVertical =
        centerX <= canvasWidth / 2 + 10 && centerX >= canvasWidth / 2 - 10;
      // Show horizontal line if the object is near the horizontal center
      const showHorizontal =
        centerY <= canvasHeight / 2 + 10 && centerY >= canvasHeight / 2 - 10;

      verticalLine.set("visible", showVertical);
      horizontalLine.set("visible", showHorizontal);

      mainCanvas.requestRenderAll();
    };

    // Function to show grid lines on hover or mouseover
    const showGridLines = (object) => {
      if (!verticalLine || !horizontalLine) return;
      verticalLine.set("visible", true);
      horizontalLine.set("visible", true);
      updateGridLines(object);
    };

    // Function to hide grid lines
    const hideGridLines = () => {
      if (!verticalLine || !horizontalLine) return;
      verticalLine.set("visible", false);
      horizontalLine.set("visible", false);
      mainCanvas.requestRenderAll();
    };

    // Initial setup of grid lines
    createCenteredGridLines();
    hideGridLines(); // Start with grid lines hidden

    // Event listener for object moving
    mainCanvas.on("object:moving", (options) => {
      const { target } = options;
      if (target) {
        isMoving = true;
        updateGridLines(target);
      }
    });

    mainCanvas.on("mouse:up", () => {
      if (isMoving) {
        console.log("moving");
        setTimeout(() => {
          whenRenderFunction(true);
          isMoving = false; // Reset the flag after rendering
        }, 100);
      }
      if (isSpacingChanged) {
        setTimeout(() => {
          whenRenderFunction(true);
          isSpacingChanged = false; // Reset the flag after rendering
        }, 100);
      }
      if (isColorChanged1) {
        setTimeout(() => {
          whenRenderFunction(true);
          isColorChanged1 = false; // Reset the flag after rendering
        }, 100);
      }
      if (isColorChanged2) {
        setTimeout(() => {
          whenRenderFunction(true);
          isColorChanged2 = false; // Reset the flag after rendering
        }, 100);
      }
    });

    // Event listener for object hover
    mainCanvas.on("mouse:over", (options) => {
      const { target } = options;
      if (target) {
        showGridLines(target);
      }
    });

    // Event listener for object hover out
    mainCanvas.on("mouse:out", () => {
      hideGridLines();
    });

    // Cleanup function to remove grid lines
    const removeGridLines = () => {
      if (verticalLine) mainCanvas.remove(verticalLine);
      if (horizontalLine) mainCanvas.remove(horizontalLine);
    };

    return {
      removeGridLines,
    };
  };

  useEffect(() => {
    // Update the background color whenever the canvasColor state changes
    if (mainCanvas) {
      mainCanvas.setBackgroundColor(canvasColor, () => {
        mainCanvas.renderAll();
        handleCanvasUpdate(); //canvas change appy
      });
    }
  }, [canvasColor]);

  const handleFontUpload = async () => {
    if (!file || typeof category !== "string") {
      setValidationMessage("Please choose a file and ensure category is valid");
      return;
    }

    const fontName = file.name.split(".").slice(0, -1).join(".");
    const formData = new FormData();
    formData.append("file_example_download", file);
    formData.append("font_name", fontName);
    formData.append("category", category);

    try {
      setshowLoaderAdmin(true);
      const response = await server_post_data(post_customfont_data, formData);

      if (response.data.error) {
        handleError(response.data.message);
        return;
      }

      const reader = new FileReader();
      reader.onload = async (e) => {
        const fontFace = new FontFace(fontName, `url(${e.target.result})`);
        try {
          const loadedFace = await fontFace.load();
          document.fonts.add(loadedFace);

          setUploadedFonts((prevFonts) => [
            ...prevFonts,
            {
              family: fontName,
              src: e.target.result,
              category: category, // Use the dynamic category here
            },
          ]);

          setFontCategories((prevCategories) => {
            const newCategory = {
              label: category,
              value: category.toLowerCase(),
            };
            if (
              !prevCategories.some((cat) => cat.value === newCategory.value)
            ) {
              return [...prevCategories, newCategory];
            }
            return prevCategories;
          });

          setFonts((prevFonts) => [
            ...prevFonts,
            {
              family: fontName,
              src: e.target.result,
              category: category, // Use the dynamic category here
            },
          ]);
        } catch (error) {
          console.error("Error loading font:", error);
        }
      };
      reader.readAsDataURL(file);
    } catch (error) {
      console.error("Error uploading font:", error);
      handleError("network");
    } finally {
      setshowLoaderAdmin(false);
      setshowFontModal(false);
    }
  };

  const handleKeyDownSelect = (e) => {
    if (e.ctrlKey && e.key === "a") {
      e.preventDefault();
      const index = e.target.dataset.index;
      if (inputRefs[index] && inputRefs[index].current) {
        inputRefs[index].current.select();
      }
    }
  };

  const specificDivRef = useRef(null); // Ref for the specific div
  const textDivRef = useRef(null);

  const handleScroll = () => {
    const container = uploadRef.current;
    const specificDiv = specificDivRef.current;
    const textDiv = textDivRef.current;

    if (container) {
      // Check if "Element" section is visible
      if (specificDiv) {
        const { top, bottom } = specificDiv.getBoundingClientRect();
        const { innerHeight } = window;
        const isDivVisible = top < innerHeight && bottom >= 0;

        if (isDivVisible) {
          // Check if we've scrolled to the bottom of the container
          console.log("DivVisible");
          if (
            container.scrollHeight - container.scrollTop <=
            container.clientHeight
          ) {
            console.log("Scrolled to the bottom in Element!");
            // Perform action for "Element" section
            // get_more_elements();
          }
        }
      }

      // Check if "Text" section is visible
      if (textDiv) {
        const { top, bottom } = textDiv.getBoundingClientRect();
        const { innerHeight } = window;
        const isDivVisible = top < innerHeight && bottom >= 0;

        if (isDivVisible) {
          // Check if we've scrolled to the bottom of the container
          if (
            container.scrollHeight - container.scrollTop <=
            container.clientHeight
          ) {
            console.log("Scrolled to the bottom in Text!");
            // Perform action for "Text" section
            loadMoreFonts();
          }
        }
      }
    }
  };

  useEffect(() => {
    const container = uploadRef.current;
    if (container) {
      console.log("uploadRef");
      container.addEventListener("scroll", handleScroll);
    }
    // Cleanup listener on component unmount
    return () => {
      if (container) {
        container.removeEventListener("scroll", handleScroll);
      }
    };
  }, []);

  const filterFonts = (query, category) => {
    const combinedFonts = [...fonts, ...uploadedFonts];
    const uniqueFonts = combinedFonts.filter(
      (font, index, self) =>
        index ===
        self.findIndex(
          (f) => f.family.toLowerCase() === font.family.toLowerCase()
        )
    );

    let filtered = uniqueFonts;

    // Handle custom category logic
    if (category && category.value === "Custom") {
      filtered = filtered.filter((font) =>
        uploadedFonts.some((customFont) => customFont.family === font.family)
      );
    } else if (category && category.value !== "All") {
      filtered = filtered.filter(
        (font) =>
          font.category &&
          font.category.toLowerCase() === category.value.toLowerCase()
      );
    }

    if (query && query.trim() !== "") {
      filtered = filtered.filter(
        (font) =>
          font.family && font.family.toLowerCase().includes(query.toLowerCase())
      );
    }

    // Filter out fonts with 'Material' in their family name
    filtered = filtered.filter(
      (font) => !font.family.toLowerCase().includes("material")
    );

    setFilteredFonts(filtered);
  };

  const handleCategoryChange = (selectedOptions) => {
    setSelectedCategory(selectedOptions || { value: "All", label: "All" });
    filterFonts(
      fontSearchQuery,
      selectedOptions || { value: "All", label: "All" }
    );
    scrollToTop();
  };
  const loadMoreFonts = () => {
    setInitialFontCount((prevCount) => prevCount + 12);
  };

  const scrollToTop = () => {
    const container = uploadRef.current;
    if (container) {
      container.scrollTo({
        top: 0,
        behavior: "smooth", // You can use 'auto' if you don't want smooth scrolling
      });
    }
  };

  const handleFontSelection = (selectedFont) => {
    handleClose();
    const fontFamily = selectedFont.family;

    const activeObject = mainCanvas.getActiveObject();

    if (activeObject && activeObject.type === "i-text") {
      activeObject.set({ fontFamily });

      // Preserve custom width after font family change
      const customWidth = activeObject.customWidth || activeObject.width;
      activeObject.set({
        width: customWidth, // Reapply custom width
        scaleX: 1,
        scaleY: 1,
      });

      mainCanvas.renderAll();
      whenRenderFunction(true);
    } else {
      createTextInput("Add Text", fontFamily);
    }

    setSelectedFontFamily(fontFamily);
  };

  const handleFontButtonClick = () => {
    handleCloseToolbar();
    // Toggle the states for 'isOpentext' and 'isActive2'
    setIsOpentext((prev) => !prev);
    setIsActive2((prev) => !prev);

    // Deactivate other states and their active classes
    setIsOpenicon(false);
    setIsOpenBg(false);
    setIsOpenshape(false);
    setIsOpenTemplate(false);
    setIsOpenAbout(false);
    setIsOpenAbout2(false);
    setIsOpenuploads(false);
    setIsOpenPictures(false);
    setIsColor(false);

    setIsActive3(false);
    setIsActive4(false);
    setIsActive5(false);

    setIsActive7(false);
    setIsActive10(false);
    setIsActive9(false);

    // Set the flag to replace text
    setReplaceText((prev) => !prev);

    // Apply font filter
    filterFonts(fontSearchQuery, selectedCategory);
  };

  const toPascalCase = (str) => {
    if (typeof str !== "string") {
      console.error("Invalid input: Expected a string");
      return ""; // Return an empty string or a default value
    }

    return str
      .replace(/[_-]/g, " ")
      .split(" ")
      .map((word) => word.charAt(0).toUpperCase() + word.slice(1).toLowerCase())
      .join(" ");
  };
  //font txt section
  const customStyles3 = {
    control: (base, state) => ({
      ...base,
      fontFamily: "Roboto, sans-serif",
      fontWeight: 400,
      fontSize: 15,
      textAlign: "left",
      paddingLeft: 8,
      paddingTop: 6,
      paddingBottom: 6,
      boxShadow: "none",
      borderRadius: 4,
    }),
    singleValue: (base) => ({
      ...base,
      fontFamily: "Roboto, sans-serif",
      fontWeight: 400,
      fontSize: 15,
      color: "black",
      textAlign: "left",
      backgroundColor: "transparent", // Set the background color to transparent
    }),
    placeholder: (base) => ({
      ...base,
      fontFamily: "Roboto, sans-serif",
      fontWeight: 400,
      fontSize: 15,
      color: "#a7a7a7",
      textAlign: "left",
    }),
    option: (base, state) => ({
      ...base,
      fontFamily: "Roboto, sans-serif",
      fontWeight: 300,
      fontSize: 15,
      color: "#a7a7a7",
      textAlign: "left",
      borderRadius: 7,
      cursor: "pointer",
      backgroundColor: state.isSelected ? "transparent" : base.backgroundColor,
    }),
    clearIndicator: (base) => ({
      ...base,
      display: "none",
    }),
  };
  const truncateText = (text, maxLength) => {
    const replacedText = text.replace(/-/g, " ");
    return replacedText.length > maxLength
      ? `${replacedText.substring(0, maxLength)}...`
      : replacedText;
  };

  const maxLength = 12; // Maximum length for truncation
  const truncatedFontFamily = truncateText(selectedFontCanvasFamily, maxLength);

  const buttonClass =
    replaceText && selectedFontFamily === selectedFontCanvasFamily
      ? "activeButton"
      : "";

  const imgSrc =
    replaceText && selectedFontFamily === selectedFontCanvasFamily
      ? fontFam
      : fontFamilYblueee;

  const handleUploadValidation = () => {
    if (!category || !file) {
      setValidationMessage("Please fill out all fields and choose a file");
      return;
    }
    handleFontUpload();
  };

  const categoryOptions = [
    { value: "All", label: "All" },
    ...fontCategories.map((cat) => ({
      value: cat,
      label: toPascalCase(cat),
    })),
    { value: "Custom", label: "Custom" },
  ];
  const handleFileChange = (e) => {
    setFile(e.target.files[0]);
  };
  const handleFontCategoryChange = (e) => {
    setCategory(e.target.value);
  };
  //Mobile view hide class toolsidebar
  useEffect(() => {
    const handleResize = () => {
      setWindowWidth(window.innerWidth);
    };

    window.addEventListener("resize", handleResize);

    // Cleanup
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  useEffect(() => {
    // Function to handle click outside events
    const handleOutsideClick = (ref, handler) => {
      const handleClick = (event) => {
        if (ref.current && !ref.current.contains(event.target)) {
          handler(false);
        }
      };

      document.addEventListener("mousedown", handleClick);
      return () => {
        document.removeEventListener("mousedown", handleClick);
      };
    };

    // Handlers for outside click events
    const navOutsideClickHandler = () => setShowSecondButton(false);
    const OpecityHideHandler = () => setShowOpacityInput(false);
    const downloadOutsideClickHandler = () => setShowSecondButton2(false);
    const alignmentOutsideClickHandler = () => {
      setIsVisible(false);
      setIsVisible2(false);
    };
    const positionOutsideClickHandler = () => SetPossitionsLayers(false);
    const flipOutsideClickHandler = () => setShowFlipButtons(false);

    const resizeOutsideClickHandler = () => setShowResize(false);

    // Add event listeners for each ref
    const cleanupFunctions = [
      handleOutsideClick(navRef, navOutsideClickHandler),
      handleOutsideClick(downloadRef, downloadOutsideClickHandler),
      handleOutsideClick(aligmentRef, alignmentOutsideClickHandler),
      handleOutsideClick(PosstionLayer, positionOutsideClickHandler),
      handleOutsideClick(FlipLayer, flipOutsideClickHandler),

      handleOutsideClick(resizeRef, resizeOutsideClickHandler),
      handleOutsideClick(opacityInputRef, OpecityHideHandler),
    ];

    // Clean-up function to remove all event listeners
    return () => {
      cleanupFunctions.forEach((cleanup) => cleanup());
    };
  }, [showSecondButton, showSecondButton2, showResize]);

  // Effect for resizing
  useEffect(() => {
    const handleClickOutsideResize = (event) => {
      if (resizeRef.current && !resizeRef.current.contains(event.target)) {
        setShowResize(false);
      }
    };

    document.addEventListener("mousedown", handleClickOutsideResize);
    return () => {
      document.removeEventListener("mousedown", handleClickOutsideResize);
    };
  }, [showResize]);
  const handleClickOutLetterside = (event) => {
    if (letterRef.current && !letterRef.current.contains(event.target)) {
      setLetterSpacingVisible(false);
    }
  };

  // Add and remove event listener on mount and unmount
  useEffect(() => {
    document.addEventListener("mousedown", handleClickOutLetterside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutLetterside);
    };
  }, []);
  // Dependencies that trigger cleanup
  const handleIconData = (iconData) => {
    fetch(iconData.url)
      .then((response) => {
        if (!response.ok) {
          throw new Error("Network response was not ok");
        }
        return response.text();
      })
      .then((svgContent) => {
        const modifiedSvgData = svgContent.replace(
          'viewBox="0 0 100 100"',
          'viewBox="0 0 100 100" fill="#000000"'
        );
        handleImageClickIcon(iconData.url, modifiedSvgData, "#000000");
      })
      .catch((error) => {
        console.error("There was a problem fetching the SVG content:", error);
      });
  };
  const handleIconData2 = (icon, color) => {
    // Get the svg_content directly from the icon data
    const shapeContent = icon.svg_content;

    // Modify the SVG fill color dynamically
    const modifiedSvgData = shapeContent.replace(
      'fill="black"',
      `fill="${color}"`
    );

    handleImageClickIcon(modifiedSvgData, color);
  };
  // Function to center text on the canvas
  const centerTextOnCanvas = (textObject) => {
    const canvasWidth = mainCanvas.width;
    const canvasHeight = mainCanvas.height;

    const newLeft = (canvasWidth - textObject.width) / 2;
    const newTop = (canvasHeight - textObject.height) / 2;

    textObject.set({
      left: newLeft,
      top: newTop,
    });
    textObject.setCoords();
    mainCanvas.requestRenderAll();
  };

  const updateCanvasDimensions = (width, height) => {
    const containerWidth = mainCanvasRef.current.clientWidth;
    const containerHeight = mainCanvasRef.current.clientHeight;

    mainCanvas.setDimensions({
      width: width || containerWidth,
      height: height || containerHeight,
    });
    mainCanvas.getObjects("i-text").forEach(centerTextOnCanvas);
  };

  // canvas show in the le
  //zoom  in and out the canvas
  const handleZoomIn = () => {
    const zoomStep = 10;
    setZoomLevel((prevZoom) => prevZoom + zoomStep);
  };

  const handleZoomOut = () => {
    const zoomStep = 10;
    setZoomLevel((prevZoom) => prevZoom - zoomStep);
  };
  const zoomStyle = {
    transform: `scale(${zoomLevel / 100})`,
    transformOrigin: "center top",
  };
  const containerClassName =
    data_type === "letterhead" ? "convasBGcontainer2" : "convasBGcontainer";
  //canvas dimention change
  //upload img remove last used img   //Delete Get Uploads img

  //Upload img

  const handleCanvasUpdate = (data_type) => {
    // Clear the secondary canvas
    // Get the dimensions of the main canvas
    const mainCanvasWidth = mainCanvas.width;
    const mainCanvasHeight = mainCanvas.height;

    // Define width and height of the T-shirt canvas (configurable)
    const tshirtCanvasWidth = 160;
    const tshirtCanvasHeight = 160;

    // Calculate scaling factors for width and height
    const scaleX = tshirtCanvasWidth / mainCanvasWidth;
    const scaleY = tshirtCanvasHeight / mainCanvasHeight;

    // Take the minimum scaling factor to ensure content fits
    const scaleFactor = Math.min(scaleX, scaleY);
    // Get the data URL of the main canvas
    const mainCanvasDataURL = mainCanvas.toDataURL();

    // Create an image object
    const img = new Image();

    // Set the source of the image and handle errors
    img.src = mainCanvasDataURL;
    img.onerror = () => {
      console.error("Error loading image from main canvas data URL");
    };

    // When the image is loaded, draw it onto the secondary canvas
    img.onload = function () {
      const imageWidth = img.width;
      const imageHeight = img.height;

      // Calculate scaled width and height based on scaleFactor
      const scaledWidth = imageWidth * scaleFactor;
      const scaledHeight = imageHeight * scaleFactor;

      // Draw the scaled image onto the secondary canvas
      if (data_type === "Logo") {
        const ctx = mainCanvas.getContext("2d");

        // Center the image if there's extra space (optional)
        if (
          scaledWidth < tshirtCanvasWidth ||
          scaledHeight < tshirtCanvasHeight
        ) {
          const horizontalOffset = (tshirtCanvasWidth - scaledWidth) / 2;
          const verticalOffset = (tshirtCanvasHeight - scaledHeight) / 2;
          ctx.drawImage(
            img,
            horizontalOffset,
            verticalOffset,
            scaledWidth,
            scaledHeight
          );
        }
      }

      // Update the data URL of the secondary canvas
      setSecondaryCanvasDataURL(mainCanvasDataURL);
    };
  };

  const handleImageClickIcon = async (src, svg_data, color_code) => {
    if (!mainCanvas) return;
    try {
      const svgDataUrl = `data:image/svg+xml;base64,${btoa(svg_data)}`;

      const imgElement = new Image();
      imgElement.onload = function () {
        const iconId = `icon-${mainCanvas.getObjects("image").length}`; // Assign unique ID
        const timestamp = new Date().getTime().toString(36); // Convert the current time to a base-36 string
        const randomNumber = Math.floor(Math.random() * 1000000000).toString(
          36
        );
        const fabricImage = new fabric.Image(imgElement, {
          left: 100,
          top: 100,
          fill: color_code,
          id: iconId, // Assign unique ID
          MkIconId: `MkIconId_${timestamp}_${randomNumber}`, // Assign unique ID
          metadata: {
            role: "icon",
            timestamp: new Date().toISOString(),
          },
        });
        fabricImage.MkIconId = new Date().toISOString();
        fabricImage.metadata = {
          role: "icon",
          timestamp: new Date().toISOString(),
        };
        //console.log("Fabric Image Object:", fabricImage); // Should log the fabric image object
        //console.log("Metadata:", fabricImage.metadata); // Should log the metadata object
        mainCanvas.add(fabricImage).setActiveObject(fabricImage);
        mainCanvas.renderAll();
        whenRenderFunction(true); //when icon add
        const addedObject = mainCanvas.getActiveObject();
        //console.log("Metadata from mainCanvas:", addedObject.metadata);
        //console.log("Added Icon ID:", iconId); // Log added icon ID
      };
      imgElement.src = svgDataUrl;
    } catch (error) {
      console.error("Error loading image:", error);
    }
  };
  const handleBackClick = () => {
    setCurrentContent("main");
  };

  const handleCanvasColorChange = (event, gradientColors) => {
    const newColor = event.target.value;
    setCanvasColor(newColor);

    if (mainCanvas) {
      const context = mainCanvas.getContext("2d");
      context.clearRect(0, 0, mainCanvas.width, mainCanvas.height);

      if (gradientColors && gradientColors.length > 0) {
        const gradient = context.createLinearGradient(
          0,
          0,
          mainCanvas.width,
          0
        );
        gradientColors.forEach((color, index) => {
          gradient.addColorStop(index / (gradientColors.length - 1), color);
        });

        context.fillStyle = gradient;
      } else {
        context.fillStyle = newColor;
      }

      context.fillRect(0, 0, mainCanvas.width, mainCanvas.height);
    }
  };
  //increase Decrase fonts on the edit tool bar
  const fontSizes = [
    6, 8, 10, 12, 14, 16, 18, 21, 24, 28, 32, 36, 42, 48, 56, 64, 72, 80, 88,
    96, 104, 120, 144,
  ];

  //increase Decrase fonts on the edit tool bar
  const handleFontSizeChange = (sizeChange) => {
    const activeObject = mainCanvas.getActiveObject();
    if (activeObject && activeObject.type === "i-text") {
      let currentFontSize = activeObject.get("fontSize");
      let newFontSize = currentFontSize + sizeChange;

      // Ensure font size doesn't go below 1px
      if (newFontSize < 1) newFontSize = 1;

      // Log font size before change

      // Set the new font size on the active object
      activeObject.set("fontSize", newFontSize);

      // Update customWidth proportionally based on the new font size
      const scaleFactor = newFontSize / currentFontSize;
      activeObject.customWidth =
        (activeObject.customWidth || activeObject.width) * scaleFactor;

      // Apply the new dimensions
      activeObject.set({
        width: activeObject.customWidth,
        scaleX: 1,
        scaleY: 1,
      });

      // Re-render the canvas to apply the changes
      mainCanvas.requestRenderAll();

      // Update the selected font size state
      setSelectedFontSize(newFontSize);
    }
  };

  // Function to change font style

  const handleFontStyleChange = (toggleBold, toggleItalic) => {
    const activeObject = mainCanvas.getActiveObject();
    if (activeObject && activeObject.type === "i-text") {
      // Preserve custom width
      const customWidth = activeObject.customWidth || activeObject.width;

      if (toggleBold) {
        const isCurrentlyBold = activeObject.fontWeight === "bold";
        activeObject.set("fontWeight", isCurrentlyBold ? "normal" : "bold");
      }

      if (toggleItalic) {
        const isCurrentlyItalic = activeObject.fontStyle === "italic";
        activeObject.set("fontStyle", isCurrentlyItalic ? "normal" : "italic");
      }

      // Restore the custom width after changing style
      activeObject.set({
        width: customWidth,
        customWidth, // Ensure customWidth is preserved
        scaleX: 1,
        scaleY: 1,
      });

      mainCanvas.renderAll();
      whenRenderFunction(true);
    }
  };

  const handleLetterSpacingChange = (e) => {
    const value = e.target.value;
    setLetterSpacingValue(value);

    const activeObject = mainCanvas.getActiveObject();
    if (activeObject && activeObject.type === "i-text") {
      // Preserve custom width
      const customWidth = activeObject.customWidth || activeObject.width;

      // Apply letter spacing
      activeObject.set("charSpacing", value * 10);

      // Restore custom width after changing letter spacing
      activeObject.set({
        width: customWidth,
        customWidth, // Ensure customWidth is preserved
        scaleX: 1,
        scaleY: 1,
      });

      mainCanvas.renderAll();
      isSpacingChanged = true;
    }
  };

  const handleheightSpacingChange = (e) => {
    const value = e.target.value;
    setheightSpacingValue(value);

    const activeObject = mainCanvas.getActiveObject();
    if (activeObject && activeObject.type === "i-text") {
      // Preserve custom width
      const customWidth = activeObject.customWidth || activeObject.width;

      // Apply line height
      activeObject.set("lineHeight", value / 50); // Adjust the divisor as needed for proper scaling

      // Restore custom width after changing line height
      activeObject.set({
        width: customWidth,
        customWidth, // Ensure customWidth is preserved
        scaleX: 1,
        scaleY: 1,
      });

      mainCanvas.renderAll();
      //whenRenderFunction(true);
    }
  };

  const createTextInput = (
    sclttab,
    text,
    fontFamily,
    left = 50,
    top = 50,
    isEditable = true,
    fontSize = selectedFontSize
  ) => {
    if (!fontFamily) {
      console.error("Font family is undefined or null. Using default font.");
      fontFamily = "Arial"; // or any other default font
    }

    const offset = 20;
    const randomLeft = left + Math.random() * offset - offset / 2;
    const randomTop = top + Math.random() * offset - offset / 2;

    const newText = new fabric.IText(text, {
      left: randomLeft,
      top: randomTop,
      fill: "black",
      fontFamily: fontFamily,
      fontSize: fontSize,
      charSpacing: letterSpacingValue * 10,
      editable: isEditable,
      metadata: {
        role: sclttab,
        timestamp: new Date().toISOString(),
      },
      textAlign: "left",
    });

    // Initialize customWidth
    newText.customWidth = newText.width;

    // Set initial properties
    newText.set({
      width: newText.customWidth,
      scaleX: 1,
      scaleY: 1,
    });

    newText.hasControls = true;
    newText.hasBorders = true;

    mainCanvas.add(newText);
    mainCanvas.setActiveObject(newText);

    if (isEditable) {
      newText.enterEditing();
      newText.selectAll();
    }

    newText.on("selected", () => {
      setSelectedFontSize(newText.get("fontSize"));
    });

    newText.on("changed", () => {
      // Calculate the actual text width
      const textWidth = newText.calcTextWidth();

      // Check if text width exceeds customWidth
      if (textWidth > newText.customWidth) {
        newText.customWidth = textWidth + 1; // Increase customWidth slightly to accommodate the text

        // Apply the updated customWidth without affecting scale
        newText.set({
          width: newText.customWidth,
          scaleX: 1,
          scaleY: 1,
        });
        newText.setCoords();
        mainCanvas.requestRenderAll();
      }
    });

    newText.on("scaling", (e) => {
      const target = e.target;
      if (target && target.type === "i-text") {
        // Ensure customWidth is applied during scaling
        target.set({
          width: target.customWidth || target.width,
          scaleX: 1,
          scaleY: 1,
        });
        whenRenderFunction(true);
      }
    });

    newText.on("editing:entered", () => {
      // Save customWidth when editing starts
      newText.customWidth = newText.width;
    });

    newText.on("editing:exited", () => {
      // Restore customWidth if it is set, otherwise use default width
      newText.set({
        width: newText.customWidth || newText.width,
        scaleX: 1,
        scaleY: 1,
      });
      newText.setCoords();
      mainCanvas.requestRenderAll();
    });
    // Center the text on the canvas
    centerTextOnCanvas(newText);
  };

  //text convert into capital
  const convertTextToUpperCase = () => {
    const activeObject = mainCanvas.getActiveObject();
    if (!activeObject || activeObject.type !== "i-text") return;

    // Preserve custom width
    const customWidth = activeObject.customWidth || activeObject.width;

    if (activeObject.originalText) {
      // Revert to original text
      activeObject.set({ text: activeObject.originalText });
      delete activeObject.originalText;
    } else {
      // Save the original text and convert to uppercase
      activeObject.originalText = activeObject.text;
      const upperCaseText = activeObject.text.toUpperCase();
      activeObject.set({ text: upperCaseText });
    }

    // Restore the custom width after changing text
    activeObject.set({
      width: customWidth,
      customWidth,
      scaleX: 1,
      scaleY: 1,
    });

    mainCanvas.renderAll();
    whenRenderFunction(true);
  };
  // Function to change text color
  const handleTextColorChange = (color) => {
    const activeObject = mainCanvas.getActiveObject();

    if (activeObject && activeObject.type === "i-text") {
      const selectionStart = activeObject.selectionStart;
      const selectionEnd = activeObject.selectionEnd;

      if (selectionStart === selectionEnd) {
        activeObject.set("fill", color);
      } else {
        for (let i = selectionStart; i < selectionEnd; i++) {
          activeObject.setSelectionStyles({ fill: color }, i, i + 1);
        }
      }

      activeObject.dirty = true;
      mainCanvas.requestRenderAll();
      isColorChanged1 = true;
    }
  };
  const getFillColorFromSVG = (svgData) => {
    // Create a DOM parser to parse the SVG string
    const parser = new DOMParser();
    const doc = parser.parseFromString(svgData, "image/svg+xml");

    // Get the <svg> element
    const svgElement = doc.querySelector("svg");

    // Return the fill color from the <svg> element
    return svgElement ? svgElement.getAttribute("fill") : null;
  };
  const handleIconColorChange = async (newColor, color_id) => {
    clearTimeout(colorTimeout); // Clear any existing timeout
    let oldColor = $("#" + color_id).attr("color_code");
    const activeObject = mainCanvas.getActiveObject();
    const objData = activeObject;
    let svgDataUrl = objData.src;
    if (!svgDataUrl) {
      svgDataUrl = objData.getSrc();
    }
    const newcolor_code = newColor;

    if (newcolor_code) {
      try {
        const response = await fetch(svgDataUrl);
        const svgData = await response.text();

        const fillColor = getFillColorFromSVG(svgData);
        const modifiedSvgData = replaceColorInSVG(
          svgData,
          fillColor,
          newcolor_code
        );
        // Set the modified SVG data to the object
        activeObject.setSrc(
          "data:image/svg+xml;base64," + btoa(modifiedSvgData),
          () => {
            mainCanvas.renderAll(); // Re-render the canvas
            isColorChanged2 = true;
            // Set a timer of 0.1 seconds (100 milliseconds)
          }
        );
      } catch (error) {
        console.error("Error fetching SVG data:", error);
      }
    }

    return;
  };

  const replaceColorInSVG = (svgData, oldColor, newColor) => {
    // Replace the old color with the new color where fill exists
    let modifiedSvgData = svgData.replace(
      new RegExp(`fill\\s*=\\s*["']${oldColor}["']`, "gi"),
      `fill="${newColor}"`
    );

    // Add fill attribute where it's missing
    modifiedSvgData = modifiedSvgData.replace(
      /(<(svg)[^>]*)(>)/gi,
      (match, p1, p2, p3) => {
        if (!/fill\s*=/.test(match)) {
          // If no fill attribute is present, add it
          return `${p1} fill="${newColor}"${p3}`;
        }
        return match;
      }
    );
    //console.log(modifiedSvgData);
    return modifiedSvgData;
  };
  //redo objects

  const redoDelete = () => {
    if (redoObjects.length > 0) {
      const objToRedo = redoObjects.pop();
      mainCanvas.remove(objToRedo);
      deletedObjects.push(objToRedo);

      mainCanvas.renderAll();

      updateButtons();
    }
  };
  //undo objects
  const undoDelete = () => {
    if (deletedObjects.length > 0) {
      const obj = deletedObjects.pop();
      if (obj) {
        mainCanvas.add(obj);
        redoObjects.push(obj);
        mainCanvas.renderAll();
        updateButtons();
      }
    }
  };

  // const whenRenderFunction = (savehistory, firstload = 0) => {
  //   if (savehistory === true) {
  //     if (firstload === 0) {
  //       let myjson = JSON.stringify(mainCanvas);
  //       //console.log(myjson);
  //       state.push(myjson);
  //     } else {
  //       // console.log("first load");
  //       state.push(firstload);
  //     }
  //   }
  // };

  // //undo objects
  // const handleUndo = () => {
  //   if (mods < state.length) {
  //     if (state[state.length - 1 - mods - 1]) {
  //       mainCanvas.clear().renderAll();
  //       mainCanvas.loadFromJSON(state[state.length - 1 - mods - 1]);
  //       mainCanvas.renderAll();
  //     }
  //     const mty = state.length - 1 - mods - 1;

  //   mainCanvas.clear().renderAll();
  //   if(mty<0){
  //     mainCanvas.loadFromJSON(state[0]);
  //   }else{
  //   mainCanvas.loadFromJSON(state[state.length - 1 - mods - 1]);
  // }
  //   mainCanvas.renderAll();
  //     mods += 1;
  //     if (mods == 0) {
  //     }
  //   }
  // };

  let history = []; // Store canvas states
  let redoStack = []; // Store states for redo operations
  let currentIndex = -1; // Index to track current state

  // Function to save the state
  const saveState = () => {
    const currentState = JSON.stringify(mainCanvas);

    // If we're not at the most recent state (due to undo), clear redoStack and future history
    if (currentIndex < history.length - 1) {
      history = history.slice(0, currentIndex + 1);
      redoStack = [];
    }

    history.push(currentState);
    currentIndex = history.length - 1; // Update current index

    console.log("State saved:", history.length);
  };

  // Triggered when the canvas is rendered or updated
  const whenRenderFunction = (saveHistory = true) => {
    if (saveHistory) {
      saveState(); // Save canvas state to history
    }
  };

  // Undo operation
  const handleUndo = () => {
    if (currentIndex > 0) {
      redoStack.push(history[currentIndex]);
      currentIndex--;
      const stateToLoad = history[currentIndex];

      if (stateToLoad) {
        mainCanvas.loadFromJSON(stateToLoad, () => {
          mainCanvas.getObjects().forEach((obj) => {
            if (obj.type === "i-text" && obj.customWidth) {
              obj.set({
                width: obj.customWidth,
                scaleX: 1,
                scaleY: 1,
              });
              obj.setCoords();
            }
          });
          mainCanvas.renderAll();
        });
      }
    }
  };

  // Redo operation
  const handleRedo = () => {
    if (redoStack.length > 0) {
      const stateToRedo = redoStack.pop();
      history.push(stateToRedo);
      currentIndex++;

      if (stateToRedo) {
        mainCanvas.loadFromJSON(stateToRedo, () => {
          mainCanvas.getObjects().forEach((obj) => {
            if (obj.type === "i-text" && obj.customWidth) {
              obj.set({
                width: obj.customWidth,
                scaleX: 1,
                scaleY: 1,
              });
              obj.setCoords();
            }
          });
          mainCanvas.renderAll();
        });
      }
    }
  };

  //delete objects

  const deleteSelectedObject = () => {
    setIsObjectSelected(true);
    setshopcolor(false);
    const activeObject = mainCanvas.getActiveObject();

    if (activeObject && activeObject.type === "activeSelection") {
      // Handle active selection (multiple objects selected)
      //console.log('group');
      const selectedObjects = activeObject.getObjects();
      selectedObjects.forEach((obj) => {
        handleDelete(obj);
      });
      mainCanvas.discardActiveObject();
      mainCanvas.renderAll();
      // UserChangingIconSelection();
    } else if (activeObject) {
      // Handle single object selected
      //console.log('else');
      handleDelete(activeObject);
      mainCanvas.renderAll();
      // UserChangingIconSelection();
      whenRenderFunction(true);
    }

    redoObjects = [];
    updateButtons();
  };
  const handleDelete = (obj) => {
    if (obj.type === "i-text") {
      handleTextDelete(obj);
    } else {
      handleObjectDelete(obj);
    }
  };

  const handleTextDelete = (obj) => {
    if (obj.type === "i-text") {
      if (obj.isEditing) {
        // If currently editing, delete a single character
        const text = obj.text;
        const cursorStart = obj.selectionStart;
        const cursorEnd = obj.selectionEnd;
        if (cursorStart !== cursorEnd) {
          // Delete selected text
          obj.removeChars(cursorStart, cursorEnd - cursorStart);
        } else {
          // Delete single character at cursor position
          if (cursorStart > 0) {
            obj.removeChars(cursorStart - 1, 1);
          }
        }
      } else {
        // If not editing, delete the entire object
        if (
          obj.metadata &&
          (obj.metadata.role === "company_name" ||
            obj.metadata.role === "slogan")
        ) {
          if (obj.metadata.role === "company_name") {
            setcompany_nametext(false);
          } else if (obj.metadata.role === "slogan") {
            setslogantext(false);
          }
        }
        mainCanvas.remove(obj);
        deletedObjects.push(obj);
      }
    }
  };

  const handleObjectDelete = (obj) => {
    // If not a text object, delete the entire object
    mainCanvas.remove(obj);
    deletedObjects.push(obj);
    setIsObjectSelected(true);
    setshopcolor(false);
  };
  const handleDeleteClick = () => {
    deleteSelectedObject();
  };
  const updateButtons = () => {
    const undoButton = document.getElementById("undoButton");
    const redoButton = document.getElementById("redoButton");
    const undoImageSrc = deletedObjects.length === 0 ? redoIcn : redoIcnBlack;
    const redoImageSrc = redoObjects.length === 0 ? undoIcn : undoIcnBlack;

    undoButton.innerHTML = `<img src="${undoImageSrc}" alt="Undo">`;
    redoButton.innerHTML = `<img src="${redoImageSrc}" alt="Redo">`;
  };
  const handleKeyDown = (event) => {
    if (event.key === "Delete") {
      deleteSelectedObject();
      redoDelete(); // Function to redo deletion
    } else if (event.ctrlKey && event.key === "z") {
      //event.preventDefault();
      handleUndo();
      //console.log("click on z");
      //mainCanvas.undo()
    } else if (event.ctrlKey && event.key === "y") {
      //event.preventDefault();
      handleRedo();
      //mainCanvas.redo()
    } else if (event.ctrlKey && event.key === "x") {
      cutSelectedObject(); // Function to cut selected object(s)
    } else if (event.ctrlKey && event.key === "c") {
      copySelectedObject(); // Function to copy selected object(s)
    } else if (event.ctrlKey && event.key === "v") {
      pasteCopiedObject(); // Function to paste copied object(s)
    } else if (event.ctrlKey && event.key === "a") {
      handleSelectAll(event); // Function to select all objects
    } else if (event.ctrlKey && event.key === "b") {
      handleFontStyleChange(true, false);
    } else if (event.ctrlKey && event.key === "i") {
      handleFontStyleChange(false, true);
    } else if (event.ctrlKey && (event.key === "." || event.key === "=")) {
      handleFontSizeChange(1);
    } else if (event.ctrlKey && event.key === ",") {
      handleFontSizeChange(-1);
    } else if (event.ctrlKey && event.key === "d") {
      event.preventDefault();
      duplicateObject();
    } else if (event.ctrlKey && event.shiftKey && event.key === "L") {
      event.preventDefault();
      toggleAlignment("left"); // Left align text
    } else if (event.ctrlKey && event.shiftKey && event.key === "C") {
      event.preventDefault();
      toggleAlignment("center"); // Center align text
    } else if (event.ctrlKey && event.shiftKey && event.key === "R") {
      event.preventDefault();
      toggleAlignment("right"); // Right align text
    } else if (event.ctrlKey && event.altKey && event.key === ".") {
      event.preventDefault();

      handleLetterSpacingChange({
        target: { value: (parseFloat(letterSpacingValue) || 0) + 1 },
      });
    } else if (event.ctrlKey && event.altKey && event.key === ",") {
      event.preventDefault();
      handleLetterSpacingChange({
        target: { value: (parseFloat(letterSpacingValue) || 0) - 1 },
      });
    } else if (event.ctrlKey && event.shiftKey && event.key === "K") {
      event.preventDefault();

      convertTextToUpperCase();
    }
  };

  // Add and remove event listener on mount and unmount
  useEffect(() => {
    const handleClickOutLetterside = (event) => {
      if (
        letterRef.current &&
        !letterRef.current.contains(event.target) &&
        buttonRef.current &&
        !buttonRef.current.contains(event.target)
      ) {
        setLetterSpacingVisible(false);
      }
    };

    document.addEventListener("mousedown", handleClickOutLetterside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutLetterside);
    };
  }, []);
  // Select All Objects shortcut
  const handleSelectAll = (event) => {
    if (event.key === "a" && event.ctrlKey) {
      event.preventDefault();
      const objects = mainCanvas.getObjects();
      mainCanvas.discardActiveObject();

      if (objects.length > 0) {
        const selection = new fabric.ActiveSelection(objects, {
          canvas: mainCanvas,
        });
        mainCanvas.setActiveObject(selection);
        mainCanvas.requestRenderAll();
        setIsGroupObj(true);
        setIsTextToolVisible(false);
        setIsObjectSelected(false); // Hide object-selected toolbar
        //console.log("All objects selected, showing group toolbar.");
      }
    }
    const activeObject = mainCanvas.getActiveObject();
    if (!activeObject) return;

    if (event.key === "ArrowUp") {
      event.preventDefault();
      activeObject.set("top", activeObject.top - 1);
      mainCanvas.requestRenderAll();
    } else if (event.key === "ArrowDown") {
      event.preventDefault();
      activeObject.set("top", activeObject.top + 1);
      mainCanvas.requestRenderAll();
    } else if (event.key === "ArrowLeft") {
      event.preventDefault();
      activeObject.set("left", activeObject.left - 1);
      mainCanvas.requestRenderAll();
    } else if (event.key === "ArrowRight") {
      event.preventDefault();
      activeObject.set("left", activeObject.left + 1);
      mainCanvas.requestRenderAll();
    }
  };

  // Function to cut selected object(s)
  const cutSelectedObject = () => {
    const activeObject = mainCanvas.getActiveObject();
    if (activeObject) {
      if (activeObject.type === "i-text") {
        // Do nothing if the active object is a text object
        return;
      }
      copyToClipboard(activeObject); // Copy to clipboard
      deleteSelectedObject(); // Delete selected object(s)
    }
  };
  // Function to copy selected object(s)
  const copySelectedObject = () => {
    const activeObject = mainCanvas.getActiveObject();
    if (activeObject) {
      if (activeObject.type === "i-text") {
        return;
      }
      copyToClipboard(activeObject); // Copy to clipboard
    }
  };
  // Function to paste copied object(s)
  const pasteCopiedObject = () => {
    if (clipboard) {
      fabric.util.enlivenObjects([clipboard], (objects) => {
        const pastedObject = objects[0];
        pastedObject.set({
          left: 10,
          top: 10,
        });
        mainCanvas.add(pastedObject);
        mainCanvas.setActiveObject(pastedObject);
        mainCanvas.requestRenderAll();
      });
    }
  };

  // Function to copy object to clipboard
  const copyToClipboard = (object) => {
    clipboard = fabric.util.object.clone(object);
  };
  //open color pallate
  const handleButtonClick = () => {
    colorPickerRef.current.click();
  };

  //canvas Background color change

  const colors = [
    {
      title: "Gradient Colors",
      list: [
        ["#ff7e5f", "#feb47b"],
        ["#6a11cb", "#2575fc"],
        ["#f953c6", "#b91d73"],
        ["#00f260", "#0575e6"],
        ["#e1eec3", "#f05053"],
        ["#ffb6b9", "#f0a6ca"],
        ["#11998e", "#38ef7d"],
        ["#5c258d", "#4389a2"],
        ["#4da0b0", "#d39d38"],
        ["#0b486b", "#3b8686"],
      ],
    },
    {
      title: "Logo Colors",
      list: ["#310b00", "#fefefe", "#757475", "#b6b6b6"],
    },

    {
      title: "Default Colors",
      list: [
        "#ebff00",
        "#ff7448",
        "#ff000f",
        "#374bff",
        "#FF616A",
        "#ca6ce6",
        "#8c53ff",
        "#5272ff",
        "#5ce1e6",
        "#7ed958",
        "#c9e265",
        "#ffbd59",
        "#ffffff",
      ],
    },
    {
      title: "Blue",
      list: [
        "#f0f8ff",
        "#b0c4de",

        "#6495ed",
        "#4169e1",
        "#1e90ff",
        "#4682b4",

        "#00bfff",
        "#87ceeb",
        "#5f9ea0",
        "#afeeee",
        "#87cefa",
        "#00ced1",
      ],
    },
    {
      title: "Teal",
      list: [
        "#00FF00",
        "#00FA00",
        "#00F500",
        "#00F000",
        "#00EB00",
        "#00E600",
        "#00E100",
        "#00DC00",
        "#00D700",
        "#00D200",
        "#00CD00",
        "#00C800",
        "#00C300",
        "#00BE00",
        "#00B900",
        "#00B400",
        "#00AF00",
        "#00AA00",
        "#00A500",
        "#00A000",
      ],
    },
  ];

  //edting tool baar toggles

  const setcanvasaboutsus = () => {
    setIsOpenAbout2(true);
  };
  const toggle = (toggleType) => {
    // Map each toggle type to its corresponding state setter
    const toggleStateMap = {
      icon: setIsOpenicon,
      text: setIsOpentext,
      background: setIsOpenBg,
      shapes: setIsOpenshape,
      template: setIsOpenTemplate,
      AboutIcon:
        subscription_active == 0 || about_logo == ""
          ? setIsOpenAbout
          : setIsOpenAbout2,
      //AboutIcon: setIsOpenAbout,
      uploads: setIsOpenuploads,
      pictures: setIsOpenPictures,
    };

    // Toggle the state of the clicked toggle
    toggleStateMap[toggleType]((prevState) => !prevState);

    // Hide other toggles if the clicked toggle was already open
    Object.keys(toggleStateMap).forEach((type) => {
      if (type !== toggleType) {
        toggleStateMap[type](false);
      }
    });
    setClicked(!clicked);
    setClicked2(!clicked2);
    setIsActive2(toggleType === "text");
    setIsActive3(toggleType === "background");
    setIsActive4(toggleType === "shapes");
    setIsActive5(toggleType === "template");
    setIsActive6(toggleType === "AboutIcon");
    setIsActive7(toggleType === "uploads");
    setIsActive10(toggleType === "Pictures");
  };

  //close
  const handleClose = () => {
    setIsOpenicon(false);
    setIsOpentext(false);
    setIsOpenBg(false);
    setIsOpenshape(false);
    setIsOpenTemplate(false);
    setIsOpenAbout(false);
    setIsOpenAbout2(false);
    setIsOpenuploads(false);
    setIsCollapsedPosstion(true);
    setIsCollapsedop(true);
    setIsCollapsedLtterSpacing(true);
    setIsOpenPictures(false);
  };

  //when you click the shpe toolbar show

  const handleObjectDeselection = () => {
    setIsObjectSelected(false);
    setshopcolor(true);
  };

  fabric.IText.prototype.toObject = (function (toObject) {
    return function () {
      return fabric.util.object.extend(toObject.call(this), {
        metadata: this.metadata,
      });
    };
  })(fabric.IText.prototype.toObject);
  //save canvas

  //flip Options SHow
  const toggleFlipButtons = () => {
    setShowFlipButtons(!showFlipButtons);
  };

  //Flip object and text
  const flipObject = (direction) => {
    const activeObject = mainCanvas.getActiveObject();
    if (activeObject) {
      if (direction === "horizontal") {
        activeObject.flipX = !activeObject.flipX;
      } else if (direction === "vertical") {
        activeObject.flipY = !activeObject.flipY;
      }
      mainCanvas.requestRenderAll();
    }
  };

  //opesity control on the object  and text
  const toggleOpacityInput = () => {
    setShowOpacityInput(!showOpacityInput);
    setIsCollapsedop(false);
  };

  const handleOpacityChange = (event) => {
    const newOpacity = parseInt(event.target.value);
    setOpacity(newOpacity);
    if (mainCanvas.getActiveObject()) {
      mainCanvas.getActiveObject().set("opacity", newOpacity / 100);
      mainCanvas.requestRenderAll();
    }
  };
  //duplicate object
  const duplicateObject = () => {
    const activeObject = mainCanvas.getActiveObject();
    if (activeObject) {
      activeObject.clone((clonedObject) => {
        clonedObject.set({
          left: activeObject.left + 10,
          top: activeObject.top - 10,
        });

        mainCanvas.add(clonedObject);

        mainCanvas.setActiveObject(clonedObject);

        mainCanvas.requestRenderAll();
        whenRenderFunction(true);
      });
    }
  };

  //options show in the navbar
  const toggleSecondButton = () => {
    setShowSecondButton((prev) => {
      setShowSecondButton2(false);
      return !prev;
    });
  };

  const [DownloadCanvas, setDownloadCanvas] = useState(null);
  const [DownloadCanvasindex, setDownloadCanvasindex] = useState(-1);

  const toggleSecondButton2 = () => {
    if (package_name === null) {
      navigate("/pricing");
    } else {
      setDownload(!showDownload);
      console.log(mainCanvas);
      console.log(mainCanvas._objects);

      const data = mainCanvas._objects.map((obj) => {
        let objData = obj.toObject();
        objData.flag = 0; // Append flag 0
        objData.color = obj.fill || "";
        objData.customWidth = obj.customWidth || obj.width;

        // Add mergedFonts to each object and include font source
        return objData;
      });

      const canvasData = {
        objects: data,
        background: mainCanvas.backgroundColor,
        width: 400,
        height: 400,
      };
      setDownloadCanvas(canvasData);
      console.log(canvasData);

      setShowSecondButton2((prev) => {
        setShowSecondButton(false);
        return !prev;
      });
    }
  };
  const handlesectionDisable = () => {
    setDownload(false);
    setShowSecondButton(false);
    setShowSecondButton2(false);
  };

  //Sidebar on off
  useEffect(() => {
    if (windowWidth < 1000) {
      setIsOpenicon(false);
      setIsOpenBg(false);
      setIsOpentext(false);
      setIsOpenshape(false);
      setIsOpenuploads(false);
      setIsOpenPictures(false);
      setIsOpenAbout(false);
      setIsOpenAbout2(false);
      setIsOpenTemplate(false);
    }
  }, [windowWidth]);
  const sidebarClassName =
    windowWidth > 540
      ? isOpenicon ||
        isOpenBg ||
        isOpentext ||
        isOpenshape ||
        isOpenPictures ||
        isOpenuploads ||
        (package_name === null || package_name === "" ? isOpenAbout : null) ||
        isOpenAbout ||
        isOpenTemplate
        ? "sideBarContentContainer sideBarContentContainerShow"
        : "sideBarContentContainer"
      : isOpenicon ||
        isOpenBg ||
        isOpentext ||
        isOpenshape ||
        isOpenPictures ||
        isOpenuploads ||
        (package_name === null || package_name === "" ? isOpenAbout : null) ||
        isOpenAbout ||
        isOpenTemplate
      ? "mobileSideBarContentContainer mobileSideBarContentContainerShow"
      : "mobileSideBarContentContainer";

  //hover sidebar icon chnge  color change  and actice

  const handleButtonUploadimg = () => {
    const input = document.createElement("input");
    input.type = "file";
    input.accept = "image/svg+xml";
    input.style.display = "none";

    input.onchange = async (event) => {
      const file = event.target.files[0];
      if (file) {
        const svgContent = await readSVGFile(file);

        await handleUpload(file, svgContent);
      }
    };

    document.body.appendChild(input);
    input.click();
  };

  const handleButtonUploadimgPicture = () => {
    const input = document.createElement("input");
    input.type = "file";
    input.accept = "image/*"; // Accept any image type
    input.style.display = "none";

    input.onchange = async (event) => {
      const file = event.target.files[0];
      if (file) {
        const fileExtension = file.name.split(".").pop().toLowerCase();
        let imageContent = null;

        // Check if the uploaded file is SVG
        if (fileExtension === "svg") {
          imageContent = await readSVGFile(file); // Handle SVG files
        } else {
          imageContent = await readImageFile(file); // Handle other image types
        }

        await handleUploadPictures(file, imageContent);
        setshowLoaderAdmin(true);
      }
    };

    input.click(); // Trigger file input click
  };

  const handleUploadPictures = async (file, svgContent) => {
    setshowLoaderAdmin(true);
    const formData = new FormData();

    const userId = localStorage.getItem("user_id");

    formData.append("upload_img_new", file);
    formData.append("svg_content", svgContent);
    formData.append("user_id", userId);
    formData.append("by_user_admin_flag", "1");

    await server_post_data(save_update_upload_picture, formData)
      .then((Response) => {
        if (Response.data.error) {
          handleError(Response.data.message);
        } else {
          // Function to call uploaded imgs
          master_data_get_user_uploads();
        }
        setshowLoaderAdmin(false); // Set to false after response
      })
      .catch((error) => {
        setshowLoaderAdmin(false);
        handleError("network");
      });
  };

  const readImageFile = (file) => {
    return new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.onload = () => resolve(reader.result);
      reader.onerror = (error) => reject(error);
      reader.readAsDataURL(file); // Read file as base64-encoded string
    });
  };

  const readSVGFile = (file) => {
    return new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.onload = (event) => {
        resolve(event.target.result);
      };
      reader.onerror = (error) => {
        reject(error);
      };
      reader.readAsText(file);
    });
  };

  const master_data_get_user_uploads = async (flag, call_id) => {
    const fd = new FormData();
    setshowLoaderAdmin(true);

    const userId = localStorage.getItem("user_id");

    fd.append("flag", flag);
    fd.append("call_id", call_id);
    fd.append("user_id", userId);

    await server_post_data(get_user_pic_uploads_blog_website, fd)
      .then((Response) => {
        if (Response.data.error) {
          handleError(Response.data.message);
        } else {
          // setTemplateData(Response.data.message.data);
          // setFiltertemplateData(Response.data.message.data);
          console.log(Response.data.message.image_path);
          setuserpictures(Response.data.message.pic_uploads_blog);
          setIMAGEPATH(Response.data.message.image_path);
        }
        setshowLoaderAdmin(false);
      })
      .catch((error) => {
        console.error(error);
        setshowLoaderAdmin(false);
      });
  };
  //------------------

  //-------------------- color code in image

  const handleImageClick = async (src, add_or_not) => {
    setshopcolor(false);
    handleClose();
    if (!mainCanvas) return;

    try {
      let colors = [];
      if (src.startsWith("data:image/svg+xml;base64,")) {
        // Extract colors from SVG
        const svgData = atob(src.split(",")[1]);
        colors = extractTopColorsFromSVG(svgData, 3); // Get top 3 colors
        if (add_or_not != 1) {
          fabric.loadSVGFromString(svgData, (objects, options) => {
            const svg = fabric.util.groupSVGElements(objects, options);
            const newColor = "#000000"; // Default black color
            svg.getObjects().forEach((obj) => {
              if (obj.set) {
                obj.set({ fill: newColor });
              }
            });

            // Set properties for SVG with updated position
            svg.set({
              left: position.left,
              top: position.top,
              scaleX: 70 / svg.width,
              scaleY: 70 / svg.height,
              globalCompositeOperation: src,
              CustomImage: "custom",
            });

            mainCanvas.add(svg).setActiveObject(svg);
            mainCanvas.renderAll();
            updatePosition(); // Update position for next image
          });
        }
      } else {
        // If it's a regular base64 image URL
        const imgElement = await loadImage(src);
        colors = extractTopColorsFromImage(imgElement, 3); // Get top 3 colors
        if (add_or_not != 1) {
          const timestamp = new Date().getTime().toString(36); // Convert the current time to a base-36 string
          const randomNumber = Math.floor(Math.random() * 1000000000).toString(
            36
          );
          const fabricImage = new fabric.Image(imgElement, {
            left: position.left,
            top: position.top,
            scaleX: 70 / imgElement.width,
            scaleY: 70 / imgElement.height,
            globalCompositeOperation: src,
            CustomImage: "custom",
            MkIconId: `MkIconId_${timestamp}_${randomNumber}`, // Assign unique ID,
            metadata: {
              role: "element",
              timestamp: new Date().toISOString(),
            },
          });
          fabricImage.MkIconId = new Date().toISOString();
          fabricImage.metadata = {
            role: "element",
            timestamp: new Date().toISOString(),
          };

          mainCanvas.add(fabricImage).setActiveObject(fabricImage);
          mainCanvas.renderAll();
          updatePosition();
        }
      }
    } catch (error) {
      console.error("Error loading image:", error);
    }
  };

  const extractTopColorsFromSVG = (svgData, topN) => {
    const parser = new DOMParser();
    const svgDoc = parser.parseFromString(svgData, "image/svg+xml");
    const colorCounts = {};

    const extractColors = (node) => {
      if (node.getAttribute) {
        const fill = node.getAttribute("fill");
        const stroke = node.getAttribute("stroke");
        if (fill) colorCounts[fill] = (colorCounts[fill] || 0) + 1;
        if (stroke) colorCounts[stroke] = (colorCounts[stroke] || 0) + 1;
      }

      for (let i = 0; i < node.childNodes.length; i++) {
        extractColors(node.childNodes[i]);
      }
    };

    extractColors(svgDoc.documentElement);

    // Group similar colors
    const groupedColors = groupSimilarColors(colorCounts);

    // Extract only unique colors
    const uniqueColors = Object.keys(groupedColors).filter(
      (color, index, self) => self.indexOf(color) === index
    );

    const sortedUniqueColors = uniqueColors.sort(
      (a, b) => groupedColors[b] - groupedColors[a]
    );

    return sortedUniqueColors.slice(0, topN);
  };

  const groupSimilarColors = (colorCounts) => {
    const groupedColors = {};
    const threshold = 32; // Adjust threshold for color similarity

    const parseColor = (color) => {
      const hex = color.replace("#", "");
      const bigint = parseInt(hex, 16);
      return {
        r: (bigint >> 16) & 255,
        g: (bigint >> 8) & 255,
        b: bigint & 255,
      };
    };

    const isSimilarColor = (color1, color2) => {
      const c1 = parseColor(color1);
      const c2 = parseColor(color2);
      return (
        Math.abs(c1.r - c2.r) < threshold &&
        Math.abs(c1.g - c2.g) < threshold &&
        Math.abs(c1.b - c2.b) < threshold
      );
    };

    Object.keys(colorCounts).forEach((color) => {
      let found = false;
      for (const groupedColor in groupedColors) {
        if (isSimilarColor(color, groupedColor)) {
          groupedColors[groupedColor] += colorCounts[color];
          found = true;
          break;
        }
      }
      if (!found) {
        groupedColors[color] = colorCounts[color];
      }
    });

    return groupedColors;
  };

  // Helper function to extract top colors from image
  const extractTopColorsFromImage = (imgElement, topN) => {
    const canvas = document.createElement("canvas");
    const ctx = canvas.getContext("2d");
    canvas.width = imgElement.width;
    canvas.height = imgElement.height;
    ctx.drawImage(imgElement, 0, 0);

    const imageData = ctx.getImageData(0, 0, canvas.width, canvas.height).data;
    const colorCounts = {};
    for (let i = 0; i < imageData.length; i += 4) {
      const r = imageData[i];
      const g = imageData[i + 1];
      const b = imageData[i + 2];
      const a = imageData[i + 3];
      if (a !== 0) {
        // Exclude transparent pixels
        const color_code = `rgba(${r},${g},${b},${a / 255})`;
        const color = rgbToHex(color_code);
        colorCounts[color] = (colorCounts[color] || 0) + 1;
      }
    }
    const sortedColors = Object.entries(colorCounts).sort(
      (a, b) => b[1] - a[1]
    );

    // Extract only unique colors
    const uniqueColors = sortedColors.map((color) => color[0]);

    return uniqueColors.slice(0, topN);
  };
  // Helper function to load image
  const loadImage = (src) => {
    return new Promise((resolve, reject) => {
      const img = new Image();
      img.crossOrigin = "Anonymous";
      img.onload = () => resolve(img);
      img.onerror = reject;
      img.src = src;
    });
  };
  // Helper function to convert RGBA to hex format
  const rgbToHex = (rgbaColor) => {
    const [r, g, b] = rgbaColor.match(/\d+/g);
    return `#${Number(r).toString(16).padStart(2, "0")}${Number(g)
      .toString(16)
      .padStart(2, "0")}${Number(b).toString(16).padStart(2, "0")}`;
  };
  //--------------------color code in image

  const updatePosition = () => {
    setPosition((prevPosition) => ({
      left: prevPosition.left + 10, // Adjust increment as needed
      top: prevPosition.top + 10, // Adjust increment as needed
    }));
  };

  const handleImageClick2 = (logoData) => {
    setSelectedLogoData(logoData);
    setConfirmModalVisible(true);
  };

  //text aligment change

  const handleAlignment = (alignmentType, group = "0") => {
    alignSelectedObjects(
      alignmentType,
      alignmentType === "center" || alignmentType === "middle",
      group
    );
  };
  const alignSelectedObjects = (
    alignmentType,
    alignVertical = false,
    group
  ) => {
    const activeObject = mainCanvas.getActiveObject();
    if (!activeObject) return;

    const canvasWidth = mainCanvas.width;
    const canvasHeight = mainCanvas.height;
    const objectWidth = activeObject.getScaledWidth();
    const objectHeight = activeObject.getScaledHeight();
    if (group == "0") {
      if (alignmentType === "left") {
        activeObject.set({ left: 0 });
      } else if (alignmentType === "right") {
        activeObject.set({ left: canvasWidth - objectWidth });
      } else if (alignmentType === "top") {
        activeObject.set({ top: 0 });
      } else if (alignmentType === "bottom") {
        activeObject.set({ top: canvasHeight - objectHeight });
      } else if (alignmentType === "middle") {
        activeObject.set({
          left: (canvasWidth - objectWidth) / 2,
          top: (canvasHeight - objectHeight) / 2,
        });
      } else if (alignmentType === "center") {
        if (alignVertical) {
          activeObject.set({
            top: (canvasHeight - objectHeight) / 2,
          });
        } else {
          activeObject.set({
            left: (canvasWidth - objectWidth) / 2,
          });
        }
      }
      activeObject.setCoords();
    } else {
      if (!activeObject || !activeObject.type === "group") return;

      const group = activeObject;

      let groupLeft, groupTop;

      const groupWidth = group.width * group.scaleX;
      const groupHeight = group.height * group.scaleY;

      switch (alignmentType) {
        case "left":
          groupLeft = 0;
          groupTop = (canvasHeight - groupHeight) / 2;
          break;
        case "right":
          groupLeft = canvasWidth - groupWidth;
          groupTop = (canvasHeight - groupHeight) / 2;
          break;
        case "center":
          groupLeft = (canvasWidth - groupWidth) / 2;
          groupTop = (canvasHeight - groupHeight) / 2;
          break;
        case "middle":
          groupLeft = (canvasWidth - groupWidth) / 2;
          groupTop = (canvasHeight - groupHeight) / 2;
          break;
        default:
          return;
      }

      // Update group position
      group.set({
        left: groupLeft,
      });
      group.setCoords();
      mainCanvas.discardActiveObject();
      mainCanvas.renderAll();
      group._objects.forEach((obj) => {
        if (alignmentType === "left") {
          obj.set({ left: 0 });
        } else if (alignmentType === "right") {
          obj.set({ left: canvasWidth - obj.getScaledWidth() });
        } else if (alignmentType === "middle") {
          obj.set({
            top: (canvasHeight - obj.getScaledHeight()) / 2,
          });
        } else if (alignmentType === "center") {
          obj.set({
            left: (canvasWidth - obj.getScaledWidth()) / 2,
          });
        }

        obj.setCoords();
      });
      mainCanvas.renderAll();
      // Step 4: Reselect the group
      const selectedObjects = [];
      group._objects.forEach((obj) => {
        selectedObjects.push(obj);
      });

      const selection = new fabric.ActiveSelection(selectedObjects, {
        canvas: mainCanvas,
      });

      mainCanvas.setActiveObject(selection);
      mainCanvas.renderAll();
    }

    mainCanvas.renderAll();
    //mainCanvas.requestRenderAll();
    whenRenderFunction(true);
  };

  // Define handleResize function
  const handleResize = () => {
    setShowResize(!showResize);
  };

  //Possitons Layer Show

  const handleShowLayers = () => {
    SetPossitionsLayers(!isPossitionsLayers);
    setIsCollapsedPosstion(false);
  };

  const bringToFront = () => {
    const activeObject = mainCanvas.getActiveObject();
    if (activeObject) {
      activeObject.bringToFront();
      StackingOrderObject(activeObject, "bringToFront");
    }
  };

  const bringLayerUp = () => {
    const activeObject = mainCanvas.getActiveObject();
    if (activeObject) {
      activeObject.bringForward();
      StackingOrderObject(activeObject, "bringLayerUp");
    }
  };

  const sendToBack = () => {
    const activeObject = mainCanvas.getActiveObject();
    if (activeObject) {
      activeObject.sendToBack();
      StackingOrderObject(activeObject, "sendToBack");
    }
  };

  const bringLayerDown = () => {
    const activeObject = mainCanvas.getActiveObject();
    if (activeObject) {
      activeObject.sendBackwards();
      StackingOrderObject(activeObject, "bringLayerDown");
    }
  };

  const StackingOrderObject = (activeObject, optn) => {
    // Get the current order of objects in the canvas
    const objects = mainCanvas.getObjects();
    const index = objects.indexOf(activeObject);

    if (index === -1) return; // If the object is not found, return early
    console.log(index);
    console.log(objects);
    return;
    // Remove the object from its current position
    objects.splice(index, 1);

    // Modify the stacking order based on the operation
    switch (optn) {
      case "bringToFront":
        objects.push(activeObject); // Add the object to the end (top)
        break;
      case "bringLayerUp":
        if (index < objects.length) {
          objects.splice(index + 1, 0, activeObject); // Move it one layer up
        }
        break;
      case "sendToBack":
        objects.unshift(activeObject); // Add the object to the beginning (bottom)
        break;
      case "bringLayerDown":
        if (index > 0) {
          objects.splice(index - 1, 0, activeObject); // Move it one layer down
        }
        break;
    }

    // Update the canvas objects order
    mainCanvas.clear(); // Clear the canvas
    objects.forEach((obj) => mainCanvas.add(obj)); // Re-add the objects in the new order

    // Re-render the canvas to reflect the new stacking order
    mainCanvas.requestRenderAll();
  };

  //object Aligment
  const toggleVisibility2 = () => {
    setIsVisible2(!isVisible2);
  };
  //get options show when i hover the img
  const handleMouseEnter4 = (index) => {
    setHoveredImageIndex(index);
  };

  const handleMouseLeave4 = () => {
    setHoveredImageIndex(null);
  };

  const toggleVisibility4 = (index) => {
    setIsVisible4((prev) => (prev === index ? null : index));
  };

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (
        HideDwnUpRef.current &&
        !HideDwnUpRef.current.contains(event.target) &&
        uploadRef.current &&
        !uploadRef.current.contains(event.target)
      ) {
        setIsVisible4(false);
      }
    };

    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  //upload img get  img Download Btn
  const handleDownload = async (imageSrc) => {
    try {
      const response = await fetch(imageSrc);
      const blob = await response.blob();
      saveAs(blob, imageSrc.split("/").pop());
    } catch (error) {}
  };
  // font upload modal
  const handleFontuploadModal = () => {
    setshowFontModal(true);
  };
  //crop object
  const handleCropButtonClick = () => {
    const img = mainCanvas.getActiveObject();
    if (img) {
      prepareCrop(img);
      setIsTextToolVisible(false);
      setIsObjectSelected(false);
      setshopcolor(true);
      setIsCropMode(true);
    }
  };
  const handleSaveCrop = () => {
    const img = mainCanvas.getActiveObject();
    const cropRect = mainCanvas
      .getObjects()
      .find((obj) => obj.id === "crop-rect");
    //console.log(cropRect);
    //console.log(objectBeingCropped);
    const overlayRect = mainCanvas
      .getObjects()
      .find((obj) => obj.id === "overlay-rect");
    if (overlayRect) {
      mainCanvas.remove(overlayRect);
    }
    if (objectBeingCropped && cropRect) {
      cropImage(cropRect, objectBeingCropped);
      setIsCropMode(false);
    }
  };
  const handleCancelCrop = () => {
    const overlayRect = mainCanvas
      .getObjects()
      .find((obj) => obj.id === "overlay-rect");
    const cropRect = mainCanvas
      .getObjects()
      .find((obj) => obj.id === "crop-rect");
    if (overlayRect) {
      mainCanvas.remove(overlayRect);
    }
    if (cropRect) {
      mainCanvas.remove(cropRect);
    }
    setCropConfirmed(true);
    mainCanvas.renderAll();
    whenRenderFunction(true);
    setIsCropMode(false);
  };
  const handleMouseDown = (options) => {
    const target = options.target;
    const activeObjects = mainCanvas.getActiveObjects();

    if (activeObjects.length > 1) {
      setIsTextToolVisible(false);
      setIsObjectSelected(false);
      setIsGroupObj(true); // Show group toolbar
      setshopcolor(true);
    } else if (
      target &&
      (target.type === "i-text" || target.type === "textbox")
    ) {
      setIsTextToolVisible(true);
      setIsObjectSelected(false);
      setIsGroupObj(false);
      setshopcolor(true);
    } else if (target) {
      setIsTextToolVisible(false);
      setIsObjectSelected(true); // Show object toolbar
      setIsGroupObj(false); // Hide group toolbar if a single object is selected
      setshopcolor(true);
    } else {
      setIsTextToolVisible(false);
      setIsObjectSelected(false);
      setIsGroupObj(false); // Hide group toolbar
      setshopcolor(true);
    }
  };

  //show  the resize custom dimention when i  check input
  const [checkboxes, setCheckboxes] = useState([
    { id: 1, label: "Business Card", dimensions: "90x55px", checked: false },
    { id: 2, label: "Letterhead", dimensions: "210x297px", checked: false },
    { id: 3, label: "Envelope", dimensions: "595x282px", checked: false },
    { id: 4, label: "Invitation Card", dimensions: "91x114px", checked: false },
    { id: 5, label: "Logo", dimensions: "400x400px", checked: false },
    {
      id: 6,
      label: "Instagram Post",
      dimensions: "1080x1080px",
      checked: false,
    },
    { id: 7, label: "Twitter", dimensions: "1200x675px", checked: false },
    { id: 8, label: "Facebook Post", dimensions: "1200x630px", checked: false },
    {
      id: 9,
      label: "Instagram Story",
      dimensions: "1080x1920px",
      checked: false,
    },
  ]);
  const resizing_design = localStorage.getItem("resizing_design");
  //custom width Height width on the canvas  in the resize button option
  const handleCheckboxChange = (id) => {
    if (resizing_design !== "Resizing designs") {
      handleUpgradeModal(true); // Show modal
      return; // Prevent further execution to avoid checking/unchecking
    }

    const newManualSetupState = !isManualSetup;
    setIsManualSetup(newManualSetupState);
    setButtonColor(newManualSetupState ? "#6473fd" : "#6473fd90");

    if (newManualSetupState) {
      const uncheckedCheckboxes = checkboxes.map((checkbox) => ({
        ...checkbox,
        checked: false,
      }));
      setCheckboxes(uncheckedCheckboxes);
    }
  };

  //Fix width Height width on the canvas  in the resize button option
  const handleCheckboxChange2 = (id) => {
    if (resizing_design !== "Resizing designs") {
      handleUpgradeModal(true); // Show modal
      return; // Prevent further execution to avoid checking/unchecking
    } else {
      const updatedCheckboxes = checkboxes.map((checkbox) =>
        checkbox.id === id
          ? { ...checkbox, checked: !checkbox.checked }
          : { ...checkbox, checked: false }
      );

      setCheckboxes(updatedCheckboxes);

      const anyChecked = updatedCheckboxes.some((checkbox) => checkbox.checked);
      setButtonColor(anyChecked ? "#6473fd" : "#6473fd90");

      setIsManualSetup(false);
    }
  };

  const handleDownloadclick = (id) => {
    if (subscription_active == 0 || downloading_rights == "") {
      handleUpgradeModal(true); // Show modal
      return; // Prevent further execution to avoid checking/unchecking
    } else {
      toggleSecondButton2();
    }
  };

  // const handleCheckboxChange2 = (id) => {
  //   const activePackage = localStorage.getItem("active_package");

  //   if (activePackage === "0") {
  //     handleUpgradeModal(true); // Show modal
  //     return; // Prevent further execution to avoid checking/unchecking
  //   }

  //   if (activePackage === "1") {
  //     handleUpgradeModal(false); // Hide modal

  //     const updatedCheckboxes = checkboxes.map((checkbox) =>
  //       checkbox.id === id
  //         ? { ...checkbox, checked: !checkbox.checked }
  //         : { ...checkbox, checked: false }
  //     );
  //     setCheckboxes(updatedCheckboxes);

  //     const anyChecked = updatedCheckboxes.some((checkbox) => checkbox.checked);
  //     setButtonColor(anyChecked ? "#6473fd" : "#6473fd90");

  //     if (anyChecked) {
  //       setIsManualSetup(false);
  //     }
  //   }
  // };
  const resizeObject = (obj, scaleX, scaleY) => {
    // Scale the object's dimensions
    obj.set({
      scaleX: obj.scaleX * scaleX,
      scaleY: obj.scaleY * scaleY,
      left: obj.left * scaleX,
      top: obj.top * scaleY,
    });

    // If the object is text, adjust its width and fontSize
    // if (obj.type === "i-text" || obj.type === "textbox") {
    //   obj.set({
    //     width: obj.width * scaleX,
    //     fontSize: obj.fontSize * Math.min(scaleX, scaleY),
    //   });
    // }

    // Update object's position and size
    obj.setCoords();
  };
  // Function to handle the resize button click
  const handleResizeClick = () => {
    if (isManualSetup) {
      const customWidth = parseInt(width, 10);
      const customHeight = parseInt(height, 10);

      if (!isNaN(customWidth) && !isNaN(customHeight)) {
        // Directly use pixels for resizing
        updateCanvasDimensions(customWidth, customHeight);
      } else {
        alert("Please enter valid width and height.");
      }
    } else {
      const selectedCheckbox = checkboxes.find((checkbox) => checkbox.checked);
      if (selectedCheckbox) {
        const [width, height] = selectedCheckbox.dimensions
          .split("x")
          .map((dim) => parseInt(dim.replace("px", ""), 10));

        updateCanvasDimensions(width, height);
      } else {
        alert("Please select a format.");
      }
    }
  };

  //additional Resizing Canvas  on the Resize Button
  const handleChange = (e) => {
    const { name, value } = e.target;

    if (name === "width") {
      setWidth(parseInt(value, 10));
    } else if (name === "height") {
      setHeight(parseInt(value, 10));
    } else if (name === "unit") {
      setUnit(value);
    }
  };

  const templateparseGradient = (gradientString) => {
    const gradientRegex = /linear-gradient\(([^,]+),(.+)\)/;
    const matches = gradientString.match(gradientRegex);

    if (matches) {
      const direction = matches[1].trim();
      const colors = matches[2].split(",").map((color) => color.trim());
      return { direction, colors };
    }

    // Default case if regex does not match (fallback)
    return { direction: "to right", colors: [gradientString] };
  };

  const setCanvasBackground = (canvas, background) => {
    if (background.startsWith("linear-gradient")) {
      const { direction, colors } = templateparseGradient(background);

      let x1 = 0,
        y1 = 0,
        x2 = 0,
        y2 = 0;
      if (direction.includes("to right")) {
        x2 = canvas.width;
      } else if (direction.includes("to bottom")) {
        y2 = canvas.height;
      } else if (direction.includes("to left")) {
        x1 = canvas.width;
      } else if (direction.includes("to top")) {
        y1 = canvas.height;
      }

      const gradient = new fabric.Gradient({
        type: "linear",
        gradientUnits: "pixels",
        coords: { x1, y1, x2, y2 },
        colorStops: colors.map((color, index) => ({
          offset: index / (colors.length - 1),
          color,
        })),
      });

      canvas.setBackgroundColor(gradient, canvas.renderAll.bind(canvas));
    } else {
      canvas.setBackgroundColor(background, canvas.renderAll.bind(canvas));
    }
  };
  useEffect(() => {}, [rangeValue]);
  const createCanvasnew = (logoData) => {
    return new Promise((resolve) => {
      if (!logoData) return;

      let canvasWidth;
      let canvasHeight;
      let canvasOrginalWidth;
      let canvasOrginalHeight;
      let background = "#000";
      canvasWidth = rangeValue;
      canvasHeight = rangeValue;
      background = logoData.background;
      canvasOrginalWidth = logoData.width;
      canvasOrginalHeight = logoData.height;
      canvas_temp_download = new fabric.Canvas(refdonwload, {
        width: canvasWidth,
        height: canvasHeight,
        borderColor: "#000",
        borderWidth: 2,
      });

      setCanvasBackground(canvas_temp_download, background);

      const scaleFactorWidth = canvasWidth / canvasOrginalWidth;
      const scaleFactorHeight = canvasHeight / canvasOrginalHeight;
      const scaleFactor = Math.min(scaleFactorWidth, scaleFactorHeight);

      const renderNextObject = async (index) => {
        if (index >= logoData.objects.length) {
          resolve(canvas_temp_download); // Resolve the promise when rendering is complete
          return;
        }
        const objData = logoData.objects[index];
        if (objData.type === "i-text" && objData.metadata) {
          const customWidth = objData.customWidth || objData.width;
          objData.customWidth = customWidth;
        }

        const scaledObject = {
          ...objData,
          scaleX: (objData.scaleX || 1) * scaleFactor,
          scaleY: (objData.scaleY || 1) * scaleFactor,
          left: (objData.left || 0) * scaleFactor,
          top: (objData.top || 0) * scaleFactor,
        };

        fabric.util.enlivenObjects([scaledObject], (objects) => {
          objects.forEach((obj) => {
            obj.selectable = false;
            if (obj.type === "i-text") {
              const customWidth = obj.customWidth || obj.width;
              obj.set({ textAlign: obj.textAlign });
              obj.set({
                width: customWidth,
              });
            }
            canvas_temp_download.add(obj);
          });
          canvas_temp_download.requestRenderAll();
          renderNextObject(index + 1);
        });
      };

      renderNextObject(0);
    });
  };

  const createCanvas = (ref, logoData) => {
    if (!ref || !logoData) return;

    const originalWidth = 400;
    const originalHeight = 400;
    const scaleFactor = 110 / Math.max(originalWidth, originalHeight);

    const canvas_temp = new fabric.Canvas(ref, {
      width: 110,
      height: 110,
      backgroundColor: logoData.background,
    });

    logoData.objects.forEach((objData) => {
      objData.scaleX = (objData.scaleX || 1) * scaleFactor;
      objData.scaleY = (objData.scaleY || 1) * scaleFactor;
      objData.left = (objData.left || 0) * scaleFactor;
      objData.top = (objData.top || 0) * scaleFactor;

      fabric.util.enlivenObjects([objData], (objects) => {
        objects.forEach((obj) => {
          canvas_temp.add(obj);
        });
        canvas_temp.requestRenderAll();
      });
    });

    return canvas_temp;
  };

  const replaceTemplate = () => {
    if (!selectedLogoData || !mainCanvas) return;

    const originalWidth = 400;
    const originalHeight = 400;
    const scaleFactor = 143 / Math.max(originalWidth, originalHeight);

    mainCanvas.clear();

    selectedLogoData.objects.forEach((objData) => {
      // Apply the scale factor to each object
      objData.scaleX = (objData.scaleX || 1) * scaleFactor;
      objData.scaleY = (objData.scaleY || 1) * scaleFactor;
      objData.left = (objData.left || 0) * scaleFactor;
      objData.top = (objData.top || 0) * scaleFactor;

      fabric.util.enlivenObjects([objData], (objects) => {
        objects.forEach((obj) => {
          mainCanvas.add(obj);
        });
        mainCanvas.requestRenderAll();
      });
    });

    mainCanvas.backgroundColor = selectedLogoData.background;
    mainCanvas.renderAll();
  };

  const handleConfirmReplace = () => {
    setConfirmModalVisible(false);
    replaceTemplate();
  };

  const [transparentBackground, setTransparentBackground] = useState(false);
  // const handleCheckboxChangeCanvas = (event) => {
  //   setTransparentBackground(event.target.checked);
  // };
  // //DOwnload Designs
  // const handleDownloadDesign = () => {
  //   const format = downloaddesign.current.querySelector("select").value;
  //   if (format === "png") {
  //     downloadAsPng();
  //   } else if (format === "jpg") {
  //     downloadAsJpg();
  //   } else if (format === "svg") {
  //     downloadAsSvg();
  //   }
  // };

  // const downloadAsPng = () => {
  //   if (transparentBackground) {
  //     const origBackgroundColor = mainCanvas.backgroundColor;
  //     mainCanvas.setBackgroundColor("transparent", () => {
  //       mainCanvas.renderAll();
  //       const dataURL = mainCanvas.toDataURL({
  //         format: "png",
  //         quality: 1.0,
  //         background: "transparent",
  //       });
  //       mainCanvas.setBackgroundColor(origBackgroundColor, () => {
  //         mainCanvas.renderAll();
  //         downloadAndSaveImage(dataURL, "image.png");
  //       });
  //     });
  //   } else {
  //     // Add watermark to canvas background
  //     const watermark = new fabric.Image.fromURL(
  //       watermarkImg,
  //       (img) => {
  //         mainCanvas.setBackgroundImage(
  //           img,
  //           mainCanvas.renderAll.bind(mainCanvas)
  //         );
  //         downloadAsPngWithWatermark();
  //       },
  //       { crossOrigin: "anonymous" }
  //     );
  //   }
  // };
  // const downloadAsPngWithWatermark = () => {
  //   const dataURL = mainCanvas.toDataURL({
  //     format: "png",
  //     quality: 1.0,
  //   });
  //   downloadAndSaveImage(dataURL, "image.png");
  // };

  // const downloadAsJpg = () => {
  //   const dataURL = mainCanvas.toDataURL({
  //     format: "jpg",
  //     quality: 1.0,
  //   });
  //   downloadAndSaveImage(dataURL, "image.jpg");
  // };

  // const downloadAsSvg = () => {
  //   const svgData = mainCanvas.toSVG();
  //   const svgBlob = new Blob([svgData], {
  //     type: "image/svg+xml;charset=utf-8",
  //   });
  //   const svgUrl = URL.createObjectURL(svgBlob);
  //   downloadAndSaveImage(svgUrl, "image.svg");
  // };

  // const downloadAndSaveImage = (dataURL, fileName) => {
  //   const link = document.createElement("a");
  //   link.href = dataURL;
  //   link.download = fileName;
  //   document.body.appendChild(link);
  //   link.click();
  //   document.body.removeChild(link);
  // };

  //Download Template

  const [format, setFormat] = useState("");
  const [showImgSizeRange, setshowImgSizeRange] = useState(false);
  const [modalMessage, setModalMessage] = useState(false);
  const [isPreparingModalOpen, setPreparingModalOpen] = useState(false);
  const [isFailureModalOpen, setFailureModalOpen] = useState(false);
  const [loading, setLoading] = useState(false);
  const handleFormatChange = (event) => {
    const selectedFormat = event.target.value;
    setFormat(selectedFormat);
    if (selectedFormat === "png") {
      setshowImgSizeRange(true);
    } else {
      setshowImgSizeRange(false);
    }
  };
  const DowloadCanvasImg = () => {
    setPreparingModalOpen(true);
    setLoading(true);

    setTimeout(() => {
      downloadCanvasTemplate()
        .then(() => {
          setPreparingModalOpen(false);
          setLoading(false);
        })
        .catch(() => {
          setPreparingModalOpen(false);
          setLoading(false);
          setFailureModalOpen(true);
        });
    }, 1000);
  };
  const downloadCanvasTemplate = async () => {
    await createCanvasnew(DownloadCanvas).then((canvasnew) => {
      newcanvas = canvasnew;

      switch (format) {
        case "png":
          downloadAsPNG();
          break;
        case "jpg":
          //downloadAsJPG();
          break;
        case "svg":
          const vectorFile = localStorage.getItem("vector_file");
          if (vectorFile === "Vectors File") {
            downloadAsSVG();
          } else {
            navigate("/pricing");
          }
          break;
        case "pdf":
          downloadAsPDF();
          break;
        case "doc":
          downloadAsDOC();
          break;
        default:
          handleDownloadFormetMsg();
      }
    });
  };

  let transparent_background = transparentBackground ? 1 : 0;

  const downloadAsPNG = () => {
    const origBackgroundColor = newcanvas.backgroundColor;
    const origWatermarkUrl = "";
    const origWatermarkVisible = newcanvas.backgroundImage?.visible;

    // Temporarily make the watermark invisible
    if (newcanvas.backgroundImage) {
      newcanvas.backgroundImage.visible = false;
      newcanvas.renderAll();
    }

    // Set the background color to transparent if needed
    const backgroundColor =
      transparent_background === 1 ? "transparent" : origBackgroundColor;

    newcanvas.setBackgroundColor(backgroundColor, () => {
      newcanvas.renderAll();
      const dataURL = newcanvas.toDataURL({
        format: "png",
        quality: 1.0,
        background: "transparent",
      });
      downloadAndSaveImage(dataURL, "image.png");
      newcanvas.setBackgroundColor(origBackgroundColor, () => {
        newcanvas.renderAll();
        if (newcanvas.backgroundImage) {
          newcanvas.backgroundImage.visible = origWatermarkVisible;
          newcanvas.renderAll();
        }
      });
    });
  };

  const downloadAsSVG = () => {
    const origBackgroundColor = newcanvas.backgroundColor;
    const backgroundColor =
      transparent_background === 1 ? "transparent" : origBackgroundColor;

    // Set background color to transparent if needed
    newcanvas.setBackgroundColor(backgroundColor, () => {
      newcanvas.renderAll();
      if (newcanvas) {
        const svg = newcanvas.toSVG({
          reviver: (obj, instance) => {
            if (instance instanceof fabric.Object) {
              return instance.toSVG();
            }
            return obj;
          },
        });
        const blob = new Blob([svg], { type: "image/svg+xml;charset=utf-8" });
        const url = URL.createObjectURL(blob);
        const link = document.createElement("a");
        link.href = url;
        link.download = "canvas.svg";
        link.click();

        // Clean up
        URL.revokeObjectURL(url);

        // Reset background color
        newcanvas.setBackgroundColor(origBackgroundColor, () => {
          newcanvas.renderAll();
        });
      } else {
        console.error("mainCanvas is undefined, unable to download SVG");
      }
    });
  };

  const downloadAsPDF = () => {
    if (!newcanvas) return;

    const origBackgroundColor = newcanvas.backgroundColor;
    const backgroundColor =
      transparent_background === 1 ? "transparent" : origBackgroundColor;

    // Set background color to transparent if needed
    newcanvas.setBackgroundColor(backgroundColor, () => {
      newcanvas.renderAll();
      const canvasElement = newcanvas;
      const dataURL = canvasElement.toDataURL("image/png");

      const pdf = new jsPDF();
      pdf.addImage(dataURL, "PNG", 0, 0);
      pdf.save("canvas.pdf");

      // Reset background color
      newcanvas.setBackgroundColor(origBackgroundColor, () => {
        newcanvas.renderAll();
      });
    });
  };

  const downloadAsDOC = () => {
    if (!newcanvas) return;

    const origBackgroundColor = newcanvas.backgroundColor;
    const backgroundColor =
      transparent_background === 1 ? "transparent" : origBackgroundColor;

    // Set background color to transparent if needed
    newcanvas.setBackgroundColor(backgroundColor, () => {
      newcanvas.renderAll();
      const canvasElement = newcanvas;
      const dataURL = canvasElement.toDataURL("image/png");

      // Use docx library to create a Word document
      const doc = new Document({
        sections: [
          {
            properties: {},
            children: [
              new Paragraph({
                children: [
                  new ImageRun({
                    data: dataURL,
                    transformation: {
                      width: newcanvas.width,
                      height: newcanvas.height,
                    },
                  }),
                ],
              }),
            ],
          },
        ],
      });

      // Export the document as a blob
      Packer.toBlob(doc).then((blob) => {
        const url = URL.createObjectURL(blob);
        const link = document.createElement("a");
        link.href = url;
        link.download = "canvas.docx";
        link.click();

        // Clean up
        URL.revokeObjectURL(url);

        // Reset background color
        newcanvas.setBackgroundColor(origBackgroundColor, () => {
          newcanvas.renderAll();
        });
      });
    });
  };

  const downloadAndSaveImage = (dataURL, filename) => {
    const link = document.createElement("a");
    link.href = dataURL;
    link.download = filename;
    link.click();
  };
  const handleDownloadFormetMsg = () => {
    console.log("handleDownloadFormetMsgdadadad");
    setModalMessage(true);
  };
  const handleDownloadModlCLose = () => {
    setModalMessage(false);
  };
  const handleRangeChange = (event) => {
    const newValue = Number(event.target.value);
    setRangeValue(newValue);
  };

  //show zoom in and zoom out
  const showZoomControls = [
    "businesscard",
    "invitationCard",
    "envelops",
    "letterhead",
  ].includes(data_type);

  /**shubham jain new code new editor */
  const prepareCrop = (e) => {
    const cropRect = new fabric.Rect({
      id: "crop-rect",
      top: e.top,
      left: e.left,
      angle: e.angle,
      width: e.getScaledWidth(),
      height: e.getScaledHeight(),
      stroke: "rgb(42, 67, 101)",
      strokeWidth: 2,
      strokeDashArray: [5, 5],
      fill: "rgba(255, 255, 255, 1)",
      globalCompositeOperation: "overlay",
      lockRotation: true,
    });

    const overlayRect = new fabric.Rect({
      id: "overlay-rect",
      top: e.top,
      left: e.left,
      angle: e.angle,
      width: e.getScaledWidth(),
      height: e.getScaledHeight(),
      selectable: false,
      selection: false,
      fill: "rgba(0, 0, 0, 0.5)",
      lockRotation: true,
    });

    const s = e.cropX || 0,
      o = e.cropY || 0,
      c = e.width,
      l = e.height;

    e.set({
      cropX: null,
      cropY: null,
      left: e.left - s * e.scaleX,
      top: e.top - o * e.scaleY,
      width: e._originalElement.naturalWidth,
      height: e._originalElement.naturalHeight,
      dirty: false,
    });

    cropRect.set({
      left: e.left + s * e.scaleX,
      top: e.top + o * e.scaleY,
      width: c * e.scaleX,
      height: l * e.scaleY,
      dirty: false,
    });

    overlayRect.set({
      left: e.left,
      top: e.top,
      width: e.width * e.scaleX,
      height: e.height * e.scaleY,
      dirty: false,
    });

    cropRect.oldScaleX = cropRect.scaleX;
    cropRect.oldScaleY = cropRect.scaleY;

    mainCanvas.add(overlayRect);
    mainCanvas.add(cropRect);
    mainCanvas.discardActiveObject();
    mainCanvas.setActiveObject(cropRect);
    mainCanvas.renderAll();

    cropRect.on("moving", function () {
      if (cropRect.top < e.top) cropRect.top = e.top;
      if (cropRect.left < e.left) cropRect.left = e.left;
      if (
        cropRect.top + cropRect.getScaledHeight() >
        e.top + e.getScaledHeight()
      ) {
        cropRect.top = e.top + e.getScaledHeight() - cropRect.getScaledHeight();
      }
      if (
        cropRect.left + cropRect.getScaledWidth() >
        e.left + e.getScaledWidth()
      ) {
        cropRect.left = e.left + e.getScaledWidth() - cropRect.getScaledWidth();
      }
    });
    cropRect.on("deselected", function () {
      cropImage(cropRect, e);
      mainCanvas.remove(overlayRect);
    });
  };
  const cropImage = (cropRect, img) => {
    mainCanvas.remove(cropRect);

    const cropX = (cropRect.left - img.left) / img.scaleX;
    const cropY = (cropRect.top - img.top) / img.scaleY;
    const cropWidth = (cropRect.width * cropRect.scaleX) / img.scaleX;
    const cropHeight = (cropRect.height * cropRect.scaleY) / img.scaleY;

    img.set({
      cropX: cropX,
      cropY: cropY,
      width: cropWidth,
      height: cropHeight,
      top: img.top + cropY * img.scaleY,
      left: img.left + cropX * img.scaleX,
      selectable: true,
      cropped: 1,
    });

    mainCanvas.renderAll();
  };

  /**shubham jain new code new editor */
  const [showShareModal, setShowShareModal] = useState(false);

  const handleCloseShere = () => setShowShareModal(false);
  const handleShare = () => setShowShareModal(true);
  //shapes
  const handleOptionClick = (id, type) => {
    if (optionsVisible === id && currentOptionsType === type) {
      setOptionsVisible(null);
      setCurrentOptionsType(null);
    } else {
      setOptionsVisible(id);
      setCurrentOptionsType(type);
    }
  };

  // Filtering based on the selected tab
  const filteredShapeData = IconShape.filter((icon) => {
    if (currentContent === "shape") return icon.shape_type === "Shapes";
    if (currentContent === "line") return icon.shape_type === "Lines";
    if (currentContent === "frame") return icon.shape_type === "Frames";
    return false;
  });
  const hoverImage = (index) => {
    setHoveredImage(index);
  };

  const handleClickShape = (index) => {
    handleClose();
    const shapeData = filteredShapeData[index];

    fabric.Image.fromURL(shapeData.shape_url, (img) => {
      img.set({
        left: mainCanvas.width / 2 - img.width / 2,
        top: mainCanvas.height / 2 - img.height / 2,
        selectable: true,
      });

      // Add the image to the canvas
      mainCanvas.add(img);
      mainCanvas.requestRenderAll();
      whenRenderFunction(true);
    });
  };

  ///only text aligment
  const toggleAlignment = () => {
    // Get the active text object
    const activeObject = mainCanvas.getActiveObject();

    if (activeObject && activeObject.type === "i-text") {
      // Save the current width before changing alignment
      const customWidth = activeObject.customWidth || activeObject.width;

      // Toggle alignment position
      const newAlignment =
        alignmentPosition === "left"
          ? "center"
          : alignmentPosition === "center"
          ? "right"
          : "left";

      // Update the alignment property
      activeObject.set({ textAlign: newAlignment });

      // Restore the custom width after changing alignment
      activeObject.set({
        width: customWidth,
        customWidth, // Ensure customWidth is preserved
      });

      // Update canvas
      mainCanvas.requestRenderAll();

      // Update state for alignment position
      setAlignmentPosition(newAlignment);
    } else {
    }
  };
  //toolbar show only mobile
  const [isMobile, setIsMobile] = useState(window.innerWidth < 540);
  const [isToolbarActive, setIsToolbarActive] = useState(false);

  // Effect to update the state when the window is resized
  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth < 540);
    };

    // Add event listener on mount
    window.addEventListener("resize", handleResize);

    // Clean up event listener on unmount
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);
  const handleCloseToolbar = () => {
    setIsToolbarActive(false);
    setIsTextToolVisible(false);
    setIsObjectSelected(false);
    setIsGroupObj(false);
    const activeObjects = mainCanvas.getActiveObjects();
    activeObjects.forEach((obj) => {
      obj.setControlsVisibility({
        mt: false,
        mb: false,
        ml: false,
        mr: false,
        mtr: false,
      });
    });
    mainCanvas.discardActiveObject();
    mainCanvas.requestRenderAll();
  };

  const handleToggle = () => {
    setIsCollapsedPosstion(!isCollapsedPosstion);
  };
  const handleToggleOp = () => {
    setIsCollapsedop(!isCollapsedOp);
  };
  const handleToggleMobileLetterSpacing = () => {
    setIsCollapsedLtterSpacing(!isLtterSpacing);
  };
  const [showUpdagradeModal, SetShowUpgradeModal] = useState(false);
  const handleUpgradeModal = () => {
    SetShowUpgradeModal(true);
  };
  const [sherevalue, setSherevalue] = useState("");

  useEffect(() => {
    // Get the current URL
    const currentUrl = window.location.href;
    const urlSubstring = currentUrl.substring(
      currentUrl.lastIndexOf(APL_LINK + "/canvasEdit/logo/") + 1
    );
    setSherevalue(urlSubstring);
  }, []);

  const [userIdFromLocalStorage, setUserIdFromLocalStorage] = useState(null);
  const handleCopySHere = () => {
    if (sherevalue) {
      // Select the text inside the input field
      inputRefShere.current.select();

      // Copy the selected text to the clipboard
      navigator.clipboard
        .writeText(sherevalue)
        .then(() => {})
        .catch((err) => {
          console.error("Failed to copy text: ", err);
        });
    }
  };

  const HandleRoute = () => {
    navigate("/pricing");
  };
  const HandleNavigateHome = () => {
    navigate("/createlogohome");
  };

  // console.log(mainCanvas);

  const master_data_get_pictures = async (flag, call_id) => {
    const fd = new FormData();
    setshowLoaderAdmin(true);
    fd.append("flag", flag);
    fd.append("call_id", call_id);

    await server_post_data(get_more_pic_uploads_blog_website, fd)
      .then((Response) => {
        if (Response.data.error) {
          handleError(Response.data.message);
        } else {
          // setTemplateData(Response.data.message.data);
          // setFiltertemplateData(Response.data.message.data);
          console.log(Response.data.message.image_path);
          setpictures(Response.data.message.pic_uploads_blog);
          setIMAGEPATH(Response.data.message.image_path);
        }
        setshowLoaderAdmin(false);
      })
      .catch((error) => {
        console.error(error);
        setshowLoaderAdmin(false);
      });
  };

  return (
    <>
      {showDownload && <div className="overlay2"></div>}
      {user_id ? (
        <div className="topBArConatiner">
          <div className="topBar">
            <Link to="/">
              <button
                onMouseEnter={handleMouseEnter4}
                onMouseLeave={handleMouseLeave4}
              >
                <img
                  className="homeIcn"
                  src={isHovered ? homeIconBlue : HomeIcn}
                  alt="Home Icon"
                ></img>

                <label>Home</label>
              </button>
            </Link>
          </div>

          <div className="sidebarBtns">
            <div className="UndoRundobtns">
              {showSidebar && (
                <div className="UndoBtn">
                  <button
                    id="undoButton"
                    className="tooltip-button "
                    onClick={handleUndo}
                  >
                    <img
                      id="undoImage"
                      className="undo "
                      src={redoIcn}
                      alt="undo"
                    ></img>
                    {/* <span className="tooltip-text">Undo (Ctrl + z)</span> */}
                  </button>
                </div>
              )}
              {showSidebar && (
                <div className="UndoBtn">
                  <button
                    id="redoButton"
                    className="redoButton tooltip-button "
                    onClick={handleRedo}
                  >
                    <img
                      id="redoImage"
                      className="redo"
                      src={undoIcn}
                      alt="redo"
                    ></img>
                    {/* <span className="tooltip-text">Redo (Ctrl + Y)</span> */}
                  </button>
                </div>
              )}
              {showSidebar && (
                <div className="reszE" onClick={handleResize} ref={buttonRef}>
                  <img src={resizeIcon} alt="resize"></img>
                  <label>Resize</label>
                </div>
              )}
            </div>
            <div
              className={`ResizeDesign ${showResize ? "ResizeDesignShow" : ""}`}
              ref={resizeRef}
            >
              <div className="container">
                <ul>
                  <li>
                    <div className="firstResize">
                      <div className="firstHeading">
                        {localStorage.getItem("active_package") === "0" ? (
                          <>
                            <p>Resize</p>
                            <img src={crownIcn} alt="crownIcn" />
                          </>
                        ) : (
                          <p>Resize</p>
                        )}
                      </div>

                      <div className="ChcBoxSize">
                        <input
                          type="checkbox"
                          checked={isManualSetup}
                          onChange={handleCheckboxChange}
                        ></input>
                        <label>Setup Manual</label>
                      </div>
                      <div>
                        {isManualSetup && (
                          <div className="inputContainer">
                            <div className="row p-0">
                              <div className="col  p-0">
                                <label htmlFor="width">Width:</label>
                                <input
                                  type="number"
                                  className="canvas_form-control"
                                  id="width"
                                  name="width"
                                  value={width}
                                  onChange={handleChange}
                                />
                              </div>
                              <div className="col p-0">
                                <label htmlFor="height">Height:</label>
                                <input
                                  type="number"
                                  className="canvas_form-control"
                                  id="height"
                                  name="height"
                                  value={height}
                                  onChange={handleChange}
                                />
                              </div>
                              <div className="col  unitss">
                                <label htmlFor="unit">Unit:</label>
                                <select
                                  className="canvas_form-control"
                                  value={unit}
                                  onChange={handleChange}
                                >
                                  <option className="opSize" value="px">
                                    px
                                  </option>
                                  <option className="opSize" value="in">
                                    in
                                  </option>
                                  <option className="opSize" value="number">
                                    number
                                  </option>
                                </select>
                              </div>
                            </div>
                          </div>
                        )}
                      </div>
                      <div className="secondSize">
                        <div className="SecondHeading">
                          <p>All Formats</p>
                        </div>
                        {checkboxes.map((checkbox) => (
                          <div key={checkbox.id} className="ChcBoxSize3">
                            <input
                              type="checkbox"
                              checked={checkbox.checked}
                              onChange={() =>
                                handleCheckboxChange2(checkbox.id)
                              }
                            />
                            <label>{checkbox.label}</label>
                            <span>{checkbox.dimensions}</span>
                          </div>
                        ))}
                      </div>
                      <div className="col">
                        {resizing_design !== "Resizing designs" ? (
                          <div className="ResizeBnt preMiumBatch">
                            <button
                              onClick={handleUpgradeModal}
                              style={{
                                backgroundColor: buttonColor,
                                cursor: isManualSetup ? "pointer" : "default",
                              }}
                            >
                              <img src={crownIcn} alt="crownIcn" /> Upgrade
                            </button>
                          </div>
                        ) : (
                          <div className="ResizeBnt">
                            <button
                              onClick={handleResizeClick}
                              style={{
                                backgroundColor: buttonColor,
                                cursor: isManualSetup ? "pointer" : "default",
                              }}
                            >
                              Resize
                            </button>
                          </div>
                        )}
                      </div>
                    </div>
                  </li>
                </ul>
              </div>
            </div>

            <div
              className={`downloadTemplates ${
                showSecondButton2 ? "visible" : ""
              }`}
            >
              <div className="crossDownloadicon">
                <button
                  onClick={handlesectionDisable}
                  style={{ background: "transparent", border: "none" }}
                >
                  <img src={crossIcn} alt="cross img"></img>
                </button>
              </div>

              <div className="container">
                <ul>
                  <li>
                    <div className="firstResize">
                      <div className="HeadingDownload">
                        <div className="d-flex  gap-2 alignment-items-center">
                          <p>Download</p>
                          <span>Package: {package_name}</span>
                        </div>
                      </div>
                      <div className="w-100 mt-2">
                        <select onChange={handleFormatChange} value={format}>
                          <option value="" disabled hidden>
                            Select format
                          </option>
                          <option value="png">PNG</option>

                          <option
                            value="svg"
                            disabled={
                              subscription_active == 0 || vector_file == ""
                            }
                          >
                            SVG
                          </option>
                          <option value="pdf">PDF</option>
                          <option value="doc">DOC</option>
                        </select>
                      </div>

                      {showImgSizeRange && (
                        <>
                          <div
                            className="imgHead"
                            style={{ paddingTop: "1rem" }}
                          >
                            <label>Image size</label>
                            <span>
                              {rangeValue}x{rangeValue}px
                            </span>
                          </div>
                          <div className="downloadRenge">
                            <input
                              className="rngePIXL"
                              type="range"
                              min="200"
                              max="2000"
                              step="5"
                              defaultValue={rangeValue}
                              onChange={(e) => handleRangeChange(e)}
                              disabled={
                                subscription_active == 0 || resolution == ""
                              }
                            />
                          </div>
                        </>
                      )}

                      <div className="downloadRenge2">
                        <input
                          type="checkbox"
                          id="transparentBg"
                          name="transparentBg"
                          checked={transparentBackground}
                          onChange={(e) => {
                            setTransparentBackground(e.target.checked);
                            console.log("Checkbox checked:", e.target.checked);
                          }}
                          disabled={
                            subscription_active == 0 ||
                            transparent_background2 == ""
                          }
                        />
                        <label> Transparent Background</label>
                      </div>

                      <div className="col">
                        <div className="downloadTemplateBtnn">
                          <button
                            onClick={DowloadCanvasImg}
                            disabled={
                              subscription_active == 0 ||
                              downloading_rights == ""
                            }
                          >
                            DownLoad
                          </button>
                        </div>
                      </div>
                    </div>
                  </li>
                </ul>
              </div>
            </div>
            {showSidebar && (
              <button className="downloadBTn" onClick={handleDownloadclick}>
                {" "}
                Download
                <img
                  className="downLoadIcn"
                  src={DownLoadIcn}
                  alt="Download"
                ></img>
              </button>
            )}

            <button className="downloadBTn" onClick={handleShare}>
              {" "}
              Share
            </button>
            {showSidebar && (
              <button className="downloadBTn" onClick={saveCanvasAsJSON}>
                Save
              </button>
            )}

            {/* <div className="OptionsButtonNav" ref={navRef}>
              {" "}
              <button onClick={toggleSecondButton}>
                <img src={navOptionsIcon} alt="Opions"></img>
              </button>
              {showSecondButton && (
                <div className="NvDotsContent">
                  <div className="SaveButn">
                    <button onClick={saveCanvasAsJSON}>Save</button>
                  </div>
                  <div className="d-flex w-100  align-items-center">
                    <button>
                      <img src={shareIcn}></img>
                    </button>
                    <p>Shere</p>
                  </div>
                </div>
              )}
            </div> */}
          </div>
        </div>
      ) : (
        <Header Showheader={false} />
      )}
      <div className="canVasContainer" style={{ overflow: "hidden" }}>
        {showLoaderAdmin && <Loader />}

        {/* <HeaderCanvas
          handleResize={handleResize}
          isManualSetup={isManualSetup}
        /> */}

        {/*-------Canvas Containt-------*/}
        <div className="container-fluid r-0 p-0" style={{ overflow: "hidden" }}>
          <div className="d-flex canvasBg">
            {showSidebar && (
              <div
                className={windowWidth > 540 ? "sideToolBar" : "mobileToolbar"}
              >
                <div className="icon">
                  <div
                    className={`TxtBtn `}
                    onMouseEnter={() => setHovered(true)}
                    onMouseLeave={() => setHovered(false)}
                  >
                    <div>
                      <button onClick={() => toggle("icon")}>
                        <img
                          src={
                            hovered || isOpenicon ? add_reactionBlue : EmogiIcn
                          }
                          alt="Icon"
                        ></img>
                      </button>
                      <label style={{ color: "initial" }}>Icon</label>
                    </div>
                  </div>

                  <div
                    className={`TxtBtn ${isActive2 ? "active" : ""}`}
                    onMouseEnter={() => setHovered2(true)}
                    onMouseLeave={() => setHovered2(false)}
                  >
                    <div>
                      {" "}
                      <button onClick={() => toggle("text")}>
                        <img
                          src={hovered2 || isOpentext ? TextIcnBlue : TxtIcn}
                          alt="Icon"
                        ></img>
                      </button>
                      <label style={{ color: isActive2 ? "blue" : "initial" }}>
                        Text
                      </label>
                    </div>
                  </div>

                  <div
                    className={`TxtBtn ${isActive3 ? "active" : ""}`}
                    onMouseEnter={() => setHovered3(true)}
                    onMouseLeave={() => setHovered3(false)}
                  >
                    <div>
                      <button onClick={() => toggle("background")}>
                        <img
                          src={
                            hovered3 || isOpenBg
                              ? BackgroundWindowIcnBlue
                              : BackgroundWindowIcn
                          }
                          alt="Icon"
                        ></img>
                      </button>
                      <label style={{ color: isActive3 ? "blue" : "initial" }}>
                        Background
                      </label>
                    </div>
                  </div>

                  <div
                    className={`TxtBtn ${isActive4 ? "active" : ""}`}
                    onMouseEnter={() => setHovered4(true)}
                    onMouseLeave={() => setHovered4(false)}
                  >
                    <button
                      onClick={() => toggle("shapes")}
                      className={clicked ? "active" : ""}
                    >
                      <img
                        src={hovered4 || isOpenshape ? ShapeIcnBlue : ShapeIcn}
                        alt="Icon"
                      ></img>
                    </button>
                    <label style={{ color: isActive4 ? "blue" : "initial" }}>
                      Shapes
                    </label>
                  </div>

                  {/* <div
                  className={`TxtBtn ${isActive5 ? "active" : ""}`}
                  onMouseEnter={() => setHovered5(true)}
                  onMouseLeave={() => setHovered5(false)}
                >
                  <button
                    onClick={() => {
                      toggle("template");
                      master_data_templates("c", null);
                    }}
                    className={clicked ? "active" : ""}
                  >
                    <img
                      src={
                        hovered5 || isOpenTemplate
                          ? TemplGridIcnBlue
                          : TemplGridIcn
                      }
                      alt="Icon"
                    ></img>
                  </button>
                  <label style={{ color: isActive5 ? "blue" : "initial" }}>
                    Template{" "}
                  </label>
                </div> */}

                  <div
                    className={`TxtBtn ${isActive6 ? "active" : ""}`}
                    onMouseEnter={() => setHovered6(true)}
                    onMouseLeave={() => setHovered6(false)}
                  >
                    <div className="HOverColorTxt">
                      <button
                        onClick={() => {
                          toggle("AboutIcon");
                          // master_data_get_full();
                        }}
                        className={clicked ? "active" : ""}
                      >
                        <img
                          src={
                            hovered6 || isOpenAbout || isOpenAbout2
                              ? aboutIcnBlue
                              : aboutIcn
                          }
                          alt="Icon"
                        ></img>
                      </button>
                      <label
                        style={{
                          color:
                            hovered6 || isOpenAbout || isOpenAbout2
                              ? "blue"
                              : "initial",
                        }}
                      >
                        About
                      </label>
                    </div>
                  </div>

                  <div
                    className={`TxtBtn ${isActive7 ? "active" : ""}`}
                    onMouseEnter={() => setHovered7(true)}
                    onMouseLeave={() => setHovered7(false)}
                  >
                    <button onClick={() => toggle("uploads")}>
                      <img
                        src={
                          hovered7 || isOpenuploads ? UploadIcnBlue : UploadIcn
                        }
                        alt="Icon"
                      ></img>
                    </button>
                    <label style={{ color: isActive7 ? "blue" : "initial" }}>
                      Uploads{" "}
                    </label>
                  </div>
                  {data_type != "logo" && (
                    <div
                      className={`TxtBtn ${isActive10 ? "active" : ""}`}
                      onMouseEnter={() => setHovered10(true)}
                      onMouseLeave={() => setHovered10(false)}
                    >
                      <button onClick={() => toggle("pictures")}>
                        <img
                          src={
                            hovered10 || isOpenPictures
                              ? pictureIcnBlue
                              : pictureIcn
                          }
                          alt="Icon"
                        ></img>
                      </button>
                      <label style={{ color: isActive10 ? "blue" : "initial" }}>
                        Pictures{" "}
                      </label>
                    </div>
                  )}
                </div>
                {!isToolbarActive && (
                  <div className="DownloadBtn">
                    <button onClick={toggleSecondButton2}>Download</button>
                  </div>
                )}

                {isToolbarActive && (
                  <div className="DownloadBtn closedBBTnn">
                    <button onClick={handleCloseToolbar}>
                      {" "}
                      <img src={crossIcn}></img>
                    </button>
                  </div>
                )}
              </div>
            )}

            <div className={sidebarClassName}>
              <div
                className="SideBarContainer sideContainerHeight"
                ref={uploadRef}
              >
                <div className="SideBarContainer">
                  <div className="container">
                    <div className="IconContnr">
                      {isOpenicon && (
                        <div>
                          {" "}
                          <div className="crossIcn icn">
                            <p>Add Icon</p>
                            <button onClick={handleClose}>
                              <img src={crossIcn}></img>
                            </button>
                          </div>{" "}
                          <Icons
                            handleClose={handleClose}
                            setIconData={handleIconData}
                          />
                        </div>
                      )}
                    </div>
                  </div>

                  <div className="container" ref={textDivRef}>
                    {isOpentext && (
                      <div className="addtxt">
                        <div className="SearchConTnnrr">
                          <div className="crossIcn icn">
                            <p></p>
                            <button onClick={handleClose}>
                              <img src={crossIcn}></img>
                            </button>
                          </div>{" "}
                          <div className="container TextFiledBox">
                            <p className="">Add Text</p>
                            <p
                              className="upladCustumFOnt"
                              onClick={handleFontuploadModal}
                            >
                              Upload
                            </p>
                          </div>
                          <div className="FontSearchbar">
                            <div className="mb-3">
                              <Select
                                styles={customStyles3}
                                options={categoryOptions}
                                value={selectedCategory}
                                onChange={handleCategoryChange}
                                placeholder="Select a category"
                                isClearable
                              />
                            </div>
                          </div>
                        </div>
                        {filteredFonts.slice(0, initialFontCount).length > 0 ? (
                          filteredFonts
                            .slice(0, initialFontCount)
                            .map((font, index) => (
                              <div className="TextAddContain" key={index}>
                                <div className="txtContainer">
                                  <div className="txt1">
                                    <button
                                      onClick={() => {
                                        if (font && font.family) {
                                          handleFontSelection(font);
                                        } else {
                                          console.error(
                                            "Font is undefined or does not have a family property."
                                          );
                                        }
                                      }}
                                    >
                                      <p
                                        style={{
                                          fontFamily: font.family,
                                          cursor: "pointer",
                                        }}
                                      >
                                        {`Add Text`}
                                      </p>
                                      <label
                                        style={{
                                          fontFamily: font.family,
                                          cursor: "pointer",
                                        }}
                                      >
                                        ({font.family || "Arial"})
                                      </label>
                                    </button>
                                  </div>
                                </div>
                              </div>
                            ))
                        ) : (
                          <p>No fonts found</p>
                        )}
                        {filteredFonts.length > initialFontCount && (
                          <div className="ShowAllDiv mb-3 loadMoreBTn">
                            {/* <button onClick={loadMoreFonts}>
                            <label>Load More</label>
                          </button> */}
                          </div>
                        )}
                      </div>
                    )}
                  </div>

                  <div className="container">
                    {isOpenBg && (
                      <div className="TxtBtn2 bgBtnInside">
                        <div className="crossIcn icn">
                          <p>Background</p>
                          <button onClick={handleClose}>
                            <img src={crossIcn} alt="bg"></img>
                          </button>
                        </div>{" "}
                        <div className="ChooseColorBtn">
                          <button onClick={handleButtonClick}>
                            <input
                              type="color"
                              ref={colorPickerRef}
                              style={{ display: "none", cursor: "pointer" }}
                              onChange={handleCanvasColorChange}
                            />
                            Choose from pallette
                          </button>
                        </div>
                        <div className="backgroundColorOptions bgColor">
                          {" "}
                          <div
                            style={{
                              display: "flex",
                              flexDirection: "row",
                              flexWrap: "wrap",
                            }}
                          >
                            {colors.map((palette, index) => (
                              <div
                                className="backgroundColorOptions"
                                key={index}
                              >
                                <div className="ColorTxt">
                                  <p>{palette.title}</p>
                                </div>
                                <div className="SideBArBackgroundCOlor">
                                  {palette.list.map((color, colorIndex) => (
                                    <div
                                      key={colorIndex}
                                      className={`catagorieBgcolors ${
                                        activeColor === color ? "active" : ""
                                      }`}
                                      style={{
                                        background: Array.isArray(color)
                                          ? `linear-gradient(to right, ${color.join(
                                              ", "
                                            )})`
                                          : color,
                                        outline:
                                          activeColor === color
                                            ? "2px solid #6473fddc"
                                            : "",
                                        border: `12px solid ${
                                          Array.isArray(color)
                                            ? "transparent"
                                            : color
                                        }`,
                                      }}
                                      onClick={() => handleClick(color)}
                                    />
                                  ))}
                                </div>
                              </div>
                            ))}
                          </div>
                        </div>
                      </div>
                    )}
                  </div>

                  <div className="container">
                    {isOpenshape && (
                      <div className="TxtBtn2 shapeBtns">
                        <div className="crossIcn icn">
                          <p className="BorderBtm">Shapes</p>
                          <button onClick={handleClose}>
                            <img src={crossIcn} alt="bg"></img>
                          </button>
                        </div>{" "}
                        <div>
                          {currentContent === "main" && (
                            <div className="ShapeTabs">
                              <div
                                className="Tab"
                                onClick={() => setCurrentContent("shape")}
                              >
                                <img src={shape1} alt="Shape Inner" />
                                <label>Shapes</label>
                              </div>
                              <div
                                className="Tab"
                                onClick={() => setCurrentContent("line")}
                              >
                                <img src={line1} alt="Line" />
                                <label>Lines</label>
                              </div>
                              <div
                                className="Tab"
                                onClick={() => setCurrentContent("frame")}
                              >
                                <img src={fram1} alt="Frames" />
                                <label>Frames</label>
                              </div>
                            </div>
                          )}

                          {currentContent !== "main" && (
                            <>
                              <button
                                className="BackButton"
                                onClick={() => {
                                  setCurrentContent("main");
                                  setOptionsVisible(null);
                                }}
                              >
                                Back to{" "}
                                {currentContent.charAt(0).toUpperCase() +
                                  currentContent.slice(1)}
                              </button>

                              <div className="Shapesdata">
                                {filteredShapeData.length > 0 ? (
                                  filteredShapeData.map((icon, index) => (
                                    <div
                                      key={icon.id}
                                      className="ItemWrapper"
                                      onMouseEnter={() => {
                                        hoverImage(index);
                                      }}
                                      onMouseLeave={() => {
                                        hoverImage(null);
                                      }}
                                    >
                                      <button
                                        onClick={() => handleClickShape(index)}
                                      >
                                        <img
                                          src={icon.shape_url}
                                          alt={`${icon.shape_type} ${icon.primary_id}`}
                                          className="ShapeImageMapd"
                                        />
                                      </button>

                                      {/* {hoveredImage === index && (
                                      <div className="imgHoverShape">
                                        <button
                                          className="optionsBttns"
                                          onClick={() =>
                                            handleOptionClick(index, icon.type)
                                          }
                                        >
                                          <img
                                            className="OPTiMG"
                                            src={uploadImgOptions}
                                            alt="Options"
                                          />
                                        </button>
                                      </div>
                                    )} */}

                                      {optionsVisible === index &&
                                        currentOptionsType === icon.type && (
                                          <div className="optionsMenu">
                                            <button onClick={() => {}}>
                                              Download
                                            </button>
                                            <button
                                              className="removeBtn"
                                              onClick={() => {}}
                                            >
                                              Remove
                                            </button>
                                          </div>
                                        )}
                                    </div>
                                  ))
                                ) : (
                                  <div
                                    style={{
                                      width: "100%",
                                      display: "flex",
                                      justifyContent: "center",
                                      paddingTop: "0.5rem",
                                    }}
                                  >
                                    No{" "}
                                    {currentContent.charAt(0).toUpperCase() +
                                      currentContent.slice(1)}{" "}
                                    available
                                  </div>
                                )}
                              </div>
                            </>
                          )}
                        </div>
                      </div>
                    )}
                  </div>
                  {isOpenTemplate && (
                    <div className="container">
                      <div className="crossIcn icn">
                        <p>Template</p>
                        <button onClick={handleClose}>
                          <img src={crossIcn} alt="bg"></img>
                        </button>
                      </div>{" "}
                      <div className="crossIcn TemplateCross">
                        <button
                          onClick={() => {
                            handleClose();
                          }}
                        >
                          <img src={crossIcn}></img>
                        </button>
                      </div>
                      {Gettemplates ? (
                        <div className="row " style={{ flexWrap: "wrap" }}>
                          {Gettemplates.map((logoData, index) => (
                            <div
                              className="col-sm-6 mt-3"
                              key={index}
                              onClick={() => {
                                handleImageClick2(logoData);
                              }}
                            >
                              <div
                                className="girdLogo_item2"
                                style={{ cursor: "pointer" }}
                              >
                                <canvas
                                  ref={(ref) => createCanvas(ref, logoData)}
                                  style={{ margin: 0, padding: 0 }}
                                />
                              </div>
                            </div>
                          ))}
                        </div>
                      ) : (
                        <p>No Templates found.</p>
                      )}
                    </div>
                  )}

                  <div className="container">
                    {" "}
                    {isOpenAbout && (
                      <div className="TxtBtn2  ">
                        <div className="crossIcn icn">
                          <p>About</p>
                          <button onClick={handleClose}>
                            <img src={crossIcn} alt="bg"></img>
                          </button>
                        </div>{" "}
                        <div className="AboutContent">
                          <h6>Get your brand package </h6>
                          <ul>
                            <li>
                              Social media kit images for Facebook, Instagram,
                              YouTube, Etsy, LinkedIn, VK
                            </li>
                            <li>Favicon for website</li>
                            <li>Logo Watermarks</li>
                            <li>Letterheads in PDF and DOCX</li>
                            <li>
                              Change logo or information anytime and download
                              updated files immediately
                            </li>
                          </ul>
                          <div className="UpgradePlanButton">
                            {" "}
                            <button>
                              <Link to="/pricing">
                                <img src={upgradeIcn}></img>
                                <h7 style={{ color: "white" }}>Upgrade</h7>
                              </Link>
                            </button>
                          </div>
                        </div>
                      </div>
                    )}
                  </div>

                  <div className="container" ref={specificDivRef}>
                    {isOpenuploads && (
                      <div className="upLoadBTn">
                        <div className="crossIcn icn">
                          <p>Upload</p>
                          <button onClick={handleClose}>
                            <img src={crossIcn} alt="bg"></img>
                          </button>
                        </div>{" "}
                        <button
                          className="updBtn"
                          onClick={handleButtonUploadimg}
                        >
                          Upload
                        </button>
                        <button className="MyUploadss">
                          <label> My Uploads</label>
                        </button>
                        <div className="container">
                          <div className="row justify-content-center">
                            <div className="col-12">
                              <div className="UploadedImggetcontainer">
                                {GetUploadImg ? (
                                  GetUploadImg.map((person, index) => (
                                    <div
                                      key={index}
                                      onMouseEnter={() =>
                                        handleMouseEnter4(index)
                                      }
                                      onMouseLeave={handleMouseLeave4}
                                      ref={(el) =>
                                        (containerRefs.current[index] = el)
                                      }
                                      className="renderUploadedImg"
                                    >
                                      <div className="UploadImgs">
                                        {hoveredImageIndex === index && (
                                          <button
                                            className="uploadImgOptions"
                                            onClick={() =>
                                              toggleVisibility4(index)
                                            }
                                          >
                                            <img
                                              src={uploadImgOptions}
                                              alt="Upload Options"
                                            />
                                          </button>
                                        )}

                                        <button
                                          onClick={() =>
                                            handleImageClick(
                                              APL_LINK +
                                                IMAGEPATH +
                                                person.upload_img
                                            )
                                          }
                                        >
                                          <img
                                            id="upload_img"
                                            name="upload_img"
                                            src={
                                              APL_LINK +
                                              IMAGEPATH +
                                              person.upload_img
                                            }
                                            alt={`Image ${index + 1}`}
                                          />
                                        </button>
                                        {isVisible4 === index && (
                                          <div
                                            className="uploadsssOpp"
                                            ref={HideDwnUpRef}
                                          >
                                            <div className="uploadGetimgContainer">
                                              <button
                                                onClick={() =>
                                                  handleDownload(
                                                    APL_LINK +
                                                      IMAGEPATH +
                                                      person.upload_img
                                                  )
                                                }
                                              >
                                                <img
                                                  src={downloadicn}
                                                  alt="Download"
                                                />
                                                <label>Download original</label>
                                              </button>
                                              <button
                                                onClick={() =>
                                                  master_data_action(
                                                    person.primary_id
                                                  )
                                                }
                                              >
                                                <img
                                                  src={removeIcn}
                                                  alt="Remove"
                                                />
                                                <label>Remove</label>
                                              </button>
                                            </div>
                                          </div>
                                        )}
                                      </div>
                                    </div>
                                  ))
                                ) : (
                                  <div className="d-flex justify-content-center">
                                    <p>No images found.</p>
                                  </div>
                                )}
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    )}
                  </div>

                  <div className="container" ref={specificDivRef}>
                    {isOpenPictures && (
                      <div className="upLoadBTn">
                        <div className="crossIcn icn">
                          <p>Upload Pictures</p>
                          <button onClick={handleClose}>
                            <img src={crossIcn} alt="bg"></img>
                          </button>
                        </div>{" "}
                        {/* <button
                          className="updBtn"
                          onClick={handleButtonUploadimgPicture}
                        >
                          Upload
                        </button>
                        <button className="MyUploadss">
                          <label> My Pictures</label>
                        </button> */}
                        <div className="container">
                          <div className="row justify-content-center">
                            <div className="col-12">
                              <div className="UploadedImggetcontainer">
                                {pictures ? (
                                  pictures.map((person, index) => (
                                    <div
                                      key={index}
                                      onMouseEnter={() =>
                                        handleMouseEnter4(index)
                                      }
                                      onMouseLeave={handleMouseLeave4}
                                      ref={(el) =>
                                        (containerRefs.current[index] = el)
                                      }
                                      className="renderUploadedImg"
                                    >
                                      <div className="UploadImgs">
                                        {hoveredImageIndex === index && (
                                          <button
                                            className="uploadImgOptions"
                                            onClick={() =>
                                              toggleVisibility4(index)
                                            }
                                          >
                                            <img
                                              src={uploadImgOptions}
                                              alt="Upload Options"
                                            />
                                          </button>
                                        )}

                                        <button
                                          onClick={() =>
                                            handleImageClick(
                                              APL_LINK +
                                                IMAGEPATH +
                                                person.upload_img
                                            )
                                          }
                                        >
                                          <img
                                            id="upload_img"
                                            name="upload_img"
                                            src={
                                              APL_LINK +
                                              IMAGEPATH +
                                              person.upload_img
                                            }
                                            alt={`Image ${index + 1}`}
                                          />
                                        </button>
                                        {isVisible4 === index && (
                                          <div
                                            className="uploadsssOpp"
                                            ref={HideDwnUpRef}
                                          >
                                            <div className="uploadGetimgContainer">
                                              <button
                                                onClick={() =>
                                                  handleDownload(
                                                    APL_LINK +
                                                      IMAGEPATH +
                                                      person.upload_img
                                                  )
                                                }
                                              >
                                                <img
                                                  src={downloadicn}
                                                  alt="Download"
                                                />
                                                <label>Download original</label>
                                              </button>
                                              {/* <button
                                                onClick={() =>
                                                  master_data_action(
                                                    person.primary_id
                                                  )
                                                }
                                              >
                                                <img
                                                  src={removeIcn}
                                                  alt="Remove"
                                                />
                                                <label>Remove</label>
                                              </button> */}
                                            </div>
                                          </div>
                                        )}
                                      </div>
                                    </div>
                                  ))
                                ) : (
                                  <div className="d-flex justify-content-center">
                                    <p>No images found.</p>
                                  </div>
                                )}
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    )}
                  </div>
                </div>
              </div>
            </div>
            <div className={!isOpenAbout2 ? "hidden" : ""}>
              <AboutCanvas
                isOpenAbout2={isOpenAbout2}
                data={isOpenAbout2 ? mainCanvas : ""}
              />
            </div>

            <div
              className={
                isOpenAbout2
                  ? "hidden"
                  : "canvasEditorCOntainer col-lg-12 mx-auto mobilePading"
              }
            >
              {showSidebar ? (
                <div className="col-col-12 toolbarPosition">
                  <div className="topHeight">
                    {/*------Text ToolBar-----*/}
                    {(isTextToolVisible || isObjectSelected || isGroupObj) && (
                      <div className="maintoolbarMob">
                        <div
                          className={`container ToolBArsection ${
                            isMobile ? "ToolBarMobile" : ""
                          }`}
                        >
                          <div className="TextEdittoolBar">
                            {isTextToolVisible && (
                              <div className="topTxtEditToolBar2">
                                <div className="TxtEdit">
                                  <div className="ediTBBUTN">
                                    <button
                                      onClick={handleFontButtonClick}
                                      className={buttonClass}
                                    >
                                      <img src={imgSrc} alt="fontFamily" />
                                      {truncatedFontFamily}
                                    </button>
                                  </div>
                                </div>
                                <div className="TxtEdit tooltip-button">
                                  <div className="gratterLess ">
                                    <button
                                      style={{ background: "transparent" }}
                                      onClick={() => handleFontSizeChange(-1)}
                                    >
                                      <img src={lessIcn}></img>
                                    </button>
                                    <select
                                      className="FontOptionDropdown"
                                      value={selectedFontSize}
                                      onChange={(e) => {
                                        const newFontSize = parseInt(
                                          e.target.value,
                                          10
                                        );
                                        // Set the font size to the selected value
                                        handleFontSizeChange(
                                          newFontSize - selectedFontSize
                                        );
                                      }}
                                    >
                                      {fontSizes.map((size) => (
                                        <option key={size} value={size}>
                                          {size}px
                                        </option>
                                      ))}
                                      {/* Add an option for custom size */}
                                      <option value={selectedFontSize} selected>
                                        {selectedFontSize}px
                                      </option>
                                    </select>
                                    <button
                                      style={{ background: "transparent" }}
                                      onClick={() => handleFontSizeChange(1)}
                                    >
                                      <img src={graterIcn}></img>
                                    </button>
                                    <span className="tooltip-text">
                                      Font size
                                    </span>
                                  </div>
                                </div>

                                <div className="colorinputtt tooltip-button">
                                  <input
                                    className="custom-input tooltip-button"
                                    type="color"
                                    value={SetTextcolor}
                                    onChange={(e) =>
                                      handleTextColorChange(e.target.value)
                                    }
                                  />
                                  <span className="tooltip-text">
                                    Font color
                                  </span>
                                </div>
                                <div className="aligmentIcn">
                                  <button
                                    className="tooltip-button"
                                    onClick={() => toggleAlignment()}
                                  >
                                    {alignmentPosition === "left" && (
                                      <img
                                        src={aligmentLeft}
                                        alt="Left Alignment"
                                      />
                                    )}
                                    {alignmentPosition === "center" && (
                                      <img
                                        src={aligmntIcn}
                                        alt="Center Alignment"
                                      />
                                    )}
                                    {alignmentPosition === "right" && (
                                      <img
                                        src={aligmentRight}
                                        alt="Right Alignment"
                                      />
                                    )}
                                    <span className="tooltip-text">
                                      Alignment
                                    </span>
                                  </button>
                                </div>
                                <div className="aligmentIcn">
                                  <button
                                    className="tooltip-button"
                                    onClick={convertTextToUpperCase}
                                  >
                                    <img src={CaptallSmallIcn} alt="img"></img>
                                    <span className="tooltip-text">
                                      Capital
                                    </span>
                                  </button>
                                </div>
                                <div
                                  className={`aligmentIcn ${
                                    Textweight ? "aligmentIcnSlct" : ""
                                  } `}
                                >
                                  <button
                                    className="tooltip-button"
                                    onClick={() =>
                                      handleFontStyleChange(true, false)
                                    }
                                  >
                                    <img src={BoldIcn} alt="Bold" />
                                    <span className="tooltip-text">Bold</span>
                                  </button>
                                </div>
                                <div
                                  className={`aligmentIcn ${
                                    Textstyle ? "aligmentIcnSlct" : ""
                                  } `}
                                >
                                  <button
                                    className="tooltip-button"
                                    onClick={() =>
                                      handleFontStyleChange(false, true)
                                    }
                                  >
                                    <img src={IteLicIcn} alt="Italic" />
                                    <span className="tooltip-text">Italic</span>
                                  </button>
                                </div>

                                <div ref={buttonLetterRef}>
                                  <div className="aligmentIcn ">
                                    <button
                                      className="tooltip-button"
                                      onClick={() =>
                                        setLetterSpacingVisible(
                                          !isLetterSpacingVisible
                                        )
                                      }
                                    >
                                      <img
                                        src={letterSpacing}
                                        className="letterSpacing"
                                        alt="Opacity"
                                      />
                                      {/* <span className="tooltip-text">
                                    Letter Spacing
                                  </span> */}
                                    </button>
                                  </div>
                                </div>

                                <div className="aligmentIcn">
                                  <button
                                    className="tooltip-button"
                                    onClick={handleShowLayers}
                                  >
                                    <img
                                      src={possitionIcn}
                                      alt="Duplicate"
                                    ></img>
                                    <span className="tooltip-text">
                                      Possition
                                    </span>
                                  </button>
                                </div>

                                <div className="aligmentIcn">
                                  <button
                                    className="tooltip-button"
                                    onClick={toggleOpacityInput}
                                  >
                                    <img src={OpeSityObjctIcn} alt="Opacity" />
                                    <span className="tooltip-text">
                                      Opacity
                                    </span>
                                  </button>
                                </div>
                                <div className="opesityFill"></div>
                                {/* <div className="aligmentIcn">
                              <button
                                className="tooltip-button"
                                onClick={duplicateObject}
                              >
                                <img
                                  src={objectDuplicateIcn}
                                  alt="Duplicate"
                                ></img>
                                <span className="tooltip-text">Duplicate</span>
                              </button>
                            </div> */}
                                <div className="aligmentIcn">
                                  <div className="Container">
                                    {" "}
                                    <button
                                      className="tooltip-button"
                                      onClick={handleDeleteClick}
                                    >
                                      <img
                                        src={objectDltIcn}
                                        alt="Delete"
                                      ></img>
                                      <span className="tooltip-text">
                                        Delete
                                      </span>
                                    </button>
                                  </div>
                                </div>
                              </div>
                            )}
                          </div>

                          {/*Object Canvas */}
                          <div className="TextEdittoolBar2">
                            {isObjectSelected && (
                              <div className="topTxtEditToolBar2">
                                {/* <div className="TxtEdit">
                              <button className="tooltip-button">
                                <img src={ReplaceIcn} alt="img"></img>
                                <span className="tooltip-text2">Replace</span>
                                Replace
                              </button>
                            </div> */}
                                <div className="TxtEdit">
                                  <button
                                    className="tooltip-button flipICnnss"
                                    onClick={toggleFlipButtons}
                                  >
                                    <span className="tooltip-text">Flip</span>
                                    Flip
                                  </button>

                                  {showFlipButtons && (
                                    <div className="flipss" ref={FlipLayer}>
                                      <div className="FlipHorizintall">
                                        <button
                                          onClick={() =>
                                            flipObject("horizontal")
                                          }
                                        >
                                          Flip Horizontally
                                        </button>
                                      </div>
                                      <div className="FlipVertically">
                                        <button
                                          onClick={() => flipObject("vertical")}
                                        >
                                          Flip Vertically
                                        </button>
                                      </div>
                                    </div>
                                  )}
                                </div>
                                {shopcolor &&
                                  (colorcodeloop && colorcodeloop.length > 0 ? (
                                    colorcodeloop.map((color, index) => (
                                      <div
                                        key={index}
                                        className="colorinputtt tooltip-button"
                                      >
                                        <input
                                          className="custom-input tooltip-button"
                                          type="color"
                                          Value={iconchngcolor} // Set the initial color value
                                          color_code="#FFFFFF"
                                          id={`color_id${index}`}
                                          onChange={(e) => {
                                            handleIconColorChange(
                                              e.target.value,
                                              `color_id${index}`
                                            );
                                          }}
                                          style={{ cursor: "pointer" }}
                                        />
                                        {/* <span className="tooltip-text">
                                      Font color
                                    </span> */}
                                      </div>
                                    ))
                                  ) : (
                                    <div className="colorinputtt tooltip-button">
                                      <input
                                        className="custom-input tooltip-button"
                                        type="color"
                                        color_code={iconchngcolor}
                                        Value={iconchngcolor}
                                        id="color_id0"
                                        onChange={(e) => {
                                          handleIconColorChange(
                                            e.target.value,
                                            "color_id0"
                                          );
                                        }}
                                        style={{ cursor: "pointer" }}
                                      />
                                      {/* <span className="tooltip-text">
                                    Font color
                                  </span> */}
                                    </div>
                                  ))}

                                <div className="TxtEdit">
                                  <button
                                    className="tooltip-button flipIcn"
                                    onClick={handleCropButtonClick}
                                  >
                                    Crop
                                    <span className="tooltip-text3">Crop</span>
                                  </button>
                                </div>
                                {/* <div className="aligmentIcn">
                              <button
                                className="tooltip-button"
                                onClick={() => toggleAlignment()}
                              >
                                {alignmentPosition === "left" && (
                                  <img
                                    src={aligmentLeft}
                                    alt="Left Alignment"
                                  />
                                )}
                                {alignmentPosition === "center" && (
                                  <img
                                    src={aligmntIcn}
                                    alt="Center Alignment"
                                  />
                                )}
                                {alignmentPosition === "right" && (
                                  <img
                                    src={aligmentRight}
                                    alt="Right Alignment"
                                  />
                                )}
                                <span className="tooltip-text">Alignment</span>
                              </button>
                            </div> */}
                                <div className="aligmentIcn">
                                  <button
                                    className="tooltip-button"
                                    onClick={handleShowLayers}
                                  >
                                    <img
                                      src={possitionIcn}
                                      alt="Duplicate"
                                    ></img>
                                    <span className="tooltip-text">
                                      Possition
                                    </span>
                                  </button>
                                </div>

                                <div className="aligmentIcn">
                                  <button
                                    className="tooltip-button"
                                    onClick={toggleOpacityInput}
                                  >
                                    <img src={OpeSityObjctIcn} alt="Opacity" />
                                    <span className="tooltip-text">
                                      Opacity
                                    </span>
                                  </button>
                                </div>
                                <div className="opesityFill"></div>
                                <div className="aligmentIcn">
                                  <button
                                    className="tooltip-button"
                                    onClick={duplicateObject}
                                  >
                                    <img
                                      src={objectDuplicateIcn}
                                      alt="Duplicate"
                                    ></img>
                                    <span className="tooltip-text">
                                      Duplicate
                                    </span>
                                  </button>
                                </div>
                                <div className="aligmentIcn">
                                  <button
                                    className="tooltip-button"
                                    onClick={handleDeleteClick}
                                  >
                                    <img src={objectDltIcn} alt="Delete"></img>
                                    <span className="tooltip-text">Delete</span>
                                  </button>
                                </div>
                                {/* <div className="aligmentIcn">
                              <button
                                className="tooltip-button"
                                onClick={handleButtonClickCheckIcon}
                                disabled={isButtonDisabled}
                                style={{ opacity: isButtonDisabled ? 0.5 : 1 }}
                              >
                                <img
                                  src={
                                    sessionStorage.getItem("checkedIconId") ===
                                    selectedIconId
                                      ? markRght
                                      : questionMark
                                  }
                                  alt="Toggle"
                                />
                                <span className="tooltip-text">
                                  {isButtonDisabled
                                    ? "uncheck previus icon"
                                    : sessionStorage.getItem(
                                        "checkedIconId"
                                      ) === selectedIconId
                                    ? "Checked"
                                    : "ICON"}
                                </span>
                              </button>
                            </div> */}
                              </div>
                            )}
                          </div>
                          {/*Group ToollBar*/}
                          <div className="TextEdittoolBar2">
                            {isGroupObj && (
                              <div className="topTxtEditToolBar2">
                                <div className="aligmentIcn">
                                  <button
                                    onClick={() =>
                                      handleAlignment("left", "group")
                                    }
                                  >
                                    <img
                                      className="me-2"
                                      src={aligmentLeft}
                                      alt="img"
                                    />
                                    Left
                                  </button>
                                </div>

                                <div className="aligmentIcn">
                                  <button
                                    onClick={() =>
                                      handleAlignment("right", "group")
                                    }
                                  >
                                    {" "}
                                    <img
                                      className="me-2"
                                      src={aligmentRight}
                                      alt="img"
                                    />
                                    Right
                                  </button>
                                </div>

                                <div className="aligmentIcn">
                                  <button
                                    onClick={() =>
                                      handleAlignment("center", "group")
                                    }
                                  >
                                    <img
                                      className="me-2"
                                      src={aligmntIcn}
                                      alt="img"
                                    />{" "}
                                    Center
                                  </button>
                                </div>

                                <div className="aligmentIcn">
                                  <button
                                    onClick={() =>
                                      handleAlignment("middle", "group")
                                    }
                                  >
                                    {" "}
                                    <img
                                      className="me-2"
                                      src={centerALigment}
                                      alt="img"
                                    />{" "}
                                    Middle
                                  </button>
                                </div>

                                <div className="aligmentIcn">
                                  <button
                                    className="tooltip-button"
                                    onClick={toggleOpacityInput}
                                  >
                                    <img src={OpeSityObjctIcn} alt="Opacity" />
                                    <span className="tooltip-text">
                                      Opacity
                                    </span>
                                  </button>
                                </div>
                                <div className="opesityFill">
                                  {showOpacityInput && (
                                    <div ref={opacityInputRef}>
                                      <div className="OppSItyyInput">
                                        <input
                                          type="range"
                                          min="0"
                                          max="100"
                                          value={opacity}
                                          onChange={(event) =>
                                            handleOpacityChange(event, "group")
                                          }
                                        />
                                        <p>{opacity}%</p>
                                      </div>
                                    </div>
                                  )}
                                </div>

                                <div className="aligmentIcn">
                                  <button
                                    className="tooltip-button"
                                    onClick={handleDeleteClick}
                                  >
                                    <img src={objectDltIcn} alt="Delete"></img>
                                    <span className="tooltip-text">Delete</span>
                                  </button>
                                </div>
                              </div>
                            )}
                          </div>
                        </div>
                      </div>
                    )}
                    {isCropMode && (
                      <div className="cropBtnSaveCencel">
                        <button onClick={handleSaveCrop}>
                          <img src={rightmakImg}></img>Save
                        </button>
                        <button onClick={handleCancelCrop}>
                          <img src={crossIconModa}></img>Cancel
                        </button>
                      </div>
                    )}
                    {showSidebar ? (
                      !isTextToolVisible && !isObjectSelected && !isGroupObj ? (
                        <label className="editAreraText">
                          Click on any object to customize its properties or
                          double-click to effortlessly edit text.
                        </label>
                      ) : null
                    ) : null}
                  </div>
                </div>
              ) : null}
              {/**ObjectPossition */}
              {isPossitionsLayers && (
                <div className="posstionLayer" ref={PosstionLayer}>
                  <div
                    className={`possitns ${
                      isCollapsedPosstion ? "collapsed" : "visible"
                    }`}
                  >
                    <div
                      className="crossIcn icn   textCrssBrr"
                      onClick={handleToggle}
                    >
                      <p>Possition</p>
                      <button onClick={handleClose}>
                        <img src={crossIcn} alt="cross"></img>
                      </button>
                    </div>{" "}
                    <div className="row">
                      <div className="PossitonDiv">
                        <div className="col-6 PossitonDivJustiStart">
                          <button onClick={bringLayerUp}>
                            <img
                              src={frontLayer}
                              alt="Front Layer"
                              className="me-2"
                            />
                            Layer Up
                          </button>
                        </div>
                        <div className="col-6 PossitonDivJustiEnd">
                          <button onClick={bringLayerDown}>
                            <img
                              src={backLayer}
                              alt="Back Layer"
                              className="me-2"
                            />
                            Layer Down
                          </button>
                        </div>
                      </div>
                    </div>
                    <div className="row">
                      <div className="PossitonDiv">
                        <div className="col-6  PossitonDivJustiStart">
                          <button onClick={bringToFront}>
                            <img
                              src={frontLayer}
                              alt="Front Layer"
                              className="me-2"
                            />
                            Front Layer
                          </button>
                        </div>
                        <div className="col-6  PossitonDivJustiEnd">
                          <button onClick={sendToBack}>
                            <img
                              src={backLayer}
                              alt="Back Layer"
                              className="me-2"
                            />
                            Back Layer
                          </button>
                        </div>
                      </div>
                    </div>
                    <label className="ALigmttxt">Alignemnt</label>
                    <div className="row">
                      <div className="PossitonDiv">
                        <div className="col-6  PossitonDivJustiStart">
                          <button onClick={() => handleAlignment("left")}>
                            <img
                              src={aligmentLeft}
                              alt="Front Layer"
                              className="me-2"
                            />
                            Left
                          </button>
                        </div>
                        <div className="col-6  PossitonDivJustiEnd">
                          <button onClick={() => handleAlignment("right")}>
                            <img
                              src={aligmentRight}
                              alt="Back Layer"
                              className="me-2"
                            />
                            Right
                          </button>
                        </div>
                      </div>
                    </div>
                    <div className="row">
                      <div className="PossitonDiv">
                        <div className="col-6  PossitonDivJustiStart">
                          <button onClick={() => handleAlignment("top")}>
                            <img
                              src={topALigment}
                              alt="Front Layer"
                              className="me-2"
                            />
                            Top
                          </button>
                        </div>
                        <div className="col-6  PossitonDivJustiEnd">
                          <button onClick={() => handleAlignment("bottom")}>
                            <img
                              className="me-2 bottmAigmnt"
                              src={topALigment}
                              alt="Back Layer"
                            />
                            Bottom
                          </button>
                        </div>
                      </div>
                    </div>
                    <div className="row">
                      <div className="PossitonDiv">
                        <div className="col-6  PossitonDivJustiStart">
                          <button onClick={() => handleAlignment("center")}>
                            <img
                              src={aligmntIcn}
                              alt="Front Layer"
                              className="me-2"
                            />
                            Center
                          </button>
                        </div>
                        <div className="col-6  PossitonDivJustiEnd">
                          <button onClick={() => handleAlignment("middle")}>
                            <img
                              className="me-2 bottmAigmnt"
                              src={centerALigment}
                              alt="Back Layer"
                            />
                            Middle
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              )}
              {/**ObjectPossition */}
              {isPossitionsLayers && (
                <div className="posstionLayer" ref={PosstionLayer}>
                  <div
                    className={`possitns ${
                      isCollapsedPosstion ? "collapsed" : "visible"
                    }`}
                  >
                    <div
                      className="crossIcn icn   textCrssBrr"
                      onClick={handleToggle}
                    >
                      <p>Possition</p>
                      <button onClick={handleClose}>
                        <img src={crossIcn}></img>
                      </button>
                    </div>{" "}
                    <div className="row">
                      <div className="PossitonDiv">
                        <div className="col-6 PossitonDivJustiStart">
                          <button onClick={bringLayerUp}>
                            <img
                              src={frontLayer}
                              alt="Front Layer"
                              className="me-2"
                            />
                            Layer Up
                          </button>
                        </div>
                        <div className="col-6 PossitonDivJustiEnd">
                          <button onClick={bringLayerDown}>
                            <img
                              src={backLayer}
                              alt="Back Layer"
                              className="me-2"
                            />
                            Layer Down
                          </button>
                        </div>
                      </div>
                    </div>
                    <div className="row">
                      <div className="PossitonDiv">
                        <div className="col-6  PossitonDivJustiStart">
                          <button onClick={bringToFront}>
                            <img
                              src={frontLayer}
                              alt="Front Layer"
                              className="me-2"
                            />
                            Front Layer
                          </button>
                        </div>
                        <div className="col-6  PossitonDivJustiEnd">
                          <button onClick={sendToBack}>
                            <img
                              src={backLayer}
                              alt="Back Layer"
                              className="me-2"
                            />
                            Back Layer
                          </button>
                        </div>
                      </div>
                    </div>
                    <label className="ALigmttxt">Alignemnt</label>
                    <div className="row">
                      <div className="PossitonDiv">
                        <div className="col-6  PossitonDivJustiStart">
                          <button onClick={() => handleAlignment("left")}>
                            <img
                              src={aligmentLeft}
                              alt="Front Layer"
                              className="me-2"
                            />
                            Left
                          </button>
                        </div>
                        <div className="col-6  PossitonDivJustiEnd">
                          <button onClick={() => handleAlignment("right")}>
                            <img
                              src={aligmentRight}
                              alt="Back Layer"
                              className="me-2"
                            />
                            Right
                          </button>
                        </div>
                      </div>
                    </div>
                    <div className="row">
                      <div className="PossitonDiv">
                        <div className="col-6  PossitonDivJustiStart">
                          <button onClick={() => handleAlignment("top")}>
                            <img
                              src={topALigment}
                              alt="Front Layer"
                              className="me-2"
                            />
                            Top
                          </button>
                        </div>
                        <div className="col-6  PossitonDivJustiEnd">
                          <button onClick={() => handleAlignment("bottom")}>
                            <img
                              className="me-2 bottmAigmnt"
                              src={topALigment}
                              alt="Back Layer"
                            />
                            Bottom
                          </button>
                        </div>
                      </div>
                    </div>
                    <div className="row">
                      <div className="PossitonDiv">
                        <div className="col-6  PossitonDivJustiStart">
                          <button onClick={() => handleAlignment("center")}>
                            <img
                              src={aligmntIcn}
                              alt="Front Layer"
                              className="me-2"
                            />
                            Center
                          </button>
                        </div>
                        <div className="col-6  PossitonDivJustiEnd">
                          <button onClick={() => handleAlignment("middle")}>
                            <img
                              className="me-2 bottmAigmnt"
                              src={centerALigment}
                              alt="Back Layer"
                            />
                            Middle
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              )}

              {isLetterSpacingVisible && (
                <div
                  ref={letterRef}
                  className={`letterSpacingRage ${
                    isLtterSpacing ? "collapsed3" : "visible3"
                  }`}
                >
                  <div className="OpesityCrossIcn">
                    <button onClick={handleToggleMobileLetterSpacing}>
                      Opecity
                    </button>
                    <button onClick={handleClose}>
                      <img src={crossIcn} alt></img>
                    </button>
                  </div>
                  <div className="LtterSpacingConts">
                    <label>Letter Spacing</label>
                    <div className="d-flex letterSpc">
                      <input
                        type="range"
                        min="0"
                        max="100"
                        value={letterSpacingValue}
                        onChange={handleLetterSpacingChange}
                      />
                      <p>{letterSpacingValue}px</p>
                    </div>
                    <label>Line Height</label>
                    <div className="d-flex Heighline">
                      <input
                        type="range"
                        min="0"
                        max="100"
                        value={heightSpacingValue}
                        onChange={handleheightSpacingChange}
                      />
                      <p>{heightSpacingValue}px</p>
                    </div>
                  </div>
                </div>
              )}

              {/**---Opesity -text */}
              {showOpacityInput && (
                <>
                  <div
                    ref={opacityInputRef}
                    className={`Oppicity ${
                      isCollapsedOp ? "collapsed2" : "visible2"
                    }`}
                  >
                    <div className="OpesityCrossIcn">
                      <button onClick={handleToggleOp}>Opecity</button>
                      <button onClick={handleClose}>
                        <img src={crossIcn} alt></img>
                      </button>
                    </div>

                    {/* <label>Opacity</label> */}
                    <div className="d-flex">
                      <input
                        type="range"
                        min="0"
                        max="100"
                        value={opacity}
                        onChange={handleOpacityChange}
                      />
                      <span>{opacity}%</span>
                    </div>
                  </div>
                </>
              )}

              <div className={containerClassName} style={zoomStyle}>
                {data_type === "letterhead" ? (
                  <div className="letterHeadCanvas">
                    <canvas className="letterCan" ref={mainCanvasRef}></canvas>
                  </div>
                ) : data_type === "businesscard" ? (
                  <div className="businesscardCanvas">
                    <canvas className="letterCan" ref={mainCanvasRef}></canvas>
                  </div>
                ) : data_type === "invitationCard" ? (
                  <div className="letterHeadCanvas">
                    <canvas className="letterCan" ref={mainCanvasRef}></canvas>
                  </div>
                ) : data_type === "envelops" ? (
                  <div className="letterHeadCanvas">
                    <canvas className="letterCan" ref={mainCanvasRef}></canvas>
                  </div>
                ) : (
                  <div
                    className="MaineditorCanvas    "
                    ref={mainEditorCanvasRef}
                  >
                    <canvas ref={mainCanvasRef}></canvas>
                  </div>
                )}
                {data_type === "logo" && (
                  <div className="RenderCanvas">
                    <div className="row ">
                      <div className="col-12 col-md-6 d-flex justify-content-center ">
                        <div className="canVsss5Bg">
                          <div className="canVsss5">
                            <div
                              className="sideMoklRender3 p-0 m-0 mx-auto"
                              style={{
                                backgroundRepeat: "no-repeat",
                                backgroundSize: "cover",
                                background: canvasColor,
                              }}
                            >
                              <div>
                                <img
                                  src={secondaryCanvasDataURL}
                                  style={{
                                    visibility: secondaryCanvasDataURL
                                      ? "visible"
                                      : "hidden",
                                  }}
                                />
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="col-12 col-md-6 d-flex justify-content-center ">
                        <div className="canVsss6">
                          <div
                            className="seCndCanvsCnter8  p-0 m-0 mx-auto"
                            style={{
                              backgroundRepeat: "no-repeat",
                              backgroundSize: "cover",
                              background: canvasColor,
                            }}
                          >
                            <div className="sideMoklRender2">
                              <img
                                src={secondaryCanvasDataURL}
                                style={{
                                  visibility: secondaryCanvasDataURL
                                    ? "visible"
                                    : "hidden",
                                }}
                              />
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="row ">
                      <div className="col-12 col-md-6 d-flex justify-content-center ">
                        <div className="canVsss2">
                          <div
                            className="seCndCanvsCnter p-0 m-0 mx-auto"
                            style={{
                              backgroundRepeat: "no-repeat",
                              backgroundSize: "cover",
                              background: canvasColor,
                            }}
                          >
                            <div className="sideMoklRender">
                              <img
                                src={secondaryCanvasDataURL}
                                style={{
                                  visibility: secondaryCanvasDataURL
                                    ? "visible"
                                    : "hidden",
                                }}
                              />
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="col-12 col-md-6 d-flex justify-content-center ">
                        <div className="canVsss3">
                          <div
                            className="seCndCanvsCnter2  p-0 m-0 mx-auto"
                            style={{
                              backgroundRepeat: "no-repeat",
                              backgroundSize: "cover",
                              background: canvasColor,
                            }}
                          >
                            <img
                              className="myy"
                              src={secondaryCanvasDataURL}
                              style={{
                                width: "115px",
                                height: "115px",
                                position: "absolute",
                                left: "58px",
                                top: "36px",
                                visibility: secondaryCanvasDataURL
                                  ? "visible"
                                  : "hidden",
                              }}
                            />
                          </div>
                        </div>
                      </div>
                    </div>

                    <div className="row">
                      <div className="col-12 col-md-6 d-flex justify-content-center">
                        <div className="canVsss4">
                          <div
                            className="seCndCanvsCnter4"
                            style={{
                              backgroundImage: `url(${secondaryCanvasDataURL})`,
                              backgroundRepeat: "no-repeat",
                              backgroundSize: "cover",
                              background: canvasColor,
                            }}
                          >
                            <div className="PhoneShowCanvas">
                              {" "}
                              <img
                                src={secondaryCanvasDataURL}
                                style={{
                                  width: "104px",
                                  height: "80px",
                                  position: "absolute",
                                  left: "0px",
                                  top: "0px",
                                  visibility: secondaryCanvasDataURL
                                    ? "visible"
                                    : "hidden",
                                }}
                              />
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="col-12 col-md-6  textWaterMarkImgggg">
                        <h5>Your Logo is ready to download</h5>
                        <h6>When buying 'Standard' or 'Business':</h6>
                        <ul>
                          <li>Unlimited design changes within 3 months</li>
                          <li>Printable vector files</li>
                          <li>Logo on transparent background</li>
                          <li>Free support</li>
                          <li>Full copyright ownership</li>
                        </ul>
                      </div>
                    </div>
                  </div>
                )}

                {data_type === "logo" && (
                  <div className="RenderCanvas">
                    <div className="row ">
                      <div className="col-12 col-md-6 d-flex justify-content-center ">
                        <div className="canVsss5Bg">
                          <div className="canVsss5">
                            <div
                              className="sideMoklRender3 p-0 m-0 mx-auto"
                              style={{
                                backgroundRepeat: "no-repeat",
                                backgroundSize: "cover",
                                background: canvasColor,
                              }}
                            >
                              <div>
                                <img
                                  src={secondaryCanvasDataURL}
                                  style={{
                                    visibility: secondaryCanvasDataURL
                                      ? "visible"
                                      : "hidden",
                                  }}
                                />
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="col-12 col-md-6 d-flex justify-content-center ">
                        <div className="canVsss6">
                          <div
                            className="seCndCanvsCnter8  p-0 m-0 mx-auto"
                            style={{
                              backgroundRepeat: "no-repeat",
                              backgroundSize: "cover",
                              background: canvasColor,
                            }}
                          >
                            <div className="sideMoklRender2">
                              <img
                                src={secondaryCanvasDataURL}
                                style={{
                                  visibility: secondaryCanvasDataURL
                                    ? "visible"
                                    : "hidden",
                                }}
                              />
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="row ">
                      <div className="col-12 col-md-6 d-flex justify-content-center ">
                        <div className="canVsss2">
                          <div
                            className="seCndCanvsCnter p-0 m-0 mx-auto"
                            style={{
                              backgroundRepeat: "no-repeat",
                              backgroundSize: "cover",
                              background: canvasColor,
                            }}
                          >
                            <div className="sideMoklRender">
                              <img
                                src={secondaryCanvasDataURL}
                                style={{
                                  visibility: secondaryCanvasDataURL
                                    ? "visible"
                                    : "hidden",
                                }}
                              />
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="col-12 col-md-6 d-flex justify-content-center ">
                        <div className="canVsss3">
                          <div
                            className="seCndCanvsCnter2  p-0 m-0 mx-auto"
                            style={{
                              backgroundRepeat: "no-repeat",
                              backgroundSize: "cover",
                              background: canvasColor,
                            }}
                          >
                            <img
                              className="myy"
                              src={secondaryCanvasDataURL}
                              style={{
                                width: "115px",
                                height: "115px",
                                position: "absolute",
                                left: "58px",
                                top: "36px",
                                visibility: secondaryCanvasDataURL
                                  ? "visible"
                                  : "hidden",
                              }}
                            />
                          </div>
                        </div>
                      </div>
                    </div>

                    <div className="row">
                      <div className="col-12 col-md-6 d-flex justify-content-center">
                        <div className="canVsss4">
                          <div
                            className="seCndCanvsCnter4"
                            style={{
                              backgroundImage: `url(${secondaryCanvasDataURL})`,
                              backgroundRepeat: "no-repeat",
                              backgroundSize: "cover",
                              background: canvasColor,
                            }}
                          >
                            <div className="PhoneShowCanvas">
                              {" "}
                              <img
                                src={secondaryCanvasDataURL}
                                style={{
                                  width: "104px",
                                  height: "80px",
                                  position: "absolute",
                                  left: "0px",
                                  top: "0px",
                                  visibility: secondaryCanvasDataURL
                                    ? "visible"
                                    : "hidden",
                                }}
                              />
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="col-12 col-md-6  textWaterMarkImgggg">
                        <h5>Your Logo is ready to download</h5>
                        <h6>When buying 'Standard' or 'Business':</h6>
                        <ul>
                          <li>Unlimited design changes within 3 months</li>
                          <li>Printable vector files</li>
                          <li>Logo on transparent background</li>
                          <li>Free support</li>
                          <li>Full copyright ownership</li>
                        </ul>
                      </div>
                    </div>
                  </div>
                )}
              </div>
              {showCreateButton && (
                <div className="OfflineCreateButton">
                  <button onClick={HandleNavigateHome}>Create a logo</button>
                </div>
              )}
            </div>
          </div>
        </div>
        {showZoomControls && (
          <div className="d-flex w-100  flex-row-reverse  zoomingCanvasContainer ">
            <div className="zoomingcan" style={{ marginRight: "10px" }}>
              <button onClick={handleZoomOut}>-</button>
              <p>{zoomLevel}%</p>
              <button onClick={handleZoomIn}>+</button>
            </div>
          </div>
        )}
      </div>
      <div className="Templatereplace">
        <Modal
          className="Templatereplace"
          show={confirmModalVisible}
          onHide={() => setConfirmModalVisible(false)}
          centered
        >
          <Modal.Body>
            <p>Are you sure you want to replace the current template?</p>
          </Modal.Body>
          <Modal.Footer>
            <Button
              variant="secondary"
              onClick={() => setConfirmModalVisible(false)}
            >
              No
            </Button>
            <Button variant="primary" onClick={handleConfirmReplace}>
              Yes
            </Button>
          </Modal.Footer>
        </Modal>
        {/*----Shere Modal-----*/}
        <Modal
          show={showShareModal}
          onHide={handleCloseShere}
          centered
          className="shereModal"
        >
          <Modal.Header>
            <Modal.Title>
              <div className="ShreTitle">
                <label>Share</label>
                <p>Share Your Logo</p>
              </div>
            </Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
              }}
            >
              <div style={{ flexGrow: 1 }}></div>
              <div
                style={{
                  width: "100%",
                  textAlign: "center",
                  paddingBottom: "20px",
                }}
              >
                <div className="container">
                  <input
                    type="text"
                    className="form-control"
                    placeholder="Enter text"
                    style={{ marginBottom: "10px", textAlign: "center" }}
                    value={sherevalue}
                    onChange={(e) => setSherevalue(e.target.value)}
                    ref={inputRefShere}
                  />
                  <div className="CopyDesign">
                    <button onClick={handleCopySHere}>Copy Link</button>
                  </div>
                </div>

                <div className="socialMediaLinks">
                  {/* Social media icons */}
                  <Link
                    to={`http://www.facebook.com/sharer.php?u=${encodeURIComponent(
                      sherevalue
                    )}`}
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    {" "}
                    <img src={faceBookIcn} alt="facebook"></img>
                  </Link>
                  <Link
                    to={`https://x.com/intent/post?url=${encodeURIComponent(
                      sherevalue
                    )}`}
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    {" "}
                    <img src={twitterLogo} alt="Twitter"></img>
                  </Link>
                  <Link
                    to={`http://www.linkedin.com/shareArticle?mini=true&amp;url=${encodeURIComponent(
                      sherevalue
                    )}`}
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    {" "}
                    <img
                      className="LinkDInshereImg"
                      src={LinkDIniconShere}
                      alt="Linkdin"
                    ></img>
                  </Link>
                </div>
              </div>
            </div>
          </Modal.Body>
        </Modal>

        {/*----FONT FAMILY UPLOAD */}

        <Modal
          className="FontFamilyUpload"
          show={showFontModal}
          onHide={() => setshowFontModal(false)}
          centered
        >
          <Modal.Body>
            {validationMessage && (
              <Alert variant="danger">{validationMessage}</Alert>
            )}
            <Form>
              <Form.Group controlId="formCategory">
                <Form.Label>Category</Form.Label>
                <Form.Control
                  type="text"
                  name="category"
                  id="category"
                  placeholder="Enter category"
                  value={category}
                  onChange={handleFontCategoryChange}
                />
              </Form.Group>
              <Form.Group controlId="formFile">
                <Form.Label>Choose File</Form.Label>
                <Form.Control
                  type="file"
                  accept=".ttf"
                  onChange={handleFileChange}
                />
              </Form.Group>
            </Form>
          </Modal.Body>
          <Modal.Footer>
            <Button variant="secondary" onClick={() => setshowFontModal(false)}>
              Cancel
            </Button>
            <Button variant="primary" onClick={handleUploadValidation}>
              Upload
            </Button>
          </Modal.Footer>
        </Modal>
        {/*----UpgradeModal*/}

        <Modal
          className="UpgradeModal"
          show={showUpdagradeModal}
          onHide={() => SetShowUpgradeModal(false)}
          centered
        >
          <Modal.Body>
            <div className="container p-0 m-0" style={{ position: "relative" }}>
              <button
                className="closeModal"
                onClick={() => SetShowUpgradeModal(false)}
              >
                <img src={crossBtn} alt="cross"></img>
              </button>
              <div className="row p-0 m-0  MobileResponsiveModa">
                {/* Left side - Heading and content */}
                <div className="col-12 col-md-6 leftContent">
                  <h3>Everything you need is available on Business plan</h3>
                  <div className="leftContainer">
                    <div className="d-flex align-items-start ">
                      <img src={starICN} alt="starICN"></img>
                      <p>
                        High quality logo images with transparent background and
                        vector files
                      </p>
                    </div>
                    <div className="d-flex align-items-start">
                      <img src={starICN} alt="starICN"></img>
                      <p>
                        High quality logo images with transparent background and
                        vector files
                      </p>
                    </div>
                    <div className="d-flex align-items-start ">
                      <img src={starICN} alt="starICN"></img>
                      <p>
                        High quality logo images with transparent background and
                        vector files
                      </p>
                    </div>
                    <div className="d-flex align-items-start ">
                      <img src={starICN} alt="starICN"></img>
                      <p>
                        High quality logo images with transparent background and
                        vector files
                      </p>
                    </div>
                  </div>
                  <div className="upgradeBtn">
                    <button
                      type="button"
                      className="UpModalBtn"
                      onClick={HandleRoute}
                    >
                      Upgrade
                    </button>
                  </div>
                </div>

                {/* Right side - Image */}
                <div className="col-12 col-md-6 rightSideImg">
                  <img src={tempUpdate} alt="Descriptive text" />
                </div>
              </div>
            </div>
          </Modal.Body>
        </Modal>

        {/**-----------download modall----*/}
        <Modal
          className="deleteModddl"
          show={modalMessage}
          onHide={handleDownloadModlCLose}
          centered
        >
          {" "}
          <Modal.Header closeButton>
            <div className="WarningHeader">Warning</div>
          </Modal.Header>
          <Modal.Body>
            <div className="DownloadFormetErrorMsg">
              Unsupported format. Please choose png, jpg, svg, or pdf.
            </div>
          </Modal.Body>
        </Modal>
        {/* Preparing Modal */}
        <Modal
          centered
          className="modlePrepared"
          show={isPreparingModalOpen}
          onHide={() => setPreparingModalOpen(false)}
        >
          <Modal.Body>
            <div className="prePareDiv">
              <div className="LoaderPrepare">
                <ShowLogoLoader />
              </div>
              <p>Your design is being prepared. Please wait...</p>
            </div>
          </Modal.Body>
        </Modal>

        {/* Failure Modal */}
        <Modal
          show={isFailureModalOpen}
          onHide={() => setFailureModalOpen(false)}
        >
          <Modal.Header closeButton>
            <Modal.Title>Download Failed</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <p>
              If you can't download the image automatically, you can try
              downloading it manually.
            </p>
            <Button
              variant="primary"
              onClick={() => {
                setFailureModalOpen(false);
                downloadCanvasTemplate();
              }}
            >
              Click here to try again
            </Button>
          </Modal.Body>
        </Modal>
      </div>
    </>
  );
};
export default CanvasEditor;
