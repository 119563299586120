import React from "react";
import gif from "../Assets/Images/LoaderLogoAcdamy.gif";
import "../Components/Css/Loader.css";

function Loader() {
  return (
    <div>
      <div className="loading_overlay"></div>
      <div className="loading_website">
        <div className="restaurant-loader-inner">
          <img src={gif} alt="Loading GIF" />
        </div>
      </div>
    </div>
  );
}

export default Loader;
