import React from "react";
import "./Css/IconLoader.css";

const IconLoader = () => {
  return (
    <div>
      <div className="loaderIconImg">
        <div style={{ position: "relative" }}>
          <div className="loader_llllll"></div>
        </div>
      </div>
    </div>
  );
};

export default IconLoader;
