import React, { useEffect } from "react";
import PaymentFaildICn from "../Assets/Images/PaymentFaildICn.gif";
import "../Components/Css/Pricing.css";
import Header from "./Header.js";
import { handleError, handleSuccess } from "../CommonJquery/CommonJquery.js";
import {
  server_post_data,
  complete_payment_from_web,
} from "../ServiceConnection/serviceconnection.js";
import Footer from "./Footer.js";
import { retrieveData } from "../LocalConnection/LocalConnection.js";
import { Link } from "react-router-dom";
const PaymentFaild = () => {
  const payment_transaction_id = retrieveData("client_secret");

  const master_data_get_update_data = async () => {
    const fd = new FormData();
    fd.append("payment_transaction_id", payment_transaction_id);
    await server_post_data(complete_payment_from_web, fd)
      .then((Response) => {
        if (Response.data.error) {
          handleError(Response.data.message);
        } else {
          handleSuccess(Response.data.message);
        }
      })
      .catch((error) => {
        handleError("network");
      });
  };

  useEffect(() => {
    master_data_get_update_data();
  }, []);

  return (
    <>
      {/* <Header Showheader={false} /> */}
      <section className="logoideas_section logoideas_section_pricingPage   ComplatePymentDiv ">
        <div className="container">
          <div className="container d-flex justify-content-center align-items-center compPyTOp ">
            <div className=" text-center animate__animated animate__zoomIn  CarDContainer">
              <div className="PaymentContainer">
                <div className="TitleCOn">
                  <img
                    className="faildPaymentgif"
                    src={PaymentFaildICn}
                    alt="mark"
                  ></img>{" "}
                  <h1 className="ContntFaild">Faild!</h1>
                  <label>Your payment could not be processed.</label>
                  <p>Please try again or contact support for assistance.</p>
                </div>

                <div className="contentPymnt">
                  <Link to="/">
                    <button className="">Back to Home</button>
                  </Link>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      {/* --------------footer---------------*/}
      {/* <Footer /> */}
    </>
  );
};

export default PaymentFaild;
