import React, { useState, useEffect } from "react";
import DOMPurify from "dompurify";
import Footer from "./Footer";
import upArrow from "../Assets/Images/downArrowBlack.svg";
import "../Components/Css/FAQ.css";
import {
  server_post_data,
  get_all_faq,
} from "../ServiceConnection/serviceconnection.js";
import { handleError } from "../CommonJquery/CommonJquery";
import Header from "./Header.js";
import Loader from "./Loader.js";
import Footer_contact from "./Footer_contact.js";
const FAQ = () => {
  const [faqdata, setFaqData] = useState([]);
  const [activeIndex, setActiveIndex] = useState(null);
  const [showLoaderAdmin, setshowLoaderAdmin] = useState(false);
  // faq accordion

  const handleClick = (index) => {
    setActiveIndex(index === activeIndex ? null : index);
  };

  useEffect(() => {
    const flag = "1";
    const call_id = "0";
    master_data_get(flag, call_id);
  }, []);

  const master_data_get = async (flag) => {
    setshowLoaderAdmin(true);
    const fd = new FormData();
    fd.append("flag", flag);
    await server_post_data(get_all_faq, fd)
      .then((Response) => {
        if (Response.data.error) {
          handleError(Response.data.message);
        } else {
          setFaqData(Response.data.message.data);
        }
        setshowLoaderAdmin(false);
      })
      .catch((error) => {
        handleError("network");
        setshowLoaderAdmin(false);
      });
  };
  return (
    <>
      <Header />
      {showLoaderAdmin && <Loader />}
      <section className="mt-3">
        <div className="faq_container_background col-lg-12">
          <div className="container faqs_container">
            <div className="faq_heading_wrapper">
              <h3>Frequently Asked Questions</h3>
            </div>
          </div>
        </div>
        {/*----------- frequently asked questions ----------  */}
        <section className="faq_section">
          <div className="container">
            <div className="faq_section_container">
              <div className="accordion_container col-md-14">
                <div className="accordion">
                  {faqdata
                    .slice()
                    .reverse()
                    .map(
                      (
                        item,
                        index // Reversing the array here
                      ) => (
                        <div key={index} className="accordion-item bgColorr">
                          <div
                            className={`accordion-title ${
                              index === activeIndex ? "active" : ""
                            }`}
                            onClick={() => handleClick(index)}
                          >
                            {item.question_name}
                            <span className="dropdown-icon">
                              <img src={upArrow} alt="downarrow" />
                            </span>
                          </div>
                          {index === activeIndex && (
                            <div className="accordion-content">
                              <p
                                className="accordion-content-text"
                                dangerouslySetInnerHTML={{
                                  __html: DOMPurify.sanitize(item.answer_name),
                                }}
                              />
                            </div>
                          )}
                        </div>
                      )
                    )}
                </div>
              </div>
            </div>
          </div>
        </section>
      </section>
      <Footer_contact />
      {/* ------------------------footer-section----------------------- */}
      <Footer />
    </>
  );
};

export default FAQ;
