import React, { useState, useEffect } from "react";
import "./Css/Blog2.css";
import rightArrowGrey from "../Assets/Images/rightArrowgrey.svg";
import { Link } from "react-router-dom";
import {
  server_post_data,
  get_blog_details_single,
} from "../ServiceConnection/serviceconnection.js";
import { useLocation } from "react-router-dom";
import DOMPurify from "dompurify";
import Footer from "./Footer.js";
import Loader from "./Loader.js";
import {
  handleError,
  handleLinkClick,
  inputdateformateChange,
  getPaginationRange,
} from "../CommonJquery/CommonJquery.js";
import Header from "./Header.js";
import authorImage from "../Assets/Images/author_image.png";
import fb from "../Assets/Images/Fb.svg";
import be from "../Assets/Images/Be.svg";
import pn from "../Assets/Images/Pn.svg";
import tw from "../Assets/Images/Tw.svg";
const Blog2 = () => {
  const location = useLocation();
  const currentUrl = location.pathname.substring(1);
  const [showLoaderAdmin, setshowLoaderAdmin] = useState(false);
  const [VacancytData, setVacancytData] = useState([]);
  const [cards, setcards] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [SEOloop, setSEOloop] = useState([]);
  const cardsPerPage = 9;
  const indexOfLastCard = currentPage * cardsPerPage;
  const indexOfFirstCard = indexOfLastCard - cardsPerPage;
  let currentCards = 0;
  let totalPages = 0;
  try {
    currentCards = cards.slice(indexOfFirstCard, indexOfLastCard);
    totalPages = Math.ceil(cards.length / cardsPerPage);
  } catch (error) {
    //error
  }

  const paginationRange = getPaginationRange(currentPage, totalPages);

  // service connection starts

  const handleFetchData = async () => {
    setshowLoaderAdmin(true);
    var form_data = new FormData();
    form_data.append("current_url", "/" + currentUrl);
    await server_post_data(get_blog_details_single, form_data)
      .then((Response) => {
        if (Response.data.error) {
          handleError(Response.data.message);
        } else {
          if (Response.data.message.data_blog.length > 0) {
            setVacancytData(Response.data.message.data_blog[0]);
          }
          setcards(Response.data.message.blog_list);
          setSEOloop(Response.data.message.seo_loop);
        }
        setshowLoaderAdmin(false);
      })
      .catch((error) => {
        setshowLoaderAdmin(false);
      });
  };
  useEffect(() => {
    handleFetchData();
  }, []);
  // service connection ends

  const match_and_return_seo_link = (v_id) => {
    let data_seo_link_final = "/blogs/blog_detail/" + v_id;
    let data_seo_link = data_seo_link_final;

    if (SEOloop) {
      const matchedItem = SEOloop.find(
        (data) => data_seo_link === data.call_function_name
      );

      if (matchedItem) {
        data_seo_link_final = matchedItem.pretty_function_name;
      }
    }

    return data_seo_link_final;
  };
  return (
    <>
      <Header />
      {showLoaderAdmin && <Loader />}
      <div className="blog_wrapper">
        <div className="Blog2_headerSection">
          <div className="blogContents">
            <img
              src={VacancytData.image_url}
              alt="blogBackgroundImage"
              id="BlogDetail_heroSection"
            />

            <div className="Blogs_heaading_wrapper">
              <h1>{VacancytData.title_name}</h1>
              <p>{VacancytData.tag_line}</p>
            </div>
          </div>
        </div>
        {/*----------------------------------*/}
        <section>
          <div className="container">
            <div className="row">
              <div className="col-12 col-lg-2 col-md-3 m-0 p-0">
                <div className="d-flex h-100 align-items-start">
                  <div className="container">
                    <div className="main-heading">
                      <div>
                        <h7
                          style={{
                            fontSize: "14px",
                            marginTop: "6px",
                            fontWeight: "500",
                            marginBottom: 0,
                          }}
                        >
                          <label
                            style={{
                              paddingRight: "10px",
                              color: "#495057",
                              textAlign: "center",
                            }}
                          >
                            {inputdateformateChange(VacancytData.entry_date)}
                          </label>
                          <label style={{ color: "#495057" }}>4 minutes</label>
                        </h7>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-12 col-md-8 m-0  p-0 ">
                <div className="container-lg">
                  <div className="d-flex h-100 align-items-start">
                    <div className="ckEditor_text">
                      <p className="blog_Initial_text imgBtM">
                        <div
                          dangerouslySetInnerHTML={{
                            __html: DOMPurify.sanitize(
                              VacancytData.description
                            ),
                          }}
                        />
                        {/* {VacancytData.description} */}
                      </p>
                    </div>
                  </div>
                  <hr className="w-100" />
                  <div className="author_details_blog">
                    <div className="authorImage_container">
                      <img src={VacancytData.author_image_url} alt="author" />
                    </div>
                    <div className="author_name">
                      <h6>By {VacancytData.author}</h6>
                      <p>{VacancytData.author_position}</p>
                    </div>
                    <div className="social_link_author">
                      <Link>
                        <img src={fb} alt="fb" />
                      </Link>
                      <Link>
                        <img src={be} alt="fb" />
                      </Link>
                      <Link>
                        <img src={tw} alt="fb" />
                      </Link>
                      <Link>
                        <img src={pn} alt="fb" />
                      </Link>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>

        {/*----------------------------------*/}
        <div className="container-lg">
          <div className="row blogs_row"></div>
          <section className="blogs_section">
            <div className="container">
              <div className="blogs_container">
                <div className="blogs_heading">
                  <h3>Read more logo posts on our blog</h3>
                </div>
                <div className="row">
                  {currentCards &&
                    currentCards.length > 0 &&
                    currentCards.map((card, index) => (
                      <div
                        key={index}
                        className="col-md-4 col-sm-6 d-flex justify-content-center mb-4"
                      >
                        <Link
                          onClick={() => {
                            handleLinkClick(
                              match_and_return_seo_link(card.primary_id, "s")
                            );
                          }}
                        >
                          <div className="custom-card fixcard_container">
                            <img src={card.image_url} alt="Blog Img" />

                            <p className="titlename mb-4">
                              {card.title_name} <br />
                            </p>
                          </div>
                        </Link>
                      </div>
                    ))}
                </div>
                <div className="pagination">
                  {paginationRange.map((page, index) =>
                    page === "..." ? (
                      <span key={index} className="dots">
                        ...
                      </span>
                    ) : (
                      <button
                        key={index}
                        onClick={() => setCurrentPage(page)}
                        className={page === currentPage ? "active" : ""}
                      >
                        {page}
                      </button>
                    )
                  )}
                  <button
                    onClick={() =>
                      setCurrentPage((prev) => Math.min(prev + 1, totalPages))
                    }
                    disabled={currentPage === totalPages}
                  >
                    <img src={rightArrowGrey} alt="rightArrow" />
                  </button>
                </div>
              </div>
            </div>
          </section>
        </div>
      </div>
      <Footer />
    </>
  );
};

export default Blog2;
