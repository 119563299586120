import React from "react";
import { Link } from "react-router-dom";
const Footer_contact = ({ color_remove = true }) => {
  return (
    <section
      className="contactUs_section"
      style={color_remove ? { background: "#eceeff", color: "black" } : {}}
    >
      <div className="container col-md-10">
        <div className="contactUs_container" style={{ color: "black" }}>
          <h3>We’re here to help build your brand</h3>
          <div className="contactus_description_container col-md-8">
            <h6>
              Logo Academy fun and friendly in-house customer success team is
              available via live chat and email to answer all your questions.
              Get help accessing files, tweaking your logo designs, and more!
            </h6>
          </div>
          <Link to="/support">
            {" "}
            <button className="rounded-pill">Contact Us</button>
          </Link>
        </div>
      </div>
    </section>
  );
};

export default Footer_contact;
