import React, { useState, useEffect } from "react";
import "./../Components/Css/BillingInfo.css";
import pdfIcon from "../Assets/Images/PDFicon.svg";
import { Modal, Button } from "react-bootstrap";
import PaymentInvoice from "./PaymentInvoice.js";
import {
  server_post_data,
  get_user_information,
  get_package_by_customer_id,
  get_customer_all_package,
} from "../ServiceConnection/serviceconnection.js";
import Loader from "./Loader.js";
import { handleError } from "../CommonJquery/CommonJquery.js";
import { storeData, retrieveData } from "../LocalConnection/LocalConnection.js";
import Invoice from "./Invoice.js";
import logoImg from "../Assets/Images/logoImg.png";
import html2canvas from "html2canvas";
import { jsPDF } from "jspdf";

const BillingInfo = () => {
  const user_id = retrieveData("user_id");
  const package_name = retrieveData("package_name");
  const package_month_year = retrieveData("package_month_year");
  const howmany_month_year = retrieveData("howmany_month_year");
  const brandkit_templates = retrieveData("brandkit_templates");
  const [showLoaderAdmin, setshowLoaderAdmin] = useState(false);
  const [SubscriptDeatails, SetSubscriptDeatails] = useState([]);
  const [loading, setLoading] = useState(false);
  const [Custumerinfo, SetCustumerinfo] = useState([]);
  const [payments, setpayments] = useState([]);
  const [editStaffData, seteditStaffData] = useState([]);
  const [rupess_left, setrupess_left] = useState([]);
  const [rupess_right, setrupess_right] = useState([]);
  const [showModal, setshowModal] = useState(false);
  const [setInvoice, setsetInvoice] = useState([]);
  function openPDF(pdfLink) {
    window.open(pdfLink, "_blank");
  }

  const openModal = (id) => {
    setsetInvoice(SubscriptDeatails[id]);
    setshowModal(true);
  };

  const closeModal = () => {
    setshowModal(false);
  };

  useEffect(() => {
    // const start_date = "";
    // const end_date = "";
    // const flag = "3";
    // let call_id = "0";
    master_data_get();
    master_data_get_user_data();
  }, []);

  const master_data_get = async () => {
    setshowLoaderAdmin(true);
    if (!user_id) {
      handleError("User ID not found in local storage");
      return; // If no user_id is found, return early
    }

    const formData = new FormData(); // Create FormData object
    formData.append("customer_id", user_id); // Append customer_id with user_id from local storage
    await server_post_data(get_user_information, formData)
      .then((Response) => {
        if (Response.data.error) {
          handleError(Response.data.message);
        } else {
          if (Response.data.message.data.length > 0) {
            seteditStaffData(Response.data.message.data[0]);
            setpayments(Response.data.message.data_blog_billing);
            setrupess_left(Response.data.message.rupess_left);
            setrupess_right(Response.data.message.rupess_right);
          }
        }
        setshowLoaderAdmin(false);
      })
      .catch((error) => {
        handleError("network");
        setshowLoaderAdmin(false);
      });
  };

  console.log(editStaffData);

  // const handleDownloadinvoice = () => {
  //   const invoiceDiv = document.querySelector(".PayemntIncoiceDiv");

  //   // Clone the invoice div
  //   const clonedInvoiceDiv = invoiceDiv.cloneNode(true);
  //   document.body.appendChild(clonedInvoiceDiv);

  //   // Move the cloned div off-screen
  //   clonedInvoiceDiv.style.position = "absolute";
  //   clonedInvoiceDiv.style.left = "-10000px";
  //   clonedInvoiceDiv.style.display = "block";

  //   // Generate the PDF from the cloned div
  //   const input = clonedInvoiceDiv.querySelector("#invoice");
  //   html2canvas(input).then((canvas) => {
  //     const imgData = canvas.toDataURL("image/png");
  //     const pdf = new jsPDF("p", "mm", "a4");
  //     const imgWidth = 210; // A4 width in mm
  //     const pageHeight = 295; // A4 height in mm
  //     const imgHeight = (canvas.height * imgWidth) / canvas.width;
  //     let heightLeft = imgHeight;
  //     let position = 0;

  //     pdf.addImage(imgData, "PNG", 0, position, imgWidth, imgHeight);
  //     heightLeft -= pageHeight;

  //     while (heightLeft >= 0) {
  //       position -= pageHeight;
  //       pdf.addPage();
  //       pdf.addImage(imgData, "PNG", 0, position, imgWidth, imgHeight);
  //       heightLeft -= pageHeight;
  //     }

  //     pdf.save("invoice.pdf");

  //     // Remove the cloned div after generating the PDF
  //     document.body.removeChild(clonedInvoiceDiv);
  //   });
  // };


  const handleDownloadinvoice = () => {
    const invoiceDiv = document.querySelector(".PayemntIncoiceDiv");
  
    // Check if the element exists
    if (!invoiceDiv) {
      console.error("Invoice div not found");
      return;
    }
  
    // Clone the invoice div to avoid modifying the original
    const clonedInvoiceDiv = invoiceDiv.cloneNode(true);
    document.body.appendChild(clonedInvoiceDiv);
  
    // Move the cloned div off-screen
    clonedInvoiceDiv.style.position = "absolute";
    clonedInvoiceDiv.style.left = "-10000px";
    clonedInvoiceDiv.style.display = "block";
  
    // Generate the PDF from the cloned div
    const input = clonedInvoiceDiv.querySelector("#invoice");
  
    if (!input) {
      console.error("Invoice content not found");
      document.body.removeChild(clonedInvoiceDiv);
      return;
    }
  
    // Set specific dimensions for the invoiceDiv (smaller than A4 size)
    const pageWidth = 210; // A4 width in mm
    const pageHeight = 297; // A4 height in mm
    const invoiceWidth = pageWidth * 0.7; // Set the invoice width to 80% of the A4 page width
    const invoiceHeight = pageHeight * 0.7; // Set the invoice height to 70% of the A4 page height
  
    // Resize the invoice div to smaller than A4 size
    clonedInvoiceDiv.style.width = `${invoiceWidth}mm`;
    clonedInvoiceDiv.style.height = `${invoiceHeight}mm`;
  
    // Generate the PDF from the cloned div
    html2canvas(input).then((canvas) => {
      const imgData = canvas.toDataURL("image/png");
      const pdf = new jsPDF("p", "mm", "a4");
  
      // Get image dimensions
      const canvasWidth = canvas.width;
      const canvasHeight = canvas.height;
  
      // Calculate the scaling factor to fit the content within the invoice size
      const scale = Math.min(invoiceWidth / canvasWidth, invoiceHeight / canvasHeight);
      const imgWidth = canvasWidth * scale;
      const imgHeight = canvasHeight * scale;
  
      // Calculate the position to center the invoice on the PDF page
      const xPos = (pageWidth - imgWidth) / 2; // Center horizontally
      const yPos = (pageHeight - imgHeight) / 2; // Center vertically
  
      // Add the image to the PDF at the center of the page
      pdf.addImage(imgData, "PNG", xPos, yPos, imgWidth, imgHeight);
  
      // Save the generated PDF
      pdf.save("invoice.pdf");
  
      // Remove the cloned div after generating the PDF
      document.body.removeChild(clonedInvoiceDiv);
    });
  };

  const master_data_get_user_data = async () => {
    setLoading(true);
    const fd = new FormData();
    fd.append("customer_id", user_id);

    try {
      const userResponse = await server_post_data(get_customer_all_package, fd);
      setLoading(false);
      if (userResponse.data.error) {
        handleError(userResponse.data.message);
      } else {
        SetSubscriptDeatails(userResponse.data.message.data);
        SetCustumerinfo(userResponse.data.message);
      }
    } catch (error) {
      console.log(error);
      setLoading(false);
      handleError("network");
    }
  };

  console.log(SubscriptDeatails);

  return (
    <div className="container-fluid">
      {showLoaderAdmin && <Loader />}
      <section className="billingInfo_section">
        <div className="billingInfo_headingWrapper">
          <h3>Billing and invoices</h3>
          <div className="MyPLan">
            <h5> My Plan</h5>
            <div className="acNameImg">
            {brandkit_templates !== '' ? (
  <img src={brandkit_templates} alt="Brand Kit" id="profileImg" />
) : null}
              <div>
                <p className="headName">{editStaffData.full_name}</p>
                {package_name != 'undefined' && (
              <div>
                {/* Your content to show when all three are not undefined */}
                <p className="packName">{package_name} {howmany_month_year} {package_month_year}</p>
              </div>
            )}

              </div>
            </div>
          </div>
          {/* <div className="d-flex  MyLogoImg">
            <img src={pdfIcon}></img>
            <div className="logoAc">
              <h3>{editStaffData.full_name}</h3>
              <p>End {editStaffData.subscription_end_date}</p>
            </div>
          </div> */}
          <div className="PaymentsDiv">
            {" "}
            <h6>Payments</h6>
          </div>
        </div>
        {SubscriptDeatails.length !== 0 ? (
          <div className="table-responsive">
            <table className="table table-bordered mt-3">
              <thead>
                <tr>
                  <th scope="col">Date</th>
                  <th scope="col">My Plan</th>
                  <th scope="col">Price</th>
                  <th scope="col">Payment ID</th>
                  <th scope="col">Invoice</th>
                </tr>
              </thead>
              <tbody>
                {SubscriptDeatails &&
                  SubscriptDeatails.length !== 0 &&
                  SubscriptDeatails.map((payment, index) => (
                    <tr key={index}>
                      <td>{payment.subscription_start_date}</td>
                      <td>{payment.package_name}</td>
                      <td>
                        {rupess_left}
                        {payment.package_price}
                        {rupess_right}/-
                      </td>
                      <td>{payment.payment_transaction_id}</td>
                      <td>
                        <button onClick={() => openModal(index)}>
                          <img src={pdfIcon} alt="PDF Icon" />
                        </button>
                      </td>
                    </tr>
                  ))}
              </tbody>
            </table>
          </div>
        ) : (
          <div>You do not have any payments</div>
        )}
        <div className="PayemntIncoiceDiv" style={{ display: "none" }}>
          {SubscriptDeatails &&
            Custumerinfo &&
            Custumerinfo.data &&
            SubscriptDeatails && (
              <PaymentInvoice
                handleDownloadinvoice={handleDownloadinvoice}
                datasubscript={setInvoice}
              />
            )}
        </div>
      </section>

      <Modal
        show={showModal}
        onHide={() => setshowModal(false)}
        size="lg"
        centered
        className="fade"
      >
        <Modal.Header closeButton></Modal.Header>
        <Modal.Body>
          {/* <Invoice payment_id={primaryId} /> */}
          <PaymentInvoice
            handleDownloadinvoice={handleDownloadinvoice}
            datasubscript={setInvoice}
          />
        </Modal.Body>
      </Modal>
    </div>
  );
};

export default BillingInfo;