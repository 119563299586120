import React, { createContext, useContext, useState, useEffect } from "react";
import { useLocation, useNavigate } from "react-router-dom";

const NavigationContext = createContext();

export function NavigationProvider({ children }) {
  const [navigationStack, setNavigationStack] = useState([]);
  const location = useLocation();

  useEffect(() => {
    setNavigationStack((prevStack) => [...prevStack, location.pathname]);
  }, [location]);

  const value = {
    navigationStack,
    pushPath: (path) => setNavigationStack((prevStack) => [...prevStack, path]),
    popPath: () => setNavigationStack((prevStack) => prevStack.slice(0, -1)),
  };

  return (
    <NavigationContext.Provider value={value}>
      {children}
    </NavigationContext.Provider>
  );
}

export function useNavigationHistory() {
  const context = useContext(NavigationContext);
  if (!context) {
    throw new Error(
      "useNavigationHistory must be used within a NavigationProvider"
    );
  }
  return context;
}

// Custom hook that combines navigation history with navigation functionality
export function useNavigationWithHistory() {
  const navigate = useNavigate();
  const { navigationStack, popPath } = useNavigationHistory();

  const goBack = () => {
    if (navigationStack.length > 1) {
      const previousPath = navigationStack[navigationStack.length - 2];
      popPath();
      navigate(previousPath);
    }
  };

  return {
    navigationStack,
    goBack,
    currentPath: navigationStack[navigationStack.length - 1],
  };
}
