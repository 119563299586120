import React, { useEffect } from "react";
import {
  saveFontsToDB,
  getFontsFromDB,
  getFontsFromDB2,
  saveFontsToDB2,
} from "./../LocalConnection/IndexedDB";
import {
  FONT_URL,
  server_post_data,
} from "../ServiceConnection/serviceconnection";

const FontLoader = () => {
  useEffect(() => {
    const fetchFonts = async () => {
      try {
        const cachedFonts = await getFontsFromDB();
        const cachedFonts2 = await getFontsFromDB2();

        if (cachedFonts) {
          applyFontStyles(cachedFonts, cachedFonts2);
        } else {
          server_post_data(FONT_URL, null)
            .then(async (Response) => {
              const fontlist = Response.data.message.fontlist;
              const fontlist2 = Response.data.message.custom_font;

              await saveFontsToDB(fontlist);
              await saveFontsToDB2(fontlist2);
              applyFontStyles(fontlist, fontlist2);
            })
            .catch((error) => {
              console.log(error);
            });
        }
      } catch (error) {
        console.error("Error fetching fonts:", error);
      }
    };

    const applyFontStyles = (fontlist, fontlist2) => {
      fontlist2.forEach((font) => {
        const fontFace = new FontFace(font.family, `url(${font.src_file})`);

        fontFace
          .load()
          .then((loadedFace) => {
            document.fonts.add(loadedFace);
          })
          .catch((error) => {
            console.error(`Error loading font "${font.family}":`, error);
          });
      });
      const fontStyles = fontlist
        .filter((font) => font.files && font.files.regular)
        .map((font) => {
          return `@font-face {
            font-family: '${font.family}';
            font-style: normal;
            font-weight: 400;
            src: url(${font.files.regular});
          }`;
        })
        .join("\n");

      const styleElement = document.createElement("style");
      styleElement.textContent = fontStyles;
      document.head.appendChild(styleElement);
    };

    fetchFonts();
  }, []);

  return null;
};

export default FontLoader;
