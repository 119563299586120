import React, { useEffect, useState } from "react";
import "./App.css";
import { BrowserRouter, Route, Routes, Navigate } from "react-router-dom";
import "bootstrap/dist/css/bootstrap.min.css";
import "bootstrap/dist/js/bootstrap.bundle.min.js";
import Home from "./Components/Home";

import Recommendation from "./Components/Recommendation";
import Pickindustry from "./Components/Pickindustry";
import ChooseIcons from "./Components/ChooseIcons";
import ShowLogos from "./Components/ShowLogos";
import Pricing from "./Components/Pricing";
import Support from "./Components/Support";
import LogoIdeas from "./Components/LogoIdeas";
import CanvasEditor from "./Components/CanvasEditor";
import LoggedInHome from "./Components/LoggedInHome";
import Settings from "./Components/Settings";
import BrandKit from "./Components/BrandKit";
import { retrieveData } from "./LocalConnection/LocalConnection.js";
import ViewBusinessCard from "./Components/ViewBusinessCard.js";
import ViewInvitationCard from "./Components/ViewInvitationCard.js";
import ViewEnvelops from "./Components/ViewEnvelops.js";
import ViewLetterHead from "./Components/ViewLetterHead.js";
import EnterCompanyName from "./Components/EnterCompanyName.js";
import Blogs from "./Components/Blogs.js";
import HowitWorks from "./Components/HowitWorks.js";
import FAQ from "./Components/FAQ.js";
import AboutUS from "./Components/AboutUS.js";
import Blog2 from "./Components/Blog2.js";
import ScrollToTop from "./Components/ScrollToTop.js";
import TermsandConditions from "./Components/TermsandConditions";
import PrivacyPolicy from "./Components/PrivacyPolicy";
import DetailedLogoIdea from "./Components/DetailedLogoIdea.js";
import ComplatePayment from "./Components/CompletePayment.js";
import Error404 from "./Components/Error404.js";
import AboutCanvas from "./Components/AboutCanvas.js";
import MyLogo from "./Components/MyLogo.js";
import MyDesigns from "./Components/MyDesigns.js";
import PaymentFaild from "./Components/PaymentFaild.js";
import PaymentInvoice from "./Components/PaymentInvoice.js";
import {
  server_post_data,
  seo_data_url,
} from "./ServiceConnection/serviceconnection.js";
import FontLoader from "./Components/FontLoader";
import RefundPolicy from "./Components/RefundPolicy.js";
import Licensing from "./Components/Licensing.js";
import { handleError } from "./CommonJquery/CommonJquery.js";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { NavigationProvider } from "./Components/NavigationProvider.js";
function App() {
  const user_id = retrieveData("user_id");

  const [SEOloop, setSEOloop] = useState([]);
  const match_and_return_seo_link_and_page = (
    call_function_name,
    pretty_function_name,
    dynamic_title_ss,
    favicon_sss
  ) => {
    let data_seo_link_final = "";

    if (call_function_name === "/") {
      data_seo_link_final = pretty_function_name + "~@~1";
    } else if (call_function_name === "/booking") {
      data_seo_link_final = pretty_function_name + "~@~2";
    } else if (call_function_name === "/team") {
      data_seo_link_final = pretty_function_name + "~@~3";
    } else if (call_function_name === "/careers") {
      data_seo_link_final = pretty_function_name + "~@~4";
    } else if (call_function_name === "/blog") {
      data_seo_link_final = pretty_function_name + "~@~5";
    } else if (call_function_name.includes("blogs/blog_detail")) {
      data_seo_link_final = pretty_function_name + "~@~6";
    }

    data_seo_link_final = pretty_function_name + "~@~6";
    return data_seo_link_final;
  };

  const get_page_name = (page_number) => {
    let data_seo_link_final = "";
    data_seo_link_final = <Blog2 />;
    // if (page_number === "1") {
    //   data_seo_link_final = <HomePage />;
    // } else if (page_number === "2") {
    //   data_seo_link_final = <HeaderBookingPage />;
    // } else if (page_number === "3") {
    //   data_seo_link_final = <TeamPage />;
    // } else if (page_number === "4") {
    //   data_seo_link_final = <Careers />;
    // } else if (page_number === "5") {
    //   data_seo_link_final = <Blog2 />;
    // } else if (page_number === "6") {
    //   data_seo_link_final = <Blogdetail />;
    // }

    return data_seo_link_final;
  };

  const handleFetchData = async () => {
    var form_data = new FormData();
    form_data.append("flag", "10000");
    await server_post_data(seo_data_url, form_data)
      .then((Response) => {
        if (Response.data.error) {
          handleError(Response.data.message);
        } else {
          setSEOloop(Response.data.message.data_seo);
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };

  useEffect(() => {
    handleFetchData();
  }, []);

  return (
    <div className="App">
      <BrowserRouter>
        <NavigationProvider>
          <FontLoader />
          <ScrollToTop />
          <Routes>
            {/**checked */}
            <Route path="/refund-policy" element={<RefundPolicy />} />
            <Route path="/licensing" element={<Licensing />} />
            <Route path="/support" element={<Support />} />
            <Route path="/logoIdeas" element={<LogoIdeas />} />
            <Route path="/logoideas/:id" element={<DetailedLogoIdea />} />
            <Route path="/blogs" element={<Blogs />} />
            <Route path="/blogs/blog_detail/:id" element={<Blog2 />} />
            <Route path="/brandkit" element={<BrandKit />} />
            <Route path="/howItWorks" element={<HowitWorks />} />{" "}
            <Route path="/faqs" element={<FAQ />} />
            <Route path="/aboutus" element={<AboutUS />} />
            <Route path="/termsofuse" element={<TermsandConditions />} />
            <Route path="/privacyPolicy" element={<PrivacyPolicy />} />
            <Route path="/complete_payment/:id" element={<ComplatePayment />} />
            <Route path="/paymentinvoice/:id" element={<PaymentInvoice />} />
            <Route path="/payment_payment" element={<PaymentFaild />} />
            <Route
              path="/createlogohome"
              element={user_id ? <EnterCompanyName /> : <EnterCompanyName />}
            />
            <Route path="/pickIndustry" element={<Pickindustry />} />
            <Route path="/recommendation" element={<Recommendation />} />
            <Route path="/pricing" element={<Pricing />} />
            <Route path="/aboutcanvas" element={<AboutCanvas />} />
            <Route path="/mylogo" element={<MyLogo />} />
            <Route path="/mydesigns" element={<MyDesigns />} />
            <Route
              path="/settings"
              element={user_id ? <Settings /> : <Navigate to="/" />}
            />
            <Route
              path="/"
              element={user_id ? <Navigate to="/home" /> : <Home />}
            />
            <Route path="/home/businesscard" element={<ViewBusinessCard />} />
            <Route
              path="/home/invitationCard"
              element={<ViewInvitationCard />}
            />
            <Route path="/home/envelops" element={<ViewEnvelops />} />
            {/**not check */}
            {/**not check */}
            <Route path="/chooseIcons" element={<ChooseIcons />} />
            <Route path="/showLogos" element={<ShowLogos />} />
            <Route path="/canvasEdit" element={<CanvasEditor />} />
            <Route
              path="/canvasEdit/:data_type/:id"
              element={<CanvasEditor />}
            />
            <Route path="/home/letterhead" element={<ViewLetterHead />} />
            <Route
              path="/home"
              element={user_id ? <LoggedInHome /> : <Navigate to="/" />}
            />
            {/**not check */}
            {/**not check */}
            {SEOloop.map((data, index) => {
              const for_loop_come = match_and_return_seo_link_and_page(
                data.call_function_name,
                data.pretty_function_name,
                data.title_name,
                data.favicon
              );
              const stringArray = for_loop_come.split("~@~");
              return (
                <Route
                  key={index}
                  path={stringArray[0]}
                  element={get_page_name(stringArray[1])}
                />
              );
            })}
            <Route path="/*" element={<Error404 />} />
          </Routes>
          <ToastContainer />
        </NavigationProvider>
      </BrowserRouter>
    </div>
  );
}

export default App;
