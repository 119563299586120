import React from "react";
import "./Css/Home.css";
import Header from "./Header";
import { useState, useEffect, useRef } from "react";
import Card from "react-bootstrap/Card";
import DOMPurify from "dompurify";
import rightArrow from "../Assets/Images/right_arrow_black.svg";
import leftArrow from "../Assets/Images/leftArrow.svg";
import RattingStarrImg from "../Assets/Images/RattingStarrImg.svg";
import googleIcon from "../Assets/Images/googleIcon.svg";
import upArrow from "../Assets/Images/downArrowBlack.svg";
import { Link } from "react-router-dom";
import Loader from "./Loader.js";
import left from "../Assets/Images/left.png";
import right from "../Assets/Images/right.png";
import glasdoor from "../Assets/Images/glassdoor.png";
import ambitionBox from "../Assets/Images/ambitionBox.png";
import clutch from "../Assets/Images/clutch.png";
import hired from "../Assets/Images/hired.png";
import payscale from "../Assets/Images/payscale.png";
import step1 from "../Assets/Images/homeScreenSS.png";
import step2 from "../Assets/Images/Re 02.png";
import step3 from "../Assets/Images/Re 03.png";
import step4 from "../Assets/Images/Re 04.png";
import step5 from "../Assets/Images/Re 05.png";
import step6 from "../Assets/Images/Re 06.png";
import Slider from "react-slick";
import starsBrandSucc from "../Assets/Images/stars_brandSuccess.svg";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
// why logo academy section images
import whyLA01 from "../Assets/Images/whyLA01.svg";
import whyLA02 from "../Assets/Images/whyLA02.svg";
import whyLA03 from "../Assets/Images/whyLA03.svg";
import whyLA04 from "../Assets/Images/whyLA04.svg";
import whyLA05 from "../Assets/Images/whyLA05.svg";
import whyLA06 from "../Assets/Images/whyLA06.svg";

import Footer from "./Footer";
import {
  removeData,
  retrieveData,
  storeData,
} from "../LocalConnection/LocalConnection.js";
import {
  server_post_data,
  APL_LINK,
  get_home_page_data,
  get_all_industry_data
} from "../ServiceConnection/serviceconnection.js";
import { handleError, handleLinkClick } from "../CommonJquery/CommonJquery";
import {
  data_logo_ideas,
  premium_features_images,
} from "../CommonJquery/WebsiteText.js";
import Footer_contact from "./Footer_contact.js";

const Home = () => {
  const [showLoaderAdmin, setshowLoaderAdmin] = useState(false);
  const [faqdata, setFaqData] = useState([]);
  const [activeIndex, setActiveIndex] = useState(null);
  const [companyName, setCompanyName] = useState("");
  const [slogan, setSlogan] = useState("");
  const [isMobile, setIsMobile] = useState(window.innerWidth < 600);
  const [FiltertemplateData, setFiltertemplateData] = useState([]);

  const [cards, setcards] = useState([]);
  const [SEOloop, setSEOloop] = useState([]);

  const [errorMessage, setErrorMessage] = useState("");
  // brands success corousel functions
  const [currentIndex, setCurrentIndex] = useState(0);
  const currentIndexRef = useRef(0);
  const [slides, setSlides] = useState([]);
  const [isDesktop, setIsDesktop] = useState(window.innerWidth < 800);

  useEffect(() => {
    const savedCompanyName = retrieveData("companyName");
    const savedSlogan = retrieveData("slogan");
    if (savedCompanyName) setCompanyName(savedCompanyName);
    if (savedSlogan) setSlogan(savedSlogan);
  }, []);

  useEffect(() => {
    storeData("companyName", companyName);
    storeData("slogan", slogan);
  }, [companyName, slogan]);

  const master_data_get_home = async (flag) => {
    setshowLoaderAdmin(true);
    const fd = new FormData();

    fd.append("flag", flag);

    await server_post_data(get_home_page_data, fd)
      .then((Response) => {
        console.log(Response.data.message);
        if (Response.data.error) {
          handleError(Response.data.message);
        } else {
          const testimonials = Response.data.message.data;
          setFaqData(Response.data.message.data_faq);
          setcards(Response.data.message.blog_list);
          setSEOloop(Response.data.message.seo_loop);
          const slides = testimonials.map((testimonial) => ({
            image: testimonial.image_link,
            heading: `“${testimonial.testimony_msg}”`,
            author: testimonial.name,
            designation: testimonial.designation,
          }));
          setSlides(slides);
          setCurrentIndex(0);
          currentIndexRef.current = 0;
        }
        setshowLoaderAdmin(false);
      })
      .catch((error) => {
        handleError("network");
        setshowLoaderAdmin(false);
      });
  };
  useEffect(() => {
    currentIndexRef.current = currentIndex;
  }, [currentIndex]);
  // Call the function to fetch testimonials on component mount
  useEffect(() => {
    master_data_get_home(1);
  }, []);
  const handlePrev = () => {
    const newIndex = currentIndex === 0 ? slides.length - 1 : currentIndex - 1;
    setCurrentIndex(newIndex);
    currentIndexRef.current = newIndex;
  };

  const handleNext = () => {
    const newIndex = currentIndex === slides.length - 1 ? 0 : currentIndex + 1;
    setCurrentIndex(newIndex);
    currentIndexRef.current = newIndex;
  };
  const handleDotClick = (index) => {
    setCurrentIndex(index);
    currentIndexRef.current = index;
  };

  const handleClick = (index) => {
    setActiveIndex(index === activeIndex ? null : index);
  };

  //blogs custom cards reusable function

  const CustomCard = ({ imageUrl, description }) => {
    return (
      <Card style={{ width: "18rem", margin: "auto" }}>
        <Card.Img variant="top" src={imageUrl} />
        <Card.Body className="Home_blogs_description_wrapper">
          <Card.Text className="titlename">{description}</Card.Text>
        </Card.Body>
      </Card>
    );
  };

  //logo ideas for every section array
  useEffect(() => {
    const handleResize = () => {
      setIsDesktop(window.innerWidth < 800);
    };

    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);
  const settings2 = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 2,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 3000,
    // nextArrow: <NextArrow />,
    // prevArrow: <PrevArrow />,
    responsive: [
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
  };

  const match_and_return_seo_link = (v_id) => {
    let data_seo_link_final = "/blogs/blog_detail/" + v_id;
    let data_seo_link = data_seo_link_final;

    if (SEOloop) {
      const matchedItem = SEOloop.find(
        (data) => data_seo_link === data.call_function_name
      );

      if (matchedItem) {
        data_seo_link_final = matchedItem.pretty_function_name;
      }
    }

    return data_seo_link_final;
  };

  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth < 600);
    };

    window.addEventListener("resize", handleResize);
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);
  const sliderRef = useRef(null);
  const settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 3000,
    responsive: [
      {
        breakpoint: 768, // when screen width is less than 768px
        settings: {
          slidesToShow: 2,
          slidesToScroll: 2,
        },
      },
      {
        breakpoint: 445, // when screen width is less than 400px
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
  };

  const handleInputChange = (setter) => (e) => {
    setter(e.target.value);
    if (errorMessage) {
      setErrorMessage("");
    }
  };

  const handleContinueClick = () => {
    if (!companyName && !slogan) {
      setErrorMessage("Please fill in the Business Name");
    } else {
      setErrorMessage("");
      document.getElementById("continue_link").click();
    }
  };
  useEffect(() => {
    // Function to clear local storage
    removeData();
    master_data_get();
  }, []);

  const master_data_get = async (flag, call_id) => {
    const fd = new FormData();
    setshowLoaderAdmin(true);
    fd.append("flag", flag);
    fd.append("call_id", call_id);

    await server_post_data(get_all_industry_data, fd)
      .then((Response) => {
        if (Response.data.error) {
          handleError(Response.data.message);
        } else {
          // setTemplateData(Response.data.message.data);
          setFiltertemplateData(Response.data.message.data);
        }
        setshowLoaderAdmin(false);
      })
      .catch((error) => {
        console.error(error);
        setshowLoaderAdmin(false);
      });
  };

  console.log(FiltertemplateData);
  return (
    <>
      <Header />
      <div className="home" style={{ width: "100%", overflow: "hidden" }}>
        {showLoaderAdmin && <Loader />}
        <div className="home_wrapper">
          <div className="home_container">
            <section className="hero">
              <img src={left} alt="left" id="leftImg" />
              <div className="container h-100 w-100">
                <div className="hero_section_container h-100">
                  <div className="row m-0 h-100">
                    <div className="col-lg-10 col-md-12 col-sm-11 h-100 centerElement m-auto">
                      <div className="hero_left">
                        <div className="col-sm-11 m-auto">
                          <div className="hero_Left_heading">
                            <h1>
                              Design Professional <br /> Logo For Free
                            </h1>
                          </div>
                          <div className="hero_left_subHead">
                            <p>
                              Craft a Stunning High-Quality AI logo for your
                              social media, website and print.
                              <span>Try it for free!</span>
                            </p>
                          </div>
                        </div>
                        <form>
                          <div className="inputs_container">
                            <input
                              type="text"
                              name="BusinessName"
                              onChange={handleInputChange(setCompanyName)}
                              placeholder="Enter your Business Name"
                              required
                            />
                          </div>
                          <div className="inputs_container2">
                            <input
                              type="text"
                              name="slogan"
                              onChange={handleInputChange(setSlogan)}
                              placeholder="Slogan (optional)"
                            />
                          </div>
                          {errorMessage && (
                            <span className="error_message">
                              {errorMessage}
                            </span>
                          )}
                          <Link
                            to="/pickIndustry"
                            id="continue_link"
                            style={{ display: "none" }}
                          />

                          <button
                            id="continue_button"
                            className="continue_btn"
                            type="button"
                            onClick={handleContinueClick}
                            // style={{ opacity: companyName || slogan ? 1 : 0.7 }}
                          >
                            Continue
                          </button>
                        </form>
                        <div className="caption_container">
                          <p>
                            More than hundreds of companies around the world use
                            logos <br className="d-none d-sm-block" /> created
                            by LogoAcademy
                          </p>
                        </div>
                        <div className="RatingContainer">
                          <img
                            clssname="RattingStarrImg"
                            src={RattingStarrImg}
                            alt="RattingStarrImg"
                          />
                          <p>4.8/5.0</p>|
                          <img
                            className="googleIcnImg"
                            src={googleIcon}
                            alt="stars"
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <img src={right} alt="right" id="rightImg" />
            </section>
            <section className="companies_section">
              <div className="company_images_container">
                <div>
                  <img src={glasdoor} alt="glasdoor" />
                </div>
                <div>
                  <img src={ambitionBox} alt="glasdoor" />
                </div>
                <div>
                  <img src={clutch} alt="glasdoor" />
                </div>
                <div>
                  <img src={hired} alt="glasdoor" />
                </div>
                <div>
                  <img src={payscale} alt="glasdoor" />
                </div>
              </div>
            </section>
            {/* -----------------------whatYouGetSection----------------- */}
            <section className="onedesign_section">
              <div className="container-lg">
                <div className="whatyouget_headings_section">
                  <div className="whatyouget_center_heading">
                    <h3> Design Something Amazing!</h3>
                  </div>
                  <div className="whatyouget_subheading">
                    <h6>Get Inspired and Design with Passion</h6>
                  </div>
                </div>
              </div>
              <div className="container-lg">
                <div className="DesignSomethingSection">
                  {isMobile ? (
                    <div className="container-lg">
                      <div className="causelMobile">
                        <Slider ref={sliderRef} {...settings}>
                          {premium_features_images.map((imagex, index) => (
                            <div key={index} className="slideContent">
                              <img
                                src={imagex.image}
                                alt={`imagesx${index + 1}`}
                              />

                              <p className="legend">{imagex.text}</p>
                            </div>
                          ))}
                        </Slider>
                      </div>
                    </div>
                  ) : (
                    <div className="image_row image_row_home  DeginCont">
                      {premium_features_images.map((imagex, index) => (
                        <div key={index} className="image-item">
                          <img src={imagex.image} alt="imagesx1" />
                          <p>{imagex.text}</p>
                        </div>
                      ))}
                    </div>
                  )}
                </div>
              </div>

              <br />
            </section>
            {/* --------------------steps section ---------------- */}
            <section className="steps_section">
              <div className="headings-section">
                <div className="main-heading">
                  <p>Design Your Logo Effortlessly</p>
                </div>
                <div className="sub-heading">
                  <p>
                    Create unique custom logos with the LogoAcademy logo
                    generator instantly.
                  </p>
                </div>
                <div className="design_inafewMinutes">
                  <p>Designs in just a few minutes. </p>
                </div>
              </div>
              <div className="container">
                {" "}
                <div className="container steps_container">
                  {/* step1  */}
                  <div className="row mt-3 mb-5 step1_wrapper">
                    <div className="col-md-6 step1_wrapper_left">
                      <img src={step1} alt="step1 " />
                    </div>
                    <div className="col-md-6 step1_wrapper_right">
                      <h2>1.Enter Business Details</h2>
                      <p>
                        To get started with LogoAcademy enter your business
                        name, slogan (if any) and type of business and let our
                        logo maker create instantly amazing logo designs. You
                        can browse all templates tailored to your business name
                        and find a design that suits your business.
                      </p>
                    </div>
                  </div>
                  {/* step2 */}
                  <div className="row mb-5 step2_wrapper ">
                    <div className="col-md-6 step1_wrapper_right ">
                      <h2>2.Pick Perfect Colours </h2>
                      <p>
                        Once you select your perfect logo design it’s a
                        five-finger exercise to change the colour, icon,
                        typography and layout that suits your business. You can
                        make changes by adding gradient shades, shapes and
                        artistic fonts.
                      </p>
                    </div>
                    <div className="col-md-6 step1_wrapper_left stepSrightSIde">
                      <img src={step2} alt="step2" />
                    </div>
                  </div>
                  {/* step3 */}
                  <div className="row mb-5 step1_wrapper">
                    <div className="col-md-6 step1_wrapper_left">
                      <img src={step3} alt="step1 " />
                    </div>
                    <div className="col-md-6 step1_wrapper_right">
                      <h2>3.Select Icons and Logo styles</h2>
                      <p>
                        There’s no limit to what you can do using our logo
                        maker. Choose the icons and logo styles(e.g.wordmark,
                        abstract marks, pictorial) from the provided library and
                        adjust the spacing in your design icons by moving the
                        elements.
                      </p>
                    </div>
                  </div>
                  {/* step4 */}
                  <div className="row mb-5">
                    <div className="col-md-6 step1_wrapper_right">
                      <h2>4.Sit back and Magic happens</h2>
                      <p>
                        Let your fingers rest and relax while LogoAcademy
                        AI-powered logo generator creates a stunning logo for
                        you! This amazing platform is nothing more than a
                        magician in designing unique logos.
                      </p>
                    </div>
                    <div className="col-md-6 step1_wrapper_left stepSrightSIde">
                      <img src={step4} alt="step2" />
                    </div>
                  </div>
                  {/* step5 */}
                  <div className="row mb-5 step1_wrapper">
                    <div className="col-md-6 step1_wrapper_left">
                      <img src={step5} alt="step1 " />
                    </div>
                    <div className="col-md-6 step1_wrapper_right">
                      <h2>5.Customise Logo Design</h2>
                      <p>
                        Now your logo is ready to view. You can further refine
                        your design as per the feedback. Pick your favourite
                        logo and customise the colours, fonts and icons to
                        create sophisticated designs.
                      </p>
                    </div>
                  </div>
                  {/* step6  */}
                  <div className="row mb-5">
                    <div className="col-md-6 step1_wrapper_right">
                      <h2>6.Download Your Logo</h2>
                      <p>
                        Now it’s time to download the crafted logo! Downloading
                        a logo in LogoAcademy is a quick and easy step. You can
                        download high-resolution files of your design along with
                        vector files, PNG and SVG logo files.
                      </p>
                    </div>
                    <div className="col-md-6 step1_wrapper_left stepSrightSIde">
                      <img src={step6} alt="step2" />
                    </div>
                  </div>
                </div>
              </div>
            </section>
            {/* -----------------------About Section------------------- */}
            <section className="about_section">
              <div className="container">
                <div className="headings-section">
                  <div className="main-heading">
                    <p>Why Logo Academy</p>
                  </div>
                </div>
                <div className="container">
                  {" "}
                  <div className="cards-container">
                    <div className="row1 row">
                      {/* card1  */}
                      <div className="white-card col-lg-6 col-md-6">
                        <div className="card-content">
                          <h2 className="card-heading">
                            <img src={whyLA01} alt="whyLA01" />
                            Premium Logo Designs
                          </h2>
                          <p className="card-subheading">
                            Every logo available in the library is unique and
                            designed by professional designers.
                          </p>
                        </div>
                      </div>
                      {/* card2 */}
                      <div className="white-card col-lg-6 col-md-6">
                        <div className="card-content">
                          <h2 className="card-heading">
                            <img src={whyLA02} alt="whyLA01" />
                            Files You Needed/ Available Various Files
                          </h2>
                          <p className="card-subheading">
                            Get high-resolution logo files (JPG & PNG) and
                            vector files (SVG & PDF) for print.
                          </p>
                        </div>
                      </div>
                    </div>
                    <div className="row2 row">
                      {/* card3  */}
                      <div className="white-card col-lg-6 col-md-6">
                        <div className="card-content">
                          <h2 className="card-heading">
                            <img src={whyLA03} alt="whyLA01" />
                            Templates for Business Purposes
                          </h2>
                          <p className="card-subheading">
                            Choose from thousands of business card templates,
                            and customise colours and fonts to match your
                            brand’s identity
                          </p>
                        </div>
                      </div>
                      {/* card4  */}
                      <div className="white-card col-lg-6 col-md-6">
                        <div className="card-content">
                          <h2 className="card-heading">
                            {" "}
                            <img src={whyLA04} alt="whyLA01" />
                            Enhance Brand’s Identity
                          </h2>
                          <p className="card-subheading">
                            Design letterheads and email signatures to match
                            your logo colours to enhance the brand’s identity.
                          </p>
                        </div>
                      </div>
                    </div>
                    <div className="row3 row">
                      {/* card5  */}
                      <div className="white-card col-lg-6 col-md-6">
                        <div className="card-content">
                          <h2 className="card-heading">
                            <img src={whyLA05} alt="whyLA01" />
                            Extra Benefits for Membership
                          </h2>
                          <p className="card-subheading">
                            You can make frequent changes after making payments.
                          </p>
                        </div>
                      </div>
                      {/* card6  */}
                      <div className="white-card col-lg-6 col-md-6">
                        <div className="card-content">
                          <h2 className="card-heading">
                            {" "}
                            <img src={whyLA06} alt="whyLA01" />
                            Copyright of Logo
                          </h2>
                          <p className="card-subheading">
                            LogoAcademy provides you full ownership or copyright
                            to use your logo worldwide for any commercial and
                            non-commercial purpose.
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <br />
            </section>

            {/* logo ideas section ========================== */}
            <section className="logoideas_section">
              <div className="container">
                <div className="howitworks_header_section">
                  <div className="howitworks_main_heading homeShowLogos_main_heading">
                    <h3>Logo ideas for every industry</h3>
                  </div>
                  <div className="home_logo_ideas col-sm-12 col-10">
  <ul className="multi-column-list row">
    {FiltertemplateData.map((item, index) => (
      <div className="col-lg-3 col-sm-4 col-6" key={index}>
        <li>
          <Link to={`/logoideas/${item.primary_id}`}>
            {item.industry_name}
          </Link>
        </li>
      </div>
    ))}
  </ul>
</div>
                </div>
                <div className="logo_ideas_button">
                  <div className="more_logos_button">
                    <Link to="/logoIdeas">
                      <button>All logo ideas</button>
                    </Link>
                  </div>
                </div>
              </div>
            </section>

            {/* /* ----------------brand success section----------------------- */}
            <section className="brand_success_section">
              <div className="container">
                <div className="brand_success_container">
                  <div className="brand_success_heading">
                    <h3>Hear What Our Clients Say</h3>
                  </div>
                  <div className="brand_success_subheading ">
                    <h6>Discover what our valued users have to say</h6>
                  </div>

                  <div
                    className="carousel col-sm"
                    style={{ position: "relative" }}
                  >
                    <div className="container ">
                      {" "}
                      <div className="testiNomial">
                        {" "}
                        <button
                          onClick={handlePrev}
                          style={{ background: "transparent", border: "none" }}
                        >
                          <img src={leftArrow} id="leftArrow" alt="leftArrow" />
                        </button>
                        <button
                          onClick={handleNext}
                          style={{ background: "transparent", border: "none" }}
                        >
                          <img
                            src={rightArrow}
                            id="rightArrow"
                            alt="rightArrow"
                          />
                        </button>
                      </div>
                    </div>
                    <div className="carousel-heading">
                      {slides.length > 0 && (
                        <div className="slide active">
                          <div className="userComment_container col-md-8">
                            <h6 style={{ fontWeight: "400" }}>
                              {slides[currentIndex].heading}
                            </h6>
                          </div>
                          <div className="carousel-images">
                            <div className="carousel-controls">
                              <div className="carousel-image-container">
                                <div className="prev_next_wrapper">
                                  <div className="author_img_wrapper flexColCenter">
                                    <img
                                      src={`${APL_LINK}assets/${slides[currentIndex].image}`}
                                      alt={`Slide ${currentIndex}`}
                                      className="carousel-image"
                                    />
                                    <p>
                                      <strong>
                                        {slides[currentIndex].author},{" "}
                                        {slides[currentIndex].designation}
                                      </strong>
                                    </p>
                                    <img src={starsBrandSucc} alt="stars" />
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      )}
                    </div>
                    <div className="carousel-indicators">
                      {slides.map((_, index) => (
                        <span
                          key={index}
                          className={
                            index === currentIndex
                              ? "indicator active"
                              : "indicator"
                          }
                          onClick={() => handleDotClick(index)}
                        ></span>
                      ))}
                    </div>
                  </div>
                </div>
              </div>
            </section>

            {/*----------- frequently asked questions ----------  */}
            <div>
              <section>
                <div className="FaqHeadContainer">
                  <div className="FAqHeading">
                    <h3> Frequently Asked Questions</h3>
                  </div>
                  <p>Find your answer to frequently asked questions</p>
                </div>
                <section className="faq_section">
                  <div className="container">
                    <div className="faq_section_container">
                      <div className="accordion_container col-md-14">
                        <div className="accordion">
                          {faqdata
                            .slice()
                            .reverse()
                            .map(
                              (
                                item,
                                index // Reversing the array here
                              ) => (
                                <div
                                  key={index}
                                  className="accordion-item bgColorr"
                                >
                                  <div
                                    className={`accordion-title ${
                                      index === activeIndex ? "active" : ""
                                    }`}
                                    onClick={() => handleClick(index)}
                                  >
                                    {item.question_name}
                                    <span className="dropdown-icon">
                                      <img src={upArrow} alt="downarrow" />
                                    </span>
                                  </div>
                                  {index === activeIndex && (
                                    <div className="accordion-content">
                                      <p
                                        className="accordion-content-text"
                                        dangerouslySetInnerHTML={{
                                          __html: DOMPurify.sanitize(
                                            item.answer_name
                                          ),
                                        }}
                                      />
                                    </div>
                                  )}
                                </div>
                              )
                            )}
                        </div>
                      </div>
                    </div>
                  </div>
                </section>
              </section>
            </div>

            {/* -------------------blogs section------------------- */}
            <section className="blogs_section">
              <div className="container-lg">
                <div className="BlogggHeading">
                  <h3> Read more logo posts on our blog</h3>
                </div>

                <div className="col-lg-10 col-md-11 mx-auto">
                  {isDesktop ? (
                    <Slider {...settings2} className="mt-4">
                      {cards.slice(0, 6).map((card, index) => (
                        <div className="mobileBlogCarausel">
                          <Link
                            onClick={() =>
                              handleLinkClick(
                                match_and_return_seo_link(card.primary_id, "s")
                              )
                            }
                            key={index}
                          >
                            <CustomCard
                              key={index}
                              className="mobileBlogCarausel"
                              imageUrl={card.image_url}
                              description={card.title_name}
                            />
                          </Link>
                        </div>
                      ))}
                    </Slider>
                  ) : (
                    <div className="row m-0 p-0">
                      {cards.slice(0, 6).map((card, index) => (
                        <div className="col-md-4 d-flex justify-content-center mt-4">
                          <Link
                            onClick={() => {
                              handleLinkClick(
                                match_and_return_seo_link(card.primary_id, "s")
                              );
                            }}
                            key={index}
                          >
                            <div className="custom-card fixcard_container">
                              <img src={card.image_url} alt="Blog Img" />

                              <p className="titlename mb-4">
                                {card.title_name}
                              </p>
                            </div>
                          </Link>
                        </div>
                      ))}
                    </div>
                  )}
                </div>
              </div>
            </section>
            {/* ---------------------------contact us scetion ---------------------- */}
            <Footer_contact />
            {/* ------------------------footer-section----------------------- */}
            <Footer />
          </div>
        </div>
      </div>
    </>
  );
};
export default Home;
