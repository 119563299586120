import React from "react";
import "./Css/AboutUs.css";
import aboutUSbg from "../Assets/Images/bg_aboutus.png";
import Header from "../Components/Header";
import Footer from "./Footer";
import Footer_contact from "./Footer_contact";

const AboutUS = () => {
  return (
    <>
      <Header />
      <section className="section_wrapper_aboutus">
        <div className="aboutus_container_background col-lg-12">
          <img
            src={aboutUSbg}
            alt="aboutusBackground img"
            className="col-lg-12 col-12"
          />
          <div className="container aboutus_container">
            <div className="aboutus_heading_wrapper">
              <h3>
                Corporate Logo Design in Just 2 Minutes
                <br /> - Easy and Fast!
              </h3>
            </div>
          </div>
        </div>
        <div className="container aboutus_textSection ">
          <div className="aboutus_heading_container">
            <h3>AI-Powered Algorithms For Designing</h3>
            <p>
              LogoAcademy introduces artificial intelligence into graphic
              designing leveraging machine learning, neural networks and
              advanced technologies.
              <br />
              {/* <div className="break_aboutUs">
                <p>
                  LogoAcademy's experienced designers developed this platform to
                  ease the logo designing process. Creating a professional logo
                  is just a piece of cake without spending a hefty amount.
                </p>
              </div> */}
            </p>
          </div>
        </div>
        <div className="container">
          <div className="secondTextSection_aboutUs">
            <h3>Our Logo Maker Tool for The Unique Logo Design</h3>
            <desc>
              LogoAcademy's experienced designers developed this platform to
              ease the logo <br />
              designing process. Creating a professional logo is just a piece of
              cake without spending a hefty amount.
            </desc>
          </div>
        </div>
      </section>
      <Footer_contact />

      <Footer />
    </>
  );
};

export default AboutUS;
