import React, { useState, useEffect, useRef } from "react";
import { Link } from "react-router-dom";
import "../Components/Css/Pricing.css";
import Header from "./Header";
import rightArrow from "../Assets/Images/right_arrow_black.svg";
import leftArrow from "../Assets/Images/leftArrow.svg";
import { handleError } from "../CommonJquery/CommonJquery";
import RattingStarrImg from "../Assets/Images/RattingStarrImg.svg";
import {
  server_post_data,
  get_all_testimonials,
  get_all_industry_data,
  APL_LINK,
} from "../ServiceConnection/serviceconnection.js";
import Footer from "./Footer";
import starsBrandSucc from "../Assets/Images/stars_brandSuccess.svg";
import PricingModal from "./PricingModal";
import Footer_contact from "./Footer_contact.js";
import { data_logo_ideas } from "../CommonJquery/WebsiteText.js";
const Pricing = () => {
  const [currentIndex, setCurrentIndex] = useState(0);
  const currentIndexRef = useRef(0);
  const [slides, setSlides] = useState([]);
  const [flag] = useState(false);

  const master_data_get_testimonials = async (flag) => {
    const fd = new FormData();
    fd.append("flag", flag);
    await server_post_data(get_all_testimonials, fd)
      .then((Response) => {
        if (Response.data.error) {
          handleError(Response.data.message);
        } else {
          const testimonials = Response.data.message.data;
          const slides = testimonials.map((testimonial) => ({
            image: testimonial.image_link,
            heading: `“${testimonial.testimony_msg}”`,
            author: testimonial.name,
            designation: testimonial.designation,
          }));
          setSlides(slides);

          setCurrentIndex(0);
          currentIndexRef.current = 0;
        }
      })
      .catch((error) => {
        handleError("network");
      });
  };
  useEffect(() => {
    currentIndexRef.current = currentIndex;
  }, [currentIndex]);
  // Call the function to fetch testimonials on component mount
  useEffect(() => {
    master_data_get_testimonials(flag);
  }, []);
  const handlePrev = () => {
    const newIndex = currentIndex === 0 ? slides.length - 1 : currentIndex - 1;
    setCurrentIndex(newIndex);
    currentIndexRef.current = newIndex;
  };

  const handleNext = () => {
    const newIndex = currentIndex === slides.length - 1 ? 0 : currentIndex + 1;
    setCurrentIndex(newIndex);
    currentIndexRef.current = newIndex;
  };
  const handleDotClick = (index) => {
    setCurrentIndex(index);
    currentIndexRef.current = index;
  };

  useEffect(() => {
    master_data_get("0", "0");
  }, []);
  const [searchTerm, setSearchTerm] = useState("");
  const [templateData, setTemplateData] = useState([]);
  const [FiltertemplateData, setFiltertemplateData] = useState([]);
  const [showLoaderAdmin, setshowLoaderAdmin] = useState(false);

  const master_data_get = async (flag, call_id) => {
    const fd = new FormData();
    setshowLoaderAdmin(true);
    fd.append("flag", flag);
    fd.append("call_id", call_id);

    await server_post_data(get_all_industry_data, fd)
      .then((Response) => {
        if (Response.data.error) {
          handleError(Response.data.message);
        } else {
          setTemplateData(Response.data.message.data);
          setFiltertemplateData(Response.data.message.data);
          console.log(Response.data.message.data);
        }
        setshowLoaderAdmin(false);
      })
      .catch((error) => {
        console.error(error);
        setshowLoaderAdmin(false);
      });
  };

  const splitIntoRows = (arr, numRows) => {
    const result = [];
    const itemsPerRow = Math.ceil(arr.length / numRows); // Calculate how many items per row
    for (let i = 0; i < numRows; i++) {
      result.push(arr.slice(i * itemsPerRow, (i + 1) * itemsPerRow));
    }
    return result;
  };
  
  const sortedData = [...FiltertemplateData].sort((a, b) =>
    a.industry_name.localeCompare(b.industry_name)
  );
  
  // Split the sorted data into 4 rows
  const rows = splitIntoRows(sortedData, 4);

  return (
    <>
      <Header Showheader={false} />
      <section style={{ overflow: "hidden" }}>
        <div className="">
          <div className="pricing_heading_wrapper">
            <h3>Choose your package</h3>
          </div>
          <div className="section_wrappper">
            <div className="pricing_card_section">
              <PricingModal />
              <div className=""></div>
            </div>
          </div>
        </div>
      </section>
      <div className="col-lg-9 m-auto">
        <div className="container">
          <section className="brand_success_section">
            <div className="brand_success_container">
              <div className="pricing_testimonials_heading">
                <h6>Logo online in Logo Academy: Reviews</h6>
                <desc>Online logo generator</desc>
                <div className="RatingContainer RatingContainerr">
                  <img
                    clssname="RattingStarrImg"
                    src={RattingStarrImg}
                    alt="RattingStarrImg"
                  />
                  <p>4.8/5.0</p>
                </div>
              </div>

              <div className="carousel col-sm" style={{ position: "relative" }}>
                <div className=" ">
                  {" "}
                  <div className="testiNomial">
                    {" "}
                    <button
                      onClick={handlePrev}
                      style={{ background: "transparent", border: "none" }}
                    >
                      <img src={leftArrow} id="leftArrow" alt="leftArrow" />
                    </button>
                    <button
                      onClick={handleNext}
                      style={{ background: "transparent", border: "none" }}
                    >
                      <img src={rightArrow} id="rightArrow" alt="rightArrow" />
                    </button>
                  </div>
                </div>

                <div className="carousel-heading">
                  {slides.length > 0 && (
                    <div className="slide active">
                      <div className="userComment_container col-md-8">
                        <h6>{slides[currentIndex].heading}</h6>
                      </div>
                      <div className="carousel-images">
                        <div className="carousel-controls">
                          <div className="carousel-image-container">
                            <div className="prev_next_wrapper">
                              <div className="author_img_wrapper flexColCenter">
                                <img
                                  src={`${APL_LINK}assets/${slides[currentIndex].image}`}
                                  alt={`Slide ${currentIndex}`}
                                  className="carousel-image"
                                />
                                <p>
                                  <strong>
                                    {slides[currentIndex].author},{" "}
                                    {slides[currentIndex].designation}
                                  </strong>
                                </p>
                                <img src={starsBrandSucc} alt="stars" />
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  )}
                </div>
                <div className="carousel-indicators">
                  {slides.map((_, index) => (
                    <span
                      key={index}
                      className={
                        index === currentIndex
                          ? "indicator active"
                          : "indicator"
                      }
                      onClick={() => handleDotClick(index)}
                    ></span>
                  ))}
                </div>
              </div>
            </div>
          </section>
        </div>
        <div className="container">
          <section className="textSection_pricingPage">
            <div className=" flexRowCenter">
              <div className="pricingPage_text_conatiner col-md-9 ">
                <p>
                  Design as many various logos as you want for free. Pay only at
                  the time of download. We offer expedient packages for our
                  products. Select your package and get your files.
                </p>
              </div>
            </div>
          </section>
        </div>
        <div className="container">
          <section className="logoideas_section logoideas_section_pricingPage ">
            <div className="">
              <div className="howitworks_header_section howitworks_header_section_pricingPage">
                <div className="howitworks_main_heading flexRowCenter howitworks_main_heading_pricingPage">
                  <h3>Logo ideas for every industry</h3>
                </div>
                <div className="">
                  <ul className="row" style={{ listStyle: "none" }}>
                    {rows.map((column, colIndex) => (
                      <div className="col-lg-3 col-sm-4 col-6" key={colIndex}>
                        <div>
                        {column.map((item) => (
                          <li>
                            <Link
                              to={`/logoideas/${item.primary_id}`}
                              style={{
                                color: "inherit",
                                textDecoration: "none",
                              }}
                            >
                              {item.industry_name}
                            </Link>
                          </li>
                        ))}
                        </div>
                      </div>
                    ))}
                  </ul>
                </div>
              </div>
              <div className="logo_ideas_button">
                <div className="more_logos_button">
                  <Link to="/logoIdeas">
                    <button>All logo ideas</button>
                  </Link>
                </div>
              </div>
            </div>
          </section>
        </div>
      </div>
      <Footer_contact color_remove={false} />
      {/* --------------footer---------------*/}
      <Footer />
    </>
  );
};

export default Pricing;
