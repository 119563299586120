import React, { useState } from "react";
import Header from "./Header";
import "../Components/Css/Settings.css";
import { Tab, Tabs, TabList, TabPanel } from "react-tabs";
import "react-tabs/style/react-tabs.css";
import accountInfo from "../Assets/Images/account_info.svg";
import login_security from "../Assets/Images/login_security.svg";
import billing from "../Assets/Images/billing.svg";
import AccountsInfo from "./AccountsInfo.js";
import LoginSec from "./LoginSec";
import BillingInfo from "./BillingInfo.js";

const Settings = () => {
  const [isTabPanelVisible, setIsTabPanelVisible] = useState(1);

  const handleTabClick = (index) => {
    setIsTabPanelVisible(index);
  };

  const handleBackClick = () => {
    setIsTabPanelVisible(false);
  };

  return (
    <>
      <Header />
      <section className="settings_section">
        <div className="container-lg">
          <div className="vertical-tabs col-lg-12 col-md-12 col-sm-12">
            <div className="tab-list" style={{ paddingTop: "3rem" }}>
              <span className="SettingText">
                <h3>Settings</h3>
              </span>
              <div
                className={` tab ${isTabPanelVisible == 1 ? "active-tab" : ""}`}
                onClick={() => handleTabClick(1)}
              >
                <span className="d-flex align-items-center">
                  <img src={accountInfo} alt="accinfo" />
                  <p
                    style={{
                      margin: "0",
                      whiteSpace: "nowrap",
                      fontSize: "14px",
                    }}
                  >
                    Account Information
                  </p>
                </span>
              </div>
              <div
                className={` tab ${isTabPanelVisible == 2 ? "active-tab" : ""}`}
                onClick={() => handleTabClick(2)}
              >
                <span className="d-flex align-items-center">
                  <img src={login_security} alt="accinfo" />
                  <p
                    style={{
                      margin: "0",
                      whiteSpace: "nowrap",
                      fontSize: "14px",
                    }}
                  >
                    Login and security
                  </p>
                </span>
              </div>
              <div
                className={` tab ${isTabPanelVisible == 3 ? "active-tab" : ""}`}
                onClick={() => handleTabClick(3)}
              >
                <span className="d-flex align-items-center">
                  <img src={billing} alt="accinfo" />
                  <p
                    style={{
                      margin: "0",
                      whiteSpace: "nowrap",
                      fontSize: "14px",
                    }}
                  >
                    Billing and invoices
                  </p>
                </span>
              </div>
            </div>
            {isTabPanelVisible == 1 && (
              <div
                className={`tab-panel w-100 ${
                  isTabPanelVisible === 1 ? "active" : ""
                }`}
                style={{ paddingTop: "2rem" }}
              >
                {/* <button className="back-button" onClick={handleBackClick}>
                  Back
                </button> */}
                <AccountsInfo />
              </div>
            )}
            {isTabPanelVisible == 2 && (
              <div
                className={`tab-panel w-100 ${
                  isTabPanelVisible === 2 ? "active" : ""
                }`}
                style={{ paddingTop: "2rem" }}
              >
                {/* <button className="back-button" onClick={handleBackClick}>
                  Back
                </button> */}
                <LoginSec />
              </div>
            )}
            {isTabPanelVisible == 3 && (
              <div
                className={`tab-panel w-100 ${
                  isTabPanelVisible === 3 ? "active" : ""
                }`}
                style={{ paddingTop: "2rem" }}
              >
                {/* <button className="back-button" onClick={handleBackClick}>
                  Back
                </button> */}
                <BillingInfo />
              </div>
            )}
          </div>
        </div>
      </section>
    </>
  );
};

export default Settings;
