import React, { useState, useEffect } from "react";
import "./Css/LoginSec.css";

import {
  server_post_data,
  change_useremail_password,
  get_user_information,
} from "../ServiceConnection/serviceconnection.js";
import {
  handleError,
  check_vaild_save,
  combiled_form_data,
  handlePasswordChange,
  handleSuccessSession,
} from "../CommonJquery/CommonJquery";
import Loader from "./Loader.js";
import hide_pass from "../Assets/Images/hide_pass.svg";
import showPass from "../Assets/Images/showPass.svg";
import { Modal, Button } from "react-bootstrap";
let vaild_data;
let fd_from;
const LoginSec = () => {
  const [showLoaderAdmin, setshowLoaderAdmin] = useState(false);
  const [showNewPasswordModal, setshowNewPasswordModal] = useState(false);
  const [editStaffData, seteditStaffData] = useState([]);

  const [showPassword, setShowPassword] = useState(false);
  const [showPassword1, setShowPassword1] = useState(false);
  const [showError, setshowError] = useState();
  const [showError2, setshowError2] = useState();
  const [new_password, setnew_password] = useState("");
  const [confirm_password, setconfirm_password] = useState("");
  // const confirm_password = document.getElementById("confirm_password").value;
  // const new_password = document.getElementById("new_password").value;

  const handlePasswordChange = (form_data) => {
    let vaild_data = check_vaild_save(form_data);
    if (vaild_data) {
      setshowNewPasswordModal(true);
      
    }
  };
  const handleSaveChangesdynamic = async (form_data, url_for_save) => {
    // Retrieve the entered current password from the input field
    let fd_from = new FormData();
    const currentPassword = document.getElementById("current_password").value;
    const user_id = localStorage.getItem("user_id");

    // Check if the entered password matches editStaffData.password
    if (currentPassword !== editStaffData.password) {
      console.log(currentPassword);
      console.log(editStaffData.password);
      setshowError("The current password is incorrect");
      console.log("The current password is incorrect");
      return; // Exit the function if the password doesn't match
    }

    // Proceed with the save logic if the password matches
    setshowLoaderAdmin(true);
    


   
    fd_from = combiled_form_data("addNewStaff", null);
    fd_from.append("customer_id", user_id);


    // let fd_from = combiled_form_data(form_data, null); // Create form data
    await server_post_data(url_for_save, fd_from)
      .then((Response) => {
        setshowLoaderAdmin(false);
        if (Response.data.error) {
          handleError(Response.data.message);
        } else {
          handleSuccessSession(Response.data.message, "/settings");
        }
      })
      .catch((error) => {
        setshowLoaderAdmin(false);
        handleError("network");
      });
  };

  useEffect(() => {
    const start_date = "";
    const end_date = "";
    const flag = "3";
    let call_id = "0";
    master_data_get(start_date, end_date, flag, call_id);
  }, []);

  const master_data_get = async () => {
    const user_id = localStorage.getItem("user_id"); // Retrieve user_id from local storage

    if (!user_id) {
      handleError("User ID not found in local storage");
      return; // If no user_id is found, return early
    }

    const formData = new FormData(); // Create FormData object
    formData.append("customer_id", user_id); // Append customer_id with user_id from local storage

    setshowLoaderAdmin(true);
    await server_post_data(get_user_information, formData)
      .then((Response) => {
        if (Response.data.error) {
          handleError(Response.data.message);
        } else {
          if (Response.data.message.data.length > 0) {
            seteditStaffData(Response.data.message.data[0]);
          }
        }
        setshowLoaderAdmin(false);
      })
      .catch((error) => {
        handleError("network");
        setshowLoaderAdmin(false);
      });
  };
  const closeModel = () => {
    setshowNewPasswordModal(!showNewPasswordModal);
  };

  return (
    <section className="settingsLogin_section">
      {showLoaderAdmin && <Loader />}
      <div className="settingsLogin_headingWrapper">
        <h3>Login and Security</h3>
        <h6>Password</h6>
      </div>
      <div className="col-xl-4 col-lg-5 col-md-6 mb-3">
        <form id="addNewStaff">
          <div className="settingsLogin_inputs">
            <div className="password_image image_icon_position input_eye_covering_div flexRowCenter">
              <input
                type={showPassword ? "text" : "password"}
                name="new_password"
                placeholder="New Password"
                id="new_password"
                maxLength={40}
                minLength={3}
                className="trio_password trio_mandatory form-control input_field_custom new_password"
                onChange={(e) => setconfirm_password(e.target.value)}
                tabIndex={2} // Set tabindex for the password input
              />
              <span
                className="eye_icon"
                onClick={() => setShowPassword(!showPassword)}
                tabIndex={3} // Set tabindex for the eye icon
              >
                {showPassword ? (
                  <div className="eye_open">
                    <img src={hide_pass} alt="hidePass" id="showPass" />
                  </div>
                ) : (
                  <div className="eye_close">
                    <img src={showPass} alt="hidePass" id="hidePass" />
                  </div>
                )}
              </span>
              <span className="condition_error condition_error2"></span>
            </div>
            <div className="password_image image_icon_position input_eye_covering_div flexRowCenter">
              <input
                type={showPassword1 ? "text" : "password"}
                name="confirm_password"
                placeholder="Confirm Password"
                id="confirm_password"
                maxLength={40}
                minLength={3}
                className="trio_password trio_mandatory form-control input_field_custom confirm_new_password"
                onChange={(e) => setnew_password(e.target.value)}
                tabIndex={2} // Set tabindex for the password input
              />
              <span
                className="eye_icon"
                onClick={() => setShowPassword1(!showPassword1)}
                tabIndex={3} // Set tabindex for the eye icon
              >
                {showPassword1 ? (
                  <div className="eye_open">
                    <img src={hide_pass} alt="hidePass" id="showPass" />
                  </div>
                ) : (
                  <div className="eye_close">
                    <img src={showPass} alt="hidePass" id="hidePass" />
                  </div>
                )}
              </span>
              <span className="condition_error condition_error2"></span>
            </div>
            <p className="text-center text-danger" style={{ fontSize: "14px" }}>
              {showError2}
            </p>

            <button
              type="button"
              onClick={() => handlePasswordChange("addNewStaff")}
            >
              Save changes
            </button>
          </div>
        </form>
      </div>

      <Modal
        show={showNewPasswordModal}
        onHide={closeModel}
        className="fade"
        centered
        backdrop={true}
        keyboard={false}
      >
        <div className="Fullmodal-container">
          <Modal.Header
            className="login-modal-header"
            closeButton
          ></Modal.Header>

          <div className="body-container">
            <Modal.Body>
              <h3>Enter current password</h3>
              <p>We need it to save your changes.</p>
              <div>
                <input
                  type={showPassword1 ? "text" : "password"}
                  name="current_password"
                  placeholder="Enter Password"
                  id="current_password" // Ensure the ID matches the one used in the function
                  maxLength={40}
                  minLength={3}
                  className="trio_password trio_mandatory form-control input_field_custom"
                  tabIndex={2} // Set tabindex for the password input
                />
                <p
                  className="text-center text-danger"
                  style={{ fontSize: "14px" }}
                >
                  {showError}
                </p>
              </div>
            </Modal.Body>
            <Modal.Footer>
              <Button
                variant="primary"
                className="login-button modal-button rounded-pill"
                type="button"
                onClick={() =>
                  handleSaveChangesdynamic(fd_from, change_useremail_password)
                }
              >
                Change password
              </Button>
            </Modal.Footer>
          </div>
        </div>
      </Modal>
    </section>
  );
};

export default LoginSec;
