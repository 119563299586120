import React from "react";
import "./Css/TermsandConditions.css";
import "./Css/PrivacyPolicy.css";
import Footer from "./Footer";
import Header from "./Header";
const TermsandConditions = () => {
  return (
    <div className="howitworks_wrapper">
      <Header />
      <div className="headerSection_howitWorks col-lg-12">
        <div className="terms_imageContainer">
          <div className="center_text_wrapper">
            <h3>Terms and Conditions of Use</h3>
          </div>
        </div>
      </div>
      <div className="container privacy_policy_headingContainer">
        <h5>
          This agreement is between you (called "User" or "you") and LogoAcademy
          (referred to as "LogoAcademy" or "we"). We give you access to a
          platform that lets you make and handle certain products (explained
          below). We offer these services through different designs, help, and
          information (all together called "Services") on our website
          <span style={{ marginRight: "4px" }}>
            <a href="https://logoacademy.com"> https://logoacademy.com</a>
          </span>
          or related online places (called "Site") or directly from LogoAcademy
          and its people. When you put in any information, start making a
          design, or use any part of our Site, you promise to follow our rules,
          privacy policy, and any other instructions on the Site. You must agree
          with all these rules to use our Services. If you have questions about
          this agreement, please ask us.
        </h5>
        <div className="termsAndConditions_points privacyPolicy_points">
          <ul>
            <li>
              1.Our Logos and Other Designs
              <ul>
                <li>
                  <b> 1.1.Platform Purpose</b> – LogoAcademy is an online
                  service that helps you make and handle different design items.
                  You can use LogoAcademy to make and change your own logo and
                  other things with your brand on them, like "branding
                  packages", "logos", "brand rules", "letters", and "business
                  cards" (we call these "End Products" or "ByProducts").
                </li>
                <li>
                  <b> 1.2. Paid Designs</b> If you want to download or use your
                  End Products from LogoAcademy outside of the website, for
                  business or personal reasons, you need to pay the Fee shown on
                  the website. You can't use any of LogoAcademy's End Products
                  outside of the website for business or personal reasons
                  without paying the right Fees first. This applies to both
                  using the End Products on a computer or in real life. Check
                  Section ‎4 below for more details.
                </li>
                <li>
                  <b> 1.3. Free Designs</b> - During the design process,
                  LogoAcademy will show the user various designs and ideas.
                  However, the user cannot use or copy any of these designs
                  unless they pay for the final version. The final design will
                  be displayed and can be downloaded from the user's account.
                  Throughout the process, LogoAcademy will use certain symbols,
                  colours, fonts, and other design elements (called "Design
                  Resources") that might be used to create the user's final
                  product. You cannot use these Design Resources separately.
                </li>
                <li>
                  <b> 1.4.Third-Party Design Resources</b>- You can use the End
                  Products you bought outside of the Site, whether it's for
                  business or personal use. Before you make and use any End
                  Product, LogoAcademy strongly suggests you check carefully to
                  ensure the Design Resources you use don't have any issues with
                  ownership or rights from other parties. LogoAcademy sometimes
                  uses symbols from The Noun Project, which gets symbols from
                  other contributors. Using these symbols is done at your own
                  risk. You must follow all copyright and trademark rules, and
                  you can't use, copy, change, translate, share, broadcast,
                  send, distribute, perform, upload, show, license, sub-license,
                  rent, lend, give away, sell, or transfer any part of the
                  Design Resources that you don't own for any reasons: (i) You
                  can't use the stuff without the owners' permission in writing,
                  and (ii) you can't break any rules that protect other people's
                  rights.
                  <span style={{ color: "red" }}>
                    You know that some of the fonts and symbols we used in
                    making your design were borrowed from other companies. We're
                    not responsible for any problems with the design materials,
                    like if something gets lost or damaged because of them. You
                    have to check everything and take the risk of using the
                    design materials, including any rights that other people
                    might have on them.
                  </span>
                </li>
                <li>
                  <b>1.5 Information </b>- When you use the LogoAcademy website,
                  you might be asked to provide information or answer questions
                  to help create your final designs (called the "Design
                  Process"). LogoAcademy will use this information according to
                  its privacy policy, which you can find at
                  <a href="https://logoacademy.com/privacy">
                    {" "}
                    https://logoacademy.com/privacy
                  </a>
                </li>
                <li>
                  <b> 1.6 Use of LogoAcademy Branding and Design</b> - All
                  designs, trademarks, logos, slogans, and taglines that you see
                  during the Design Process belong to LogoAcademy. Unless
                  specifically allowed by LogoAcademy, you cannot use any of
                  these trademarks, logos, slogans, or taglines. Using them for
                  any purpose without LogoAcademy's permission or the permission
                  of their licensors is not allowed. LogoAcademy keeps all
                  rights that are not specifically given to you.
                </li>
                <li>
                  <b> 1.7 Adjust and Re-download Logo</b> - If you pay for a
                  logo, you have 24 hours to fix any mistakes made during its
                  creation on the website. You can recreate and re-download the
                  paid logo under the terms you bought. After this 24-hour
                  period, you can make 3 free changes to the logo by reaching
                  out to LogoAcademy's customer support. You can download the
                  paid logo anytime from the website, but LogoAcademy doesn't
                  save any changes made to it. You can only download the logo as
                  it looks on the website.
                </li>
              </ul>
            </li>
            <li>
              2. Accessing LogoAcademy Account
              <ul>
                <li>
                  <b> 2.1. Account Login</b>- When you start designing, we'll
                  give you login details for a private account on our website
                  (the "Account"). This lets you view your designs on one
                  computer at a time through our website.
                </li>
                <li>
                  <b> 2.2. Account Details</b>- You promise to give us complete
                  and correct information when you set up your Account and to
                  keep this information up-to-date. If you don't, your Account
                  might be cancelled, and you could lose any designs you've
                  already bought or won't be able to use future services. Also,
                  you agree (a) not to share your login with anyone who isn't
                  working for you, and (b) to make sure everyone who uses the
                  login knows about and has agreed to follow the rules of this
                  agreement.
                </li>
                <li>
                  <b>2.3. Product Information</b>- During the design process,
                  you will supply information needed to make your final product,
                  and you are legally responsible for all the information used.
                  LogoAcademy does not take responsibility for the content you
                  provide, whether it's for the final product or your account.
                  You are fully responsible for the correctness and suitability
                  of all data and content in your final products, logos, and
                  brands; this includes names, slogans, and details. LogoAcademy
                  will not be held accountable for any incorrect information or
                  potential harm caused by these inaccuracies. You also
                  understand that Logo Academy might not keep copies of these
                  design files and can refuse to change information at any time.
                </li>
                <li>
                  <b> 2.4 Hosting</b>- You permit Logo Academy to store the
                  designs you make on their platform, without charging you any
                  fees and without limiting your rights. You promise that you
                  have the authority to allow us to do this. You need to be
                  aware that you might lose or not be able to get back any
                  content or final products you create using our services. It's
                  a good idea to save copies of your final products often and
                  safely.
                </li>
              </ul>
            </li>
            <li>
              3. User Responsibilities
              <ul>
                <li>
                  <b>3. 1 Legal Use</b>– You are fully responsible for
                  everything you do on our website, including any designs you
                  create or share using LogoAcademy. You must use our services
                  legally and make sure you follow all the laws in your area,
                  like those about copyright, intellectual property, trademarks,
                  and design patents. LogoAcademy decides if someone breaks
                  these rules. If someone does, we might keep track of their
                  information and might stop them from using our services or
                  having an account. If we think your account is being used
                  illegally, we can shut it down right away and even report it
                  to law enforcement.
                </li>
                <li>
                  <b> 3.2. General</b>Also, you agree to all of these:
                </li>
                <ol type="a">
                  <li>
                    <b>A.</b> . You say that you are 18 years old or older and
                    promise not to use LogoAcademy Service or the things you
                    make with it for anything illegal or to do illegal things,
                    like cheating, stealing, hiding money illegally, or
                    pretending to be someone else.
                  </li>
                  <li>
                    <b>B.</b> You won't use machines to use the service and you
                    won't use the service if you live in a country that the U.S.
                    doesn't allow things to, or if your name is on a special
                    list by the U.S. Treasury Department.
                  </li>
                  <li>
                    <b>C.</b>You won't say or make it seem like LogoAcademy
                    supports you or is connected to you without getting a clear
                    written okay from us.
                  </li>
                  <li>
                    <b>D.</b> You must use the Services or the Account only
                    through the interfaces provided by LogoAcademy. Do not try
                    to bypass or break our security measures. Also, do not take
                    any actions that could disrupt the proper functioning of
                    LogoAcademy or put too much strain on our systems.
                  </li>
                  <li>
                    <b>E.</b>. LogoAcademy is not responsible for any kind of
                    loss or damage that may happen directly or indirectly from
                    using or clicking on links to third-party websites on our
                    Site.
                  </li>
                  <li>
                    <b>F.</b> Do not use LogoAcademy to create products that
                    are: pornographic, sexually explicit, violent, likely to
                    cause harm, or could be seen as defamatory or libelous.
                  </li>
                </ol>
              </ul>
            </li>
            <li>
              4. Fees and Taxes
              <ul>
                <li>
                  <b>4.1. . General </b> Some LogoAcademy services might cost
                  money, either as a one-time payment or a regular subscription,
                  as decided by LogoAcademy and clearly mentioned with those
                  services (we call these "Fees" or "Paid Tools"). If you want
                  to use these Paid Tools or use any of LogoAcademy's products
                  outside of the website, for business or personal reasons, you
                  need to pay the required fees upfront. If you buy any services
                  or products that cost money, you agree to let LogoAcademy or
                  our partners keep and update your payment card details. You
                  also agree (a) that we can charge your card (b) for the stated
                  Fee, (c) for any other Fees for services you buy, and any
                  related taxes for using the services, and (d) to cover our
                  costs and extra charges for any late payments.
                </li>
                <li>
                  <b> 4.2.Fee Changes </b>– You know that new services or
                  products that might be added later could cost extra. You also
                  agree that LogoAcademy can change its prices whenever it
                  wants, as long as it tells you if these changes affect your
                  current subscription.
                  <span style={{ color: "red" }}>
                    If you get a discount or a special deal on a subscription
                    fee, LogoAcademy can automatically renew your subscription
                    without telling you again, and you'll have to pay the full
                    price next time.
                  </span>
                </li>
                <li>
                  <b>4.3 One-Time Fee and Refunds</b>– The one-time fee for the
                  final product is not refundable. If a user thinks they were
                  charged by mistake, they can send a written message to our
                  customer service and ask to cancel the fee. If the user
                  qualifies, customer service can cancel the fee within 7 days
                  of the payment date, but only if the final products related to
                  the fee haven't been downloaded or used outside of the
                  LogoAcademy website, either for business or personal use (this
                  7-day period is called the "One Time Fee Refund Period"). We
                  won't cancel fees after this period or if the final product
                  has been downloaded or used outside of the LogoAcademy
                  website. Whether a user can cancel a fee will be decided by
                  our customer service team based on several factors, including
                  breaking our Terms of Service and misusing the LogoAcademy
                  platform.
                </li>
                <li>
                  <b> 4.4 Subscription Fee and Refunds</b>– If you're not happy
                  with any LogoAcademy service that you pay a monthly or yearly
                  fee for, you can cancel your subscription and ask for a refund
                  in writing within 7 days after paying the first fee (we call
                  this the "Subscription Refund Period"). You can only get a
                  refund if you haven't downloaded or used any design files made
                  with the service outside of the platform. If you live in a
                  place that has different refund rules, we'll follow those
                  rules based on the laws there. If you tell our customer
                  support team you want a refund during the Subscription Refund
                  Period, we'll give you back the money you paid and stop your
                  service. Once the Subscription Refund Period is over, the fees
                  you paid can't be refunded or cancelled.
                  <br />
                  Furthermore, if we discover that any products related to the
                  Fee have been downloaded or used outside of our website,
                  either for business or personal reasons and/or if the
                  cancellation notice was given with dishonest intentions or to
                  avoid paying for services that were actually used, we have the
                  right to charge the user who gave such notice for any
                  LogoAcademy services they actually received, as allowed by
                  law.
                </li>
                <li>
                  <b>4.5 Taxes and Currencies</b>– All fees on the LogoAcademy
                  Platform are in U.S. Dollars unless stated differently in
                  writing by LogoAcademy. You are completely responsible for all
                  taxes and fees related to our fees and services, including any
                  sales tax from buying or selling Services and/or End Products.
                  When you buy Services and/or End Products, it's up to you to
                  figure out if sales taxes apply and to pay the right amount to
                  the correct authority. LogoAcademy won't be responsible for
                  any taxes or fees connected to the Services and/or End
                  Products. Any tools showing estimated taxes due are just for
                  showing an example.
                </li>
                <li>
                  <b>4.6 Subscriptions Renewal</b>– To ensure that your
                  LogoAcademy Subscription Services don't stop unexpectedly
                  because a subscription period ends, LogoAcademy will attempt
                  to renew these services automatically before they expire. The
                  renewal will last as long as the original subscription or the
                  updated renewal period we have for you, either monthly or
                  yearly. We will use the payment method you've saved with us to
                  charge the necessary fees for this renewal. To prevent any
                  service disruptions, LogoAcademy may also charge you for the
                  next renewal period up to one week before it starts, though
                  this is not mandatory.If LogoAcademy is unable to collect the
                  fees you owe, we may, but are not required to, attempt to
                  collect them later, or we may block or cancel your account
                  without further notice. It's your responsibility to make sure
                  the LogoAcademy services you use are renewed successfully. You
                  are solely responsible for any interruption of services you
                  previously purchased, whether due to a cancellation, failure
                  to collect fees, or because the services are not part of our
                  automatic renewal feature. You agree not to hold LogoAcademy
                  responsible for any interruption of services or third-party
                  services related to LogoAcademy, for any reason.
                </li>

                <li>
                  <b>4.7 Chargebacks</b>- If a User disputes the payment for the
                  fees (called a "Chargeback"), this will be seen as not keeping
                  their payment promise. As a result, their access to
                  LogoAcademy services might be stopped or ended. LogoAcademy
                  can also block the User's account, and they won't be able to
                  buy or use it again.
                  <span style={{ color: "red" }}>
                    All the files and designs in the account will be affected
                    too. The User won't get their account back until they pay
                    all the fees they owe. If a User has questions or issues
                    about a payment made by LogoAcademy, they should talk to
                    LogoAcademy's customer service first, instead of starting a
                    Chargeback. LogoAcademy can argue against any Chargeback and
                    show the credit company, bank, or financial institution
                    proof that the User should pay the fees.
                  </span>
                </li>
              </ul>
            </li>
            <li>
              5. Termination Policy
              <ul>
                <li>
                  <b>5.1. Cancellation by LogoAcademy </b>– These Terms Of
                  Service will stay in effect as long as you use LogoAcademy’s
                  Platform or our Services or End Products. We can stop you from
                  using our Services (including your Account and End Products)
                  whenever we want, especially if you break this Agreement. If
                  we do this, your Account and all related information and End
                  Products will be deleted from our active databases.
                </li>
                <li>
                  <b>5.2 Cancellation by User </b>– You can choose to stop using
                  our Services and End Products and ask to cancel your
                  Subscription Services whenever you want. The cancellation will
                  start as soon as you ask for it. The cancellation of the
                  Subscription Fee will take effect at the next time the
                  subscription is set to renew. Your subscription will only end
                  when the time you've already paid for is over. Remember, it
                  takes a little while for LogoAcademy to process cancellations.
                  To make sure you don't get charged for the next period, you
                  need to ask for cancellation at least ten (10) weekdays before
                  your current subscription ends.
                </li>
              </ul>
            </li>
            <li>
              6.Other
              <ul>
                <li>
                  <b>6.1 Disputes </b>– If there are any disagreements about
                  what this agreement means or how it was made, and if any
                  problems come up because of it, these will be handled under
                  the laws of the USA that apply to it. Any legal actions about
                  these issues must be taken to the courts in San Francisco,
                  California, and you agree to let these courts handle your
                  case.
                </li>
                <li>
                  <b>6.2 Indemnification </b>– You promise to protect, defend,
                  and not cause trouble for LogoAcademy and all the people who
                  work there, like the bosses, workers, helpers, and anyone else
                  connected to them, from any problems, lawsuits, or costs that
                  might happen because of how you use LogoAcademy's services,
                  products, designs, or website. This includes any issues with
                  your account, logo, branding kit, or design tools.
                </li>
                <li>
                  <b>6.3 Updates to this agreement </b>– We may change parts of
                  this agreement sometimes. When we do, we'll let you know on
                  our website or by email to our registered users. Because these
                  notices can be missed, we suggest you check this page often
                  for updates. Using our services after any changes to this
                  agreement means you accept those changes.
                </li>
                <li>
                  <b>6.4 Privacy </b>– Check out our Privacy Policy if you have
                  any questions about your privacy.
                </li>
                <li>
                  <b>6.5 Other stuff </b>– If we don't make a rule a big deal,
                  it doesn't mean we won't later. If part of this agreement
                  can't be enforced, the rest of it will still work.
                </li>
              </ul>
            </li>
            <li>
              7. Limitation of Liability and Warranty Disclaimer
              <ul>
                <li>
                  Our website, design resources, finished products, services,
                  materials, and any other content or product are offered "as
                  they are," "available," "with all flaws," and without any kind
                  of warranty, either stated or implied. You agree that using
                  the LogoAcademy website and services is done at your own risk.
                  To the maximum extent allowed by law, LogoAcademy, its
                  leaders, managers, workers, and representatives do not
                  guarantee anything, either clearly stated or implied, about
                  the website and your use of it. LogoAcademy does not promise
                  or show that the content or designs are accurate. If there are
                  errors, mistakes, or wrong information in the content, or if
                  someone gets hurt or their property is damaged in any way from
                  using our services and website, LogoAcademy, its leaders,
                  managers, workers, or representatives will not be held
                  responsible, either directly or indirectly.LogoAcademy is not
                  responsible for any harm, whether it's direct, indirect, or
                  any other type, that happens because someone illegally gets
                  into our secure servers and takes personal information or
                  other data stored there.
                  <span style={{ color: "red" }}>
                    We won't be blamed if our site stops working or if someone
                    sends harmful things like bugs, viruses, or Trojan horses
                    through our site. We also won't be responsible for mistakes
                    in content or for any problems caused by using content from
                    our platform. This rule applies as much as the law allows in
                    the area where you are.
                  </span>
                </li>
              </ul>
            </li>
            <li>
              8. Customer Support
              <ul>
                <li>
                  Please feel free to contact our customer care with any
                  questions you may have about these terms of service.
                </li>
              </ul>
            </li>
          </ul>
        </div>
      </div>
      <Footer />
    </div>
  );
};

export default TermsandConditions;
