import "../Components/Css/ViewInvitationCard.css";
import madeforyouImg from "../Assets/Images/made_for_you.svg";
import allTemplates from "../Assets/Images/all_templates.svg";
import pen from "../Assets/Images/penIcon.svg";
import createBlank from "../Assets/Images/create_blank.svg";
import Header from "./Header";
import ImagesModal from "./ImagesModal.js";
import React, { useState, useEffect, useRef } from "react";
import { fabric } from "fabric";
import { useNavigate } from "react-router-dom";
import { Link } from "react-router-dom";
import axios from "axios";
import { retrieveData, storeData } from "../LocalConnection/LocalConnection.js";
import { Modal, Button, Form, Alert } from "react-bootstrap";
import {
  server_post_data,
  get_user_templete,
  serve_image,
  save_update_user_templates,
  save_update_user_templates_dublicate2
} from "../ServiceConnection/serviceconnection.js";
import { handleError, handleLinkClick } from "../CommonJquery/CommonJquery";
import Loader from "./Loader.js";
import ShowLogoLoader from "./ShowLogoLoader.js";
let templtarray = {};
let backgrondclr = {};
let iconselect = [];
let canvas_upts;
let letcompanyname = "";
let letusername = "";
const ViewInvitationCard = () => {
  let template_type = "Invitation";
  const [showLoaderAdmin, setshowLoaderAdmin] = useState(false);
  const [businessCards, setbusinessCards] = useState([]);
  const [businessCards2, setbusinessCards2] = useState([]);
  const [selectModal, setSelectmodal] = useState(false);
  const [colWidth, setColWidth] = useState(400);
  const [colHeight, setHeight] = useState(300);
  const [isLoaderVisible, setIsLoaderVisible] = useState(false);
  const [navigateTo, setNavigateTo] = useState("");
  const navigate = useNavigate();
  const [lastInsertedIds, setLastInsertedIds] = useState({});
  
  const columnRef = useRef(null);
  {
    /* ref added by Rohan*/
  }

  {
    /* Height Width added by Rohan*/
  }
  const updateColumnDimensions = () => {
    if (columnRef.current) {
      const columnWidth = columnRef.current.clientWidth;
      const columnHeight = columnRef.current.clientHeight;

      setColWidth(284.3);
      setHeight(columnHeight);
    }
  };

  useEffect(() => {
    updateColumnDimensions();
    master_data_get();
  }, []);

  useEffect(() => {
    if (navigateTo) {
      navigate(navigateTo);
      // Reset navigation state after navigating
      setNavigateTo("");
    } else {
      console.log("No navigation URL set.");
    }
  }, [navigateTo]);

  const master_data_get = async () => {
    setshowLoaderAdmin(true);
    let fd = new FormData();
    fd.append("template_type", template_type);
    await server_post_data(get_user_templete, fd)
      .then((Response) => {
        console.log(Response);
        if (Response.data.error) {
          handleError(Response.data.message);
        } else {
          console.log(Response.data);
          if (Response.data.message.data.length > 0) {
            setbusinessCards(Response.data.message.data);
            //console.log(Response.data);
          }

          if (Response.data.message.brandkitlist.length > 0) {
            letcompanyname = Response.data.message.brandkitlist[0].company_name;
            letusername =
              Response.data.message.brandkitlist[0].personal_data_name;
          }
          setbusinessCards2(Response.data.message.data2);
          canvas_upts = Response.data.message.template_canvas;
        }
        setshowLoaderAdmin(false);
      })
      .catch((error) => {
        console.log(error);
        handleError("network");
        setshowLoaderAdmin(false);
      });
  };

  const handleMOdalshow = () => {
    setSelectmodal(true);
  };
  const handleClose = () => {
    setSelectmodal(false);
  };

  const convertCanvasToJPG = (index) => {
    return new Promise((resolve, reject) => {
      try {
        // Convert the canvas to a JPG data URL
        // console.log(canvasRefs);
        // console.log(canvasRefs.current[index]);
        // console.log(index);
        // return
        const canvas = canvasRefs.current[index];

        const jpgDataURL = canvas.toDataURL("image/jpeg", 1.0);
        resolve(jpgDataURL);
      } catch (error) {
        reject(error);
      }
    });
  };

  const base64ToBlob = (base64, mime) => {
    // Check if base64 string is correct
    if (!base64 || !mime) {
      throw new Error("Invalid Base64 string or MIME type");
    }

    // Handle Data URL format
    const byteString = atob(base64.split(",")[1]);
    const mimeString = base64.split(",")[0].split(":")[1].split(";")[0];

    // Create a buffer and Uint8Array to handle byte data
    const arrayBuffer = new ArrayBuffer(byteString.length);
    const uintArray = new Uint8Array(arrayBuffer);

    for (let i = 0; i < byteString.length; i++) {
      uintArray[i] = byteString.charCodeAt(i);
    }

    return new Blob([arrayBuffer], { type: mime || mimeString });
  };

  const replaceColorInSVG = (svgData, oldColor, newColor) => {
    // Replace the old color with the new color where fill exists
    let modifiedSvgData = svgData.replace(
      new RegExp(`fill\\s*=\\s*["']${oldColor}["']`, "gi"),
      `fill="${newColor}"`
    );

    // Add fill attribute where it's missing
    modifiedSvgData = modifiedSvgData.replace(
      /(<(svg)[^>]*)(>)/gi,
      (match, p1, p2, p3) => {
        if (!/fill\s*=/.test(match)) {
          // If no fill attribute is present, add it
          return `${p1} fill="${newColor}"${p3}`;
        }
        return match;
      }
    );

    return modifiedSvgData;
  };

  const getFillColorFromSVG = (svgData) => {
    // Create a DOM parser to parse the SVG string
    const parser = new DOMParser();
    const doc = parser.parseFromString(svgData, "image/svg+xml");

    // Get the <svg> element
    const svgElement = doc.querySelector("svg");
    if (!svgElement.hasAttribute("fill")) {
      // Set the default fill color if not present
      svgElement.setAttribute("fill", "#000000");
    }
    // Return the fill color from the <svg> element
    return svgElement ? svgElement.getAttribute("fill") : null;
  };
  const [loadingStates, setLoadingStates] = useState([]);
  let canvasRefs = useRef([]);
  const createCanvas = async (
    ref,
    logoData,
    index_act,
    width_height,
    allResponses,
    different_color_shade,
    change_data,
    color_index,
    PriorityWiseColor,
    change_icon_id,
    isshapbcg,
    onsave = 0
  ) => {
    let bckgrndclr;
    let combinationarray;
    let PriorityWiseColorArray;

    //bckgrndclr = combinationarray.background;
    if(logoData.background==''){
      bckgrndclr = "white";
      }else{
        bckgrndclr = logoData.background;
      }

    const templatejson = logoData;
    // console.log(colWidth);
    let newCanvasWidth = colWidth; // New canvas width
    let newCanvasHeight = colHeight; // New canvas height

    // let newCanvasWidth;
    // let newCanvasHeight;

    // if (onsave == 0) {
    //   newCanvasWidth = 238; // New canvas width by mk
    //   newCanvasHeight = 300; // New canvas height by mk
    // } else {
    //   //save time
    //   newCanvasWidth = 400; // New canvas width by mk
    //   newCanvasHeight = 400; // New canvas height by mk
    // }

    // Calculate scale factor based on the new canvas size
    const originalWidth = 480;
    const originalHeight = 672;
    const scaleFactor = Math.min(
      newCanvasWidth / originalWidth,
      newCanvasHeight / originalHeight
    );

    // const backgroundHex = selectedPalette.background;
    // const textHex = selectedPalette.text;
    // const elementHex = selectedPalette.element;
    const canvas = new fabric.Canvas(ref, {
      width: newCanvasWidth,
      height: newCanvasHeight,
    });

    canvas.backgroundColor = bckgrndclr;

    if (!Array.isArray(templtarray[index_act])) {
      templtarray[index_act] = []; // Initialize it as an array if it doesn't exist
      backgrondclr[index_act] = [];
    } else {
      templtarray[index_act].length = 0; //blank the array
      backgrondclr[index_act].length = 0;
    }

    let newCanvasObject = JSON.parse(canvas_upts);
    let backgroundColor = newCanvasObject.background;
    /********** Text code Start ************/

    if (templatejson.objects) {
      //templatejson.objects.forEach(async (objData) => {

      const renderNextObject = async (index) => {
        if (index >= logoData.objects.length) {
          //resolve();
          return;
        }
        const objData = templatejson.objects[index];
        let newcolor_code = "";
        if (objData.type === "image" && objData.metadata) {
          console.log(objData.metadata.role);

          if (objData.metadata.role === "Shapes") {
            ///if (objData.MkIconId == isshapbcg) {
            let svgDataUrl = objData.src;
            if (!svgDataUrl) {
              svgDataUrl = objData.getSrc();
            }
            newcolor_code = backgroundColor; // Ensure colordata and icon are properly defined
            if (newcolor_code) {
              await fetch(svgDataUrl)
                .then((response) => response.text())
                .then((svgData) => {
                  const fillColor = getFillColorFromSVG(svgData); // Ensure this function is defined
                  console.log(fillColor);
                  const modifiedSvgData = replaceColorInSVG(
                    svgData,
                    fillColor,
                    newcolor_code
                  ); // Ensure this function is defined
                  objData.src =
                    "data:image/svg+xml;base64," + btoa(modifiedSvgData);
                })
                .catch((error) => {
                  console.error("Error fetching SVG data:", error);
                });
            }
          }

          if (objData.metadata.role === "replace") {
            console.log("inside replace");
          }
        }

        const scaledObject = {
          ...objData,
          scaleX: (objData.scaleX || 1) * scaleFactor,
          scaleY: (objData.scaleY || 1) * scaleFactor,
          left: (objData.left || 0) * scaleFactor,
          top: (objData.top || 0) * scaleFactor,
        };

        fabric.util.enlivenObjects([scaledObject], (objects) => {
          objects.forEach((obj, lop) => {
            obj.selectable = false;
            if (obj.type === "i-text" && obj.metadata) {
              if (obj.MkIconId === allResponses.BrandkitCompanyNameId) {
                if (letcompanyname) {
                  obj.set("text", letcompanyname);
                }
              }
              if (obj.MkIconId === allResponses.BrandkitUserNameId) {
                if (letusername) {
                  obj.set("text", letusername);
                }
              }

              const customWidth = obj.customWidth || obj.width;

              let textWidth = obj.width,
                textHeight = obj.height,
                maxWidth = customWidth, // set the maximum width
                maxHeight = 100, // set the maximum height
                fontSize = obj.fontSize;

              // Calculate the maximum font size that fits the text within the textbox
              while (textWidth > maxWidth || textHeight > maxHeight) {
                fontSize--;
                obj.set("fontSize", fontSize);
                //console.log(fontSize);
                textWidth = obj.width;
                textHeight = obj.height;
              }

              obj.set({
                textAlign: obj.textAlign,
              });

              obj.set({
                width: customWidth,
              });
            }
            if (obj.type === "image" && obj.metadata) {
              if (obj.metadata.role == "replace") {
                obj.set({
                  crossOrigin: "anonymous",
                });
                let objectToReplace = null;

                objectToReplace = obj;
                if (objectToReplace) {
                  let companyLogoCanvasData = newCanvasObject;
                  let placeholderLeft = obj.left;
                  let placeholderTop = obj.top;
                  const placeholderScaleX = obj.scaleX || 1;
                  const placeholderScaleY = obj.scaleY || 1;
                  let placeholderWidth = obj.width;
                  let placeholderHeight = obj.height;
                  canvas.remove(obj);
                  // Example: Yellow

                  // let minLeft = Infinity, minTop = Infinity, maxRight = -Infinity, maxBottom = -Infinity;

                  // companyLogoCanvasData.objects.forEach(function(logoObj) {
                  //     const objRight = logoObj.left + (logoObj.width * logoObj.scaleX);
                  //     const objBottom = logoObj.top + (logoObj.height * logoObj.scaleY);

                  //     minLeft = Math.min(minLeft, logoObj.left);
                  //     minTop = Math.min(minTop, logoObj.top);
                  //     maxRight = Math.max(maxRight, objRight);
                  //     maxBottom = Math.max(maxBottom, objBottom);
                  // });

                  // // Create a background rectangle
                  // let backgroundRect = new fabric.Rect({
                  //   left: placeholderLeft + (minLeft * placeholderScaleX),
                  //   top: placeholderTop + (minTop * placeholderScaleY),
                  //   width: (maxRight - minLeft) * placeholderScaleX,
                  //   height: (maxBottom - minTop) * placeholderScaleY,
                  //   fill: backgroundColor,
                  //   selectable: false, // So it won't interfere with the other objects
                  //   evented: false // Prevent interaction with the background
                  // });

                  // // Add the background rectangle before adding the company logo
                  // canvas.add(backgroundRect);

                  companyLogoCanvasData.objects.forEach(function (logoObj) {
                    let newObject;
                    const newLeft =
                      placeholderLeft + logoObj.left * placeholderScaleX;
                    const newTop =
                      placeholderTop + logoObj.top * placeholderScaleY;

                    // Check if the object is an image
                    if (logoObj.type === "image") {
                      ///const proxyUrl = 'https://cors-anywhere.herokuapp.com/';  // Use a reliable proxy or your own if possible
                      const proxyUrl = ""; // Use a reliable proxy or your own if possible
                      const proxiedImageUrl = proxyUrl + logoObj.src; // Prepend the proxy to the image URL
                      console.log(proxiedImageUrl);
                      fabric.Image.fromURL(
                        proxiedImageUrl,
                        function (img) {
                          img.set({
                            left: newLeft,
                            top: newTop,
                            scaleX: placeholderScaleX * logoObj.scaleX,
                            scaleY: placeholderScaleY * logoObj.scaleY,
                            angle: logoObj.angle,
                            opacity: logoObj.opacity,
                            crossOrigin: "anonymous", // Ensure this is set
                          });
                          canvas.add(img);
                          canvas.renderAll();
                          if (onsave != 0) {
                            templtarray[index_act].push(img);
                            backgrondclr[index_act].push(
                              canvas.backgroundColor
                            );
                          }
                        },
                        { crossOrigin: "anonymous" }
                      );
                    }

                    // Check if the object is text (i-text or text)
                    else if (
                      logoObj.type === "i-text" ||
                      logoObj.type === "text"
                    ) {
                      newObject = new fabric.IText(logoObj.text, {
                        left: newLeft,
                        top: newTop,
                        scaleX: placeholderScaleX * logoObj.scaleX, // Adjust scale according to placeholder
                        scaleY: placeholderScaleY * logoObj.scaleY,
                        fontFamily: logoObj.fontFamily,
                        fontSize: logoObj.fontSize,
                        fill: logoObj.fill,
                        textAlign: logoObj.textAlign,
                        opacity: logoObj.opacity,
                      });

                      newObject.set({
                        width: logoObj.customWidth,
                      });
                      console.log(logoObj.textAlign);
                      canvas.add(newObject);
                      if (onsave != 0) {
                        templtarray[index_act].push(newObject);
                        backgrondclr[index_act].push(canvas.backgroundColor);
                      }
                    }
                  });

                  canvas.renderAll();
                }
              } else {
                canvas.add(obj);
                if (onsave != 0) {
                  templtarray[index_act].push(objects[0]);
                  backgrondclr[index_act].push(canvas.backgroundColor);
                }
              }
            } else {
              canvas.add(obj);
              if (onsave != 0) {
                templtarray[index_act].push(objects[0]);
                backgrondclr[index_act].push(canvas.backgroundColor);
              }
            }
          });
          canvas.requestRenderAll();

          renderNextObject(index + 1);
        });

        //});
      };

      renderNextObject(0);
    }
    //console.log(canvas);
    canvas.requestRenderAll();
    ///return canvas;
    /********** Text code close ************/
  };
  
  const handleDuplicateOpenInEditor = async (primary_id) => {
    let fd_from = new FormData();
    const user_id = retrieveData("user_id");
    fd_from.append("customer_id", user_id);
    fd_from.append("main_id", "1");
    fd_from.append("primary_id", primary_id);
    fd_from.append("template_type",'Letterhead');
    fd_from.append("width",colWidth);
    fd_from.append("height",colHeight);
     
    setshowLoaderAdmin(true);
    try {
      const Response = await axios.post(
        save_update_user_templates_dublicate2,
        fd_from,
        {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        }
      );
      if (Response.status === 200) {
        //await server_post_data(save_update_user_templates, fd_from).then((Response) => {
        if (Response.data.error) {
          handleError(Response.data.message);
        } else {
          //handleSuccess(Response.data.message);
          navigate(`/canvasEdit/invitationCard/${Response.data.last_inserted_id}`);
        }
      }
    } catch (error) {
      handleError("network");
    }
  };

  const handleCanvasRef = (
    ref,
    logoData,
    index,
    colWidth,
    allResponses,
    different_color_shade,
    intrvl
  ) => {
    if (ref && !canvasRefs.current[index]) {
      // Set initial loader state
      setLoadingStates((prev) => {
        const newStates = [...prev];
        newStates[index] = true;
        return newStates;
      });

      canvasRefs.current[index] = ref;
      ///console.log(logoData);
      intrvl = 0;
      setTimeout(() => {
        try {
          createCanvas(
            ref,
            JSON.parse(logoData),
            index,
            colWidth,
            allResponses,
            different_color_shade,
            logoData.change_data,
            logoData.color_index,
            logoData.PriorityWiseColor,
            logoData.change_icon_id,
            logoData.change_background_shape_id,
            "1"
          );

          // Keep loader for an extra 1 second after canvas has finished loading
          setTimeout(() => {
            setLoadingStates((prev) => {
              const newStates = [...prev];
              newStates[index] = false;
              return newStates;
            });
          }, 0); // 1 second delay after the canvas is loaded
        } catch (error) {
          console.error("Error creating canvas:", error);
        }
      }, 0);
    }
  };

  const handleEditClick = async (primary_id, index_act, nevigatn = 1) => {
    let indev = index_act;
    console.log(indev);
    const data = templtarray[indev].map((obj) => {
      let objData = obj.toObject();
      objData.flag = 0; // Append flag 0
      objData.color = obj.fill || "";
      objData.customWidth = obj.customWidth || obj.width;

      // Add mergedFonts to each object and include font source
      return objData;
    });
    //console.log(data);
    // console.log(backgrondclr[indev]);
    //return;
    const canvasData = {
      objects: data,
      background: backgrondclr[indev][0],
      width: colWidth,
      height: colHeight,
    };

    //return;
    const user_id = retrieveData("user_id");
    console.log(canvasData);
    console.log(user_id);

    //return;
    if (!user_id) {
      var event = new CustomEvent("customEvent");
      document.getElementById("login_check_jquery").dispatchEvent(event);
    } else {
      if (canvasData.objects.length > 0) {
        let fd_from = new FormData();
        fd_from.append("customer_id", user_id);
        fd_from.append("main_id", "0");
        fd_from.append("primary_id", primary_id);
        fd_from.append("template_type", template_type);
        //console.log(canvasData);
        //return;
        fd_from.append("template_canvas_data", JSON.stringify(canvasData));
        const canvasImageURL = await convertCanvasToJPG(index_act);
        const blob = base64ToBlob(canvasImageURL, "image/jpeg");
        fd_from.append("file", blob, "canvas-image.jpg");
        //setshowLoaderAdmin(true);
        try {
          const Response = await axios.post(
            save_update_user_templates,
            fd_from,
            {
              headers: {
                "Content-Type": "multipart/form-data", // Set the Content-Type header for form data
              },
            }
          );
          if (Response.status === 200) {
            //await server_post_data(save_update_user_templates, fd_from).then((Response) => {
            if (Response.data.error) {
              handleError(Response.data.message);
            } else {
              //handleSuccess(Response.data.message);
              if (nevigatn === 1) {
                // Delay navigation by 2 seconds
                setPreparingModalOpen(true);
                setTimeout(() => {
                  setIsLoaderVisible(false);
                  console.log(Response.data.last_inserted_id);

                  setNavigateTo(
                    `/canvasEdit/invitationCard/${Response.data.last_inserted_id}`
                  );
                }, 2000);
              }
              if (nevigatn == 2) {
                storeData("last_inserted_id", Response.data.last_inserted_id);
                setNavigateTo(`/pricing`);
                //storeData("last_inserted_id", Response.data.last_inserted_id);

                // Retrieve the active_package from local storage
                // const activePackage = localStorage.getItem("active_package");

                // if (activePackage === null) {
                //   setNavigateTo(`/pricing`);
                // } else {
                //   console.log(
                //     "Active package exists, not navigating to pricing."
                //   );
                // }
              } else {
                //wishlist logic

                setLastInsertedIds((prev) => ({
                  ...prev,
                  [index_act]: Response.data.last_inserted_id, // Store the last_inserted_id for this index
                }));
                //console.log(lastInsertedIds);
                return Response.data.last_inserted_id;
              }
            }
          }
        } catch (error) {
          handleError("network");
        }
        //setshowLoaderAdmin(false);
        // })
        // .catch((error) => {
        //   //setshowLoaderAdmin(false);
        //   handleError("network");
        // });
      } else {
        handleError("Please Add Creative");
      }
    }
  };

  const handleEditSaveCanvas = (primary_id, index) => {
    //setshowLoaderAdmin(true);
    //setIsLoaderVisible(true);
    handleEditClick(primary_id, index);
  };
  const [isPreparingModalOpen, setPreparingModalOpen] = useState(false);

  const prepareDesign = () => {
    setPreparingModalOpen(true);
  };
  const handleEditSaveCanvas2 = (primary_id) => {
    setPreparingModalOpen(true);
                setTimeout(() => {
                  setIsLoaderVisible(false);

                  setNavigateTo(
                    `/canvasEdit/invitationCard/${primary_id}`
                  );
                }, 2000);
  };
  return (
    <>
      {showLoaderAdmin && <Loader />}
      <Header Showheader={true} CheckLogin={true} />
      <div className="container">
        <div className="viewBusinesscard_section">
          <div className="businesscard_wrapper">
            <div className="brandkit_heading_links businessCard_heading_links">
              <span className="flexRow mb-1">
                {" "}
                <Link to="/home">Home</Link>
                <h6>/ Invitation Card</h6>
              </span>
              <div className="businessCard_heading_buttons invitationCard_heading_buttons ">
                <div className="brand_kit_button brand_kit_buttonnn invitationCard_brand_kit_buttonnn">
                  <img src={pen} alt="penicon" />
                  <Link
                    style={{
                      color: "#666666",
                      fontWeight: "500",
                      fontSize: "14px",
                      cursor: "pointer",
                      textDecoration: "none",
                    }}
                    to="/brandkit"
                  >
                    Brand Kit
                    <input
                      name="company_logo_new"
                      id="logoInput"
                      style={{ display: "none" }}
                    />
                  </Link>
                </div>
                <div
                  className="brand_kit_button brand_kit_buttonnn"
                  onClick={handleMOdalshow}
                >
                  <label
                    style={{
                      color: "#666666",
                      fontWeight: "500",
                      fontSize: "14px",
                      cursor: "pointer",
                    }}
                  >
                    Select Photo
                    <input
                      name="company_logo_new"
                      id="logoInput"
                      style={{ display: "none" }}
                    />
                  </label>
                </div>
              </div>
            </div>
            <div className="BussinEstxt">
              <p>Invitation Card</p>
            </div>
            <p className="businessCard_dimensions">91x114 mm</p>
            <div className="made_for_you_text_wrapper">
              <img src={madeforyouImg} alt="made for you Image" />
              <p>Made for you</p>
            </div>
            <div className="row">
              <div className="col-lg-3 col-md-4 col-sm-6 col-12 px-sm-1 px-2">
                {/* <Link
                  to={"/canvasEdit/" + template_type + "/0"}
                  target="_blank"
                >
                  <div
                    className="create_blank_div "
                    style={{ width: 200, height: 400 }}
                  >
                    <img src={createBlank} alt="create blank" />
                  </div>
                </Link> */}
                <div
                  className="create_blank_div letterheadBlackDiv "
                  ref={columnRef} 
                  onClick={() =>
                    handleEditSaveCanvas2(0)
                  }
                >
                  <img src={createBlank} alt="create blank" />
                </div>
              </div>
              {businessCards2.map((businessImage, index) => (
                <div
                  className="col-lg-3 col-md-4 col-sm-6 col-12 px-sm-2 px-2"
                  key={`Bcard-${index}`}
                >
                  {/* Height Width added by Rohan*/}
                  <div
                    className="businessCards_images_div mb-0"
                    style={{ width: colWidth, height: colHeight }}
                    onClick={() =>
                      handleEditSaveCanvas(businessImage.primary_id, index)
                    }
                  >
                    {/* <img
                    src={`https://api.logoacademy.co/assets/${businessImage.template_url}`}
                    alt="favorite_logos"
                  /> */}

                    <canvas
                      ref={(ref) =>
                        handleCanvasRef(
                          ref,
                          businessImage.template_canvas,
                          index,
                          0,
                          businessImage,
                          businessImage.different_color_shade,
                          0
                        )
                      }
                      className="adjustwidthcls"
                      style={{
                        display: loadingStates[index] ? "none" : "block",
                      }}
                    />
                  </div>
                </div>
              ))}
            </div>
            <div className="all_templates_pictures_wrapper py-3">
              <div className="made_for_you_text_wrapper">
                <img src={allTemplates} alt="made for you" />
                <p>All Templates</p>
              </div>
            </div>
            <div className="row">
              {businessCards.map((businessImage, index) => (
                <div
                  className="col-lg-3 col-md-4 col-sm-6 col-12 px-sm-1 p-2"
                  key={`Bcard-${index}`}
                >
                  <div
                    className="businessCards_images_div "
                    // onClick={() =>
                    //   handleLinkClick(
                    //     "/canvasEdit/" +
                    //       template_type +
                    //       "/" +
                    //       businessImage.primary_id
                    //   )
                    // }
                    onClick={() => {
                      handleDuplicateOpenInEditor(
                        businessImage.primary_id
                      );
                    }}
                  >
                    <img
                      src={`https://api.logoacademy.co/assets/${businessImage.template_url}`}
                      alt="favorite_logos"
                    />
                  </div>
                </div>
              ))}
            </div>
          </div>
        </div>

        <Modal
        centered
        className="modlePrepared"
        show={isPreparingModalOpen}
        onHide={() => setPreparingModalOpen(false)}
      >
        <Modal.Body>
          <div className="prePareDiv">
            <div className="LoaderPrepare">
              <ShowLogoLoader />
            </div>
            <p>Your design is being prepared. Please wait...</p>
          </div>
        </Modal.Body>
      </Modal>
        <ImagesModal open={selectModal} handleClose={handleClose} />
      </div>
    </>
  );
};

export default ViewInvitationCard;
