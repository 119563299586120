import React, { useEffect, useState, useRef } from "react";
import { jsPDF } from "jspdf";
import html2canvas from "html2canvas";
import successPaymentGif from "../Assets/Images/successPaymentGif.gif";
import "../Components/Css/Pricing.css";
import Header from "./Header.js";
import PaymentInvoice from "./PaymentInvoice.js";
import { handleError } from "../CommonJquery/CommonJquery.js";
import headerLogo from "../Assets/Images/headerLogo.png";
import {
  server_post_data,
  get_package_by_customer_id,
  get_package_by_id,
  get_customer_all_package,
} from "../ServiceConnection/serviceconnection.js";
import { storeData, retrieveData } from "../LocalConnection/LocalConnection.js";
import { Modal, Button } from "react-bootstrap";
import { Link, useParams } from "react-router-dom";
const CompletePayment = () => {
  const user_id = retrieveData("user_id");

  const payment_transaction_id = retrieveData("client_secret");
  const [SubscriptDeatails, SetSubscriptDeatails] = useState([]);
  const [SubscriptionDeatails, SetSubscriptionDeatails] = useState();
  const [loading, setLoading] = useState(false);
  const [Custumersubinfo, SetCustumersubinfo] = useState();
  const [Custumerinfo, SetCustumerinfo] = useState([]);
  const [setInvoice, setsetInvoice] = useState([]);
  const [showModal, setshowModal] = useState(false);

  const openModal = () => {
    // setsetInvoice(SubscriptionDeatails[id]);
    setshowModal(true);
  };

  const closeModal = () => {
    setshowModal(false);
  };

  useEffect(() => {
    const master_data_get_user_data = async () => {
      setLoading(true);
      const fd = new FormData();
      fd.append("customer_id", user_id);

      try {
        const userResponse = await server_post_data(
          get_package_by_customer_id,
          fd
        );
        setLoading(false);
        if (userResponse.data.error) {
          handleError(userResponse.data.message);
        } else {
          SetSubscriptDeatails(userResponse.data.message);
          SetCustumerinfo(userResponse.data.message);
          storeSubscriptionData(userResponse.data.message);
        }
      } catch (error) {
        console.log(error);
        setLoading(false);
        handleError("network");
      }
    };
    const storeSubscriptionData = (SubscriptDeatails) => {
      console.log(SubscriptDeatails)
      const details = SubscriptDeatails[0];
      console.log(details);
      storeData("Price", details.Price);
      storeData("brand_identity", details.brand_identity);
      storeData("business_card", details.business_card);
      storeData("subscription_end_date", details.subscription_end_date);
      storeData("copyright_ownership", details.copyright_ownership);
      storeData("downloading_rights", details.downloading_rights);
      storeData("favicon", details.favicon);
      storeData("file_example_download", details.file_example_download);
      storeData("new_version_for_logo", details.new_version_for_logo);
      storeData("package_days", details.package_days);
      storeData("package_name", details.package_name);
      storeData("primary_id", details.primary_id);
      storeData("resizing_design", details.resizing_design);
      storeData("resolution", details.resolution);
      storeData("support", details.support);
      storeData("transparent_background", details.transparent_background);
      storeData("unlimited_changes", details.unlimited_changes);
      storeData("vector_file", details.vector_file);
      storeData("watermark", details.watermark);
      storeData("about_logo", details.about_logo);
      storeData("package_month_year", details.package_month_year);
      storeData("howmany_month_year", details.howmany_month_year);
    };

    
    master_data_get_user_data();
    master_data_get_user_subscription_data();
  }, [payment_transaction_id]);

  const full_name = retrieveData("full_name");
  const user_email = retrieveData("user_email");
  const entryDate = SubscriptDeatails.entry_date;

  const dateObject = new Date(entryDate);

  const formattedDate = `${dateObject.getDate().toString().padStart(2, "0")}/${(
    dateObject.getMonth() + 1
  )
    .toString()
    .padStart(2, "0")}/${dateObject.getFullYear()}`;
  const Enddate = SubscriptDeatails.subscription_end_date;

  // Create a new Date object
  const dateObject2 = new Date(Enddate);

  // Format the date
  const formattedEndDate = `${dateObject2
    .getDate()
    .toString()
    .padStart(2, "0")}/${(dateObject2.getMonth() + 1)
    .toString()
    .padStart(2, "0")}/${dateObject2.getFullYear()}`;

  const handleDownloadinvoice = () => {
    console.log("sdfgdgdffg");
    const invoiceDiv = document.querySelector(".PayemntIncoiceDiv");
    // Check if the element exists
    if (!invoiceDiv) {
      console.log(invoiceDiv);
      console.error("Invoice div not found");
      return;
    }
    // Clone the invoice div to avoid modifying the original
    const clonedInvoiceDiv = invoiceDiv.cloneNode(true);
    document.body.appendChild(clonedInvoiceDiv);

    // Move the cloned div off-screen
    clonedInvoiceDiv.style.position = "absolute";
    clonedInvoiceDiv.style.left = "-10000px";
    clonedInvoiceDiv.style.display = "block";

    // Generate the PDF from the cloned div
    const input = clonedInvoiceDiv.querySelector("#invoice");

    if (!input) {
      console.error("Invoice content not found");
      document.body.removeChild(clonedInvoiceDiv);
      return;
    }

    // Set specific dimensions for the invoiceDiv (smaller than A4 size)
    const pageWidth = 210; // A4 width in mm
    const pageHeight = 297; // A4 height in mm
    const invoiceWidth = pageWidth * 0.7; // Set the invoice width to 80% of the A4 page width
    const invoiceHeight = pageHeight * 0.7; // Set the invoice height to 70% of the A4 page height

    // Resize the invoice div to smaller than A4 size
    clonedInvoiceDiv.style.width = `${invoiceWidth}mm`;
    clonedInvoiceDiv.style.height = `${invoiceHeight}mm`;

    // Generate the PDF from the cloned div
    html2canvas(input).then((canvas) => {
      const imgData = canvas.toDataURL("image/png");
      const pdf = new jsPDF("p", "mm", "a4");

      // Get image dimensions
      const canvasWidth = canvas.width;
      const canvasHeight = canvas.height;

      // Calculate the scaling factor to fit the content within the invoice size
      const scale = Math.min(
        invoiceWidth / canvasWidth,
        invoiceHeight / canvasHeight
      );
      const imgWidth = canvasWidth * scale;
      const imgHeight = canvasHeight * scale;

      // Calculate the position to center the invoice on the PDF page
      const xPos = (pageWidth - imgWidth) / 2; // Center horizontally
      const yPos = (pageHeight - imgHeight) / 2; // Center vertically

      // Add the image to the PDF at the center of the page
      pdf.addImage(imgData, "PNG", xPos, yPos, imgWidth, imgHeight);

      // Save the generated PDF
      pdf.save("invoice.pdf");

      // Remove the cloned div after generating the PDF
      document.body.removeChild(clonedInvoiceDiv);
    });
  };
  const master_data_get_user_subscription_data = async () => {
    setLoading(true);
    const fd = new FormData();
    fd.append("customer_id", user_id);

    try {
      const userResponse = await server_post_data(get_customer_all_package, fd);
      setLoading(false);

      if (userResponse.data.error) {
        handleError(userResponse.data.message);
      } else {
        const allSubscriptionDetails = userResponse.data.message.data;

        if (
          Array.isArray(allSubscriptionDetails) &&
          allSubscriptionDetails.length > 0
        ) {
          const latestSubscription =
            allSubscriptionDetails[allSubscriptionDetails.length - 1];
          setsetInvoice(latestSubscription);
          SetSubscriptionDeatails(latestSubscription);
        } else {
          handleError("No subscription data found.");
        }

        SetCustumersubinfo(userResponse.data.message);
      }
    } catch (error) {
      console.log(error);
      setLoading(false);
      handleError("network");
    }
  };


  const printButtonRef = useRef(null);
  const triggerPrint = () => {
    console.log("sdfgdgdffg");
    if (printButtonRef.current) {
      console.log("sdfgdgdffgtttttttt");
      printButtonRef.current.click();
    }
  };
  return (
    <>
      <Header Showheader={false} />
      <section
        className="logoideas_section logoideas_section_pricingPage   ComplatePymentDiv "
        style={{ background: "white" }}
      >
        <div className="container">
          <div
            className="container d-flex justify-content-center align-items-center compPyTOp "
            style={{ background: "white" }}
          >
            <div className=" text-center animate__animated animate__zoomIn  CarDContainer">
              <div className="PaymentContainer">
                <div className="TitleCOn">
                  <img src={successPaymentGif} alt="mark"></img>{" "}
                  <h1>Success!</h1>
                  <label>Your request has been processed successfully</label>
                  <p>You receive a notification shortly in your email</p>
                </div>

                <div
                  className="contentPymnt"
                  style={{ flexWrap: "wrap", gap: "0.5rem" }}
                >
                  <button
                    style={{ marginRight: "0.3rem", whiteSpace: "nowrap" }}
                    onClick={triggerPrint}
                  >
                    Download Invoice
                  </button>

                  <Link to="/">
                    <button className="" style={{ whiteSpace: "nowrap" }}>
                      {" "}
                      Back to Home
                    </button>
                  </Link>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="PayemntIncoiceDiv" style={{ display: "none" }}>
          <PaymentInvoice
            handleDownloadinvoice={handleDownloadinvoice}
            datasubscript={setInvoice}
            reference={printButtonRef}
          />
        </div>
      </section>
    </>
  );
};

export default CompletePayment;
