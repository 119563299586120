import React from "react";
import "./Css/TermsandConditions.css";
import "./Css/PrivacyPolicy.css";
import Footer from "./Footer";
import Header from "./Header";
const RefundPolicy = () => {
  return (
    <div className="howitworks_wrapper">
      <Header />
      <div className="headerSection_howitWorks col-lg-12">
        <div className="terms_imageContainer">
          <div className="center_text_wrapper">
            <h3>Refund Policy</h3>
          </div>
        </div>
      </div>
      <div className="container privacy_policy_headingContainer">
        <h5>
          At LogoAcademy, we value your satisfaction and strive to provide
          excellent AI-powered branding services that empower your brand growth.
          Our complete set of tools is made to give you all the necessary
          resources to display your distinctive brand identity successfully.
        </h5>

        <div className="termsAndConditions_points privacyPolicy_points">
          <ul>
            <li>
              1. Preview Before Purchase
              <ul>
                <li>
                  To simplify our client’s decision-making process, LogoAcademy
                  provides a detailed view option. This feature lets you
                  carefully check all possible assets to ensure they fit your
                  requirements and preferences before making the purchase.
                </li>
              </ul>
            </li>
            <li>
              2. Instant Download Post Purchase
              <ul>
                <li>
                  Recognising the value of being on time, LogoAcademy offers you
                  instant access to the purchased items. This means you can
                  download the chosen items in different formats as soon as you
                  make a purchase, all set to be used in your branding
                  activities.
                </li>
              </ul>
            </li>
            <li>
              3. Refund Policy
              <ul>
                <li>
                  LogoAcademy is digital, and we deliver assets quickly. Because
                  of this, we have a strict refund policy. Usually, we don't
                  give refunds after a purchase is made. This policy emphasizes
                  how exclusive and distinctive our products are.
                </li>
              </ul>
            </li>
            <li>
              4.Conditions for Refund/ Eligible Criteria for Refund
              <ul>
                <li>
                  We understand how important it is for customers to be
                  satisfied and maintain fidelity to our products. Therefore,
                  the refund can be considered under the following
                  circumstances:
                </li>
                <li>
                  <b>Product Discrepancy</b> {""}-If the delivered product is
                  completely different from what you saw before purchase.
                </li>
                <li>
                  <b>File Damage</b> {""}-If the received files are corrupted or
                  damaged and we are unable to provide a resolution or fix your
                  issue.
                </li>
                <li>
                  <label style={{ marginRight: "4px" }}>
                    {" "}
                    Within 48 hours of making your purchase, we urge you to
                    email
                  </label>
                  <a href="bdm@thefoxadvertisement.com">
                    bdm@thefoxadvertisement.com
                  </a>{" "}
                  ,{" "}
                  <a href="support@logoacademy.com">support@logoacademy.com</a>{" "}
                  to our support team if you experience any of the
                  aforementioned problems. Please provide thorough details
                  regarding the problem along with any relevant evidence to
                  support your claim.. Our support team will evaluate your
                  request and try to fix the problem; if necessary, we may
                  replace the asset or offer a refund.
                </li>
              </ul>
            </li>
            <li>
              5. Commitment by LogoAcademy
              <ul>
                <li>
                  We appreciate you trusting LogoAcademy for your branding needs
                  and we're committed to making sure that you have a great
                  experience. Our support team is ready to help with any
                  questions or issues, helping you make the right decisions for
                  your brand.
                </li>
                <li className="mt-2">
                  Thank you for choosing LogoAcademy.Together, we'll make sure
                  your brand is distinctive and effectively conveys the distinct
                  culture and mission of your company.
                </li>
              </ul>
            </li>
          </ul>
        </div>
      </div>
      <Footer />
    </div>
  );
};

export default RefundPolicy;
