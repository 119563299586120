import { openDB } from "idb";

const dbPromise = openDB("font-store", 2, {
  upgrade(db) {
    db.createObjectStore("fonts3");
    db.createObjectStore("fonts4");
  },
});

export async function saveFontsToDB(fontlist) {
  const db = await dbPromise;
  await db.put("fonts3", fontlist, "fontlist3");
}
export async function saveFontsToDB2(fontlist) {
  const db = await dbPromise;
  await db.put("fonts4", fontlist, "fontlist4");
}

export async function getFontsFromDB() {
  const db = await dbPromise;
  return db.get("fonts3", "fontlist3");
}
export async function getFontsFromDB2() {
  const db = await dbPromise;
  return db.get("fonts4", "fontlist4");
}
