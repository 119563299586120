import React, { useState, useEffect } from "react";
import Header from "./Header";
import "../Components/Css/Blogs.css";
import rightArrow from "../Assets/Images/right_arrow.svg";
import {
  handleError,
  handleLinkClick,
  getPaginationRange,
} from "../CommonJquery/CommonJquery.js";
import rightArrowGrey from "../Assets/Images/rightArrowgrey.svg";
import { Link } from "react-router-dom";
import Footer from "./Footer.js";
import {
  server_post_data,
  get_blog_details_url,
} from "../ServiceConnection/serviceconnection.js";
import DOMPurify from "dompurify";
import Loader from "./Loader.js";
const Blogs = () => {
  const [cards, setcards] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [SEOloop, setSEOloop] = useState([]);
  const cardsPerPage = 9;
  const indexOfLastCard = currentPage * cardsPerPage;
  const indexOfFirstCard = indexOfLastCard - cardsPerPage;
  const currentCards = cards.slice(indexOfFirstCard, indexOfLastCard);
  const totalPages = Math.ceil(cards.length / cardsPerPage);
  const [showLoaderAdmin, setshowLoaderAdmin] = useState(false);
  const paginationRange = getPaginationRange(currentPage, totalPages);

  const handleFetchData = async () => {
    setshowLoaderAdmin(true);
    var form_data = new FormData();
    await server_post_data(get_blog_details_url, form_data)
      .then((Response) => {
        if (Response.data.error) {
          handleError(Response.data.message);
        } else {
          setcards(Response.data.message.blog_list);
          setSEOloop(Response.data.message.seo_loop);
        }
        setshowLoaderAdmin(false);
      })
      .catch((error) => {
        setshowLoaderAdmin(false);
      });
  };

  useEffect(() => {
    handleFetchData();
  }, []);

  const match_and_return_seo_link = (v_id) => {
    let data_seo_link_final = "/blogs/blog_detail/" + v_id;
    let data_seo_link = data_seo_link_final;

    if (SEOloop) {
      const matchedItem = SEOloop.find(
        (data) => data_seo_link === data.call_function_name
      );

      if (matchedItem) {
        data_seo_link_final = matchedItem.pretty_function_name;
      }
    }

    return data_seo_link_final;
  };
  return (
    <>
      <Header />
      {showLoaderAdmin && <Loader />}
      <div className="blog_wrapper">
        <div className="headerSection">
          <div className="container-lg ">
            <div className="blogs_heaading_wrapper flexRow">
              <h3>Blog</h3>

              <button style={{ padding: "7px", paddingLeft: "10px" }}>
                <Link
                  to="/"
                  style={{ textDecoration: "none", color: "inherit" }}
                >
                  Create Logo
                  <img src={rightArrow} alt="eight arrow" />
                </Link>
              </button>
            </div>
            <div className="Blogs_heading_description col-md-6">
              <p className="blogs_description_paragraph">
                Whether you have a new business idea or need design tips, the
                Logo Academy blog is filled with helpful articles and stories to
                help you build a business you love.
              </p>
            </div>
          </div>
        </div>
        <div className="container-lg">
          {currentCards.map((card, index) =>
            index === 0 ? (
              <Link
                onClick={() =>
                  handleLinkClick(
                    match_and_return_seo_link(card.primary_id),
                    "sss"
                  )
                }
                key={index}
              >
                <div className="row my-5">
                  <div className="blogs_leftimg_section col-md-6">
                    <img src={card.image_url} alt="blogimg " />
                  </div>
                  <div className="blogs_right_section col-md-6">
                    <p>
                      {new Date(card.entry_date).toLocaleDateString("en-GB", {
                        day: "2-digit",
                        month: "short",
                        year: "numeric",
                      })}
                    </p>
                    <p className="elepsis1">{card.title_name}</p>
                    <p className="elepsis2">
                      <div
                        dangerouslySetInnerHTML={{
                          __html: DOMPurify.sanitize(card.description),
                        }}
                      />{" "}
                      ...Readmore
                    </p>
                  </div>
                </div>
              </Link>
            ) : (
              <div></div>
            )
          )}
          <section className="blogs_section">
            <div className="container-lg">
              <div className="blogs_container">
                <div className="blogs_heading">
                  <h3>Read more logo posts on our blog</h3>
                </div>
                {/* <div className="card_container "> */}
                <div className="row ">
                  {currentCards.map((card, index) => (
                    <div
                      key={index}
                      className="col-md-4 col-sm-6 d-flex justify-content-center  mb-3"
                    >
                      <Link
                        onClick={() =>
                          handleLinkClick(
                            match_and_return_seo_link(card.primary_id)
                          )
                        }
                      >
                        <div className="custom-card fixcard_container">
                          <img src={card.image_url} alt="Blog Img " />
                          <p className="titlename mb-4">
                            {card.title_name} <br />
                          </p>
                        </div>
                      </Link>
                    </div>
                  ))}
                </div>
                <div className="pagination">
                  {paginationRange.map((page, index) =>
                    page === "..." ? (
                      <span key={index} className="dots">
                        ...
                      </span>
                    ) : (
                      <button
                        key={index}
                        onClick={() => setCurrentPage(page)}
                        className={page === currentPage ? "active" : ""}
                      >
                        {page}
                      </button>
                    )
                  )}
                  <button
                    onClick={() =>
                      setCurrentPage((prev) => Math.min(prev + 1, totalPages))
                    }
                    disabled={currentPage === totalPages}
                  >
                    <img src={rightArrowGrey} alt="rightArrow" />
                  </button>
                </div>
              </div>
            </div>
          </section>
        </div>
        <Footer />
      </div>
    </>
  );
};

export default Blogs;
