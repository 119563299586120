import React, { useEffect, useState } from "react";
import { Modal, Button, Form, Tabs, Tab } from "react-bootstrap";
import Masonry from "react-masonry-css";
import "./Css/imagesModal.css";
import Em1 from "../Assets/Images/Em-1.png";
import Em2 from "../Assets/Images/Em-2.png";
import Em3 from "../Assets/Images/Em-3.png";
import Em4 from "../Assets/Images/Em-4.png";
import Em5 from "../Assets/Images/Em-5.png";
import Em6 from "../Assets/Images/Em-6.png";
import Loader from "./Loader.js";
import UploadIcon from "../Assets/Images/UploadIcn.svg";
import Picture from "../Assets/Images/picture3.png";
import Delet from "../Assets/Images/deletWhite.png";
import { ColumnsPhotoAlbum } from "react-photo-album";
import "react-photo-album/columns.css";
import {
  APL_LINK,
  get_more_pic_uploads_blog_website,
  server_post_data,
  save_update_upload_picture,
  get_user_pic_uploads_blog_website,
  update_user_background,
  get_user_background,
} from "../ServiceConnection/serviceconnection.js";
import { handleError } from "../CommonJquery/CommonJquery.js";

// Image data with unique IDs

const images = [
  { url: Em1 },
  { url: Em2 },
  { url: Em3 },
  { url: Em4 },
  { url: Em5 },
  { url: Em6 },
  { url: Em6 },
  { url: Em6 },
  { url: Em6 },
  { url: Em6 },
  { url: Em6 },
  { url: Em6 },
  { url: Em6 },
  { url: Em6 },
  { url: Em6 },
  { url: Em6 },
  { url: Em6 },
];

const photos = [
  {
    src: "https://via.placeholder.com/600x400",
    width: 600,
    height: 400,
  },
  {
    src: "https://via.placeholder.com/500x700",
    width: 500,
    height: 700,
  },
  {
    src: "https://via.placeholder.com/800x600",
    width: 800,
    height: 600,
  },
  {
    src: "https://via.placeholder.com/300x400",
    width: 300,
    height: 400,
  },
  {
    src: "https://via.placeholder.com/400x300",
    width: 400,
    height: 300,
  },
  {
    src: "https://via.placeholder.com/700x500",
    width: 700,
    height: 500,
  },
];

const breakpointColumnsObj = {
  default: 4,
  1100: 3,
  700: 2,
  500: 1,
};

function ImagesModal({ open, handleClose }) {
  const [selectedImages, setSelectedImages] = useState([]);
  const [selectedIndex, setSelectedIndex] = useState(null);
  const [pictures, setpictures] = useState([]);
  const [IMAGEPATH, setIMAGEPATH] = useState("main");
  const [userpictures, setuserpictures] = useState([]);
  const [key, setKey] = useState("photos"); // Manage active tab
  const [showLoaderAdmin, setshowLoaderAdmin] = useState(false);

  const master_data_get = async (flag, call_id) => {
    const fd = new FormData();
    setshowLoaderAdmin(true);
    fd.append("flag", flag);
    fd.append("call_id", call_id);

    await server_post_data(get_more_pic_uploads_blog_website, fd)
      .then((Response) => {
        if (Response.data.error) {
          handleError(Response.data.message);
        } else {
          // setTemplateData(Response.data.message.data);
          // setFiltertemplateData(Response.data.message.data);
          console.log(Response.data.message.image_path);
          setpictures(Response.data.message.pic_uploads_blog);
          setIMAGEPATH(Response.data.message.image_path);
        }
        setshowLoaderAdmin(false);
      })
      .catch((error) => {
        console.error(error);
        setshowLoaderAdmin(false);
      });
  };

  const master_data_get_user_uploads = async (flag, call_id) => {
    const fd = new FormData();
    setshowLoaderAdmin(true);

    const userId = localStorage.getItem("user_id");

    fd.append("flag", flag);
    fd.append("call_id", call_id);
    fd.append("user_id", userId);

    await server_post_data(get_user_pic_uploads_blog_website, fd)
      .then((Response) => {
        if (Response.data.error) {
          handleError(Response.data.message);
        } else {
          // setTemplateData(Response.data.message.data);
          // setFiltertemplateData(Response.data.message.data);
          console.log(Response.data.message.image_path);
          setuserpictures(Response.data.message.pic_uploads_blog);
          setIMAGEPATH(Response.data.message.image_path);
        }
        setshowLoaderAdmin(false);
      })
      .catch((error) => {
        console.error(error);
        setshowLoaderAdmin(false);
      });
  };

  const master_data_get_user_background = async (flag, call_id) => {
    const fd = new FormData();
    setshowLoaderAdmin(true);

    const userId = localStorage.getItem("user_id");

    fd.append("flag", flag);
    fd.append("call_id", call_id);
    fd.append("user_id", userId);

    await server_post_data(get_user_background, fd)
      .then((Response) => {
        if (Response.data.error) {
          handleError(Response.data.message);
        } else {
          // setTemplateData(Response.data.message.data);
          // setFiltertemplateData(Response.data.message.data);
          console.log(Response.data.made_for_you_background);
          // setSelectedImages(Response.data.made_for_you_background);
        }
        setshowLoaderAdmin(false);
      })
      .catch((error) => {
        console.error(error);
        setshowLoaderAdmin(false);
      });
  };
  console.log(selectedImages)

  // Function to handle selecting/deselecting images
  const handleSelectImage = (image, index) => {
    const imageId = image;

    if (selectedImages === imageId) {
      setSelectedImages(null); // Deselect the image
      setSelectedIndex(null);
    } else {
      setSelectedImages(imageId); // Select the image
      setSelectedIndex(index);
    }
  };

  useEffect(() => {
    master_data_get();
    master_data_get_user_uploads();
    master_data_get_user_background();
  }, []);

  const handleButtonUploadimgPicture = () => {
    const input = document.createElement("input");
    input.type = "file";
    input.accept = "image/*"; // Accept any image type
    input.style.display = "none";

    input.onchange = async (event) => {
      const file = event.target.files[0];
      if (file) {
        const fileExtension = file.name.split(".").pop().toLowerCase();
        let imageContent = null;

        // Check if the uploaded file is SVG
        if (fileExtension === "svg") {
          imageContent = await readSVGFile(file); // Handle SVG files
        } else {
          imageContent = await readImageFile(file); // Handle other image types
        }

        await handleUploadPictures(file, imageContent);
        setshowLoaderAdmin(true);
      }
    };

    input.click(); // Trigger file input click
  };
  const handleUploadPictures = async (file, svgContent) => {
    setshowLoaderAdmin(true);
    const formData = new FormData();

    const userId = localStorage.getItem("user_id");

    formData.append("upload_img_new", file);
    formData.append("svg_content", svgContent);
    formData.append("user_id", userId);
    formData.append("by_user_admin_flag", "1");

    await server_post_data(save_update_upload_picture, formData)
      .then((Response) => {
        if (Response.data.error) {
          handleError(Response.data.message);
        } else {
          // Function to call uploaded imgs
          master_data_get_user_uploads();
        }
        setshowLoaderAdmin(false); // Set to false after response
      })
      .catch((error) => {
        setshowLoaderAdmin(false);
        handleError("network");
      });
  };

  const readSVGFile = (file) => {
    return new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.onload = (event) => {
        resolve(event.target.result);
      };
      reader.onerror = (error) => {
        reject(error);
      };
      reader.readAsText(file);
    });
  };

  // Function to read non-SVG image files (e.g., PNG, JPEG)
  const readImageFile = (file) => {
    return new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.onload = () => resolve(reader.result);
      reader.onerror = (error) => reject(error);
      reader.readAsDataURL(file); // Read file as base64-encoded string
    });
  };

  // const handleSave = (image) => {
  //   if (image) {
  //     console.log("Saving image:", image);
  //     // Add your logic here
  //   } else {
  //     console.log("No image selected");
  //   }
  // };

  const handleSave = async (image) => {
    setshowLoaderAdmin(true);
    const formData = new FormData();

    const userId = localStorage.getItem("user_id");

    formData.append("made_for_you_background", image);
    formData.append("user_id", userId);

    await server_post_data(update_user_background, formData)
      .then((Response) => {
        if (Response.data.error) {
          handleError(Response.data.message);
        } else {
          // Function to call uploaded imgs
          master_data_get_user_uploads();
          window.location.reload();
        }
        setshowLoaderAdmin(false); // Set to false after response
      })
      .catch((error) => {
        setshowLoaderAdmin(false);
        handleError("network");
      });
  };

  console.log(selectedImages);

  return (
    <Modal className="ImgsMOdal" show={open} onHide={handleClose} centered>
      <Modal.Header closeButton>
        {showLoaderAdmin && <Loader />}
        <div className="headerCOntainer">
          <Modal.Title>Select Photos</Modal.Title>
          <div className="BothTabs">
            <div className="rightBttns">
              <div className="smallImg">
                {selectedImages ? (
                  <img
                    src={
                      APL_LINK && IMAGEPATH && selectedImages
                        ? APL_LINK + IMAGEPATH + selectedImages
                        : UploadIcon
                    }
                    alt="Selected"
                  />
                ) : (
                  <img src={UploadIcon} alt="UploadIcon" />
                )}
                <button>
                  <img src={Delet} alt="Logo Academy" />
                </button>
              </div>
              <Button
                style={{
                  height: "40px",
                  width: "80px",
                  justifyContent: "center",
                }}
                onClick={() => handleSave(selectedImages)}
              >
                Save
              </Button>
            </div>
            <Tabs defaultActiveKey="photos" id="uncontrolled-tab-example">
              <Tab
                eventKey="photos"
                title={
                  <div className="d-flex py-2">
                    <img
                      src={Picture}
                      alt="Photos Icon"
                      style={{ width: "20px", marginRight: "5px" }} // Adjust width and margin as needed
                    />
                    <p style={{ color: "#000", margin: 0 }}>Photos</p>
                  </div>
                }
              >
                {/* <div className="container">
                  <div className="row">
                    <div className="col-9 d-flex p-0">
                      <div className="col-8 pe-2 p-0">
                        <Form.Group controlId="search">
                          <Form.Control type="text" placeholder="Search..." />
                        </Form.Group>
                      </div>
                      <div className="col-4 p-0">
                        <Button className="ButtonSearch">Search</Button>
                      </div>
                    </div>
                  </div>
                </div> */}
                <div className="Suggestion">
                  <p>Suggestion</p>
                </div>
                <div className="ImgConainerSelected">
                  <div className="row m-0">
                    {pictures.length > 0 &&
                      pictures.map((image, index) => (
                        <div className="col-md-3 p-2">
                          <div
                            className={`photosCont ${
                              selectedImages &&
                              selectedImages.includes(image.upload_img)
                                ? "selected"
                                : selectedIndex !== null &&
                                  selectedIndex !== index
                                ? "disabledPhoto"
                                : ""
                            }`}
                          >
                            <img
                              key={index}
                              src={APL_LINK + IMAGEPATH + image.upload_img}
                              alt={`Image ${index}`}
                              className={`collage-img `}
                              onClick={() =>
                                handleSelectImage(image.upload_img, index)
                              }
                            />
                          </div>
                        </div>
                      ))}
                  </div>
                  {/* <ColumnsPhotoAlbum photos={photos} /> */}
                </div>
              </Tab>

              <Tab
                eventKey="upload"
                title={
                  <div className="d-flex py-2">
                    <img
                      src={UploadIcon}
                      alt="Photos Icon"
                      style={{ width: "20px", marginRight: "5px" }} // Adjust width and margin as needed
                    />
                    <p style={{ color: "#000", margin: 0 }}>Upload</p>
                  </div>
                }
              >
                <button
                  className="upBtn"
                  onClick={handleButtonUploadimgPicture}
                >
                  Upload
                </button>
                <div className="MYUploads">
                  <p>My Uploads</p>
                </div>
                <div className="row m-0">
                  {userpictures.length > 0 &&
                    userpictures.map((image, index) => (
                      <div className="col-md-3 p-2">
                        <div
                          className={`photosCont ${
                            selectedImages &&
                            selectedImages.includes(image.upload_img)
                              ? "selected"
                              : selectedIndex !== null &&
                                selectedIndex !== index
                              ? "disabledPhoto"
                              : ""
                          }`}
                        >
                          <img
                            key={index}
                            src={APL_LINK + IMAGEPATH + image.upload_img}
                            alt={`Image ${index}`}
                            className={`collage-img ${
                              selectedImages &&
                              selectedImages.includes(image.upload_img)
                                ? "selected"
                                : ""
                            }`}
                            onClick={() => handleSelectImage(image.upload_img)}
                          />
                        </div>
                      </div>
                    ))}
                </div>
              </Tab>
            </Tabs>
          </div>
        </div>
      </Modal.Header>

      {/* <Modal.Body>
        <div className="rightBttns">
          <div className="smallImg">
            <img src={UploadIcon} alt="UploadIcon" />
            <img src={UploadIcon} alt="UploadIcon" />
          </div>
          <Button>Save</Button>
        </div>
      </Modal.Body> */}
    </Modal>
  );
}

export default ImagesModal;
