import React, { useState, useEffect } from "react";
import "./../Components/Css/AccountsInfo.css";
import faceBook from "../Assets/Images/Facebook_F.png";
import Google from "../Assets/Images/googleBtnNew.png";
import logoImg from "../Assets/Images/logoImg.png";
import { useGoogleLogin } from "@react-oauth/google";
import {
  server_post_data,
  change_useremail_password,
  get_user_information,
  APL_LINK,
  server_get_data,
  google_login_online,
  google_login_connect,
  google_login_disconnect,
} from "../ServiceConnection/serviceconnection.js";
import {
  handleError,
  handleSuccess,
  handleSuccessSession,
  check_vaild_save,
  combiled_form_data,
  handleAlphabetsWithoutSpaceChange,
  handleEmailChange,
} from "../CommonJquery/CommonJquery";
import {
  storeData,
  retrieveData,
  storeDataSubscription,
} from "../LocalConnection/LocalConnection.js";
import Loader from "./Loader.js";
const AccountsInfo = () => {
  const [showLoaderAdmin, setshowLoaderAdmin] = useState(false);
  const [editStaffData, seteditStaffData] = useState([]);
  const [editOldImageData, seteditOldImageData] = useState([]);
  const [dynaicimage, setDynaicimage] = useState(null);
  const [user, setUser] = useState([]);
  const package_name = retrieveData("package_name");
  const brandkit_templates = retrieveData("brandkit_templates");
  const package_month_year = retrieveData("package_month_year");
  const howmany_month_year = retrieveData("howmany_month_year");
  const [isConnected, setIsConnected] = useState(false);
  const [ConnectBtnn, setConnectBtnn] = useState(false);
  const [connectGoogle, setConnectGoogle] = useState(() => !!localStorage.getItem("google_email"));
   //console.log(localStorage.getItem("google_email"));
  const handleFileChangedynamic = (keyname) => (event) => {
    const file = event.target.files[0];
    let new_file_name = keyname + "_show";

    if (!file) {
      handleError("Please select an image.");
      return;
    }

    if (file.type.startsWith("image/")) {
      // Validate file size
      if (file.size < 200 * 1024) {
        // 200KB in bytes
        handleError("File size is below the minimum limit (200KB).");
        return;
      }

      if (file.size > 500 * 1024) {
        // 500KB in bytes
        handleError("File size exceeds the maximum limit (500KB).");
        return;
      }

      const reader = new FileReader();

      reader.onload = () => {
        const img = new Image();
        img.src = reader.result;

        setDynaicimage((prevImages) => ({
          ...prevImages,
          [keyname]: file,
          [new_file_name]: reader.result,
        }));
      };

      reader.readAsDataURL(file);
    } else {
      alert("Please select a valid image file.");
      event.target.value = ""; // Clear the file input
    }
  };

  const handleSaveChangesdynamic = async (form_data, url_for_save) => {
    let vaild_data = check_vaild_save(form_data, null);

    if (vaild_data) {
      setshowLoaderAdmin(true);

      // Retrieve user_id from local storage
      const user_id = localStorage.getItem("user_id");

      if (!user_id) {
        handleError("User ID not found in local storage");
        setshowLoaderAdmin(false);
        return; // Exit early if user_id is missing
      }

      // Create FormData object and append necessary fields
      let fd_from = combiled_form_data(form_data, null);
      // fd_from.append("old_image_link", editOldImageData);
      fd_from.append("customer_id", user_id); // Append customer_id

      await server_post_data(url_for_save, fd_from)
        .then((Response) => {
          setshowLoaderAdmin(false);
          if (Response.data.error) {
            handleError(Response.data.message);
          } else {
            handleSuccessSession(Response.data.message, "/settings");
          }
        })
        .catch((error) => {
          setshowLoaderAdmin(false);
          handleError("network");
        });
    }
  };
  useEffect(() => {
    const start_date = "";
    const end_date = "";
    const flag = "3";
    let call_id = "0";
    master_data_get(start_date, end_date, flag, call_id);
  }, []);

  // const master_data_get = async () => {
  //   setshowLoaderAdmin(true);
  //   await server_post_data(get_user_information, null)
  //     .then((Response) => {
  //       if (Response.data.error) {
  //         handleError(Response.data.message);
  //       } else {
  //         if (Response.data.message.data.length > 0) {
  //           seteditStaffData(Response.data.message.data[0]);
  //           seteditOldImageData(Response.data.message.data[0].user_image);

  //           setDynaicimage({
  //             event_list_image_show:
  //               APL_LINK +
  //               Response.data.message.image_path +
  //               Response.data.message.data[0].user_image,
  //           });
  //         }
  //       }
  //       setshowLoaderAdmin(false);
  //     })
  //     .catch((error) => {
  //       handleError("network");
  //       setshowLoaderAdmin(false);
  //     });
  // };

  const master_data_get = async () => {
    const user_id = localStorage.getItem("user_id"); // Retrieve user_id from local storage

    if (!user_id) {
      handleError("User ID not found in local storage");
      return; // If no user_id is found, return early
    }

    const formData = new FormData(); // Create FormData object
    formData.append("customer_id", user_id); // Append customer_id with user_id from local storage

    setshowLoaderAdmin(true);
    await server_post_data(get_user_information, formData)
      .then((Response) => {
        if (Response.data.error) {
          handleError(Response.data.message);
        } else {
          if (Response.data.message.data.length > 0) {
            seteditStaffData(Response.data.message.data[0]);
          }
        }
        setshowLoaderAdmin(false);
      })
      .catch((error) => {
        handleError("network");
        setshowLoaderAdmin(false);
      });
  };

  const logingoogle = useGoogleLogin({
    onSuccess: (codeResponse) => {
      setUser(codeResponse);
    },
    onError: (error) => console.log("Login Failed:", error),
  });

  useEffect(() => {
    if (user.length !== 0) {
      setshowLoaderAdmin(true);
      server_get_data(google_login_online, user.access_token, user.access_token)
        .then((Response) => {
          setshowLoaderAdmin(false);
          saving_data_google(Response.data);
        })
        .catch((error) => {
          console.log(error);

          setshowLoaderAdmin(false);
        });
    }
  }, [user]);

  const saving_data_google = async (jsonData_needflt) => {
    console.log(jsonData_needflt);
    let fd_from = new FormData();
    fd_from.append("login_type", "gmail");
    fd_from.append("useremail", jsonData_needflt.email);
    fd_from.append("full_name", jsonData_needflt.name);
    fd_from.append("user_id", localStorage.getItem("user_id"));
    setshowLoaderAdmin(true);
    await server_post_data(google_login_connect, fd_from)
      .then((Response) => {
        console.log(Response);
        if (Response.data.error) {
          handleError(Response.data.message);
        } else {
          handleSuccess(Response.data.message);
          setConnectGoogle(true)
        }
        setshowLoaderAdmin(false);
      })
      .catch((error) => {
        console.log(error);
        handleError(error);
        setshowLoaderAdmin(false);
      });
  };
  
  const dislogingoogle = async () => {
    let fd_from = new FormData();
    fd_from.append("user_id", localStorage.getItem("user_id"));
    fd_from.append("google_email", localStorage.getItem("google_email"));
    setshowLoaderAdmin(true);
    await server_post_data(google_login_disconnect, fd_from)
      .then((Response) => {
        console.log(Response);
        if (Response.data.error) {
          handleError(Response.data.message);
        } else {
          handleSuccess(Response.data.message);
          setConnectGoogle(false)
          storeData("google_email", "");
        }
        setshowLoaderAdmin(false);
      })
      .catch((error) => {
        console.log(error);
        handleError(error);
        setshowLoaderAdmin(false);
      });
  };

  
  const toggleConnection = () => {
    setConnectBtnn((prevState) => !prevState);
  };


  


  const toggleConnection2 = () => {
    setConnectGoogle((prevState) => !prevState);
  };

  console.log(editStaffData);

  return (
    <div className="container-lg">
      {showLoaderAdmin && <Loader />}
      <section className="AccInfo_section mb-5">
        <form id="addNewStaff">
          <div className="accInfo_heading_wrapper">
            <h3>Your Account</h3>
          </div>
          <div className="acNameImg">

          {brandkit_templates !== '' ? (
  <img src={brandkit_templates} alt="Brand Kit" id="profileImg" />
) : null}
            <div>
              <p className="headName">{editStaffData.full_name}</p>
              {package_name != 'undefined' && (
              <div>
                {/* Your content to show when all three are not undefined */}
                <p className="packName">{package_name} {howmany_month_year} {package_month_year}</p>
              </div>
            )}

            </div>
          </div>
          <div className="col-xl-4 col-lg-5 col-md-6 mb-2">
            <label
              htmlFor="nameInput"
              className="form-label"
              style={{ fontWeight: 500 }}
            >
              Email
            </label>
            <input
              id="user_email"
              name="user_email"
              defaultValue={editStaffData.user_email || ""}
              className="form-control mt-2"
              placeholder="Enter Your Email"
              onInput={handleEmailChange}
              type="text"
              disabled
            />
          </div>

          <div className="col-xl-4 col-lg-5 col-md-6 mb-2">
            <label
              htmlFor="nameInput"
              className="form-label"
              
              style={{ fontWeight: 500 }}
            >
              Name
            </label>
            <input
              id="username"
              name="username"
              // defaultValue={editStaffDaFta.full_name || ""}
              className="form-control trio_mandatory mt-2"
              placeholder="Enter Your Name"
              defaultValue={editStaffData.full_name || ""}
              //onInput={handleAlphabetsWithoutSpaceChange}
              type="text"
            />
          </div>

          <button
            className="btn btn-primary"
            type="button"
            style={{ fontSize: "16", height: "30px", padding: "0 0.5rem" }}
            onClick={() =>
              handleSaveChangesdynamic("addNewStaff", change_useremail_password)
            }
          >
            Save
          </button>

          <div className="coonectBtns mt-4">
            <h5 className="mb-3">Connected accounts</h5>

            {/* <div className="connectBttn">
              <buttom className="fbIconBtn">
                <img src={faceBook} alt="faceBook" />
                <p>Connected Facebook</p>
                <p>Facebook</p>
              </buttom>
              {!ConnectBtnn ? (
                <button
                  type="button"
                  className="disconct"
                  onClick={toggleConnection}
                >
                  <p>Connect</p>
                </button>
              ) : (
                <button
                  type="button"
                  className="disconct"
                  onClick={toggleConnection}
                >
                  <p>Disconnect</p>
                </button>
              )}
            </div> */}
            <div className="d-flex gap-1 mt-2">
              <button className="googlerVBtrn">
                <img src={Google} alt="Google" />
                <p>Google</p>
              </button>
              
            
            

              {!connectGoogle ? (
        <button type="button" className="disconct" onClick={() => logingoogle()}>
          <p>Connect</p>
        </button>
      ) : (
        <button type="button" className="disconct" onClick={() => dislogingoogle()}>
          <p>Disconnect</p>
        </button>
      )}
            </div>
          </div>
        </form>
      </section>
    </div>
  );
};

export default AccountsInfo;
