import React, { useState } from "react";
import { Button } from "react-bootstrap";
import "../Components/Css/Support.css";
import supportIcon from "../Assets/Images/support.svg";
import messageIcon from "../Assets/Images/msgIcon.svg";
import {
  handleEmailChange,
  check_vaild_save,
  combiled_form_data,
  empty_form,
  handleSuccess,
  handleError,
  handleAphabetsChange,
  handleIaphabetnumberChange,
  handleNumbersChange,
  handleEmailClick,
} from "../CommonJquery/CommonJquery";
import {
  server_post_data,
  save_update_enquiry,
} from "../ServiceConnection/serviceconnection.js";
import Loader from "./Loader.js";
import Header from "./Header.js";
import { website_data } from "../CommonJquery/WebsiteText.js";

const Support = () => {
  const [showLoaderAdmin, setshowLoaderAdmin] = useState(false);

  const handleSaveChangesdynamic = async (form_data, url_for_save) => {
    let vaild_data = check_vaild_save(form_data);
    if (vaild_data) {
      setshowLoaderAdmin(true);
      let fd_from = combiled_form_data(form_data, null);
      await server_post_data(url_for_save, fd_from)
        .then((Response) => {
          if (Response.data.error) {
            handleError(Response.data.message);
          } else {
            empty_form(form_data);
            handleSuccess(Response.data.message);
          }
          setshowLoaderAdmin(false);
        })
        .catch((error) => {
          handleError("network");
          setshowLoaderAdmin(false);
        });
    }
  };

  return (
    <>
      <div className="wrapper">
        <Header />
        {showLoaderAdmin && <Loader />}
        <div className="container-lg support_section h-100 py-2">
          <div className="row row_container">
            <div className="col-lg-5 col-md-5 col-10 left_wrapper">
              <div
                className="designSectionSUpport"
                style={{ paddingLeft: "18px" }}
              >
                <h3>Here to help you build your brand!</h3>
                <p>
                  Need assistance? Reach Logo Academy's customer success team
                  via live chat or email for support with files and logo design
                  updates!
                </p>
              </div>

              <div className="form_wrapperr">
                <form id="brand_query_form">
                  <div>
                    <input
                      name="username"
                      placeholder="Enter your name"
                      className="trio_mandatory form-control input_field_custom"
                      maxLength={75}
                      minLength={2}
                      onInput={handleAphabetsChange}
                      tabIndex={1}
                    />
                    <span className="condition_error"></span>
                  </div>
                  <div>
                    <input
                      name="useremail"
                      placeholder="Enter your email"
                      className="trio_email trio_mandatory form-control input_field_custom"
                      maxLength={75}
                      minLength={3}
                      onInput={(e) => handleEmailChange(e)}
                      tabIndex={1}
                    />
                    <span className="condition_error"></span>
                  </div>
                  <div>
                    <textarea
                      name="userquery"
                      placeholder="Enter your query"
                      className="py-4 trio_mandatory form-control input_field_custom"
                      minLength={2}
                      maxLength={300}
                      onInput={handleIaphabetnumberChange}
                      tabIndex={1}
                      rows="4"
                      cols="50"
                    ></textarea>
                    <span className="condition_error"></span>
                  </div>
                  <input type="hidden" name="brand_or_designer" value={0} />
                </form>
              </div>
              <div className="btn_submit" style={{ marginTop: "0.8rem" }}>
                <Button
                  variant="primary"
                  id="supoprt_submit_btn"
                  className="login-button modal-button rounded-pill notransition"
                  onClick={() =>
                    handleSaveChangesdynamic(
                      "brand_query_form",
                      save_update_enquiry
                    )
                  }
                >
                  Submit
                </Button>
              </div>
            </div>
            <div className="col-lg-5 col-md-5 col-10 left_wrapper">
              <div className="designSectionSUpport2 SectionHead">
                <h3> Get Assistance from Designer</h3>
                <p>
                  Want assistance from our designer? You can also submit a
                  request for a custom design. We’re just a click away!
                </p>
              </div>
              <div className="form_wrapperr">
                <form id="designer_query_form">
                  <label className="Support_right_col">
                    <input
                      name="username"
                      placeholder="Enter your name"
                      className="trio_mandatory form-control input_field_custom"
                      maxLength={75}
                      minLength={2}
                      onInput={handleAphabetsChange}
                      tabIndex={1}
                    />
                    <span className="condition_error"></span>
                  </label>
                  <label>
                    <input
                      name="phone_number"
                      placeholder="Enter Phone no."
                      className="validateMobile form-control input_field_custom"
                      maxLength={10}
                      onInput={handleNumbersChange}
                      minLength={2}
                      tabIndex={1}
                    />
                    <span className="condition_error"></span>
                  </label>
                  <label>
                    <input
                      name="business_industry"
                      placeholder="Business/Industry"
                      className="trio_mandatory form-control input_field_custom"
                      maxLength={75}
                      minLength={2}
                      onInput={handleIaphabetnumberChange}
                      tabIndex={1}
                    />
                    <span className="condition_error"></span>
                  </label>
                  <label>
                    <input
                      name="business_name"
                      placeholder="Business Name"
                      className="trio_mandatory form-control input_field_custom"
                      maxLength={75}
                      onInput={handleIaphabetnumberChange}
                      minLength={2}
                      tabIndex={1}
                    />
                    <span className="condition_error"></span>
                  </label>
                  <input type="hidden" name="brand_or_designer" value={1} />
                </form>
              </div>
              <span className="btn_submit">
                <Button
                  variant="primary"
                  className="login-button modal-button notransition"
                  id="supoprt_submit_btn"
                  onClick={() =>
                    handleSaveChangesdynamic(
                      "designer_query_form",
                      save_update_enquiry
                    )
                  }
                >
                  Submit
                </Button>
              </span>
            </div>
          </div>
          <div className="row right_wrapper">
            <div className="col-lg-5 col-10 email_wrapper">
              <div className="designSectionSUpport3">
                <span className="heading_email">
                  <img src={messageIcon} alt="messageIcon" />
                  <h6>Email</h6>
                </span>
                <p>Send us an email and we’ll get back to you soon!</p>
              </div>
              <div className="send_email">
                <button
                  className="sendEmail_button_supportPage"
                  onClick={() => handleEmailClick(website_data.Email_id)}
                >
                  Send Email
                </button>
              </div>
            </div>
            <div className="col-lg-5 col-10 email_wrapper" style={{opacity:0}}>
              <div className="designSectionSUpport3">
                <span className="heading_email">
                  <img src={supportIcon} alt="SupportIcon" />
                  <h6> Live Support</h6>
                </span>
                <p>Ask your queries and get a resolution immediately!</p>
              </div>
              <div className="chat_with_us_btn send_email">
                <button disabled className="sendEmail_button_supportPage">
                  Chat with us!
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Support;
