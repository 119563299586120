import React, { useState, useEffect, useRef } from "react";
import { Modal, Button, Form } from "react-bootstrap";
import headerLogo from "../Assets/Images/headerLogo.png";
import earth from "../Assets/Images/earth.svg";
import googleLogo from "../Assets/Images/googleLogo.svg";
import settingsIcon from "../Assets/Images/settings.png";
import hamburgerIcon from "../Assets/Images/hamburgerIcon.svg";
import HomeIcn from "../Assets/Images/HomeIcon.svg";
import "./Css/Header.css";
import Drawer from "react-modern-drawer";
import "react-modern-drawer/dist/index.css";
import backArrow from "../Assets/Images/leftArrow.svg";
import hide_pass from "../Assets/Images/hide_pass.svg";
import showPass from "../Assets/Images/showPass.svg";
import { useGoogleLogin } from "@react-oauth/google";
import squareQuesMark from "../Assets/Images/help-square-contained.png";
import { removeData } from "../LocalConnection/LocalConnection.js";
import home from "../Assets/Images/HomeIcon.svg";
import cross from "../Assets/Images/crossIcon.png";
import logomaker from "../Assets/Images/logoMaker.svg";
import logoideas from "../Assets/Images/logoideas.svg";
import faq from "../Assets/Images/faq.svg";
import support from "../Assets/Images/squareQuesMark.svg";
import settings from "../Assets/Images/settings.png";
import signout from "../Assets/Images/signout.svg";
import blog from "../Assets/Images/blog.svg";
import heart from "../Assets/Images/heartIcon.svg";
import { useLocation } from "react-router-dom";
import $ from "jquery";
import {
  server_post_data,
  user_registration,
  user_login,
  forgotemail,
  change_password_by_forgot,
  server_get_data,
  google_login_online,
  get_package_by_id_mk,
  verifyuser,
} from "../ServiceConnection/serviceconnection.js";
import {
  handleEmailChange,
  handlePasswordChange,
  check_vaild_save,
  combiled_form_data,
  handleSuccess,
  handleError,
  handleLinkClick,
} from "../CommonJquery/CommonJquery";

import { changePasswardPage, languages } from "../CommonJquery/WebsiteText";
import {
  storeData,
  retrieveData,
  storeDataSubscription,
} from "../LocalConnection/LocalConnection.js";
import { Link, useNavigate } from "react-router-dom";
import Loader from "./Loader.js";

let isLoggedIn = false;
let lastinsertedid;
function Header({ Showheader = true, CheckLogin = false, wishlistTemp }) {
  const user_id = retrieveData("user_id");
  const subscription_end_date = retrieveData("subscription_end_date");
  console.log(subscription_end_date);
  if (subscription_end_date != null) {
    // Parse the subscription end date string to a Date object
    const endDate = new Date(subscription_end_date); // Convert to Date object
    const currentDate = new Date(); // Current date
    // Compare the dates
    if (endDate >= currentDate) {
      storeData("subscription_active", 1);
      //console.log("The subscription end date is in the past.");
    } else {
      storeData("subscription_active", 0);
      //console.log("The subscription end date is in the future or today.");
    }
  } else {
    console.log("else");
  }

  const navigate = useNavigate();
  if (user_id !== null && user_id !== "" && user_id !== "0") {
    isLoggedIn = true;
  }
  const location = useLocation();
  const [user, setUser] = useState([]);
  const [showLoaderAdmin, setshowLoaderAdmin] = useState(false);
  const [selectedLanguage, setSelectedLanguage] = useState("English");

  const [showLoginModal, setShowLoginModal] = useState(false);
  const [showSignupModal, setShowSignupModal] = useState(false);
  const [showResetPasswordModal, setShowResetPasswordModal] = useState(false);
  const [showPassword1, setShowPassword1] = useState(false);
  const [showNewPasswordModal, setShowNewPasswordModal] = useState(false);
  const [showOTPModal, setshowOTPModal] = useState(false);
  const [showOTPModalSignUP, setshowOTPModalSignUP] = useState(false);
  const [backdropVisible, setBackdropVisible] = useState(false);
  const [signUpOtp, setsignUpOtp] = useState();
  const [error, setError] = useState();

  const [datalogin, setdatalogin] = useState([]);
  const showLoginModalOverlay = location.pathname === "/showLogos";
  const handleLanguageChange = (language) => {
    setSelectedLanguage(language);
  };

  $("#login_check_jquery").on("customEvent", function () {
    closeModel(6);
  });
  $("#login_check_jquery_hide_data").on("customEvent", function () {
    setBackdropVisible(true);
    closeModel(6);
  });
  const activePackage = retrieveData("active_package");

  //otp input function -->
  const [otp, setOtp] = useState(["", "", "", ""]);
  const inputRefs = useRef([]);

  const handleChange = (index, value) => {
    if (/^\d?$/.test(value)) {
      const newOtp = [...otp];
      newOtp[index] = value;
      setOtp(newOtp);
      if (value !== "" && index < otp.length - 1) {
        inputRefs.current[index + 1].focus();
      }
    }
  };
  console.log(otp);
  const handleBack = () => {
    const currentUrl = location.pathname.substring(1);
    console.log(currentUrl);
    if (currentUrl == "showLogos") {
      navigate("/chooseIcons");
    } else if (currentUrl == "chooseIcons") {
      navigate("/recommendation");
    } else if (currentUrl == "recommendation") {
      navigate("/pickIndustry");
    } else if (currentUrl == "pickIndustry") {
      navigate("/createlogohome");
    } else if (currentUrl == "pricing") {
      let last_inserted_id = retrieveData("last_inserted_id");
      if (last_inserted_id) {
        navigate(`/canvasEdit/logo/${last_inserted_id}`);
      } else {
        navigate(-1);
      }
    } else {
      navigate(-1);
    }
  };

  const handleBackspace = (index, event) => {
    if (event.key === "Backspace" && otp[index] === "" && index > 0) {
      inputRefs.current[index - 1].focus();
    }
  };
  //-------------------otp function end

  const logingoogle = useGoogleLogin({
    onSuccess: (codeResponse) => {
      setUser(codeResponse);
    },
    onError: (error) => console.log("Login Failed:", error),
  });

  useEffect(() => {
    if (user.length !== 0) {
      setshowLoaderAdmin(true);
      server_get_data(google_login_online, user.access_token, user.access_token)
        .then((Response) => {
          setshowLoaderAdmin(false);
          saving_data_google(Response.data);
        })
        .catch((error) => {
          console.log(error);

          setshowLoaderAdmin(false);
        });
    }
  }, [user]);

  const saving_data_google = async (jsonData_needflt) => {
    let fd_from = new FormData();
    fd_from.append("login_type", "gmail");
    fd_from.append("useremail", jsonData_needflt.email);
    fd_from.append("full_name", jsonData_needflt.name);
    fd_from.append("userpassword", jsonData_needflt.id);
    setshowLoaderAdmin(true);
    await server_post_data(user_registration, fd_from)
      .then((Response) => {
        console.log(Response);
        if (Response.data.error) {
          const invalidElement =
            document.getElementsByClassName("invalid_data")[0];
          const invalidElement2 =
            document.getElementsByClassName("invalid_data2")[0];
          if (invalidElement) {
            invalidElement.innerHTML = Response.data.message;
            invalidElement.style.display = "block";
          }
          if (invalidElement2) {
            invalidElement2.innerHTML = Response.data.message;
            invalidElement2.style.display = "block";
          }
        } else {
          session_func(Response.data.message);
          ///master_data_get_subscriptionDetails(Response.data.message);
          setShowSignupModal(false);
          setshowOTPModalSignUP(true);
          closeModel(0);
        }

        setshowLoaderAdmin(false);
      })
      .catch((error) => {
        console.log(error);
        setshowLoaderAdmin(false);
      });
  };

  const handleSaveChangesdynamic = async (form_data, url_for_save) => {
    let vaild_data = true;
    if (form_data !== null) {
      vaild_data = check_vaild_save(form_data);
    }
    if (vaild_data) {
      setshowLoaderAdmin(true);
      let fd_from = new FormData();
      if (form_data !== null) {
        fd_from = combiled_form_data(form_data, null);
      }
      if (datalogin) {
        if (datalogin.data_admin && datalogin.data_admin.user_email) {
          //fd_from.append("useremail", datalogin.data_admin.user_email);
          fd_from.append("primary_id", datalogin.data_admin.primary_id);
        }
      }
      fd_from.append("login_type", "normal");
      await server_post_data(url_for_save, fd_from)
        .then((Response) => {
          if (Response.data.error) {
            const invalidElement =
              document.getElementsByClassName("invalid_data")[0];
            if (invalidElement) {
              invalidElement.innerHTML = Response.data.message;
              invalidElement.style.display = "block";
            }

            if (form_data === "forgotemailFormData") {
              document.querySelector(".server_error").textContent =
                Response.data.message;
            }
          } else {
            if (form_data === "loginFormData") {
              session_func(Response.data.message);
            } else if (form_data === "forgotemailFormData") {
              setdatalogin(Response.data.message);

              closeModel(8);
            } else if (form_data === "ChangePassword") {
              //for normal Login
              handleSuccess("Password Change Successfully");
              closeModel(0);
            } else if (form_data === "registrationFormData") {
              //for normal Login
              // handleSuccess("Registration successful! Welcome aboard!");
              //
              console.log("Success Data", Response.data.message);
              $("#registrationFormData").hide();
              $("#OTPFormData").show();

              setsignUpOtp(Response.data.message.data_admin.user_otp);
              lastinsertedid = Response.data.message.data_admin.primary_id;
              console.log(lastinsertedid);
              //closeModel(0);
            }
          }

          setshowLoaderAdmin(false);
        })
        .catch((error) => {
          console.log(error);

          setshowLoaderAdmin(false);
        });
    }
  };

  const handleSIgnUp = async (form_data, url_for_save) => {
    let fd_from = combiled_form_data(form_data, null);
    fd_from.append("primary_id", lastinsertedid);
    const otp_number = String(otp.join(""));
    if (signUpOtp === otp_number) {
      console.log("yes this one is activated");
      setshowLoaderAdmin(true);
      await server_post_data(url_for_save, fd_from)
        .then((Response) => {
          if (Response.data.error) {
            handleError(Response.data.message);
            console.log(Response.data.message);
          } else {
            session_func(Response.data.message);
            closeModel(0);
            handleLinkClick("");
          }

          setshowLoaderAdmin(false);
        })
        .catch((error) => {
          console.log(error);

          setshowLoaderAdmin(false);
        });
    } else {
      setError("Invaild OTP please enter correct OTP");
    }
  };

  const [SubscriptDeatails, SetSubscriptDeatails] = useState([]);
  //pakage Deatails
  // const master_data_get_subscriptionDetails = async () => {
  //   setshowLoaderAdmin(true);
  //   const fd = new FormData();
  //   fd.append("main_id", user_id);
  //   fd.append("package_id", activePackage);
  //   try {
  //     const response = await server_post_data(get_package_by_id_mk, fd);
  //     const responseData = response.data.message.data;

  //     console.log("responseData", responseData);
  //     storeSubscriptionData(responseData);
  //     SetSubscriptDeatails(responseData);

  //     setshowLoaderAdmin(false);
  //   } catch (error) {
  //     setshowLoaderAdmin(false);
  //     // handleError("network");
  //   }
  // };

  useEffect(() => {
    ///master_data_get_subscriptionDetails();
  }, []);
  // const storeSubscriptionData = (data) => {
  //   console.log("ssss", data[0].Price);
  //   storeData("Price", data[0].Price);
  //   storeData("brand_identity", data[0].brand_identity);
  //   storeData("business_card", data[0].business_card);
  //   storeData("copyright_ownership", data[0].copyright_ownership);
  //   storeData("downloading_rights", data[0].downloading_rights);
  //   storeData("favicon", data[0].favicon);
  //   storeData("file_example_download", data[0].file_example_download);
  //   storeData("new_version_for_logo", data[0].new_version_for_logo);
  //   storeData("package_days", data[0].package_days);
  //   storeData("package_name", data[0].package_name);
  //   storeData("package_name", data[0].package_name);
  //   storeData("primary_id", data[0].primary_id);
  //   storeData("resizing_design", data[0].resizing_design);
  //   storeData("resolution", data[0].resolution);
  //   storeData("support", data[0].support);
  //   storeData("transparent_background", data[0].transparent_background);
  //   storeData("unlimited_changes", data[0].unlimited_changes);
  //   storeData("vector_file", data[0].vector_file);
  //   storeData("watermark", data[0].watermark);
  // };

  const session_func = (response) => {
    storeData("user_id", response.data_admin.primary_id);
    storeData("full_name", response.data_admin.full_name);
    storeData("user_email", response.data_admin.user_email);
    storeData("google_email", response.data_admin.google_email);
    storeData("first_login", response.data_admin.first_login);
    storeData("login_type", response.data_admin.login_type);
    storeData("active_package", response.data_admin.package_id);
    storeData(
      "subscription_end_date",
      response.data_admin.subscription_end_date
    );

    //console.log("subscription_end_date", response.data_admin.subscription_end_date);
    console.log("ssss", response.package);
    storeData("Price", response.package.Price);
    storeData("brand_identity", response.package.brand_identity);
    storeData("business_card", response.package.business_card);
    storeData("copyright_ownership", response.package.copyright_ownership);
    storeData("downloading_rights", response.package.downloading_rights);
    storeData("favicon", response.package.favicon);
    storeData("file_example_download", response.package.file_example_download);
    storeData("new_version_for_logo", response.package.new_version_for_logo);
    storeData("package_days", response.package.package_days);
    storeData("package_name", response.package.package_name);
    storeData("package_month_year", response.package.package_month_year);
    storeData("howmany_month_year", response.package.howmany_month_year);
    storeData("primary_id", response.package.primary_id);
    storeData("resizing_design", response.package.resizing_design);
    storeData("resolution", response.package.resolution);
    storeData("support", response.package.support);
    storeData("about_logo", response.package.about_logo);
    storeData(
      "transparent_background",
      response.package.transparent_background
    );
    storeData("unlimited_changes", response.package.unlimited_changes);
    storeData("vector_file", response.package.vector_file);
    storeData("watermark", response.package.watermark);
    if (response.brandkit_templates == "") {
      storeData("brandkit_templates", response.brandkit_templates);
      storeData("brandkit_id", response.brandkit_id);
    } else {
      storeData(
        "brandkit_templates",
        "https://api.logoacademy.co/assets/" + response.brandkit_templates
      );
    }
    console.log(response.brandkit_templates);
    window.location.reload();
  };

  // setTimeout(() => {
  //   window.location.reload();
  // }, 500);

  const [showPassword, setShowPassword] = useState(false);

  const handleConfirmNewPasswordModal = () => {
    let otp_full =
      String(otp[0]) + String(otp[1]) + String(otp[2]) + String(otp[3]);
    if (datalogin) {
      if (String(datalogin.data_admin.user_otp) === otp_full) {
        setShowNewPasswordModal(true);
      } else {
        const invalidElement =
          document.getElementsByClassName("invalid_data2")[0];
        if (invalidElement) {
          invalidElement.innerHTML = "Invalid OTP. Please enter a valid OTP.";
          invalidElement.style.display = "block";
        }
      }
    }
  };

  const [isOpen, setIsOpen] = React.useState(false);
  const toggleDrawer = () => {
    setIsOpen((prevState) => !prevState);
  };
  const logout = () => {
    removeData();
    window.location.reload();
  };
  const [isNavbarOpen, setIsNavbarOpen] = useState(false);
  const [navbarBackground, setNavbarBackground] = useState("#ffffff");
  const [navbarShadow, setNavbarShadow] = useState("none");
  const handleScroll = () => {
    const show = window.scrollY > 50;
    if (show && !isNavbarOpen) {
      setNavbarBackground("white");
      setNavbarShadow("rgba(0, 0, 0, 0.05) 0px 0px 0px 1px");
    } else if (!isNavbarOpen) {
      setNavbarBackground("#ffffff");
      setNavbarShadow("none");
    }
  };
  const toggleNavbar = () => {
    setIsNavbarOpen((prevState) => {
      const newState = !prevState;
      if (newState) {
        setNavbarBackground("white");
      } else {
        setNavbarBackground(window.scrollY > 50 ? "white" : "white");
      }
      return newState;
    });
  };

  const navbarRef = useRef(null);

  const handleClickOutside = (event) => {
    if (navbarRef.current && !navbarRef.current.contains(event.target)) {
      setIsNavbarOpen(false);
    }
  };

  useEffect(() => {
    // window.addEventListener("scroll", handleScroll);
    window.addEventListener("click", handleClickOutside);
    return () => {
      // window.removeEventListener("scroll", handleScroll);
      window.removeEventListener("click", handleClickOutside);
    };
  }, [isNavbarOpen]);
  useEffect(() => {
    const retrivemsg = retrieveData("session_msg");
    if (
      retrivemsg !== "" &&
      retrivemsg !== null &&
      retrivemsg !== "0" &&
      retrivemsg !== "1"
    ) {
      handleSuccess(retrivemsg, 1);
    }
  }, []);
  const closeModel = (click_type = 0) => {
    if (click_type === 0) {
      setShowLoginModal(false);
      setShowSignupModal(false);
      setShowResetPasswordModal(false);
      setshowOTPModal(false);
      setShowNewPasswordModal(false);
    } else if (click_type === 1) {
      setshowOTPModal(false);
      setShowResetPasswordModal(true);
    } else if (click_type === 2) {
      setShowSignupModal(true);
      setShowResetPasswordModal(false);
    } else if (click_type === 3) {
      setShowLoginModal(true);
      setShowSignupModal(false);
    } else if (click_type === 4) {
      setShowLoginModal(false);
      setShowResetPasswordModal(true);
    } else if (click_type === 5) {
      setShowSignupModal(true);
      setShowLoginModal(false);
    } else if (click_type === 6) {
      setShowLoginModal(true);
    } else if (click_type === 7) {
      setShowLoginModal(true);
    } else if (click_type === 8) {
      setshowOTPModal(true);
      setShowResetPasswordModal(false);
    } else if (click_type === 9) {
      setshowOTPModalSignUP(false);
      showSignupModal(true);
    }
  };
  const handleHome = () => {
    navigate("/");
  };

  return (
    <>
      {showLoaderAdmin && <Loader />}
      <div className="topNavBar" ref={navbarRef}>
        {Showheader ? (
          <nav
            className="navbar navbar-expand-lg ResponSIveNav"
            style={{
              backgroundColor: navbarBackground,
              transition: "background-color 0.3s ease, box-shadow 0.3s ease",
            }}
          >
            <div className="container-lg">
              <a className="navbar-brand" href="/">
                <Link to="/home">
                  <img
                    src={headerLogo}
                    alt="Logo"
                    width="150"
                    className="d-inline-block align-text-top logoImg"
                  />
                </Link>
              </a>
              <button
                className="navbar-toggler"
                type="button"
                onClick={toggleNavbar}
                data-bs-toggle="collapse"
                aria-expanded={isNavbarOpen ? "true" : "false"}
                aria-label="Toggle navigation"
              >
                <span className="navbar-toggler-icon"></span>
              </button>
              <div
                className={`collapse navbar-collapse ${
                  isNavbarOpen ? "show" : ""
                }`}
                // id="navbarNavDropdown"
              >
                <ul className="navbar-nav me-auto justify-content-start">
                  <li
                    className={`nav-item ${
                      location.pathname === "/howitworks" ? "activePage" : ""
                    }`}
                  >
                    <Link
                      className="nav-link active"
                      aria-current="page"
                      to="/howitworks"
                    >
                      How it works
                    </Link>
                  </li>
                  <li
                    className={`nav-item ${
                      location.pathname === "/logoIdeas" ? "activePage" : ""
                    }`}
                  >
                    <Link className="nav-link active " to="/logoIdeas">
                      Logo Ideas
                    </Link>
                  </li>
                  <li
                    className={`nav-item ${
                      location.pathname === "/pricing" ? "activePage" : ""
                    }`}
                  >
                    <Link className="nav-link active " to="/pricing">
                      Pricing
                    </Link>
                  </li>
                  <li
                    className={`nav-item ${
                      location.pathname === "/blogs" ? "activePage" : ""
                    }`}
                  >
                    <Link className="nav-link active " to="/blogs">
                      Blog
                    </Link>
                  </li>
                  <li
                    className={`nav-item ${
                      location.pathname === "/support" ? "activePage" : ""
                    }`}
                  >
                    <Link className="nav-link active " to="/support">
                      Support
                    </Link>
                  </li>
                </ul>
                <ul className="navbar-nav me-auto">
                  {isLoggedIn ? (
                    <>
                      <div className=" dropdown hiddenAfter991">
                        <span className="right_btns">
                          <li className="navitem dropdown">
                            <Link to="/faqs">
                              <img src={squareQuesMark} alt="questionmark" />
                            </Link>
                          </li>
                          <li className="navitem">
                            <Link to="/settings">
                              <img src={settingsIcon} alt="questionmark" />
                            </Link>
                          </li>
                          <li className="nav-item">
                            <button id="create_design">
                              <Link
                                to="/createlogohome"
                                style={{ color: "white" }}
                              >
                                Create design
                              </Link>
                            </button>
                          </li>

                          <li className="navitem">
                            <img
                              src={hamburgerIcon}
                              onClick={toggleDrawer}
                              alt="questionmark"
                            />
                          </li>
                        </span>
                      </div>
                      <li className="nav-item hideTill991">
                        <Link className="nav-link active " to="/faqs">
                          FAQs
                        </Link>
                      </li>
                      <li className="nav-item hideTill991">
                        <Link className="nav-link active " to="/settings">
                          Settings
                        </Link>
                      </li>
                      <li className="nav-item hideTill991">
                        <Link className="nav-link active " to="/createlogohome">
                          Create design
                        </Link>
                      </li>
                      <li className="nav-item hideTill991" onClick={logout}>
                        <Link className="nav-link active ">Logout</Link>
                      </li>
                    </>
                  ) : (
                    <>
                      <li className="nav-item">
                        <button className="btn" onClick={() => closeModel(7)}>
                          Sign in
                        </button>
                      </li>
                      <li className="nav-item">
                        <button
                          className="btn"
                          type="button"
                          onClick={() => closeModel(5)}
                        >
                          Sign Up
                        </button>
                      </li>
                      <li className="nav-item selected_language_nav_item">
                        {/* <Link
                          id="selectedLanguage"
                          className="nav-link dropdown-toggle"
                          to="/"
                          role="button"
                          data-bs-toggle="dropdown"
                          aria-expanded="false"
                        >
                          <img
                            alt="Logo Academy"
                            src={earth}
                            id="earth"
                            width={"18px"}
                          />
                          {selectedLanguage}
                        </Link> */}
                        <ul className="dropdown-menu dropdown-menu_header ">
                          {languages.map((language, index) => (
                            <li key={index}>
                              <button
                                className="dropdown-item"
                                onClick={() =>
                                  handleLanguageChange(language.value)
                                }
                              >
                                {language.value}
                              </button>
                            </li>
                          ))}
                        </ul>
                      </li>
                    </>
                  )}
                </ul>
              </div>
            </div>
          </nav>
        ) : (
          <nav className="navbar navbar-expand-lg navbar-light bg-light shadow-sm secndNAvUp">
            <div className="container-fluid d-flex justify-content-between align-items-center">
              {location.pathname.includes("/canvasEdit/logo") ? (
                <div className="navbar_backbutton" onClick={handleHome}>
                  <img src={HomeIcn} alt="Home Icon" />
                  <p>Home</p>
                </div>
              ) : (
                <div className="navbar_backbutton" onClick={handleBack}>
                  <img src={backArrow} alt="Back Arrow" />
                  <p>Back</p>
                </div>
              )}
              <div className="navbar-brand secNavImg text-center">
                <Link to="/home">
                  <img
                    src={headerLogo}
                    alt="Logo"
                    width="50"
                    className="d-inline-block align-top"
                  />
                </Link>
              </div>
              <div>
                {isLoggedIn ? (
                  <Link to="/home">
                    <button className="btn my_logos" type="button">
                      <img src={heart} alt="heart" />
                      My Logos
                      {location.pathname === "/showLogos" && (
                        <label className="whishlistTemP">{wishlistTemp}</label>
                      )}
                    </button>
                  </Link>
                ) : (
                  <div>
                    <button
                      className="btn loginBtnSec"
                      type="button"
                      onClick={() => closeModel(6)}
                    >
                      Sign in
                    </button>
                  </div>
                )}
              </div>
            </div>
          </nav>
        )}
      </div>
      <Drawer
        open={isOpen}
        onClose={toggleDrawer}
        direction="right"
        className="bla bla bla"
      >
        <ul className="content_wrapper">
          <div className="headerLogo_container">
            {" "}
            <img src={headerLogo} alt="header" />
            <img src={cross} alt="cross" onClick={toggleDrawer} />
          </div>

          <li>
            <img className="sidebarHome" src={home} alt="home" />
            <Link to="/home">Home</Link>
          </li>
          <li>
            <img className="sidebarHome" src={logomaker} alt="home" />
            <Link to="/createlogohome" className="">
              Logo Maker
            </Link>
          </li>
          <li>
            <img className="sidebarHome" src={logoideas} alt="home" />

            <Link to="/logoIdeas">Logo Ideas</Link>
          </li>
          <li>
            <img className="sidebarHome" src={faq} alt="home" />

            <Link to="/faqs">FAQ</Link>
          </li>
          <li>
            <img className="sidebarHome" src={blog} alt="home" />

            <Link to="/blogs">Blog</Link>
          </li>
          <li>
            <img className="sidebarHome" src={support} alt="home" />

            <Link to="/support">Support</Link>
          </li>
          <li>
            <img className="sidebarHome" src={settings} alt="home" />

            <Link to="/settings">Settings</Link>
          </li>
          <li onClick={logout}>
            <img className="sidebarHome" src={signout} alt="home" />

            <Link>Sign Out</Link>
          </li>
          <li id="create_design_button">
            <button style={{ color: "#ffffff" }}>
              <Link to="/createlogohome" style={{ color: "#ffffff" }}>
                Create Design
              </Link>
            </button>
          </li>
        </ul>
      </Drawer>
      {/* login modal */}
      {showLoginModalOverlay && (
        <>{showLoginModal && <div className="modal-overlay"></div>}</>
      )}

      <Modal
        show={showLoginModal}
        onHide={closeModel}
        className="login-Modal fade"
        backdrop={backdropVisible ? "static" : true}
        keyboard={false}
        centered
      >
        <div className="Fullmodal-container">
          {!backdropVisible && (
            <Modal.Header
              className="login-modal-header"
              closeButton
            ></Modal.Header>
          )}
          <div
            className="body-container"
            style={{
              width: "fit-content !important",
              display: "flex",
              alignItems: "center",
              flexDirection: "column",
              border: "none !important",
            }}
          >
            <form id="loginFormData">
              <Modal.Body>
                <Modal.Title className="login-modal-title text-center  Paddi  ">
                  Welcome back!
                </Modal.Title>
                <div className="buttons">
                  <Button
                    variant="outlined"
                    className="mb-3"
                    style={{ background: "#E8E8E8" }}
                    onClick={() => logingoogle()}
                  >
                    <img alt="Logo Academy" src={googleLogo} />
                    <span>Google</span>
                  </Button>
                </div>
                <div className="or-section">
                  <hr />
                  <p>or</p>
                  <hr />
                </div>
                <div className="or-section">
                  <span className="invalid_data"></span>
                </div>
                <div className="login_modal_inputs_wrapper">
                  <div className="email_image image_icon_position">
                    <label className="NameEE">Email Address</label>
                    <input
                      type="text"
                      name="useremail"
                      id="useremail"
                      className="trio_email trio_mandatory form-control input_field_custom"
                      maxLength={75}
                      minLength={3}
                      onInput={(e) => handleEmailChange(e)}
                      autoFocus
                      tabIndex={1} // Set tabindex for the email input
                    />
                    <span className="condition_error"></span>
                  </div>
                  <div>
                    {" "}
                    <label className="NameEE">Password</label>
                    <div
                      className="password_image image_icon_position input_eye_covering_div flexRowCenter"
                      style={{ display: "flex", flexDirection: "column" }}
                    >
                      <input
                        type={showPassword ? "text" : "password"}
                        name="userpassword"
                        id="userpassword"
                        maxLength={40}
                        minLength={3}
                        className="trio_password trio_mandatory form-control input_field_custom"
                        onInput={(e) => handlePasswordChange(e)}
                        tabIndex={2} // Set tabindex for the password input
                      />
                      <span
                        className="eye_icon"
                        onClick={() => setShowPassword(!showPassword)}
                        tabIndex={3} // Set tabindex for the eye icon
                      >
                        {showPassword ? (
                          <div className="eye_open">
                            <img src={hide_pass} alt="hidePass" id="showPass" />
                          </div>
                        ) : (
                          <div className="eye_close">
                            <img src={showPass} alt="hidePass" id="hidePass" />
                          </div>
                        )}
                      </span>
                      <span
                        className="condition_error condition_error2"
                        style={{
                          background: "#f5f5f5 ",
                          boxShadow: "none",
                          border: "none",
                        }}
                      ></span>
                    </div>
                  </div>
                </div>
              </Modal.Body>
              <Modal.Footer className="login-modal-footer">
                <Button
                  variant="primary"
                  className="login-button modal-button rounded-pill"
                  onClick={() =>
                    handleSaveChangesdynamic("loginFormData", user_login)
                  }
                >
                  Log in
                </Button>
                <div className="login-modal-links">
                  <span
                    onClick={() => {
                      closeModel(5);
                    }}
                  >
                    {" "}
                    New User?{" "}
                  </span>
                  <span
                    onClick={() => {
                      closeModel(4);
                    }}
                  >
                    {" "}
                    Reset Password{" "}
                  </span>
                </div>
                <p>
                  By signing in, you agree to our{" "}
                  <a
                    href="/termsofuse"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    Terms Of Use
                  </a>{" "}
                  and{" "}
                  <a
                    href="/privacyPolicy"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    Privacy Policy
                  </a>{" "}
                  In addition, you also agree to receive email messages from us
                  about your experience with our service, and features you can
                  use.
                </p>

                {/* Additional login action buttons */}
              </Modal.Footer>
            </form>
          </div>
        </div>
      </Modal>

      {/* Signup Modal */}

      {showLoginModalOverlay && (
        <> {showSignupModal && <div className="modal-overlay"></div>}</>
      )}

      <Modal
        show={showSignupModal}
        onHide={closeModel}
        centered
        className="login-Modal fade"
        size="md"
        backdrop={backdropVisible ? "static" : true}
        keyboard={false}
      >
        <div className="Fullmodal-container ">
          {!backdropVisible && (
            <Modal.Header
              className="login-modal-header"
              closeButton
            ></Modal.Header>
          )}
          <div
            className="body-container"
            style={{
              width: "fit-content !important",
              display: "flex",
              alignItems: "center",
              flexDirection: "column",
              border: "none !important",
            }}
          >
            <form id="registrationFormData">
              <Modal.Body>
                <Modal.Title className="login-modal-title text-center">
                  Create an Account
                </Modal.Title>
                <div className="buttons">
                  <Button
                    variant="outlined"
                    className="mb-3"
                    style={{ background: "#E8E8E8" }}
                    onClick={() => logingoogle()}
                  >
                    <img alt="Logo Academy" src={googleLogo} />
                    <span>Google</span>
                  </Button>
                </div>
                <div className="or-section">
                  <hr />
                  <p>or</p>
                  <hr />
                </div>
                <div className="or-section">
                  <span className="invalid_data"></span>
                </div>

                <div className="loginInputs all_signup_inputs_wrapper">
                  <div className="image_icon_position">
                    <label className="NameEE">Full Name</label>
                    <input
                      type="text"
                      name="full_name"
                      className="trio_mandatory form-control input_field_custom"
                      maxLength={75}
                      minLength={2}
                      autoFocus
                      tabIndex={1} // Set tabindex for the email input
                    />
                    <span className="condition_error"></span>
                  </div>
                  <div className="email_image image_icon_position">
                    <label className="NameEE">Email Address</label>
                    <input
                      type="text"
                      name="useremail"
                      className="trio_email trio_mandatory form-control input_field_custom"
                      maxLength={75}
                      minLength={3}
                      onInput={(e) => handleEmailChange(e)}
                      autoFocus
                      tabIndex={1} // Set tabindex for the email input
                    />
                    <span className="condition_error"></span>
                  </div>
                  <div className="signup_modal_password_input input_wrapper_signup_modal">
                    <label className="NameEE">Password</label>
                    <div
                      className="password_image image_icon_position wrap_input"
                      style={{ display: "flex", flexDirection: "column" }}
                    >
                      <input
                        type={showPassword ? "text" : "password"}
                        name="userpassword"
                        placeholder="Password"
                        maxLength={40}
                        minLength={3}
                        className="trio_password trio_mandatory form-control input_field_custom"
                        onInput={(e) => handlePasswordChange(e)}
                        tabIndex={2} // Set tabindex for the password input
                      />
                      <span
                        className="eye_icon"
                        onClick={() => setShowPassword(!showPassword)}
                        tabIndex={3} // Set tabindex for the eye icon
                      >
                        {showPassword ? (
                          <div className="eye_open">
                            <img src={hide_pass} alt="hidePass" id="showPass" />
                          </div>
                        ) : (
                          <div className="eye_close">
                            <img src={showPass} alt="hidePass" id="hidePass" />
                          </div>
                        )}
                      </span>
                      <span
                        className="condition_error condition_error2"
                        style={{
                          background: "#f5f5f5 ",
                          boxShadow: "none",
                          border: "none",
                        }}
                      ></span>
                    </div>
                  </div>
                </div>
              </Modal.Body>
              <Modal.Footer className="login-modal-footer">
                <Button
                  variant="primary"
                  className="login-button modal-button rounded-pill"
                  onClick={() => {
                    handleSaveChangesdynamic(
                      "registrationFormData",
                      user_registration
                    );
                  }}
                >
                  Sign Up
                </Button>

                <div className="login-modal-links">
                  <span
                    onClick={() => {
                      closeModel(3);
                    }}
                  >
                    Already Registered?
                  </span>
                </div>
                <p>
                  By signing in, you agree to our{" "}
                  <Link to="/">
                    <u>Terms Of Us</u>
                  </Link>
                  e and{" "}
                  <Link to="/">
                    <u>Privacy Policy</u>
                  </Link>{" "}
                  In addition, you also agree to receive email messages from us
                  about your experience with our service, and features you can
                  use.
                </p>
                {/* Additional login action buttons */}
              </Modal.Footer>
            </form>

            <form id="OTPFormData">
              <Modal.Body>
                <Modal.Title className="login-modal-title text-center">
                  Validate OTP
                </Modal.Title>
                <div className="description_validateOTP">
                  <p>
                    Enter the OTP you have received on your email to verify your
                    account.
                  </p>
                </div>
                <div className="or-section">
                  <span className="invalid_data2"></span>
                </div>
                <div className="loginInputs all_signup_inputs_wrapper gap-0">
                  <div className="otp_input verify_input mb-1">
                    {otp.map((digit, index) => (
                      <input
                        className="text-center"
                        key={index}
                        type="text"
                        maxLength="1"
                        value={digit}
                        onChange={(e) => handleChange(index, e.target.value)}
                        onKeyDown={(e) => handleBackspace(index, e)}
                        ref={(el) => (inputRefs.current[index] = el)}
                      />
                    ))}
                  </div>
                  <p
                    className="text-center text-danger"
                    style={{
                      fontSize: "14px",
                      marginBottom: error !== "" ? "0.5rem" : 0,
                    }}
                  >
                    {error}
                  </p>
                </div>
                <div className="resendTxtOtp">
                  <p>Didn't receive the OTP?</p>
                  <button
                    type="button"
                    className="resendOTPButton newOtpSendBtn"
                    onClick={() => {
                      handleSaveChangesdynamic(
                        "registrationFormData",
                        user_registration
                      );
                    }}
                  >
                    Resend OTP
                  </button>
                </div>
              </Modal.Body>
              <Modal.Footer className="login-modal-footer">
                <div className="d-flex align-items-center justify-content-between w-100 mt-3">
                  <div className="otp_input_btn">
                    <button
                      type="button"
                      className="editEmailbutton"
                      onClick={() => {
                        $("#registrationFormData").show();
                        $("#OTPFormData").hide();
                      }}
                    >
                      <img src={backArrow} alt="Back Arrow" /> Edit email
                    </button>
                  </div>
                  <Button
                    variant="primary"
                    className="login-button modal-button rounded-pill"
                    onClick={() => {
                      handleSIgnUp("registrationFormData", verifyuser);
                    }}
                  >
                    Verify
                  </Button>
                </div>
              </Modal.Footer>
            </form>
          </div>
        </div>
      </Modal>
      {/* forgot poassword modal  */}

      {showLoginModalOverlay && (
        <> {showResetPasswordModal && <div className="modal-overlay"></div>}</>
      )}

      <Modal
        show={showResetPasswordModal}
        onHide={closeModel}
        className="login-Modal fade"
        centered
        backdrop={backdropVisible ? "static" : true}
        keyboard={false}
      >
        <div className="Fullmodal-container">
          {!backdropVisible && (
            <Modal.Header
              className="login-modal-header"
              closeButton
            ></Modal.Header>
          )}
          <div
            className="body-container"
            style={{
              width: "fit-content !important",
              display: "flex",
              alignItems: "center",
              flexDirection: "column",
              border: "none !important",
            }}
          >
            <form id="forgotemailFormData">
              <Modal.Body>
                <Modal.Title className="login-modal-title text-center">
                  Forgot password ?
                </Modal.Title>
                <div className="buttons">
                  <Button
                    variant="outlined"
                    className="mb-3"
                    style={{ background: "#E8E8E8" }}
                    onClick={() => logingoogle()}
                  >
                    <img alt="Logo Academy" src={googleLogo} />
                    <span>Google</span>
                  </Button>
                </div>
                <div className="or-section">
                  <hr />
                  <p>or</p>
                  <hr />
                </div>

                <Form.Group
                  controlId="formBasicEmail"
                  className="email-section"
                >
                  <label className="NameEE">Email Address</label>
                  <Form.Control
                    type="text"
                    name="useremail"
                    className="trio_email trio_mandatory form-control input_field_custom"
                    maxLength={75}
                    minLength={3}
                    onInput={(e) => handleEmailChange(e)}
                    autoFocus
                    tabIndex={1}
                  />
                  <span className="condition_error"></span>
                </Form.Group>
                <div
                  className="py-2"
                  style={{
                    display: "flex",
                    justifyContent: "center",
                    width: "100%",
                    color: "#4bb06e",
                  }}
                >
                  {" "}
                  <span className="server_error"></span>
                </div>
              </Modal.Body>
              <Modal.Footer className="login-modal-footer">
                <Button
                  variant="primary"
                  className="login-button modal-button rounded-pill"
                  onClick={() =>
                    handleSaveChangesdynamic("forgotemailFormData", forgotemail)
                  }
                >
                  Send OTP
                </Button>
                <div className="login-modal-links">
                  <span
                    onClick={() => {
                      closeModel(2);
                    }}
                  >
                    {" "}
                    New User?{" "}
                  </span>
                </div>
                <p>
                  By signing in, you agree to our{" "}
                  <Link>
                    <u>Terms Of Us</u>
                  </Link>
                  e and{" "}
                  <Link>
                    <u>Privacy Policy</u>
                  </Link>{" "}
                  In addition, you also agree to receive email messages from us
                  about your experience with our service, and features you can
                  use.
                </p>
                {/* Additional login action buttons */}
              </Modal.Footer>
            </form>
          </div>
        </div>
      </Modal>
      {/* send otp modal */}
      {showLoginModalOverlay && (
        <> {showOTPModal && <div className="modal-overlay"></div>}</>
      )}

      <Modal
        show={showOTPModal}
        onHide={closeModel}
        className="fade"
        centered
        backdrop={backdropVisible ? "static" : true}
        keyboard={false}
      >
        <div className="Fullmodal-container">
          {!backdropVisible && (
            <Modal.Header
              className="login-modal-header"
              closeButton
            ></Modal.Header>
          )}
          <div
            className="body-container"
            style={{
              width: "fit-content !important",
              display: "flex",
              alignItems: "center",
              flexDirection: "column",
              border: "none !important",
            }}
          >
            <Modal.Body>
              <Modal.Title className="login-modal-title text-center">
                Validate OTP
              </Modal.Title>
              <div className="description_validateOTP">
                <p>
                  Enter the OTP you have received on your email to verify your
                  account.
                </p>
              </div>
              <div className="or-section">
                <span className="invalid_data2"></span>
              </div>
              <Form>
                <div className="otp_input verify_input">
                  {otp.map((digit, index) => (
                    <input
                      className="text-center"
                      key={index}
                      type="text"
                      maxLength="1"
                      value={digit}
                      onChange={(e) => handleChange(index, e.target.value)}
                      onKeyDown={(e) => handleBackspace(index, e)}
                      ref={(el) => (inputRefs.current[index] = el)}
                    />
                  ))}
                </div>
                <div className="d-flex align-items-center justify-content-between mt-3">
                  <div className="otp_input_btn">
                    <button
                      type="button"
                      className="editEmailbutton"
                      onClick={() => {
                        closeModel(1);
                      }}
                    >
                      <img src={backArrow} alt="Back Arrow" /> Edit email
                    </button>
                    <button
                      type="button"
                      className="resendOTPButton"
                      onClick={() =>
                        handleSaveChangesdynamic(null, forgotemail)
                      }
                    >
                      Resend OTP
                    </button>
                  </div>
                  <Button
                    variant="primary"
                    className="login-button modal-button rounded-pill"
                    onClick={() => {
                      // Add verification logic here
                      handleConfirmNewPasswordModal();
                    }}
                  >
                    Verify
                  </Button>
                </div>
              </Form>
            </Modal.Body>
          </div>
        </div>
      </Modal>
      {/*reset to new password modal*/}
      {showLoginModalOverlay && (
        <> {showNewPasswordModal && <div className="modal-overlay"></div>}</>
      )}

      <Modal
        show={showNewPasswordModal}
        onHide={closeModel}
        className="fade"
        centered
        backdrop={backdropVisible ? "static" : true}
        keyboard={false}
      >
        <div className="Fullmodal-container">
          {!backdropVisible && (
            <Modal.Header
              className="login-modal-header"
              closeButton
            ></Modal.Header>
          )}

          <div
            className="body-container"
            style={{
              width: "fit-content !important",
              display: "flex",
              alignItems: "center",
              flexDirection: "column",
              border: "none !important",
            }}
          >
            <Modal.Body>
              <Modal.Title className="login-modal-title text-center">
                Create new password
              </Modal.Title>
              <Form id="ChangePassword">
                <div className="new_password_container">
                  <div
                    className="new_password_div password_image image_icon_position"
                    style={{ display: "flex", flexDirection: "column" }}
                  >
                    <label>Enter new password</label>
                    <input
                      type={showPassword ? "text" : "password"}
                      name="new_password"
                      maxLength={40}
                      minLength={3}
                      className="trio_password trio_mandatory form-control input_field_custom new_password"
                      onInput={(e) => handlePasswordChange(e)}
                      onPaste={(e) => e.preventDefault()}
                      onCopy={(e) => e.preventDefault()}
                      onCut={(e) => e.preventDefault()}
                      onDrag={(e) => e.preventDefault()}
                      onDrop={(e) => e.preventDefault()}
                    />
                    <span
                      className="eye_icon"
                      onClick={() => setShowPassword(!showPassword)}
                    >
                      {showPassword ? (
                        <div className="eye_open"></div>
                      ) : (
                        <div className="eye_close"></div>
                      )}
                    </span>
                    <span className="condition_error"></span>
                  </div>
                </div>
                <div
                  className="new_password_div password_image image_icon_position"
                  style={{ display: "flex", flexDirection: "column" }}
                >
                  <label>Confirm new password</label>
                  <input
                    type={showPassword1 ? "text" : "password"}
                    name="confirm_new_password"
                    maxLength={40}
                    minLength={3}
                    className="trio_password trio_mandatory form-control input_field_custom confirm_new_password"
                    onInput={(e) => handlePasswordChange(e)}
                    onPaste={(e) => e.preventDefault()}
                    onCopy={(e) => e.preventDefault()}
                    onCut={(e) => e.preventDefault()}
                    onDrag={(e) => e.preventDefault()}
                    onDrop={(e) => e.preventDefault()}
                  />
                  <span
                    className="eye_icon"
                    onClick={() => setShowPassword1(!showPassword1)}
                  >
                    {showPassword1 ? (
                      <div className="eye_open"></div>
                    ) : (
                      <div className="eye_close"></div>
                    )}
                  </span>
                  <span className="condition_error"></span>
                </div>
                <p className="passwordValidation">
                  <span className="errorMsg">*</span>
                  {changePasswardPage.Error_massage}
                </p>
                <div className="newpasss_input_btn">
                  <Button
                    variant="primary"
                    className="login-button modal-button rounded-pill"
                    type="button"
                    onClick={() =>
                      handleSaveChangesdynamic(
                        "ChangePassword",
                        change_password_by_forgot
                      )
                    }
                  >
                    Change password
                  </Button>
                </div>
              </Form>
            </Modal.Body>
          </div>
        </div>
      </Modal>
      <div id="login_check_jquery"></div>
      <div id="login_check_jquery_hide_data"></div>
    </>
  );
}
export default Header;

//dropdown-menu-end
