import React, { useState, useEffect, useRef } from "react";
import "./Css/Home.css";
import { Link } from "react-router-dom";
import Header from "./Header";
import { storeData, retrieveData } from "../LocalConnection/LocalConnection.js";
import { Modal } from "react-bootstrap";
import { useLocation, useNavigate } from "react-router-dom";
import { useNavigationWithHistory } from "./NavigationProvider.js";
let sesionclr = 0;
let lastElement;
const EnterCompanyName = () => {
  const { navigationStack, goBack, currentPath } = useNavigationWithHistory();

  lastElement = navigationStack[navigationStack.length - 1];
  //console.log(lastElement);

  const [slogan, setSlogan] = useState("");
  const [companyName, setCompanyName] = useState("");
  const [sloganName, setSloganName] = useState("");
  const [errorMessage, setErrorMessage] = useState("");
  const [showModalReload, setshowModalReload] = useState(false);
  const location = useLocation();
  const navigate = useNavigate();

  const handleInputChange = (setter) => (e) => {
    setter(e.target.value);
    if (errorMessage) {
      setErrorMessage("");
    }
  };

  const handleReload = () => {
    window.location.reload();
  };

  window.addEventListener("beforeunload", function (event) {
    if (window.location.pathname === "/createlogohome") {
      sessionStorage.setItem("reloadAttempt", "true");
      event.returnValue = "Are you sure you want to leave?";
    }
  });
  // On page load
  window.addEventListener("load", function () {
    if (window.location.pathname === "/createlogohome") {
      const reloadAttempt = sessionStorage.getItem("reloadAttempt");
      const navEntries = performance.getEntriesByType("navigation");
      if (
        navEntries.length > 0 &&
        window.location.pathname === "/createlogohome"
      ) {
        const navType = navEntries[0].type;

        if (reloadAttempt || navType === "reload") {
          console.log(
            "The page was reloaded (Ctrl+R, refresh button, or Enter in the URL bar)."
          );
          // Clear sessionStorage flag after detecting the reload
          sessionStorage.removeItem("reloadAttempt");
          storeData("companyName", "");
          storeData("card_colors", "");
          storeData("selectedIcons", "");
          storeData("slogan", "");
          storeData("related_industry", null);
          storeData("industry", null);
          storeData("selectedIndustry", "");
          sesionclr = 0;
        }
      }
    }
  });

  if (lastElement == "/pickIndustry") {
    sesionclr = 1;
  } else {
    //console.log('else work');
    storeData("companyName", "");
    // storeData("slogan", "");
    // storeData("related_industry", null);
    // storeData("industry", null);
    // storeData("selectedIndustry", '');
    sesionclr = 0;
    console.log(lastElement);
  }

  useEffect(() => {
    console.log(sesionclr);
    if (sesionclr == 1) {
      let storedCompanyName = retrieveData("companyName");
      if (storedCompanyName) {
        // Remove surrounding quotes if they exist
        if (
          storedCompanyName.startsWith('"') &&
          storedCompanyName.endsWith('"')
        ) {
          storedCompanyName = storedCompanyName.slice(1, -1);
        }
        setCompanyName(storedCompanyName);
      }
      let storedSloganName = retrieveData("slogan");
      if (storedSloganName) {
        // Remove surrounding quotes if they exist
        if (
          storedSloganName.startsWith('"') &&
          storedSloganName.endsWith('"')
        ) {
          storedSloganName = storedSloganName.slice(1, -1);
        }
        setSlogan(storedSloganName);
      }
    } else {
      sesionclr = 1;
    }
  }, [companyName, slogan]);

  const handleCancelUnclick = () => {
    setshowModalReload(false);
  };

  const handleContinueClick = () => {
    if (!companyName && !slogan) {
      setErrorMessage("Please fill in the Business Name");
    } else {
      setErrorMessage("");
      storeData("companyName", companyName);
      storeData("slogan", slogan);
      document.getElementById("continue_link").click();
    }
  };
  return (
    <>
      <Header />
      <section
        className="hero"
        style={{ backgroundColor: "var(--light-grey-background)" }}
      >
        <div className="container-lg h-100 w-100">
          <div className="hero_section_container h-100">
            <div className="row m-0 h-100">
              <div className="col-lg-10 col-md-11 col-sm-11 h-100 centerElement m-auto">
                <div className="hero_left">
                  <div className="col-11 m-auto">
                    <div className="hero_Left_heading">
                      <h1> Design Professional Logo For Free </h1>
                    </div>
                    <div className="hero_left_subHead">
                      <p>
                        Craft a Stunning High-Quality AI logo for your social
                        media, website and print.
                        <span>Try it for free!</span>
                      </p>
                    </div>
                  </div>
                  <form>
                    <div className="inputs_container">
                      <input
                        type="text"
                        name="BusinessName"
                        value={companyName}
                        onChange={handleInputChange(setCompanyName)}
                        placeholder="Enter your Business Name"
                        required
                      />
                    </div>
                    <div className="inputs_container2">
                      <input
                        type="text"
                        name="slogan"
                        defaultValue={slogan}
                        onChange={handleInputChange(setSlogan)}
                        placeholder="Slogan (optional)"
                      />
                    </div>
                    {errorMessage && (
                      <span className="error_message">{errorMessage}</span>
                    )}
                    <Link
                      to="/pickIndustry"
                      id="continue_link"
                      style={{ display: "none" }}
                    />

                    <button
                      id="continue_button"
                      className="continue_btn"
                      type="button"
                      onClick={handleContinueClick}
                      style={{ opacity: companyName || slogan ? 1 : 0.7 }}
                    >
                      Continue
                    </button>
                  </form>
                  <div className="caption_container">
                    <p>
                      More than hundreds of companies around the world use logos
                      created by LogoAcademy{" "}
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      <Modal
        show={showModalReload} // Correct state used here
        onHide={handleCancelUnclick}
        centered
        className="LiketemplateModal"
      >
        <Modal.Body>
          <h3>Reload Site?</h3>
          <p>Changes you made may not be saved.</p>
        </Modal.Body>
        <Modal.Footer>
          <div className="w-100 ModalCencellDltButton">
            <button onClick={handleCancelUnclick}>Cancel</button>
            <button className="deleteFav" onClick={handleReload}>
              Reload
            </button>
          </div>
        </Modal.Footer>
      </Modal>
    </>
  );
};

export default EnterCompanyName;
