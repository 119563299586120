import React, { useState, useEffect } from "react";
import Header from "./Header.js";
import "../Components/Css/LogoIdeas.css";
import searchIcon from "../Assets/Images/searchIcon.svg";
import { Link } from "react-router-dom";
import Footer from "./Footer";
import Loader from "./Loader.js";
import {
  server_post_data,
  get_all_industry_data,
} from "../ServiceConnection/serviceconnection.js";
import { handleError } from "../CommonJquery/CommonJquery";

const LogoIdeas = () => {
  useEffect(() => {
    master_data_get("0", "0");
  }, []);
  const [searchTerm, setSearchTerm] = useState("");
  const [templateData, setTemplateData] = useState([]);
  const [FiltertemplateData, setFiltertemplateData] = useState([]);
  const [showLoaderAdmin, setshowLoaderAdmin] = useState(false);
  const master_data_get = async (flag, call_id) => {
    const fd = new FormData();
    setshowLoaderAdmin(true);
    fd.append("flag", flag);
    fd.append("call_id", call_id);

    await server_post_data(get_all_industry_data, fd)
      .then((Response) => {
        if (Response.data.error) {
          handleError(Response.data.message);
        } else {
          setTemplateData(Response.data.message.data);
          setFiltertemplateData(Response.data.message.data);
        }
        setshowLoaderAdmin(false);
      })
      .catch((error) => {
        console.error(error);
        setshowLoaderAdmin(false);
      });
  };

  const handleSearch = (event) => {
    const searchValue = event.target.value;
    setSearchTerm(searchValue);

    // Filter table data based on search value
    const filteredDatashow = templateData.filter((row) => {
      return Object.values(row).some((value) => {
        if (value !== null && value !== undefined) {
          // Add null check here
          return (
            typeof value === "string"
              ? value.toLowerCase()
              : value.toString().toLowerCase()
          ).includes(searchValue.toLowerCase());
        }
        return false;
      });
    });

    setFiltertemplateData(filteredDatashow);
  };

  // const splitIntoColumns = (arr, numColumns) => {
  //   const result = Array.from({ length: numColumns }, () => []);
  //   arr.forEach((item, index) => {
  //     result[index % numColumns].push(item);
  //   });
  //   return result;
  // };
  // const sortedData = [...FiltertemplateData].sort((a, b) =>
  //   a.industry_name.localeCompare(b.industry_name)
  // );

  // // Split the sorted data into 3 columns
  // const columns = splitIntoColumns(sortedData, 4);
  

  const splitIntoRows = (arr, numRows) => {
    const result = [];
    const itemsPerRow = Math.ceil(arr.length / numRows); // Calculate how many items per row
    for (let i = 0; i < numRows; i++) {
      result.push(arr.slice(i * itemsPerRow, (i + 1) * itemsPerRow));
    }
    return result;
  };
  
  const sortedData = [...FiltertemplateData].sort((a, b) =>
    a.industry_name.localeCompare(b.industry_name)
  );
  
  // Split the sorted data into 4 rows
  const rows = splitIntoRows(sortedData, 4);
  
  return (
    <>
      <div className="wrapper" style={{ overflow: "hidden" }}>
        <Header />
        {showLoaderAdmin && <Loader />}
        <div className="container">
          <div className="heading_wrapper">
            <h2>Logo ideas</h2>
            <h6>
              <strong> Need logo design inspiration? </strong>
              <br className="showStyle d-none" />
              <span style={{ fontWeight: "400" }}>
                Get 100s of cool logo ideas.
              </span>{" "}
            </h6>
            <p className="descriptionText">
              Refine your logo creation process by beginning with inspiration.
              Explore our extensive collection of over 3,200+ existing logo
              designs spanning across more than 150 popular industries. Each
              industry section is accompanied by tailored design tips to guide
              your creative journey. <br /> Eager to craft a unique logo for
              your venture? Utilize our user-friendly online logo maker to
              generate countless customized logo concepts in mere minutes. Let
              your imagination soar as you embark on the exciting journey of
              branding your business!
            </p>
            <Link to="/createlogohome">
              <button>Create Logo</button>
            </Link>
          </div>
          <div className="search_section">
            <h3>Logo ideas for every industry</h3>
            <div>
              <span className="col-lg-4 flexRowCenter">
                <img src={searchIcon} alt="searchicon" />
                <input type="text" value={searchTerm} onChange={handleSearch} />
              </span>
              <div className="multi-column-list row">
                {rows.map((column, colIndex) => (
                  <div className=" col-lg-3 col-sm-4 col-6" key={colIndex}>
                    <ul>
                      {column.map((item) => (
                        <li key={item.primary_id}>
                          <Link to={`/logoideas/${item.primary_id}`}>
                            {item.industry_name}
                          </Link>
                        </li>
                      ))}
                    </ul>
                  </div>
                ))}
              </div>
            </div>
          </div>
        </div>
        <section
          className="contactUs_section"
          style={{ background: "#6473fd" }}
        >
          <div className="container col-md-10">
            <div className="contactUs_container" style={{ color: "white" }}>
              <h3>We’re here to help build your brand</h3>
              <h6>
                Logo Academy fun and friendly in-house customer success team is
                available via live chat and email to answer all your questions.
                Get help accessing files, tweaking your logo designs, and more!
              </h6>
              <Link to="/support">
                <button className="rounded-pill" style={{ fontWeight: "500" }}>
                  Contact Us
                </button>
              </Link>
            </div>
          </div>
        </section>
        <Footer />
      </div>
    </>
  );
};

export default LogoIdeas;
