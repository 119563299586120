import React, { useState, useEffect } from "react";
import { Link, useNavigate } from "react-router-dom";
import tick from "../Assets/Images/tick_img.svg";
import Header from "./Header";
import {
  retrieveData2,
  storeData2,
  storeData
} from "../LocalConnection/LocalConnection.js";
import { cardDetails } from "../CommonJquery/WebsiteText.js";
import ContinueButtonLoader from "./ContinueButtonLoader.js";
let selectedCardCount;
let sesionclr = 0;
const Recommendation = () => {
  const [selectedCards, setSelectedCards] = useState(Array(9).fill(false));
  const [isLoading, setIsLoading] = useState(false);
  const navigate = useNavigate();

  useEffect(() => {
    const storedCardColors = retrieveData2("card_colors");
    console.log(storedCardColors);
    if (storedCardColors && Array.isArray(storedCardColors)) {
      const updatedSelectedCards = Array(9).fill(false);
      storedCardColors.forEach((color) => {
        const index = cardDetails.findIndex((card) => card.title === color);
        if (index !== -1) {
          updatedSelectedCards[index] = true;
        }
      });
      setSelectedCards(updatedSelectedCards);
      
      //setSelectedCardCount(storedCardColors.length);
      const filledCardColors = storedCardColors.filter(color => color.trim() !== "");
      selectedCardCount = filledCardColors.length;
    }else{
      selectedCardCount=0
    }
  }, []);


  window.addEventListener("beforeunload", function (event) {
    if (window.location.pathname === "/recommendation") {
      sessionStorage.setItem("reloadAttempt", "true");
      event.returnValue = "Are you sure you want to leave?";
    }
  });

  // On page load
  window.addEventListener("load", function () {
    if (window.location.pathname === "/recommendation") {
      const reloadAttempt = sessionStorage.getItem("reloa  dAttempt");
      const navEntries = performance.getEntriesByType("navigation");
      if (
        navEntries.length > 0 &&
        window.location.pathname === "/recommendation"
      ) {
        const navType = navEntries[0].type;

        if (reloadAttempt || navType === "reload") {
          console.log(
            "The page was reloaded (Ctrl+R, refresh button, or Enter in the URL bar)."
          );
          // Clear sessionStorage flag after detecting the reload
          sessionStorage.removeItem("reloadAttempt");
          storeData("companyName", "");
          storeData("card_colors", "");
          storeData("selectedIcons", "");
          storeData("slogan", "");
          storeData("related_industry", null);
          storeData("industry", null);
          storeData("selectedIndustry", "");
          sesionclr = 0;
          navigate("/createlogohome");
        }
      }
    }
  });

  const handleCardClick = (index) => {
    console.log(selectedCardCount);
    if (selectedCardCount === 3 && !selectedCards[index]) {
      return;
    }
    // const storedCardColors = retrieveData2("card_colors");
    // const filledCardColors = storedCardColors.filter(color => color.trim() !== "");
    // selectedCardCount = filledCardColors.length;
    const updatedSelectedCards = [...selectedCards];
    updatedSelectedCards[index] = !updatedSelectedCards[index];
    setSelectedCards(updatedSelectedCards);
    
    const newSelectedCardCount = updatedSelectedCards.filter(
      (isSelected) => isSelected
    ).length;

    selectedCardCount = newSelectedCardCount;

    const selectedColors = updatedSelectedCards.reduce(
      (acc, isSelected, idx) => {
        if (isSelected) {
          acc.push(cardDetails[idx].title);
        }
        return acc;
      },
      []
    );
//console.log(selectedColors);
    storeData2("card_colors", selectedColors);
  };

  

  const scrollToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  };

  const handleClick = () => {
    setIsLoading(true);
    setTimeout(() => {
      setIsLoading(false);
      navigate("/chooseIcons");
    }, 400);
    scrollToTop();
  };


  
  return (
    <>
      <Header Showheader={false} />
      <section className="pickIndustry_section pickIndustry_sectionColor">
        <div className="container-md h-100" style={{ overflowY: "scroll" }}>
          <div className="row m-0 h-100">
            <div className="col-xl-8 col-lg-11 mx-auto">
              <div className="heading_section marginTop768">
                <h3>Recommended logo colors</h3>
                <h6>Colors help convey emotion in your logo</h6>
                <h5>You can choose up to 3 color palettes</h5>
              </div>
              <section className="recommendation_section">
                <div className="mt-2">
                  <div className="row marginBottom768">
                    {cardDetails.map((card, index) => (
                      <div
                        className="col-lg-4 col-md-4 col-sm-4 col-6 px-2 mb-3"
                        key={index}
                      >
                        <div
                          className={`card ${
                            selectedCards[index] ? "outlined" : ""
                          } position-relative`}
                          onClick={() => handleCardClick(index)}
                          style={{
                            opacity: selectedCards[index]
                              ? 1
                              : selectedCardCount === 3
                              ? 0.5
                              : 1,
                          }}
                        >
                          <div
                            className={`card-body card${index + 1}`}
                            style={{
                              cursor:
                                selectedCardCount === 3 && !selectedCards[index]
                                  ? "not-allowed"
                                  : "pointer",
                            }}
                          >
                            <span className="d-flex justify-content-between align-items-center">
                              <h6 className="card-title">{card.title}</h6>
                              {/* {selectedCards[index] && (
                                <img src={tick} className="" alt="Tick" />
                              )} */}
                            </span>
                            <p className="card-text">{card.description}</p>
                          </div>
                        </div>
                      </div>
                    ))}
                  </div>
                </div>
              </section>
            </div>
          </div>
        </div>
      </section>
      <div className="navbar_bottom">
        <button
          className={` px-3 ${
            selectedCardCount >= 1 ? "continue_btn2" : "skip_btn"
          }`}
          onClick={handleClick}
          disabled={isLoading}
        >
          {isLoading ? (
            <div className="loader">
              <ContinueButtonLoader />
            </div>
          ) : (
            <p>{selectedCardCount >= 1 ? "Continue" : "Skip This Step"}</p>
          )}
        </button>
      </div>
    </>
  );
};

export default Recommendation;
