import React from "react";
import ReactDOM from "react-dom/client";
import { GoogleOAuthProvider } from "@react-oauth/google";
import App from "./App";
const root = ReactDOM.createRoot(document.getElementById("root"));
const client_id_google =
  "374525263013-58st94pp4ggmj9kdg1a50ctasf8agamf.apps.googleusercontent.com";
root.render(
  <GoogleOAuthProvider clientId={client_id_google}>
    <App />
  </GoogleOAuthProvider>
);
