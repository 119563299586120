import { useState } from "react";
import "./Css/SubscriptionModal.css";
import { Modal } from "react-bootstrap";
import starICN from "../Assets/Images/starICN.svg";
import tempUpdate from "../Assets/Images/tempUpdate.jpg";
import crossBtn from "../Assets/Images/crossBtn.svg";
import { useNavigate } from "react-router-dom";
const SubscriptionModal = ({ updateModal, handleModal }) => {
  const navigate = useNavigate();
  const handleNavigate = () => {
    navigate("/pricing");
  };
  return (
    <div>
      {/*----UpgradeModal*/}

      <Modal
        className="UpgradeModal"
        show={updateModal}
        onHide={() => handleModal(false)}
        centered
      >
        <Modal.Body>
          <div className="container p-0 m-0" style={{ position: "relative" }}>
            <button className="closeModal" onClick={() => handleModal(false)}>
              <img src={crossBtn} alt="cross"></img>
            </button>
            <div className="row p-0 m-0  MobileResponsiveModa">
              {/* Left side - Heading and content */}
              <div className="col-12 col-md-6 leftContent">
                <h3>Everything you need is available on Business plan</h3>
                <div className="leftContainer">
                  <div className="d-flex align-items-start ">
                    <img src={starICN} alt="starICN"></img>
                    <p>
                      High quality logo images with transparent background and
                      vector files
                    </p>
                  </div>
                  <div className="d-flex align-items-start">
                    <img src={starICN} alt="starICN"></img>
                    <p>
                      High quality logo images with transparent background and
                      vector files
                    </p>
                  </div>
                  <div className="d-flex align-items-start ">
                    <img src={starICN} alt="starICN"></img>
                    <p>
                      High quality logo images with transparent background and
                      vector files
                    </p>
                  </div>
                  <div className="d-flex align-items-start ">
                    <img src={starICN} alt="starICN"></img>
                    <p>
                      High quality logo images with transparent background and
                      vector files
                    </p>
                  </div>
                </div>
                <div className="upgradeBtn">
                  <button
                    type="button"
                    className="UpModalBtn"
                    onClick={handleNavigate}
                  >
                    Upgrade
                  </button>
                </div>
              </div>

              {/* Right side - Image */}
              <div className="col-12 col-md-6 rightSideImg">
                <img src={tempUpdate} alt="Descriptive text" />
              </div>
            </div>
          </div>
        </Modal.Body>
      </Modal>
    </div>
  );
};

export default SubscriptionModal;
